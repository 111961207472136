import {useEffect, useState} from "react";
import ApprovalPrintLogData from "../data/ApprovalPrintLogData";
import SplitButton, {SplitButtonData} from "../../../common/component/SplitButton";
import React from "react";
import TableListControl, {
    GetItemStyle,
    TableItemHeader,
    TableItemType,
    TableListData
} from "common/control/TableListControl";
import {PAGE_MAX, printError} from "Define";
import Modal from "react-modal";
import {useNavigate} from "react-router-dom";
import {LIST_COUNT} from "../Define";

type DocumentPrintButtonProps = {
    documentID: string,
    onPrint: () => Promise<void>,
}

const DocumentPrintButton = ({documentID, onPrint}: DocumentPrintButtonProps) =>
{
    const printLogModalOpenState = useState(false);

    const buttonPrint: SplitButtonData = {
        text: "인쇄",
        onClick: onPrint,
    }

    const buttonPrintSub: SplitButtonData[] = [
        {
            text: "인쇄이력",
            onClick: () => printLogModalOpenState[1](true),
        }
    ]

    return (
        <>
            <SplitButton button={buttonPrint} subButtons={buttonPrintSub} />
            <ButtonPrintLogModal documentID={documentID} open={printLogModalOpenState} />
        </>
    )
}

export default DocumentPrintButton;

type ButtonPrintLogModalProps = {
    documentID: string,
    open: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}
const ButtonPrintLogModal = ({documentID, open}: ButtonPrintLogModalProps) =>
{
    const modalStyle = {
        content: {
            width:"1000px",
            margin:"0 auto",
            background: "var(--tblr-bg-surface)",
            border:0,
            padding:0,
            inset:`${window.innerWidth <= 577? "20px":"40px"}`,
            height:"fit-content"
        },
        overlay: {
            background: "rgba(0,0,0,0.5)",
            zIndex:"100"
        }
    }

    const headerTable: TableItemHeader<ApprovalPrintLogData>[] = [
        {name: "이름", key: "PrintName", styleItem: GetItemStyle(20, false)},
        {name: "부서", key: "PrintDeptName", styleItem: GetItemStyle(20, false)},
        {name: "직급", key: "PrintPositionName1", styleItem: GetItemStyle(20, false)},
        {name: "직책", key: "PrintPositionName2", styleItem: GetItemStyle(20, false)},
        {name: "인쇄일", key: "PrintTime", styleItem: GetItemStyle(20, false), type: TableItemType.DateTime},
    ];

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = open;
    //const [total, setTotal] = useState<number | null>(null);

    async function onRefreshPrintList(offset: number, count: number): Promise<TableListData<ApprovalPrintLogData>>
    {
        count = count < 1 ? LIST_COUNT : count;
        let data: ApprovalPrintLogData[] = [];
        let total = 0;
        try
        {
            /*
            if(total == null)
            {
                const _total = await ApprovalPrintLogData.GetCountAsync(documentID);
                if(_total == null) return { total: 0, data: [] };
                else setTotal(_total);
            }
             */

            total = await ApprovalPrintLogData.GetCountAsync(documentID) ?? 0;
            data = await ApprovalPrintLogData.GetListAsync(documentID, offset, count) ?? [];
        }
        catch (e) { printError(e, "인쇄 이력 가져오는 중 오류발생!!"); }
        return { total: total ?? 0, data: data };
    }

    return (
        <Modal isOpen={isOpen} style={modalStyle} ariaHideApp={false}>
            <div className="card">
                <div className="card-header">
                    인쇄 이력
                </div>
                <div className="card-body">
                    <TableListControl<ApprovalPrintLogData> total={0}
                        autoRefresh={true} headerTable={headerTable} navigate={navigate} maxPage={PAGE_MAX} onRefresh={onRefreshPrintList}/>
                </div>

                <div className="card-footer text-end">
                    <button type="button" className="btn" onClick={() => setIsOpen(false)}>닫기</button>
                </div>
            </div>
        </Modal>
    )
}