import React, {useEffect, useRef, useState} from "react";
import {IconBell, IconX} from "@tabler/icons-react";
//import dummy from "../data/Menu.json";
import {randomKey} from "@hskernel/hs-utils";
import AlertData from "data/API/Alert/AlertData";
import {AddAlertCallback, RemoveAlertCallback} from "Alert";
import LoadingCircle from "../common/control/LoadingCircle";
import {Link} from "react-router-dom";
import {URL_FRONT_USER_ALERT} from "../Define";
import AlertViewDialog from "./AlertViewDialog";


interface NotificationData<T> {
    title: string;
    icon?: string;
    body?: string;
    data?: T;
    timeout?: number;
    onClick?: (data?: T) => void,
    isPushMode?: boolean
}

/**
 * 브라우저로 알림 보내기
 */
class AlertNotification<T>
{
    private data: NotificationData<T>;
    private noti: Notification;
    private timeout = 5000;

    //private grantPermission = false;

    constructor(data: NotificationData<T>)
    {
        this.data = data;
        const timeout = this.timeout = data.timeout ?? this.timeout;

        this.onDispose = this.onDispose.bind(this);
        this.onClick = this.onClick.bind(this);

        if(window.Notification != null)
        {
            window.Notification.requestPermission((result) => {
                if(result === 'granted')
                {
                    const options: NotificationOptions = {
                        body: this.data.body ?? "",
                        icon: this.data.icon,
                        data: this.data.data,
                        //timeout: this.data.timeout ?? DEFAULT_TIMEOUT,
                        lang: "ko-KR"
                    };
                    if(data.isPushMode)
                    {
                        navigator.serviceWorker.ready.then((registration) => {
                            registration.showNotification(this.data.title, options).then();
                        });
                    }
                    else
                    {
                        this.noti = new window.Notification(this.data.title, options);
                        this.noti.onshow = () => window.setTimeout(() => this.noti.close(), timeout);
                        this.noti.onclose = this.onDispose;
                        this.noti.onclick = this.onClick;
                    }
                }
            });
        }
    }

    onDispose()
    {
        //
    }
    onClick(data?: any)
    {
        if (data.onClick != null) data.onClick!(this.data);
    }

    public static fromAlertData = (alert: AlertData, isPushMode?: boolean) => new AlertNotification<AlertData>({
        title: alert.Title ?? "",
        body: alert.Message,
        data: alert,
        isPushMode: isPushMode,
        onClick: (data) => console.log(data),
    });
}

/**
 * 안 읽은 알림 목록 UI
 * @returns {React.JSX.Element}
 * @constructor
 */
const AlertsUI = () =>
{
    const [count, setCount] = useState(-1);
    const [list, setList] = useState<AlertData[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [isNew, setIsNew] = useState(false);

    const viewDialog = useRef<AlertViewDialog | null>(null);

    useEffect(() =>
    {
        //글로벌 알림 이벤트 수신
        AddAlertCallback(onAlert);
        onRefresh().then();
        //컴포넌트가 제거될때
        return () =>
        {
            RemoveAlertCallback(onAlert)
        }
        //addCallback((alert) => console.log(alert));
    }, []);

    const onAlert = (alert: AlertData | null) =>
    {
        if (alert != null)
        {
            setIsNew(true);
            //AlertNotification.fromAlertData(alert, true);
        }

        onRefresh().then();
    }

    const onRefresh = async () =>
    {
        setLoading(true);
        try
        {
            setCount(await AlertData.GetCountAsync(false)); //false
            setList(await AlertData.GetListAsync(false, 0, 10));
        }
        catch (e) { console.log("알림 불러오는 중 오류발생!!"); console.log(e); }
        finally { setLoading(false); }
    }

    const onClick = async (data: AlertData, index: number) =>
    {
        if (list != null && viewDialog.current != null)
        {
            await viewDialog.current!.setAlertAsync(data);
            setList(list.filter(item => item.ID !== data.ID));
        }
    }
    const onDelete = async (data: AlertData, index: number) =>
    {
        if (list != null && await AlertData.DeleteAsync([data.ID]))
        {
            setList(list.filter(item => item.ID !== data.ID));
        }
    }

    const getItemUI = () =>
    {
        //console.log(list)
        if(list == null) return <div className="list-group-item"><div className="row align-items-center text-muted">오류가 발생하였습니다</div></div>;
        else if(list.length == 0) return <div className="list-group-item"><div className="row align-items-center text-muted">알림을 모두 확인했습니다.</div></div>;
        else return list.map((alert, i) =>
            <NewAlarm<AlertData> key={randomKey(i)} index={i} data={alert} isModal={false} showIcon={false} title={alert.Title ?? ''} contents={alert.Message} category={alert.Category ?? ""}
                                 onClick={(data, index) => onClick(data, index).then()}
                                 onDelete={(data, index) => onDelete(data, index).then()}/>
        )
    }
    return (
        <>
        <AlertViewDialog ref={viewDialog} />
        <div className="dropdown d-md-flex me-3">
            <a className="nav-link px-0" data-bs-toggle="dropdown" aria-label="Show notifications" style={{cursor: "pointer"}} onClick={() => setIsNew(false)}>
                <IconBell size={20} strokeWidth={1}/>
                {isNew ? <span className="badge bg-red"></span> : ""}
            </a>
            <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card" style={{width: "300px"}}>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title col">최신 알림사항</h3>
                        <Link to={URL_FRONT_USER_ALERT} className="col-auto text-muted">더보기</Link>
                    </div>

                    <div className="list-group list-group-flush list-group-hoverable overflow-auto" style={{maxHeight:"300px"}}>
                        {loading ? <div className="mt-3 mb-2" style={{textAlign:"center"}}><LoadingCircle size="20px"/></div> : getItemUI()}
                        {/*dummy.alarms.map((alarm, i) => {
                            return (
                                <NewAlarm key={randomKey(i)} category={alarm.category} name={alarm.name} contents={alarm.contents} isModal={true}/>
                            );
                        })*/}
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}
export default AlertsUI;

//const AlertsList = () => {}

type NewAlarmProps<T> = {
    isModal: boolean,
    category?: string,
    showIcon?: boolean,
    title: string,
    contents: string,
    data: T,
    index: number,
    onClick: (data: T, index: number) => void,
    onDelete: (data: T, index: number) => void | Promise<void>,
}
function NewAlarm<T>(props: NewAlarmProps<T>)
{
    const isModal = props.isModal;
    const [isDeleteDisable, setIsDeleteDisable] = useState(false);

    function onDelete(event: React.MouseEvent<HTMLButtonElement>)
    {
        //상위 영역 클릭해지는걸 방지
        event.stopPropagation();

        setIsDeleteDisable(true);
        Promise.resolve(props.onDelete(props.data, props.index)).then(() => setIsDeleteDisable(false));
    }

    if (!isModal) {
        return (
            <div className="list-group-item" onClick={() => props.onClick(props.data, props.index)}>
                <div className="row align-items-center">
                    {props.showIcon ?
                        <div className="col-auto">
                            <span className="avatar"></span>
                        </div> : ""
                    }
                    <div className="col text-truncate">
                        <a className="text-reset text-truncate d-block" style={{cursor: 'pointer'}}>{props.category ? `[${props.category}] ` : ""}<span>{props.title}</span></a>
                        <div className="d-block text-muted text-truncate mt-n1">{props.contents}</div>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-ghost-secondary" onClick={onDelete} disabled={isDeleteDisable}>
                            <IconX size={"20px"}/>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="list-group-item">
                <div className="row align-items-center">
                    <div className="col-auto">
                        <span className="status-dot bg-orange d-block"></span>
                    </div>
                    <div className="col text-truncate">
                        <a className="text-reset d-block" style={{cursor: 'pointer'}}>{props.category ? `[${props.category}] ` : ""}<span>{props.title}</span></a>
                        <div className="d-block text-muted text-truncate mt-n1">{props.contents}</div>
                    </div>
                </div>
            </div>
        )
    }
}
