import React, {useEffect, useRef} from 'react';
import styles from './index.module.css';

type LoadingCircleTypes = {
    size?: string,
    border?: number,
    color?: string,
    text?: string,
}
const LoadingCircle = (props: LoadingCircleTypes) =>
{
    const size = props.size ?? "10px";
    const border = props.border ?? 2;
    const color = props.color ?? "#3498db";

    return (
    <div className={styles.loading_wrap}>
        <p className={styles.loading_spin} style={
            {
                width: size,
                height: size,
                border: `${border}px solid #f3f3f3`,
                borderTop: `${border}px solid ${color}`,
                marginBottom: "10px"
            }
        }/>
        {props.text == null ? "" : <a>{props.text}</a>}
    </div>)
}

export default LoadingCircle;