import React, {Component, createRef, useEffect, useMemo, useRef, useState} from "react";
import {checkGetValue, getParam, isEmptyString, randomKey} from "@hskernel/hs-utils";
import {setParam, KeyValue} from "@hskernel/hs-utils-html5";
import {Navigate, NavigateFunction, useLocation, useNavigate} from "react-router-dom";
import qs from "qs";
import SelectOption, {SelectOptionClass, SelectOptionData, SelectOptionValue} from "../component/SelectOption";

export const PARAM_SEARCH_NAME = 'search';
export const PARAM_SEARCH_KEYWORD = 'keyword';
export const PARAM_SEARCH_OPTION = 'option';

export function makeSearchKey(key: string, name?: string)
{
    if (isEmptyString(name)) name = PARAM_SEARCH_NAME;
    return `${name}_${key}`;
}
export const makeSearchParamKey = (name?: string) => makeSearchKey(PARAM_SEARCH_KEYWORD, name);
export function makeSearchOptionParamKey(index: number, name?: string, option_name?: string)
{
    if (name == null) name = PARAM_SEARCH_NAME;
    return option_name == null ? `${name}_${PARAM_SEARCH_OPTION}_${index}` : `${name}_${option_name}`;
}

export type SearchOptionValue = string | undefined;
export type SearchOptionEvent = {index: number, value: string, name: string | undefined} | undefined;
export type SearchOptionProps = {
    /*
    true: 검색 유형 선택 시 검색 이벤트 발생
     */
    autoSearch?: boolean,
    name?: string,
    options: SelectOptionData[]
}

/*
export const getKindEvent = (option?: SearchKindOption, kind?: number): SearchKindAddEvent | undefined => kind == null || option == null ? undefined : {index: kind, data: option.kind[kind]};
export const getKindAddEvent = (options?: SearchKindOption[], kinds?: SearchKindAddIndexes): SearchKindAddDatas => options == null || kinds == null ? [] : options.map((option, i) => getKindEvent(option, kinds[i]));


export function getKindAddDefaultValueFromArray(kindAdd?: SearchKindOption[] | null): SearchKindAddDatas | undefined
{
    if (kindAdd == null) return undefined;
    return kindAdd.length == 0 ? [] : kindAdd.map((kind) => kind.kind.length > 0 ? {index: 0, data: kind.kind[0]} : undefined)
}
 */

export function getSearchOptionValueFromEvent(searchEvent?: SearchOptionEvent[]): SearchOptionValue[]
{
    return searchEvent == null ? [] : searchEvent.map(event => event?.value);
}
export function getSearchOptionEventFromValue(options?: SearchOptionProps[], optionValues?: SelectOptionValue[]): SearchOptionEvent[]
{
    const _events: SearchOptionEvent[] = [];
    options?.forEach((prop, i) =>
    {
        _events[i] = undefined;

        if (optionValues != null && optionValues.length > 0)
        {
            const value = optionValues[i];
            if (value == null) _events[i] = prop.options.length > 0 ? {index: 0, value: prop.options[0].Value, name: prop.name} : undefined;
            else if (typeof value == "number")
            {
                _events[i] = value > -1 && value < prop.options.length ? {index: value, value: prop.options[value].Value, name: prop.name} : undefined;
            }
            else
            {
                for (let j = 0; j < prop.options.length; j++)
                {
                    const option = prop.options[j];
                    if (option.Value == value)
                    {
                        _events[i] = {index: j, value: option.Value, name: prop.name};
                        break;
                    }
                }
            }
        }
    });

    return _events;
}


export function getDefaultSearchOptionsFromProps(options: SearchOptionProps[]): SearchOptionValue[]
{
    return options.map(option => option.options[0].Value);
}

/**
 *
 * @param name 검색 컨트롤 이름
 * @param keyword 기본 키워드
 * @param options 검색 옵션
 * @param optionValues 검색 옵션
 * @param ignoreURL
 */
export function getDefaultSearch(name?: string, keyword?: string | null, options?: SearchOptionProps[], optionValues?: SelectOptionValue[], ignoreURL = false): SearchControlData
{
    const data: SearchControlData = {
        keyword: keyword ?? null,
        options: getSearchOptionEventFromValue(options, optionValues),
    }

    if (!ignoreURL)
    {
        //null 이면 KeywordKind, 아니면 KeywordKindAdd
        const query = qs.parse(location.search, { ignoreQueryPrefix: true });

        data.keyword = query[makeSearchParamKey(name)]?.toString() ?? '';

        options?.forEach((prop, i) =>
        {
            const param_name = makeSearchOptionParamKey(i, name, prop.name);
            const param = query[param_name];
            for (let j = 0; j < prop.options.length; j++)
            {
                if (prop.options[j].Value == param)
                {
                    data.options[i] = {index: j, value: prop.options[j].Value, name: prop.name};
                    break;
                }
            }
        });
    }

    return data;
}

export function getKeyValueFromOptions(searchEvents: SearchOptionEvent[], name?: string): KeyValue | null
{
    if (searchEvents.length > 0)
    {
        const data: KeyValue = {};
        searchEvents.map((event, i) =>
        {
            if (event != null)
            {
                const key = makeSearchOptionParamKey(i, name, event.name);
                data[key] = event.value;
            }
        });
        return data;
    }
    return null;
}

/**
 *
 * @param state
 * @param name
 * @param defaultOptionValues 해당 값을 주면 일치하는 해당 값은 파라미터 표시 안함
 * @param Navigate
 * @param clearParam 검색 데이터가 바뀔 때 해당 URL 파라미터 키값을 지웁니다, null 일시 검색데이터 파라미터를 제외한 모든 키를 지웁니다
 */
export function setSearchParam(state: SearchControlData, name: string | undefined, defaultOptionValues?: SelectOptionValue[], Navigate?: NavigateFunction, clearParam?: string[] | null): qs.ParsedQs
{
    const params = clearParam === null ? qs.parse('') : qs.parse(location.search, { ignoreQueryPrefix: true });
    //파라미터 지우기
    clearParam?.forEach(key => params[key] = undefined);

    params[makeSearchParamKey(name)] = isEmptyString(state.keyword) ? undefined : state.keyword!;
    state.options?.map((option, i) =>
    {
        const key = makeSearchOptionParamKey(i, name, option?.name);
        let isMatch = false;
        if (defaultOptionValues != undefined && option != undefined)
        {
            isMatch = typeof defaultOptionValues[i] == 'number' ?
                defaultOptionValues[i] == option.index :
                defaultOptionValues[i] == option.value;
        }
        params[key] = option == null || isMatch ? undefined : option.value;
    });

    if (Navigate != null) Navigate({ pathname: location.pathname, search: qs.stringify(params, { skipNulls: true })});
    return params;
}

type SearchControlPropsBase = {
    /**
     * 해당 검색 컨트롤 이름
     */
    name?: string,
    disabled?: boolean,
    onInit?: (keyword: string | null, optionEvents: SearchOptionEvent[]) => void,
    onSearch: (keyword: string | null, optionEvents: SearchOptionEvent[]) => void | Promise<void>,
    /**
     *
     * @param value
     * @param {number} seq 순서 (인덱스)
     * @return {boolean} 자동검색 활성화 시 true 면 자동검색, false 면 자동검색 안함
     */
    onSelectedOptions?: (seq: number, option: SearchOptionEvent) => boolean | Promise<boolean>,

    width?: number,
    placeholder?: string,
    /**
     * 해당 값을 주면 URL 뒤쪽 파라미터를 변경 합니다
     */
    Navigate?: NavigateFunction,
    /**
     * col-lg-*
     */
    colWidth?: number,
    /**
     * 초기화 시 URL 에서 파라미터 불러오기 / 설정하기 여부
     */
    initParam?: boolean,
    /**
     * 검색 데이터가 바뀔 때 해당 URL 파라미터 키값을 지웁니다, null 일시 검색데이터 파라미터를 제외한 모든 키를 지웁니다
     */
    clearParam?: string[] | null,
}
type SearchControlProps = SearchControlPropsBase & {
    keyword?: string | null,
    options?: SearchOptionProps[],
    /**
     * 검색 옵션을 설정합니다
     */
    optionValue?: SelectOptionValue[],
}
type SearchControlClassProps = SearchControlPropsBase & {
    defaultKeyword?: string | null,
    defaultOptions?: SearchOptionProps[],
    /**
     * 검색 옵션 기본값을 설정합니다
     */
    defaultOptionValue?: SelectOptionValue[],
}

export type SearchControlData = {
    keyword: string | null,
    options: SearchOptionEvent[],
}

function getOptionEventFromState(options: SelectOptionData[], state: SearchOptionValue, name: string | undefined): SearchOptionEvent
{
    if (state != null)
    {
        for (let i = 0; i < options.length; i++)
            if (options[i].Value == state) return {index: i, value: state, name: name};
    }

    return undefined;
}

type SearchControlState = {
    isBusy: boolean,
    keyword: string | null,
    options?: SearchOptionProps[],
    optionValues: SearchOptionValue[],
    defaultOptionValues?: SearchOptionValue[]
}
export class SearchControlClass extends Component<SearchControlClassProps, SearchControlState>
{
    txtSearch = createRef<HTMLInputElement>();

    constructor(props: SearchControlClassProps) {
        super(props);

        const _state = getDefaultSearch(props.name, props.defaultKeyword ?? undefined, props.defaultOptions, props.defaultOptionValue, props.initParam === false);
        const _optionValues = _state.options.map((o) => o?.value);

        this.state = {
            keyword: _state.keyword ?? null,
            options: props.defaultOptions,
            defaultOptionValues: _optionValues,
            optionValues: _optionValues,
            isBusy: false,
        }

        this.onEnter = this.onEnter.bind(this);
        this.onSearchAsync = this.onSearchAsync.bind(this);
        this.onSelectAsync = this.onSelectAsync.bind(this);
        this.onEnter = this.onEnter.bind(this);

        if (props.onInit != null) props.onInit(_state.keyword, _state.options);
    }

    public setBusyAsync = async (isBusy: boolean) => await new Promise<void>((r) => this.setState({...this.state, isBusy: isBusy}, r));
    public setKeywordAsync = async (keyword: string) => await new Promise<void>((r) => this.setState({...this.state, keyword: keyword}, r));
    public getKeyword = () => this.txtSearch.current?.value ?? this.state.keyword;

    /**
     * 검색창에서 엔터키 눌렀을 때
     * @param e
     * @private
     */
    onEnter(e: React.KeyboardEvent<HTMLInputElement>) {
        const key = e.key || e.keyCode;
        if (key === 'Enter' || key === 13) this.onSearchAsync().then();
    }

    async onSelectAsync(optionsProp: SearchOptionProps, i: number, value: SelectOptionData, index: number, name: string | undefined)
    {
        const _optionValue = [...this.state.optionValues];
        _optionValue[i] = value.Value;
        await new Promise<void>((r) => this.setState({...this.state, optionValues: _optionValue}, r));
        console.log(_optionValue)

        let doSearch = optionsProp.autoSearch ?? false;
        if (this.props.onSelectedOptions != null)
        {
            try
            {
                await this.setBusyAsync(true);
                doSearch = await this.props.onSelectedOptions(i, {index: index, value: value.Value, name: name});
            }
            finally { await new Promise<void>((r) => this.setState({...this.state, isBusy: false}, r)); }
        }
        if (doSearch) await this.onSearchAsync(this.getKeyword(), _optionValue); //, i
    }

    async onSearchAsync(_keyword?: string | null, _optionValue?: SearchOptionValue[])
    {
        await this.setBusyAsync(true);
        const state = await this.setDataAsync(_keyword, _optionValue);

        try { await Promise.resolve<void>(this.props.onSearch(state.keyword, state.options)); }
        finally
        {
            await this.setBusyAsync(false);
            if (this.props.Navigate != null) setSearchParam(state, this.props.name, this.state.defaultOptionValues, this.props.Navigate, this.props.clearParam);
        }
    }

    /**
     * 검색 옵션을 변경합니다
     * @param options
     * @param defaultOptionValues
     * @param navigate
     * @param clearParam 검색 데이터가 바뀔 때 해당 URL 파라미터 키값을 지웁니다, null 일시 검색데이터 파라미터를 제외한 모든 키를 지웁니다
     */
    public async setOptionsAsync(options?: SearchOptionProps[] | null, defaultOptionValues?: SearchOptionValue[] | null, navigate?: NavigateFunction | null, clearParam?: string[] | null)
    {
        //const _keyword = checkGetValue(keyword, this.state.keyword, undefined);
        const _options = checkGetValue(options, this.state.options, undefined);
        const _defaultOptionValues = checkGetValue(defaultOptionValues, this.state.defaultOptionValues, undefined);
        //const _optionValues = checkGetValue(optionValues, this.state.optionValues, _defaultOptionValues);

        const _state = getDefaultSearch(this.props.name, this.state.keyword, _options, _defaultOptionValues, true);
        //const _newOptionValues = getSearchOptionEventFromValue(_options, _optionValues);

        const newDefaultOptionValues = getSearchOptionValueFromEvent(_state.options);
        //const newOptionValues = getSearchOptionValueFromEvent(_newOptionValues);
        await new Promise<void>(r => this.setState({...this.state, options: _options, defaultOptionValues: newDefaultOptionValues}, r));

        const _navigate = checkGetValue(navigate, this.props.Navigate, undefined);
        if (_navigate != null) setSearchParam(_state, this.props.name, newDefaultOptionValues, _navigate, clearParam);

        return newDefaultOptionValues;
    }

    /**
     * 검색 데이터를 지정합니다
     * @param data 검색 데이터 입니다
     */
    public setSearchDataAsync = (data: SearchControlData) => this.setDataAsync(data.keyword, getSearchOptionValueFromEvent(data.options))

    /**
     * 검색 데이터를 지정합니다
     * @param keyword undefined 면 키워드 지정 안함(그대로 기존 데이터 사용), null 이면 빈 값을 지정합니다
     * @param optionValues undefined 면 옵션 지정 안함(그대로 기존 데이터 사용), null 이면 옵션 값을 초기화 합니다
     */
    public async setDataAsync(keyword?: string | null, optionValues?: SearchOptionValue[] | null)
    {
        const _keyword = checkGetValue(keyword, this.getKeyword(), null);
        const _optionValues = checkGetValue<SearchOptionValue[]>(optionValues, this.state.optionValues, []);

        const _state = getDefaultSearch(this.props.name, _keyword, this.state.options, _optionValues, true);
        await new Promise<void>(r => this.setState({...this.state, keyword: _state.keyword, optionValues: getSearchOptionValueFromEvent(_state.options)}, r));

        if (this.txtSearch.current != null) this.txtSearch.current.value = _state.keyword ?? '';

        if (this.props.Navigate != null) setSearchParam(_state, this.props.name, this.state.defaultOptionValues, this.props.Navigate, this.props.clearParam);

        return _state;
    }

    public clearDataAsync = () => this.setDataAsync(null, null);

    render()
    {
        const disabled = this.state.isBusy || this.props.disabled;
        return (
            <div className={this.props.colWidth == null ? (this.state.optionValues != null && this.state.optionValues.length > 0 ? "col-lg-12" : '') : `col-lg-${this.props.colWidth}`}>
                <div className='row mb-2'>
                    <div className="col-auto row">
                        {this.state.options != null && this.state.options.length > 0 ?
                            this.state.options.map((optionsProp, i) =>
                                <SelectOptionClass
                                    key={randomKey(i)} initParam={this.props.initParam} manageSelect={false} disabled={disabled}
                                    value={this.state.optionValues[i]} options={optionsProp.options}
                                    name={makeSearchOptionParamKey(i, this.props.name, optionsProp.name)}
                                    onSelect={(value, index, name) => this.onSelectAsync(optionsProp, i, value, index, name)}/>) : <></>}
                    </div>
                    <div className="col" style={{paddingRight: "0", paddingLeft: "0.3rem"}}>
                        <div className="input-group">
                            <input ref={this.txtSearch} type="text" className="form_search form-control" disabled={disabled} defaultValue={this.state.keyword ?? undefined}
                                   placeholder={this.props.placeholder ?? "검색어 입력"}
                                   onKeyUp={this.onEnter} style={{paddingLeft: "10px"}}/>
                            <button type="button" className="btn bg-blue text-blue-fg" disabled={disabled} onClick={() => this.onSearchAsync().then()}>검색</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const SearchControl = (props: SearchControlProps) => {
    const searchElement = useRef<HTMLInputElement | null>(null);
    const [isInit, setIsInit] = useState(true);
    const [isBusy, setIsBusy] = useState(false);

    const [keyword, setKeyword] = useState<string | null>(null);
    const [optionValue, setOptionValue] = useState<SearchOptionValue[]>([]);

    function Init(setValue: boolean) {
        console.log(`CALL INIT!! (${setValue})`)
        const _state = getDefaultSearch(props.name, props.keyword ?? undefined, props.options, props.optionValue, !isInit || props.initParam == false);

        if (setValue) {
            setOptionValue(_state.options.map((o) => o?.value));
            setKeyword(_state.keyword);
        }

        return _state;
    }

    useEffect(() => {
        const _state = Init(true); //props.usingParam
        if (isInit) setIsInit(false);
        else {
            console.log("EFFECT (false)!!");
            if (props.Navigate != null) setSearchParam(_state, props.name, props.optionValue, props.Navigate, props.clearParam);
        }
    }, [props.keyword, props.optionValue]);

    useEffect(() =>
    {
        console.log("INIT!!");
        if (props.onInit != null)
        {
            const _state = Init(false);
            props.onInit(_state.keyword, _state.options);
        }
    }, []);

    useEffect(() => {
        console.log(optionValue)
    }, [optionValue]);

    function onSearch(_keyword?: string | null, _optionValue?: SearchOptionValue[], _optionIndex?: number)
    {
        _optionValue = _optionValue ?? optionValue;

        //console.log(kind);
        //console.log(kindAdd);

        const optionProp = props.options;
        const state: SearchControlData = {
            keyword: checkGetValue(_keyword, keyword, null),
            options: optionProp == null ? [] : _optionValue.map((value, i) => getOptionEventFromState(optionProp[i].options, value, optionProp[i].name)),
        }

        setIsBusy(true);
        Promise.resolve(props.onSearch(state.keyword, state.options))
            .then()
            .finally(() =>
            {
                setIsBusy(false);
                if (props.Navigate != null) setSearchParam(state, props.name, props.optionValue, props.Navigate, props.clearParam);
            })
    }
    /**
     * 검색창에서 엔터키 눌렀을 때
     * @param e
     * @private
     */
    function onEnter(e: React.KeyboardEvent<HTMLInputElement>) {
        const key = e.key || e.keyCode;
        if (key === 'Enter' || key === 13) onSearch();
    }

    return (
        <div className={props.colWidth == null ? (props.options != null && props.options.length > 0 ? "col-lg-12": '') : `col-lg-${props.colWidth}`}>
            <div className='row mb-2'>
                <div className="col-auto row">
                    {props.options != null && props.options.length > 0 ?
                        props.options.map((optionsProp, i) =>
                            <SelectOption key={randomKey(i)} initParam={true} manageSelect={false} disabled={props.disabled} value={optionValue[i]} options={optionsProp.options} name={makeSearchOptionParamKey(i, props.name, optionsProp.name)} onSelect={async (value, index, name) =>
                            {
                                const _optionValue = [...optionValue];
                                _optionValue[i] = value.Value;
                                setOptionValue(_optionValue);

                                let doSearch = optionsProp.autoSearch ?? false;
                                if (props.onSelectedOptions != null)
                                {
                                    try
                                    {
                                        setIsBusy(true);
                                        doSearch = await props.onSelectedOptions(i, {index: index, value: value.Value, name: name});
                                    }
                                    finally { setIsBusy(true); }
                                }
                                if (doSearch) onSearch(undefined, _optionValue, i);
                            }}/>) : <></>}
                </div>
                <div className="col" style={{paddingRight:"0",paddingLeft:"0.3rem"}}>
                    <div className="input-group">
                        <input type="text" className="form_search form-control" ref={searchElement} disabled={isBusy || props.disabled}
                               value={keyword ?? undefined}
                               placeholder={props.placeholder ?? "검색어 입력"}
                               onChange={(e) => setKeyword(e.target.value)}
                               onKeyUp={onEnter} style={{paddingLeft:"10px"}}/>
                        <button type="button" className="btn bg-blue text-blue-fg" disabled={isBusy || props.disabled} onClick={() => onSearch()}>검색</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchControl;