import "reflect-metadata";
import {Expose, Type} from "class-transformer";
import ApprovalStatus from "./ApprovalStatus";
import {
    GetDocumentStatusText,
    STATUS_COMPLETE,
    STATUS_FINAL,
    STATUS_HOLD,
    STATUS_PROGRESS,
    STATUS_REJECT,
    STATUS_REPORT, STATUS_SAVE
} from "./ApprovalDocumentBase";
import ApprovalDocument from "./ApprovalDocument";
import ApprovalInsteadData from "./ApprovalInsteadData";

/**
 * 버튼 활성화
 */
export const STATUS_INFO_AVAILABLE = "Available";
/**
 * 버튼 비활성화
 */
export const STATUS_INFO_DISABLE = "Disable";
/**
 * 버튼 비활성화 + 숨김
 */
export const STATUS_INFO_HIDDEN = "Hidden";

/**
 * 결재가능정보
 */
export default class ApprovalInfo
{
    /**
     * 현재 문서 상태
     */
    @Expose() public DocumentStatus: string;
    /**
     * 결재 가능 상태 여부
     */
    @Expose() public Approvalable: string;
    /**
     * 결재 취소 가능 상태 여부
     */
    @Expose() public Cancelable: string;
    /**
     * 반려 가능 상태 여부
     */
    @Expose() public Rejectable: string;
    /**
     * 전결 가능 여부
     */
    @Expose() public Finalable: string;
    /**
     * 보류 가능 여부
     */
    @Expose() public Holdable: string;
    /**
     * 집행 가능 여부
     */
    @Expose() public Executable: string;
    /**
     * 현재 결재자 (종결일 경우에는 마지막 종결자 정보)
     */
    @Expose() @Type(() => ApprovalStatus)
    public Current: ApprovalStatus | null;
    /**
     * 현재 결재자의 대결자
     */
    @Expose() @Type(() => ApprovalInsteadData)
    public Instead: ApprovalInsteadData | null;
    /**
     * 내 결재 정보 (로그인 ID가 일치하지 않으면 나는 대결상태임)
     */
    @Expose() @Type(() => ApprovalStatus)
    public Me: ApprovalStatus | null;


    /**
     * 집행시간
     */
    @Expose() @Type(() => Date)
    public ExecTime: Date | null;

    public toString(){ return this.ToString(true); }
    public ToString(showName: boolean)
    {
        const status = GetDocumentStatusText(this.DocumentStatus);
        //문서상태가 상신단계 이거나 결재 상태가 없으면 이름 표시하지 않음
        return showName && this.Current != null && this.DocumentStatus != STATUS_REPORT ? `${status} (${this.Current.Approver.Name})` : status;
    }
}
