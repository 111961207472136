import React, {FormEvent, useEffect, useRef, useState} from "react";
import {useNavigate, useParams, useBeforeUnload} from "react-router-dom";
import FileAttachControl from "common/control/FileAttachControl";
//import CKEditor from "common/control/editor/TextAreaCKEditor";
import {BoardKindtoName, GetBoardURL} from "../Define";
import BoardForm from "../data/BoardForm";
import PageLoadingTabler from "common/PageLoadingTabler";
import {isEmptyString} from "@hskernel/hs-utils";
import BoardData from "../data/BoardData";
import TextAreaNamoSE from "common/control/editor/TextAreaNamoSE";
import NamoSE from "../../../common/control/editor/TextAreaNamoSE";


const BoardWrite = () =>
{
    const Navigate = useNavigate();
    const {BoardKind, BoardID} = useParams();
    const [board, setBoard] = useState<BoardData | null>(null);
    const [prevent, setPrevent] = useState(false);
    const [busy, setBusy] = useState(false);

    const editor = useRef<NamoSE>(null);

    useBeforeUnload((e) =>
    {
        if (prevent)
        {
            e.preventDefault();
            if (confirm("정말 저장하지 않고 페이지를 나가시겠습니까?"))
                return true;
        }
    });

    useEffect(() =>
    {
        if(BoardKind == null)
        {
            alert("잘못된 접근입니다");
            history.back();
        }
        else
        {
            if(BoardID == null || isEmptyString(BoardID)) setBoard(new BoardData());
            else BoardData.GetViewAsync(BoardKind, BoardID).then(data => setBoard(data));
        }
    }, [BoardKind, BoardID]);

    function onChange(){ setPrevent(true); }

    function onSubmit(event: FormEvent<HTMLFormElement>)
    {
        event.preventDefault();
        if(editor.current != null) editor.current.save();

        if(BoardKind != null)
        {
            const form = BoardForm.FromForm(new FormData(event.currentTarget));
            const message = form.Check();
            if (message == null)
            {
                const Progress = async () =>
                {
                    setBusy(true);
                    try
                    {
                        const _BoardID = await form.Submit(BoardKind, BoardID).then();
                        Navigate(GetBoardURL(BoardKind, _BoardID));
                        if (window.opener != null) window.opener.location.reload();
                    }
                    catch(error: any) { console.log(error); alert(error.message); }
                    finally { setBusy(false); }
                }
                Progress().then();
            }
            else alert(message);
        }
        else alert("게시판 형식이 지정되지 않았습니다");
    }

    return (board == null ? <PageLoadingTabler message="게시글 작성 준비중입니다"/> :
        <form onSubmit={onSubmit}>
            <div className="card-body">
                <h2>{BoardKindtoName(BoardKind!)} 작성</h2>
                <div className="pb-3 pt-3 row" style={{borderBottom:"1px solid var(--tblr-border-color)",borderTop:"1px solid var(--tblr-border-color)"}}>
                    <div className="col-lg-1 col-sm-12">
                        <b className="form-label mt-2">제목</b>
                    </div>
                    <div className="col-lg-11 col-sm-12">
                        <input type="text" className="form-control" name="Title" defaultValue={board.Title} placeholder="게시글 제목" onChange={onChange}/>
                    </div>
                </div>
                <div className="pb-3 pt-3 row" style={{borderBottom:"1px solid var(--tblr-border-color)"}}>
                    <div className="col-lg-1 col-sm-12">
                        <b className="form-label mt-2">내용</b>
                    </div>
                    <div className="col-lg-11 col-sm-12">
                        {/*<CKEditor name="Contents" value={board.Contents} onChange={() => onChange()}/>*/}
                        <TextAreaNamoSE ref={editor} name="Contents" value={board.Contents}/>
                    </div>
                </div>
                <div className="mt-3 card">
                    <div className="card-body">
                        <FileAttachControl attachs={board.Attach} name="FormAttachFile" nameDelete="FormAttachFileDelete"/>
                    </div>
                </div>
            </div>
            <div className="card-footer text-end">
                <input type="submit" className="btn btn-primary" disabled={busy} value={BoardID == null ? "등록" : "수정"}/>&nbsp;&nbsp;
                <input type="button" className="btn" disabled={busy} value="취소" onClick={() => Navigate(-1)}/>
            </div>
        </form>
    )
}

export default BoardWrite;