import UserData from "./UserData";
import {Expose, plainToInstance} from "class-transformer";
import {serializeForm} from "@hskernel/hs-utils";

export default class UserDataForm extends UserData
{
    @Expose() public ProfileImage?: string;
    public static fromForm(form: HTMLFormElement)
    {
        const raw = serializeForm(new FormData(form), true);
        return plainToInstance(UserDataForm, raw);
    }
}