import ErrorPageBase, {ErrorPageParam} from "./Base";

export default class ErrorPage500 extends ErrorPageBase
{
    constructor(props?: ErrorPageParam)
    {
        super({
            errorCode: '500',
            title: props?.title ?? '서버 오류가 발생 하였습니다',
            message: props?.message,
            hideBack: props?.hideBack ?? true,
            hideHome: props?.hideHome,
        });
    }
}