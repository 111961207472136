import styled from "styled-components";

export const ItemButton = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 100%;
  position: relative;
  padding-left: 30px;
`;

export const ItemPlusMinus = styled.button`
  border: none;
  background: transparent;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 20px;
`;
