export type RNMessageTypeWV = 'TOKEN_FCM' | 'ALERT' | 'NOTI' | 'NOTI_CLICK' | 'DARKMODE' | 'UNKNOWN';

export interface RNMessageDataWV
{
    type: RNMessageTypeWV;
    data: any;
}

export interface RNDeviceInfoData
{
    platform: string;
    id?: string;
    os?: string;
    base_os?: string;
    manufacturer?: string;
    name?: string;
    brand?: string;
    model?: string;
    system?: string;
    version?: string;
    useragent?: string;
}

// @ts-ignore
// eslint-disable-next-line eqeqeq
export const isWVMode = () => getWVData('mode') == 'RN';

/**
 * ReactNativeWebView 에서 해당 이름의 값을 가져옵니다
 * @returns 해당 이름의 값을 반환합니다
 * @param name
 */
// @ts-ignore
export const getWVData = (name: string): any | undefined | null => window == null || window.ReactNativeWebView == null ? undefined : window.ReactNativeWebView[name];

/**
 *
 * @param {string} name
 * @param data
 */
export function setWVData(name: string, data: any)
{
    // @ts-ignore
    if (isWVMode()) window.ReactNativeWebView[name] = data;
}


const _onReceiveRNDataWV: ((data: RNMessageDataWV) => void)[] = [];

export function onReceiveRNDataWVAdd(onReceiveRNDataWV: (data: RNMessageDataWV) => void)
{
    //console.log(`onReceiveRNDataWV: ${onReceiveRNDataWV}`);
    if (onReceiveRNDataWV != undefined)
    {
        for (let i = 0; i < _onReceiveRNDataWV.length; i++)
        {
            if (_onReceiveRNDataWV[i] === onReceiveRNDataWV) return;
        }
        _onReceiveRNDataWV.push(onReceiveRNDataWV);
    }
}

export function onReceiveRNDataWVRemove(onReceiveRNDataWV: (data: RNMessageDataWV) => void)
{
    for (let i = 0; i < _onReceiveRNDataWV.length; i++)
        if (_onReceiveRNDataWV[i] == onReceiveRNDataWV) _onReceiveRNDataWV.remove(i);
}

function onReceiveRNDataRaw(raw: any)
{
    //console.log(`onReceiveRNDataLength: ${_onReceiveRNDataWV.length} / onReceiveRNDataRaw: ${(typeof raw).toString() === 'object' ? JSON.stringify(raw) : raw}`);
    if (_onReceiveRNDataWV.length >= 0)
    {
        try
        {
            const data: RNMessageDataWV = JSON.parse(raw.data);
            if (data.type !== undefined) _onReceiveRNDataWV.map((callback) => callback(data));
        }
        catch (e)
        {
            const _e = (typeof raw).toString() === 'object' ? JSON.stringify(raw) : raw;
            const _data = (typeof raw.data).toString() === 'object' ? JSON.stringify(raw.data) : raw.data;
            console.info(`Not RN Message Data!! [WV] (${_e}, DATA: ${_data})`);
        }
    }
}

export function InitRNMessage()
{
    if (isWVMode())
    {
        // ios
        // @ts-ignore
        window.addEventListener('message', (e) => onReceiveRNDataRaw(e));
        // android
        // @ts-ignore
        document.addEventListener('message', (e) => onReceiveRNDataRaw(e));
    }
}
