import 'reflect-metadata';
import {Expose} from "class-transformer";
import {isEmpty, isNullorWhiteSpace, serializeForm} from "utils/HSUtils";
import ApprovalDocument from "./ApprovalDocument";
import {dateFormat} from "utils/date.format";
import {DATETIME_FORMAT} from "../Define";
import {STATUS_SAVE} from "./ApprovalDocumentBase";

export default abstract class ApprovalDocumentForm
{
    @Expose() public DocumentID?: string;
    @Expose() public DocumentClass: string;
    @Expose() public Title: string;
    @Expose() public WriteDateText?: string | null;
    @Expose() public WriteDateChanged: boolean;
    @Expose() public Text: string;

    @Expose() public WriteDeptCode?: string | null;
    @Expose() public WriteDeptName?: string | null;
    @Expose() public FormApproval: string[];
    @Expose() public FormAgreement?: string[];
    @Expose() public FormExecUser?: string[];
    @Expose() public FormRefUser?: string[];
    @Expose() public FormDocumentRef?: string[];
    @Expose() public FormAttachFileDelete?: string[] | null;
    public FormAttachFile?: File[] | null;

    protected constructor()
    {
        this.Apply = this.Apply.bind(this);
        this.ApplyForm = this.ApplyForm.bind(this);
        this.CheckValid = this.CheckValid.bind(this);
    }

    /**
     * 폼 양식 데이터를 원본 문서로부터 적용합니다
     * @param docData
     * @constructor
     */
    public Apply(docData: ApprovalDocument)
    {
        this.DocumentID = docData.DocumentID;
        this.DocumentClass = docData.DocumentClass;
        this.WriteDateText = dateFormat(docData.WriteDate, DATETIME_FORMAT);
        this.WriteDateChanged = docData.WriteDateChanged;
        this.Title = docData.Title;
        this.Text = docData.Text;
        this.WriteDeptCode = docData.WriteDeptCode;
        this.WriteDeptName = docData.WriteDeptName;
    }

    /**
     *
     * @param docFormData
     * @constructor
     */
    public ApplyForm(docFormData: FormData): any
    {
        const form = serializeForm(docFormData);
        if(form.DocumentID !== undefined) this.DocumentID = form.DocumentID;
        if(form.DocumentClass !== undefined) this.DocumentClass = form.DocumentClass;
        if(form.Title !== undefined) this.Title = form.Title;
        if(form.WriteDateText !== undefined) this.WriteDateText = form.WriteDateText;
        else if(form.WriteDate !== undefined) this.WriteDateText = dateFormat(form.WriteDate, DATETIME_FORMAT);
        if(form.WriteDateChanged !== undefined) this.WriteDateChanged = form.WriteDateChanged;
        if(form.Text !== undefined) this.Text = form.Text;

        if(form.WriteDeptCode !== undefined) this.WriteDeptCode = form.WriteDeptCode;
        if(form.WriteDeptName !== undefined) this.WriteDeptName = form.WriteDeptName;
        if(form.FormApproval !== undefined) this.FormApproval = form.FormApproval;
        if(form.FormAgreement !== undefined) this.FormAgreement = form.FormAgreement;
        if(form.FormExecUser !== undefined) this.FormExecUser = form.FormExecUser;
        if(form.FormRefUser !== undefined) this.FormRefUser = form.FormRefUser;
        if(form.FormDocumentRef !== undefined) this.FormDocumentRef = form.FormDocumentRef;
        if(form.FormAttachFileDelete !== undefined) this.FormAttachFileDelete = form.FormAttachFileDelete;
        if(form.FormAttachFile !== undefined) this.FormAttachFile = form.FormAttachFile;
        return form;
    }

    public CheckValid(DocumentStatus: string, ERPText?: string | null): string | null
    {
        if (isNullorWhiteSpace(this.DocumentClass)) return "문서 종류는 비어있을 수 없습니다";
        if (DocumentStatus == STATUS_SAVE && (isEmpty((this.FormApproval)) || this.FormApproval.length == 0)) return "결재라인은 비어있을 수 없습니다";
        if (isNullorWhiteSpace(this.WriteDeptCode)) return "기안 부서코드는 비어있을 수 없습니다";
        if (isNullorWhiteSpace(this.Title)) return "제목은 비어있을 수 없습니다";
        if (isNullorWhiteSpace(ERPText) && isNullorWhiteSpace(this.Text)) return "내용은 비어있을 수 없습니다";
        return null;
    }

    /**
     * 
     * @param {string} password
     * @param {string | null} comment
     * @param {boolean} isReport true 면 바로 저장 및 상신단계 false 면 저장만
     * @returns {Promise<any>}
     * @constructor
     */
    public abstract Submit(password?: string, comment?: string | null, isReport?: boolean): Promise<any | null>;
}