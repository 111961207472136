import {PAGE_COUNT, PAGE_MAX, URL_API} from "Define";
import {getModulePath} from "../ModuleInfo";
import {PathMakerMulti} from "utils/HSUtils";
import {ListKind} from "./data/ApprovalDocumentListData";
import {isoUtcDateTime} from "../../common/control/DateTimeControl";
import {PathMaker} from "@hskernel/hs-utils";
import qs from "qs";

export const MAX_APPROVALS = PAGE_MAX;
export const LIST_COUNT = PAGE_COUNT;
export const DATETIME_FORMAT = isoUtcDateTime;

export const DOC_WINDOW_WIDTH = 1100;
export const DOC_CONTENT_WIDTH = 920;
export const DOC_FORM_WIDTH = 795;

/**
 * 모두 (사용하지 않음)
 */
export const LIST_KIND_ALL = "all";
/**
 *
 * @type {string}
 */
export const LIST_MY_CLASS = "my_class";

/**
 * 임시 보관함
 */
export const LIST_KIND_TEMP = "temp";
/**
 * 결재 요청
 */
export const LIST_KIND_REQUEST = "request";
/**
 * 상신함
 */
export const LIST_KIND_REPORT = "report";
/**
 * 미결함
 */
export const LIST_KIND_PROGRESS = "progress";
/**
 * 미결함 (내것 포함)
 */
export const LIST_KIND_PROGRESS_ME = "progress_me";
/**
 * 미결함 (모두)
 */
export const LIST_KIND_PROGRESS_ALL = "progress_all";
/**
 * 기결함
 */
export const LIST_KIND_DECISION = "decision";
/**
 * 전결함
 */
export const LIST_KIND_COMPLETE = "complete";
/**
 * 반려함
 */
export const LIST_KIND_REJECT = "reject";
/**
 * 보류함
 */
export const LIST_KIND_HOLD = "hold";
/**
 * 수신참조함
 */
export const LIST_KIND_REF = "receiveref";
/**
 * 집행함
 */
export const LIST_KIND_EXEC = "execute";
/**
 * 집행 확인함
 */
export const LIST_KIND_EXEC_ALL = "execute_all";

export const URL_API_APPROVAL = URL_API + "/approval";
export const URL_API_APPROVAL_DOCU = URL_API_APPROVAL + "/docu";
export const URL_API_APPROVAL_DOCU_FILE = URL_API_APPROVAL_DOCU + "/file";
export const URL_API_APPROVAL_DOCU_LIST = URL_API_APPROVAL_DOCU + "/list";
export const URL_API_APPROVAL_DOCU_COUNT = URL_API_APPROVAL_DOCU + "/count";
export const URL_API_APPROVAL_DOCU_TYPE = URL_API_APPROVAL_DOCU + "/type";
export const URL_API_APPROVAL_DOCU_FORMAT = URL_API_APPROVAL_DOCU + "/format";
export const URL_API_APPROVAL_ACTION = URL_API_APPROVAL + "/action";
export const URL_API_APPROVAL_ACTION_REPORT = URL_API_APPROVAL_ACTION + "/report";
export const URL_API_APPROVAL_ACTION_HOLD = URL_API_APPROVAL_ACTION + "/hold";
export const URL_API_APPROVAL_ACTION_REJECT = URL_API_APPROVAL_ACTION + "/reject";
export const URL_API_APPROVAL_ACTION_FINAL = URL_API_APPROVAL_ACTION + "/final";
export const URL_API_APPROVAL_ACTION_APPROVAL = URL_API_APPROVAL_ACTION + "/approval";
export const URL_API_APPROVAL_ACTION_CANCEL = URL_API_APPROVAL_ACTION + "/cancel";

//결재/합의자 가져오기
export const URL_API_APPROVAL_APPROVALS = URL_API_APPROVAL + "/approvals"

//결재설정
export const URL_API_APPROVAL_INFO = URL_API_APPROVAL + "/setting/info";
export const URL_API_APPROVAL_LINES = URL_API_APPROVAL + "/setting/lines";

//인수인계
export const MENU_HANDOVER = "handover";
export const MENU_HANDOVER_DOC = "doc";

export const URL_API_APPROVAL_HANDOVER = URL_API_APPROVAL + "/handover";
export const URL_API_APPROVAL_HANDOVER_LIST = URL_API_APPROVAL_HANDOVER + "/class/list";
export const URL_API_APPROVAL_HANDOVER_COUNT = URL_API_APPROVAL_HANDOVER + "/class/count";
export const URL_API_APPROVAL_HANDOVER_DOC_LIST = URL_API_APPROVAL_HANDOVER + "/doc/list";
export const URL_API_APPROVAL_HANDOVER_DOC_COUNT = URL_API_APPROVAL_HANDOVER + "/doc/count";

//결재라인
export const URL_API_APPROVAL_LINE = URL_API_APPROVAL + "/line";
export const URL_API_APPROVAL_LINE_DELETE = URL_API_APPROVAL_LINE + "/delete";
export const URL_API_APPROVAL_LINE_LIST = URL_API_APPROVAL_LINE + "/list";
export const URL_API_APPROVAL_LINE_COUNT = URL_API_APPROVAL_LINE + "/count";
export const URL_API_APPROVAL_LINE_DOCU = URL_API_APPROVAL_LINE + "/docu";

/**
 * 프린트 ㄱㄱ
*/
export const URL_API_APPROVAL_PRINT_LOG = URL_API_APPROVAL + "/print/log";
/**
 * 수신및참조 설정
 */
export const URL_API_APPROVAL_REF_USER = URL_API_APPROVAL + "/ref/user";
/**
 * 집행시간 설정
 */
export const URL_API_APPROVAL_EXEC_TIME = URL_API_APPROVAL + "/exec/time";

export const PATH_APPROVAL = "approval";

export const PATH_APPROVAL_VIEW = "view";
export const URL_APPROVAL_VIEW = getModulePath(PathMakerMulti(PATH_APPROVAL, PATH_APPROVAL_VIEW));
export const URL_APPROVAL_VIEW_FORMAT = PathMaker(URL_APPROVAL_VIEW, "format");
export const PATH_APPROVAL_FORM = "form";
export const URL_APPROVAL_FORM = getModulePath(PathMakerMulti(PATH_APPROVAL, PATH_APPROVAL_FORM));

/**
 * 대결자 설정
 */
export const URL_API_APPROVAL_INSTEAD = URL_API_APPROVAL + "/setting/instead";

/**
 * 전자결재 > [결재문서작성]
 */
export const PATH_APPROVAL_WRITE = "write";

/**
 * 전자결재 > [결재문서작성] > [결재문서양식]
 */
export const PATH_APPROVAL_WRITE_FORMAT = "format";
/**
 * 전자결재 > [결재문서작성] > [임시저장문서]
 */
export const PATH_APPROVAL_WRITE_TEMP = ListKind.Temp;

/**
 * 전자결재 > [문서목록]
 */
export const PATH_APPROVAL_LIST = "list";
export const URL_APPROVAL_LIST = getModulePath(PathMakerMulti(PATH_APPROVAL, PATH_APPROVAL_LIST));
/**
 * 전자결재 > [결재설정]
 */
export const PATH_APPROVAL_SETTING = "setting";
/**
 * 전자결재 > [결재문서작성] > [참조문서선택]
 */
export const PATH_APPROVAL_REF_DOCUMENT = "ref";
/**
 * 전자결재 > [인수인계]
 */
export const PATH_APPROVAL_HANDOVER = "handover";
/**
 * 전자결재 > [인수인계] > [인수인계 관리]
 */
export const PATH_APPROVAL_HANDOVER_MANAGE = "manage";


export const APPROVER_DIV_APPROVAL = "Approval";
export const APPROVER_DIV_AGREE = "Agreement";
export const APPROVER_DIV_EXEC = "Exec";
export const APPROVER_DIV_REF = "Ref";
export const APPROVER_DIV_SHARE = "Share";
export function ApproverDivision(DivKind: string)
{
    switch (DivKind)
    {
        case APPROVER_DIV_APPROVAL: return "결재";
        case APPROVER_DIV_AGREE: return "합의";
        case APPROVER_DIV_EXEC: return "시행";
        case APPROVER_DIV_REF: return "수신및참조";
        case APPROVER_DIV_SHARE: return "공유";
        default: return "(알 수 없음)";
    }
}

export function getListString(kind: string)
{
    switch (kind)
    {
        case LIST_KIND_REPORT: return "상신함";
        case LIST_KIND_PROGRESS: return "미결함";
        case LIST_KIND_PROGRESS_ME: return "미결함 [기안]";
        case LIST_KIND_PROGRESS_ALL: return "미결함 [전체]";
        case LIST_KIND_DECISION: return "기결함";
        case LIST_KIND_COMPLETE: return "전결함";
        case LIST_KIND_REJECT: return "반려함";
        case LIST_KIND_HOLD: return "보류함";
        case LIST_KIND_REF: return "수신참조함";
        case LIST_KIND_EXEC: return "집행함";
        case LIST_KIND_EXEC_ALL: return "집행확인문서";
        case LIST_KIND_TEMP: return "임시보관문서";
        default: return kind;
    }
}

export function isWindowOpen()
{
    //const parse = qs.parse(location.search, {ignoreQueryPrefix: true});
    return window.opener != null;
}