import React from "react";
import {getParam} from "@hskernel/hs-utils";
import {NavigateFunction, useParams} from "react-router-dom";
import {calcHeight, URL_PREVIEW_FILE} from "Define";
import FileViewer, { FileViewerType } from "common/control/FileViewerControl";

export const FilePreviewParam = "fileKind";
type FilePreviewParams = {
    [FilePreviewParam]: FileViewerType
}
const FilePreview = () =>
{
    const params = useParams<FilePreviewParams>();
    const url = getParam("url");
    return (() =>
    {
        const type = params[FilePreviewParam];
        if(url != null && type != null)
        {
            return <FileViewer fileURL={url} type={type}/>
            /*
            switch (type)
            {
                case "PDF": return <FileViewer fileURL={url} type="pdf"/>
                case "IMAGE": return <FileViewer fileURL={url} type="image"/>
            }
            */
        }

        return <div></div>
    })()
}

export default FilePreview;

export function FilePreviewOpen(kind: FileViewerType, url: string, Navigate?: NavigateFunction)
{
    url = `${URL_PREVIEW_FILE}/${kind}?url=${encodeURI(url)}`;
    if (Navigate == null) window.open(url, '_blank', `width=1000,height=${calcHeight()}`);
    else
    {
        Navigate(url, {replace: true});
        location.href = url;
    }
}