import React, {Component} from "react";
import PDFViewer from "./viewer/PDFViewer";
import ImageViewer from "./viewer/ImageViewer";
import ReactModal from "react-modal";
import {IconAlertCircle, IconX} from "@tabler/icons-react";
import {readData} from "utils/HSUtils";
import LoadingCircle from "../LoadingCircle";
import VideoPlayer from "./viewer/VideoPlayer";
import MusicPlayer from "./viewer/MusicPlayer";
import TextViewer from "./viewer/TextViewer";

export type FileViewerType = "PDF" | "Image" | "Text" | "Video" | "Audio";

type FileViewerControlProps = {
    fileURL: string,
    type: FileViewerType,
    title?: string
}

type FileViewerData = {
    fileOrURL: string | Blob,
    type: FileViewerType,
    title?: string
}

const FileViewerControl = (props: FileViewerControlProps) =>
{
    function getView()
    {
        switch (props.type)
        {
            case "PDF": return <PDFViewer fileURL={props.fileURL} fileName={props.title} />
            case "Image": return <ImageViewer fileURL={props.fileURL} fileName={props.title}/>
            case "Text": return <TextViewer fileURL={props.fileURL} fileName={props.title}/>
            case "Video": return <VideoPlayer fileURL={props.fileURL} fileName={props.title}/>
            case "Audio": return <MusicPlayer fileURL={props.fileURL} fileName={props.title}/>
            default: return <div></div>
        }
    }
    return getView();
}

export default FileViewerControl;

type FileViewerDialogState = {
    isOpen: boolean,
    isLoad: boolean,
    message?: string,
    data?: FileViewerControlProps,
}
export class FileViewerDialog extends Component<any, FileViewerDialogState>{
    constructor(props: any)
    {
        super(props);
        this.state = {
            isOpen: false,
            isLoad: false,
        }

        this.open = this.open.bind(this);
        this.openAsync = this.openAsync.bind(this);
    }

    public async openAsync(data: FileViewerData)
    {
        if (typeof data.fileOrURL == 'string') this.setState({...this.state, isOpen: true, data: {...data, fileURL: data.fileOrURL}});
        else
        {
            const blob = data.fileOrURL as Blob;
            if (blob != null)
            {
                this.setState({...this.state, isOpen: true, isLoad: true});
                try
                {
                    const loadFile = await readData(blob);
                    if (loadFile == null) this.setState({...this.state, isOpen: true, isLoad: false, message: '파일 미리보기 실패'});
                    else this.setState({...this.state, isOpen: true, isLoad: false, data: {...data, fileURL: loadFile}});
                    //await sleep(3000);
                    //this.setState({...this.state, isOpen: true, isLoad: false, message: '테스트'});
                }
                catch (ex)
                {
                    this.setState({...this.state, isOpen: true, isLoad: false, message: '파일 미리보기 오류'});
                }
            }
            else
            {
                //알 수 없는 형식
                this.setState({...this.state, isOpen: true, isLoad: false, message: '알 수 없는 파일 형식 입니다'});
            }
        }
    }
    public open = (data: FileViewerData) => this.openAsync(data).then();

    render()
    {
        const modalStyle: ReactModal.Styles = {
            overlay: {
                backgroundColor: " rgba(0, 0, 0, 0.4)",
                width: "100%",
                height: "100vh",
                zIndex: "1000",
                position: "fixed",
                top: "0",
                left: "0",
            },
            content: {
                zIndex: "150",
                backgroundColor:"rgba(0,0,0,0.6)",
                overflowY:"hidden",
                height:"100vh",
                inset:"0",
                borderRadius:0,
            },
        }

        const InnerElement = () =>
        {
            if (this.state.isLoad) return <div className="text-center"><LoadingCircle size="50px" border={5}/></div>;
            if (this.state.data == null)
            {
                const message = this.state.message == null ? '콘텐츠가 없습니다' : this.state.message;
                return (
                    <div className="card-body">
                        <h3 className="text-white text-center" style={{height: "400px", marginTop: "300px"}}><IconAlertCircle className="text-red"/> {message}</h3>
                    </div>
                )
            }
            else return <FileViewerControl {...this.state.data}/>;
        }

        return (
            <ReactModal isOpen={this.state.isOpen} ariaHideApp={false} shouldCloseOnOverlayClick={true} style={modalStyle} onRequestClose={() => {this.setState({ isOpen: false })}} >
                <div style={{height:"100%"}}>
                    <div className="card-header mb-1">
                        <h3 className="col mb-0" style={{color: "white", paddingLeft:"58px",textAlign:"center"}}>{this.state.data == null ? "" : this.state.data.title}</h3>
                        <div className="col-auto" style={{textAlign:"right"}}>
                            <button className="btn btn-ghost-light" onClick={() => this.setState({isOpen: false})}><IconX stroke={2}/></button>
                        </div>
                    </div>
                    {InnerElement()}
                </div>
            </ReactModal>
        )
    }
}