import ModuleInfo, {AdminMenuKind} from "modules/ModuleInfo";

import {
    BOARD_KIND_CLUB,
    BOARD_KIND_EVENT_FAMILY,
    BOARD_KIND_HR_PERSONAPP,
    BOARD_KIND_NOTICE,
    BOARD_KIND_QA_SYSTEM,
    ID
} from "../Define";
import {PathMakerMulti} from "@hskernel/hs-utils";

import iconDefault from "../resource/light.png";
import iconDefaultDark from "../resource/dark.png";
import iconShortcut from "../resource/menu/menu_default.png";
import iconShortcutDark from "../resource/menu/menu_default_dark.png";
import subIconDefault from "../resource/light.png";
import React from "react";
import { IconClipboardList } from "@tabler/icons-react";

const renderIconShortcut = (darkMode: boolean) => darkMode ? iconShortcutDark : iconShortcut ;
const renderIcon = (darkMode: boolean) => darkMode ? iconDefault : iconDefaultDark;

export const DEFAULT_MODULE_PATH = PathMakerMulti(ID, BOARD_KIND_NOTICE);
const Info: ModuleInfo =
{
    id: ID,
    name: "Board",
    title: "게시판",
    onRenderIcon: (darkMode, isSmall) => <IconClipboardList/>,
    admin: AdminMenuKind.UserOnly,
    category: [
        {
            id: "list",
            title: "사내게시판",
            path: "",
            open: true,
            menu: [
                {
                    id: BOARD_KIND_NOTICE,
                    title: "공지사항",
                    path: BOARD_KIND_NOTICE,
                    popup: false,
                    shortcut: () => 'show',
                    onRenderIcon: renderIconShortcut,
                },
                {
                    id: BOARD_KIND_EVENT_FAMILY,
                    title: "경조사",
                    path: BOARD_KIND_EVENT_FAMILY,
                    popup: false,
                    shortcut: () => 'show',
                    onRenderIcon: renderIconShortcut,
                },
                {
                    id: BOARD_KIND_HR_PERSONAPP,
                    title: "인사발령 공지",
                    path: BOARD_KIND_HR_PERSONAPP,
                    popup: false,
                    shortcut: () => 'show',
                    onRenderIcon: renderIconShortcut,
                },
                {
                    id: BOARD_KIND_CLUB,
                    title: "동호회",
                    path: BOARD_KIND_CLUB,
                    popup: false,
                    shortcut: () => 'show',
                    onRenderIcon: renderIconShortcut,
                },
                {
                    id: BOARD_KIND_QA_SYSTEM,
                    title: "전산문의",
                    path: BOARD_KIND_QA_SYSTEM,
                    popup: false,
                    shortcut: () => 'show',
                    onRenderIcon: renderIconShortcut,
                }
            ]
        }
    ]
}

export default Info;