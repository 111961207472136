import React, {useRef, useState} from "react";
import SearchWithDateRangeControl, {
    SearchWithDateRangeControlClass, SearchWithDateRangeFullData,
} from "common/control/SearchWithDateRangeControl";
import {SearchOptionProps} from "common/control/SearchControl";
import HandoverManageDialog from "./HandoverManageDialog";
import TableListControl, {
    GetItemStyle,
    TableItemHeader,
    TableItemType,
    TableListData
} from "common/control/TableListControl";
import ApprovalHandoverListData from "../../data/ApprovalHandoverListData";
import {useAuth} from "context/AuthContext";

const HandoverManagePage = () => {
    const searchKind: SearchOptionProps[] = [
        {
            autoSearch: false,
            options: [
                { Title: "문서분류", Value: "DocumentClassName" }
            ]
        }
    ]
    const TableHeader: TableItemHeader<ApprovalHandoverListData>[] = [
        {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(20, false)},
        {name: "인수자", key: "Acquire", clickable: false, styleItem: GetItemStyle(15, false), onRender: (value) => value.toString()},
        {name: "인수자 부서", key: "Acquire.DeptName", clickable: false, styleItem: GetItemStyle(15, false)},
        {name: "인계자", key: "Successor", clickable: false, styleItem: GetItemStyle(15, false), onRender: (value) => value.toString()},
        {name: "인계자 부서", key: "Successor.DeptName", clickable: false, styleItem: GetItemStyle(15, false)},
        {name: "처리일", key: "WriteDate", clickable: false, type: TableItemType.Date, styleItem: GetItemStyle(20, false)}
    ]

    const Auth = useAuth();
    const [search, setSearch] = useState<SearchWithDateRangeFullData | null>(null);
    const dialog = useRef<HandoverManageDialog | null>(null);

    function refresh(): TableListData<ApprovalHandoverListData>
    {
        //ApprovalHandoverListData.GetListAsync()
        return {data: []};
    }

    return Auth.auth == null ? <></> : (
        <>
            <HandoverManageDialog ref={dialog} auth={Auth.auth} onSave={refresh}/>
            <SearchWithDateRangeControlClass
                dateRangeTitle={"처리일"} defaultOptions={searchKind} clearParam={TableListControl.URLParamKeys}
                onChange={(search) => { console.log(search); setSearch(search); }}/>
            <div className="card-body">
                <div className="row">
                    <div className="col"></div>
                    <div className="col-auto">
                        <button type="button" className="btn" onClick={() => {if(dialog.current != null) dialog.current.open(); }}>추가</button>&nbsp;&nbsp;
                        <button type="button" className="btn">삭제</button>
                    </div>
                </div>
                <TableListControl<ApprovalHandoverListData> autoRefresh={true} headerTable={TableHeader} onRefresh={refresh}/>
            </div>
        </>
    )
}
export default HandoverManagePage;