import React, {Component, useCallback, useState} from "react";
import {randomKey} from "@hskernel/hs-utils";
import {useTheme} from "context/ThemeContext";

type CommentControlProps = {
    title?: string,
    placeHolder?: string,
    onRefresh?: () => Promise<CommentItem[]>
    items?: CommentItem[]
}
type CommentControlState = {
    busy: boolean,
    items: CommentItem[]
}


export type CommentItem = {
    title?: string,
    text: string,
    tail?: string,
    showRemove?: boolean,
    showEdit?: boolean,
    data?: any,
}

export default class CommentControl extends Component<CommentControlProps, CommentControlState>
{
    title = "댓글";
    constructor(props: CommentControlProps) {
        super(props);
        this.state = {
            busy: false,
            items: props.items ?? []
        }

        if(props.title != null) this.title = props.title;
    }

    async componentDidMount()
    {
        if(this.props.items == null) await this.refresh();
    }

    public async refresh()
    {
        if(this.props.onRefresh != null)
        {
            this.setState({...this.state, busy: true, items: []});
            this.props.onRefresh()
                .then((items) => this.setState({...this.state, items: items}))
                .finally(() => this.setState({...this.state, busy: false}))
        }
    }

    private _getCommentNormals(busy: boolean, items: CommentItem[])
    {
        if(busy) return <div>목록을 불러오는 중입니다...</div>
        else if(items.length == 0) return <div>{this.props.placeHolder ?? '의견이 존재하지 않습니다'}</div>;
        else return items.map((item, i) => <CommentControlUI key={randomKey(i)} item={item}/>);
    }
    render()
    {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="card-title" style={{fontWeight: 'lighter'}}>{this.title} {this.state.items.length > 0 ? `(${this.state.items.length}개)` : ''}</div>
                    { this._getCommentNormals(this.state.busy, this.state.items) }
                </div>
            </div>
        )
    }
}

type CommentControlUIProps = {
    item: CommentItem,
    onEdit?: (item: CommentItem) => Promise<CommentItem>,
    onDelete?: (item: CommentItem) => void
}
const CommentControlUI = (props: CommentControlUIProps) =>
{
    const [item, setItem] = useState(props.item);
    const {theme} = useTheme();

    const onEdit = useCallback(() => {if(props.onEdit != null) props.onEdit(item).then((item) => setItem(item))}, [props.onEdit]);
    const onDelete = useCallback(() => {if(props.onDelete != null) props.onDelete(item)}, [props.onDelete]);

    const color = theme.darkMode ? '--tblr-muted' : '--tblr-light-darken';
    return (
        <div style={{marginBottom:"15px"}}>
            <div style={{display:"inline-block", background: `var(${color})`, border: `1px dotted var(${color})`, padding:"10px", borderRadius:"10px"}}>
                {props.item.title == null ? "" : <b>{props.item.title}</b>}
                <div>{props.item.text}</div>
                {props.item.tail == null ? "" : <a>{props.item.tail}</a>}
            </div>
        </div>
    )
}
