import React, {useEffect, useRef, useState} from 'react';
import TableListControl, {CardItemHeader, GetItemStyle, TableItemHeader} from "common/control/TableListControl";
import LoginHistoryData from "data/API/LoginHistoryData";
import {dateFormat} from "@hskernel/hs-utils";
import {PAGE_COUNT, PAGE_MAX, printError} from "Define";
import {useNavigate} from "react-router-dom";

const LoginHistoryPage = () =>
{
    function resultBadge(value: boolean)
    {
        return <span className={value?"badge bg-lime-lt":"badge bg-red-lt"}>{value ? "성공" : "실패"}</span>
    }

    const headerTable: TableItemHeader<LoginHistoryData>[] = [
        {name: "접속 IP", key: "IP", clickable: false, styleItem: GetItemStyle(35, false)},
        {name: "접속 시간", key: "Time", clickable: false, styleItem: GetItemStyle(50, false), onRender: (value => dateFormat(value, "yyyy-mm-dd TT hh:MM:ss"))},
        {name: "결과", key: "Success", clickable: false, styleItem: GetItemStyle(15, false), onRender: (value => value ? "성공" : "실패")}
    ]
    const headerCard: CardItemHeader<LoginHistoryData>[] = [
        //...headerTable[0], styleItem: {}, classNameItem: undefined, onRender: (value, header, item) => <>{resultBadge(item.Success)}&nbsp;{value}</>},
        {...headerTable[0], styleItem: {}, classNameItem: undefined},
        {...headerTable[1], styleItem: {}, classNameItem: undefined},
        {...headerTable[2], styleItem: {}, classNameItem: undefined, isRight: "title", onRender: (value => resultBadge(value))},
    ]

    const table = useRef<TableListControl<LoginHistoryData> | null>(null);
    const navigate = useNavigate();

    async function onRefresh(offset: number, count: number)
    {
        count = Math.max(count, PAGE_COUNT);
        return await LoginHistoryData.GetListAsync(offset, count) ?? []
    }

    async function Refresh()
    {
        try
        {
            if(table.current != null)
            {
                await table.current?.setTotalAsync(await LoginHistoryData.GetCountAsync() ?? 0);
                await table.current.refresh();
            }
        }
        catch (e) { printError(e); }
    }

    useEffect(() => { Refresh().then(); }, []);

    return (
        <div className="col-12 col-md-9 d-flex flex-column">
            <div className="card-body" style={{minHeight: "800px"}}>
                <h2>로그인 기록</h2>
                <TableListControl<LoginHistoryData> ref={table} autoRefresh={false} headerTable={headerTable} headerCard={headerCard} navigate={navigate} maxPage={PAGE_MAX}
                        onRefresh={async (offset, count) => {return {data: await LoginHistoryData.GetListAsync(offset, count) ?? []}}} />
                {/*<table className={styles.history_table}>
                    <tr>
                        <th>접속 IP</th>
                        <th>접속 시간</th>
                        <th>로그인 결과</th>
                    </tr>
                    <tr>
                        <td>1111111</td>
                        <td>{new Date().toISOString()}</td>
                        <td>성공</td>
                    </tr>
                </table>*/}
            </div>
        </div>
    )
}

export default LoginHistoryPage;
