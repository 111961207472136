import {Expose, Type} from "class-transformer";
import axios from "axios";
import {ArrayToBase64, isEmptyString} from "utils/HSUtils";
import {URL_API_FILE_DOWNLOAD} from "Define";
import {SendValueAsync} from "API";

export default class AttachData
{
    @Expose() public Key: string;
    @Expose() public Name: string;
    @Expose() public Size: number;
    @Expose() public Type: string;
    @Expose() public CDNURL: string;
    @Expose() @Type(() => Date)
    public TimeStamp: Date;
    @Expose() public IsImage: boolean;

    public getURLWithParams(url: string)
    {
        if (!isEmptyString(this.getParam())) url += `?${this.getParam()}`;
        return url;
    }

    public getURL = () => isEmptyString(this.CDNURL) ? this.getURLWithParams(`${URL_API_FILE_DOWNLOAD}/${this.Key}`): this.CDNURL;

    private _Param: string | null = null;
    public getParam = () => this._Param;
    public setParam(Param: string | null)
    {
        this._Param = Param;
        return this;
    }

    /**
     * 데이터를 Base64 형식으로 다운로드하여 가져옵니다
     */
    public async getDataAsync(): Promise<string | null>
    {
        const data = await SendValueAsync<Blob>(axios.get(this.getURL(), { responseType: "blob" }), false);
        if(data != null)
        {
            return new Promise(resolve =>
            {
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onloadend = () => resolve(reader.result as string);
            });
        }

        return null;
    }

    /**
     * 썸네일 데이터를 Base64 형식으로 다운로드하여 가져옵니다 (이미지 일때만)
     */
    /*
    public async getThumbDataAsync(): Promise<string | null>
    {
        const data = await SendValueAsync<Blob>(axios.get(this.getURL(), { responseType: "blob" }), false);
        console.log(data)
        if(data != null) return ArrayToBase64(data);

        return null;
    }
     */
}
