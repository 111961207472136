import axios from "axios";
import React, {CSSProperties, useEffect, useRef, useState} from "react";
import { Async } from "react-async";
import LoadingCircle from "../LoadingCircle";
import ImageEmpty from "./image/file-empty.png";

/*eslint @typescript-eslint/no-var-requires: "off"*/
//const fileLoadingIcon = require("./image/file-loading.gif");
const ImageError = require("./image/file-error.png");

const loadingStyle: CSSProperties = {
    width: "auto",
    height: "100%",
};
export const imgStyle: CSSProperties = {
    display: "block",
    width: "100%",
    height: "100%",
    margin: "0 auto"
};

type ImageViewerControlProps = {
    src: string,
    alt?: string,
    defaultImage?: string
}

const ImageViewerControl = ({src, alt, defaultImage}: ImageViewerControlProps) =>
{
    const img = useRef<HTMLImageElement | null>(null);
    const [loading, setLoading] = useState(true);

    const getDefaultImage = () => defaultImage ?? ImageEmpty;

    const getImageBlob = async (url: string): Promise<string | undefined | null> =>
    {
        try
        {
            const response = await axios.get(url, {responseType: 'blob'});
            if(response.status == 200) return URL.createObjectURL(response.data);
            else if(response.status == 204) return null;
            else return undefined;

        }
        catch (e)
        {
            console.log(e);
            return undefined;
        }
    }

    useEffect(() =>
    {
        setLoading(true);
        getImageBlob(src)
            .then(data => { if(img.current != null) img.current.src = data === null ? getDefaultImage() : (data ?? ImageError); })
            .finally(() => setLoading(false))

    }, [src]);

    //<img alt={alt} src={fileLoadingIcon} style={loadingStyle}/>
    return(
        <>
            {loading ? <LoadingCircle size="24px"/> : ''}
            <img ref={img} style={{...imgStyle, display: loading ? 'none' : 'block'}} alt={alt}/>
        </>
    )
}

export default ImageViewerControl;