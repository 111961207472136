import React, {useEffect, useRef, useState} from "react";
import parseHTML from "html-react-parser";
import {useParams} from "react-router-dom";
import {useReactToPrint} from "react-to-print";
import axios from "axios";
import {isNullorWhiteSpace} from "@hskernel/hs-utils";
import {SendValueAsync} from "API";
import {useMenuUI} from "context/MenuUIContext";
import {URL_API_APPROVAL_DOCU_FORMAT} from "../../Define";
import {Close, DocumentFormat } from "./DocumentWritePage";
import ApprovalDocumentTypeData from "../../data/ApprovalDocumentTypeData";
import {reactPrintDummy} from "../../../../Define";

type DocumentFormatViewParam = {
    Class: string
}

const DocumentViewFormatPage = () =>
{
    const {setMenuUI} = useMenuUI();

    const params = useParams<DocumentFormatViewParam>();
    const [text, setText] = useState<string>("");
    const [docFormat, setDocFormat] = useState<ApprovalDocumentTypeData | null | undefined>(undefined);

    const format_view = useRef<HTMLDivElement | null>(null);
    const handlePrint = useReactToPrint({
        content: () => format_view.current,
        documentTitle: docFormat == null ? undefined : docFormat.ClassName
    });

    useEffect(() =>
    {
        setMenuUI({hideAll: true});

        async function Init()
        {
            if (params.Class == null || isNullorWhiteSpace(params.Class)) Close("잘못된 접근 입니다");
            else
            {
                //문서 번호로 불러오기
                try
                {
                    const format = await ApprovalDocumentTypeData.GetDocumentType(params.Class, true);
                    setDocFormat(format);

                    const formatText = await SendValueAsync<DocumentFormat>(axios.get(`${URL_API_APPROVAL_DOCU_FORMAT}/${params.Class}`), true);
                    setText(formatText == null ? "" : formatText.Text);
                }
                catch (ex: any){ alert(ex.message); }
            }
        }
        Init().then();
    }, [])

    function render()
    {
        if(docFormat === undefined)
        {
            return (
                <div>
                    <div className="page page-center">
                        <div className="container container-slim py-4">
                            <div className="text-center" style={{marginTop: "300px"}}>
                                <div className="text-muted mb-3" style={{fontSize: "16px"}}>문서를 불러오는 중입니다.<br/>잠시만 기다려주세요.</div>
                                <div className="progress progress-sm">
                                    <div className="progress-bar progress-bar-indeterminate"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if(docFormat === null) return <h1>문서양식을 찾을 수 없습니다</h1>;
        else 
            return (
                <>
                    <div className="navbar navbar-expand-md d-print-none">
                        <div className="container-xl">
                            <p>문서 양식 보기</p>
                            <div className="navbar-nav flex-row order-md-last">
                                <div className="nav-item d-md-flex me-3">
                                    <div className="btn-list">
                                        <button type="button" className="btn" onClick={() => handlePrint(reactPrintDummy)}>인쇄</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-xl">
                        <div className="card" style={{width:"210mm",margin:"0 auto"}}>
                            <div className="card-body">
                                <div ref={format_view} className="document_format_view">{parseHTML(text)}</div>
                            </div>
                        </div>
                    </div>
                </>
            )
    }
    
    return render();
}

export default DocumentViewFormatPage;