import React, {Component} from 'react';
import AlertData from "../data/API/Alert/AlertData";
import Modal from "react-modal";
import {dateFormat} from "@hskernel/hs-utils";
import {IconX} from "@tabler/icons-react";
import {fireAlertClick} from "Alert";

const AlertViewDialogStyle = {
    content: {
        maxWidth: "600px",
        height:"450px",
        margin: "100px auto",
        background: "transparent",
        border:"none"
    },
    overlay: {
        background: "rgba(0,0,0,0.5)",
        zIndex: "9"
    }
}

type AlertViewDialogProps = {
    /**/
}
type AlertViewDialogState = {
    isOpen: boolean,
    alert: AlertData | null,
    busy: boolean,
    error: string | null,
}

export default class AlertViewDialog extends Component<AlertViewDialogProps, AlertViewDialogState>
{
    constructor(props: AlertViewDialogProps)
    {
        super(props);

        this.state = {
            isOpen: false,
            alert: null,
            busy: false,
            error: null,
        }

        this.close = this.close.bind(this);
        this.setAlertAsync = this.setAlertAsync.bind(this);
        this.setAlertIDAsync = this.setAlertIDAsync.bind(this);
    }

    public async setAlertAsync(alert: AlertData)
    {
        if(alert.IsRead != true)
        {
            this.setState({ isOpen: true, busy: true, alert: alert, error: null });
             try { await AlertData.MarkReadAsync([alert.ID]); }
             catch (e) { /* 알림 읽음 설정 실패!! */ }
             finally { this.setState({...this.state, busy: false, alert: alert, error: null}); }
        }
        else this.setState({isOpen: true, busy: false, alert: alert, error: null});
    }

    public async setAlertIDAsync(alertID: string)
    {
        this.setState({isOpen: true, busy: true, alert: null, error: null});
        try
        {
            const alert = await AlertData.GetViewAsync(alertID);
            if(alert == null) this.setState({...this.state, busy: false, alert: null, error: "해당 알림을 찾을 수 없습니다"});
            else this.setState({...this.state, busy: false, alert: alert, error: null});
        }
        catch (e) { this.setState({...this.state, busy: false, alert: null, error: `알림을 가져오는 중 오류가 발생하였습니다. (${e})`}) }
    }

    public close()
    {
        this.setState({isOpen: false, busy: false, alert: null, error: null})
    }

    render() {
        return (
            <Modal ariaHideApp={false} isOpen={this.state.isOpen} style={AlertViewDialogStyle}>
                {this.state.busy ?
                    <AlertContentView onClose={this.close} placeholder={true}/> :
                    <AlertContentView onClose={this.close} alert={this.state.alert}/>
                }
            </Modal>
        );
    }
}

type AlertContentViewProps = {
    alert?: AlertData | null,
    placeholder?: boolean,
    onClose: () => void,
}
const AlertContentView = ({alert, placeholder, onClose}: AlertContentViewProps) =>
{
    function viewAlert() {
        return alert == null ?
            <div>오류</div>
            :
            <div className="card">
                <h3 className="card-header">
                    <div className="col">{alert.Category} 알림</div>
                    <div className="col-auto" onClick={onClose}>
                        <button className="btn btn-ghost-secondary"><IconX/></button>
                    </div>
                </h3>
                <div className="card-body">
                    <div className="col">
                        <b>제목 : {alert.Title}</b>
                    </div>
                    <div className="col mt-2">
                        <p>내용 : {alert.Message}</p>
                    </div>
                </div>
                <div className="card-body">
                    <div className="col">
                        수신 시간 : {alert.ReadTime && dateFormat(alert.ReadTime, "yyyy-mm-dd TT hh:MM:ss")}
                    </div>
                    <div className="col">
                        읽은 시간 : {alert.ReadTime != null ? dateFormat(alert.ReadTime, "yyyy-mm-dd TT hh:MM:ss") : '-'}
                    </div>
                </div>
                <div className="card-footer bg-transparent mt-auto">
                    <div className="row">
                        <div className="col">
                            <button type="button" className="btn btn-primary" onClick={() => { fireAlertClick(alert); onClose(); }}>바로가기</button>
                        </div>
                        <div className="col-auto">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>확인</button>
                        </div>
                    </div>
                </div>
            </div>
    }

    return (
        placeholder ?
            <div className="card placeholder-glow">
                <div className="card-header">
                    <p className="placeholder col">............</p>
                    <div className="col-auto" onClick={onClose}>
                        <button className="btn btn-ghost-secondary"><IconX/></button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="col">
                        <div className="placeholder col-12">
                            ............
                        </div>
                        <div className="placeholder col-12 mt-1">
                            ............
                        </div>
                        <div className="placeholder col-12 mt-1">
                            ............
                        </div>
                    </div>
                </div>
            </div>
            :
            viewAlert()
    )
}
