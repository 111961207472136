import React, {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import TableListControl, {
    CardItemHeader,
    GetItemStyle,
    TableItemHeader, TableItemHeaderBase,
    TableItemType,
    TableListData
} from "common/control/TableListControl"

import {
    LIST_COUNT,
    LIST_MY_CLASS,
    LIST_KIND_COMPLETE,
    LIST_KIND_DECISION,
    LIST_KIND_EXEC, LIST_KIND_EXEC_ALL,
    LIST_KIND_HOLD,
    LIST_KIND_PROGRESS,
    LIST_KIND_REF,
    LIST_KIND_REJECT,
    LIST_KIND_REPORT,
    LIST_KIND_REQUEST,
    LIST_KIND_TEMP,
    URL_API_APPROVAL_ACTION_APPROVAL,
    LIST_KIND_PROGRESS_ME,
    LIST_KIND_PROGRESS_ALL,
} from "../../Define";
import ApprovalDocumentListData, {
    SEARCH_KIND_DOC, SEARCH_KIND_TITLE, SEARCH_KIND_WRITER,
} from "../../data/ApprovalDocumentListData";
import {DocumentViewOpen} from "./DocumentViewPage";
import ApprovalInfo, {STATUS_INFO_AVAILABLE} from "../../data/ApprovalInfo";
import ApprovalStatusProgress from "../../component/ApprovalStatusProgress";
import {dateFormat, getParam} from "@hskernel/hs-utils";
import {GoErrorPage, PAGE_MAX} from "Define";
import {IconMessageDots, IconPaperclip} from "@tabler/icons-react";
import {GetDocumentStatusColor, GetDocumentStatusText} from "../../data/ApprovalDocumentBase";
import {AuthContextType, useAuth} from "context/AuthContext";
import PasswordInputModal from "common/control/PasswordInputModal";
import AuthData from "Auth";
import {API_RESULT_SUCCESS, APIData, SendValueAsync} from "API";
import axios from "axios";
import {AddAlertCallback, RemoveAlertCallback} from "Alert";
import AlertData from "data/API/Alert/AlertData";
import {
    AddApprovalRefreshCallback,
    ApprovalModuleID,
    DEFAULT_DOCUMENT_SEARCH_PARAM,
    GET_DEFAULT_DATE,
    getMenuCountParam,
    getSearchOptionInit,
    getSearchOptionValueInit,
    RemoveApprovalRefreshCallback,
    SEARCH_CONTROL_NAME,
    SearchDocumentParam,
    setMenuCountParam
} from "../../info/approval";
import {getBadgeStyle} from "DefineStyle";
import SearchWithDateRangeControl, {SearchWithDateRangeControlClass} from "common/control/SearchWithDateRangeControl";
import {SelectOptionValue} from "../../../../common/component/SelectOption";



function getDefaultStyle(item: ApprovalDocumentListData, changeColor = true): React.CSSProperties
{
    return item.ReadCount == 0 ? {fontWeight: 'bold'} : {fontWeight: 'normal', color: changeColor ? 'var(--tblr-secondary)' : undefined};
}
const renderDefaultStyle = (value: any, header: TableItemHeaderBase<ApprovalDocumentListData>, item: ApprovalDocumentListData, renderText: string, changeColor = true) => <div style={getDefaultStyle(item, changeColor)}>{renderText}</div>

export const StatusHeader: TableItemHeader<ApprovalDocumentListData> = {
    name: "상태", key: "ApprovalInfo", clickable: true, styleItem: {...GetItemStyle(7, true), color: "#6199ff"},
    onRender: (value: ApprovalInfo, header, data) => renderDefaultStyle(value, header, data, value == null ? data.ApprovalString : value.ToString(false), false),
    onHover: (isLeave, value) => {
        //console.log(isLeave)
    },
};

type DocumentListPageProps = {
    kind?: string,
    myClass?: string,
}
type DocumentListPageState = DocumentListPageProps & {
    headerTable: TableItemHeader<ApprovalDocumentListData>[]
    headerCard: CardItemHeader<ApprovalDocumentListData>[]
}

function DocumentListPage(props: DocumentListPageProps)
{
    const {kind} = useParams();
    const [state, setState] = useState<DocumentListPageState>({headerTable: [], headerCard: []});
    const auth = useAuth();
    const [checkable, setCheckable] = useState(false);

    const renderTitle = (width: number): TableItemHeader<ApprovalDocumentListData> =>
    {
        return {
            name: "제목",
            key: "Title",
            clickable: true,
            styleItem: GetItemStyle(width, true, true),
            onRender: (title, header, data) =>
                <div style={getDefaultStyle(data)}>
                    {title}
                    {data.IsAttachFile ? <>&nbsp;<IconPaperclip stroke={1} width={16} height={16}/></> : ""}
                    {data.Comments > 0 ? <>&nbsp;<IconMessageDots stroke={1} width={16} height={16}/></> : ""}
                </div>
        }
    }
    const renderTitleCard = (valueUserName: any, header: TableItemHeaderBase<ApprovalDocumentListData>, item: ApprovalDocumentListData) =>
    {
        //TODO: 나중에 읽음 또는 결재가능 여부 확인해서 회색 / 검은색 처리하기
        const Text = GetDocumentStatusText(item.DocumentStatus);
        const badgeColor = `bg-${GetDocumentStatusColor(item.DocumentStatus)}-lt`;
        const badgeTextColor = "color";

        const style = getDefaultStyle(item, false);
        return (
            <div style={style}>
                <span className={`badge ${badgeColor}`} style={{...style, color: badgeTextColor}}>{Text}</span>
                &nbsp;&nbsp;
                {item.getUserData().toString()}
            </div>)
    }
    const renderDocumentClassCard = (DocClass: string, header: TableItemHeaderBase<ApprovalDocumentListData>, item: ApprovalDocumentListData) => getBadgeStyle(DocClass, 'blue', getDefaultStyle(item));

    useEffect(() =>
    {
        let headerTable: TableItemHeader<ApprovalDocumentListData>[] = [];
        let headerCard: CardItemHeader<ApprovalDocumentListData>[] = [];
        const _kind = props.kind ?? kind;
        const _myClass = props.myClass ?? getParam("class");
        let checkable = false;

        headerTable = [
            {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(10, false), onRender: renderDefaultStyle},
            {name: "문서번호", key: "DocumentID", clickable: true, styleItem: GetItemStyle(20, true), onRender: renderDefaultStyle},
            renderTitle(30),
            {name: "기안일자", key: "WriteDate", clickable: false, styleItem: GetItemStyle(10, false), type: TableItemType.Date, onRender: renderDefaultStyle},
            {name: "기안부서", key: "WriteDeptName", clickable: false, styleItem: GetItemStyle(10, false), onRender: renderDefaultStyle},
            {name: "기안자", key: "WriteNameKor", clickable: false, styleItem: GetItemStyle(10, false), onRender: renderDefaultStyle},
            StatusHeader,
        ];
        headerCard = [
            {...headerTable[5], styleItem: {}, classNameItem: undefined, onRender: renderTitleCard},
            {...headerTable[2], styleItem: {}, classNameItem: undefined},
            {...headerTable[3], styleItem: {}, classNameItem: undefined, isRight: "normal"},
            {...headerTable[0], styleItem: {}, classNameItem: undefined, isRight: "title", onRender: renderDocumentClassCard},
        ]

        if(auth.admin)
        {
            if(_kind == LIST_MY_CLASS)
            {
                if(_myClass == null)
                {
                    GoErrorPage(404, "문서 형식이 지정되지 않았습니다");
                }
                else
                {
                    headerTable = [
                        {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(10, false), onRender: renderDefaultStyle},
                        {name: "문서번호", key: "DocumentID", clickable: true, styleItem: GetItemStyle(15, true), onRender: renderDefaultStyle},
                        renderTitle(20),
                        {name: "기안일자", key: "WriteDate", clickable: false, styleItem: GetItemStyle(10, false), type: TableItemType.Date, onRender: renderDefaultStyle},
                        {name: "기안부서", key: "WriteDeptName", clickable: false, styleItem: GetItemStyle(10, false), onRender: renderDefaultStyle},
                        {name: "기안자", key: "WriteNameKor", clickable: false, styleItem: GetItemStyle(7, false), onRender: renderDefaultStyle},
                        {name: "공급가액", key: "SupplyAMT", clickable: true, styleItem: GetItemStyle(7, true, false), onRender: ((value, header, item) => renderDefaultStyle(value, header, item, value.toLocaleString()))},
                        {name: "부가세액", key: "SupplyVAT", clickable: true, styleItem: GetItemStyle(7, true, false), onRender: ((value, header, item) => renderDefaultStyle(value, header, item, value.toLocaleString()))},
                        {name: "공급대가", key: "SupplyTOT", clickable: true, styleItem: GetItemStyle(7, true, false), onRender: ((value, header, item) => renderDefaultStyle(value, header, item, value.toLocaleString()))},
                        StatusHeader,
                    ];
                }
            }
        }
        else
        {
            //임시보관함
            if (_kind == LIST_KIND_TEMP)
            {
                headerTable = [
                    {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(20, false), onRender: renderDefaultStyle},
                    renderTitle(65),
                    {name: "작성일", key: "WriteDate", clickable: false, styleItem: GetItemStyle(15, false), type: TableItemType.Date, onRender: renderDefaultStyle}
                ];

                headerCard = [
                    {...headerTable[0], styleItem: {}, clickable: true},
                    {...headerTable[1], styleItem: {}},
                    {...headerTable[2], styleItem: {}, classNameItem: undefined, isRight: "normal"},
                ]
            }
            //미결함
            else if (_kind == LIST_KIND_PROGRESS || _kind == LIST_KIND_PROGRESS_ALL || _kind == LIST_KIND_PROGRESS_ME)
            {
                checkable = true;
                headerTable = [
                    {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(10, false), onRender: renderDefaultStyle},
                    {name: "문서번호", key: "DocumentID", clickable: true, styleItem: GetItemStyle(18, true), onRender: renderDefaultStyle},
                    renderTitle(25),
                    {name: "기안일자", key: "WriteDate", clickable: false, styleItem: GetItemStyle(9, false), type: TableItemType.Date, onRender: renderDefaultStyle},
                    {name: "수신일자", key: "ApprovalInfo.Current.ReceiveDate", clickable: false, styleItem: GetItemStyle(9, false), type: TableItemType.Date, onRender: renderDefaultStyle},
                    {name: "기안부서", key: "WriteDeptName", clickable: false, styleItem: GetItemStyle(10, false), onRender: renderDefaultStyle},
                    {name: "기안자", key: "WriteNameKor", clickable: false, styleItem: GetItemStyle(9, false), onRender: renderDefaultStyle},
                    StatusHeader
                ];

                headerCard = [
                    {...headerTable[6], styleItem: {}, classNameItem: undefined},
                    {...headerTable[2], styleItem: {}, classNameItem: undefined},
                    {...headerTable[3], styleItem: {}, classNameItem: undefined, isRight: "normal"},
                    {...headerTable[0], styleItem: {}, classNameItem: undefined, isRight: "title", onRender: renderDocumentClassCard},
                ]
            }
            //기결함
            else if (_kind == LIST_KIND_DECISION)
            {
                headerTable = [
                    {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(10, false)},
                    {name: "문서번호", key: "DocumentID", clickable: true, styleItem: GetItemStyle(17, true)},
                    renderTitle(18),
                    {
                        name: "기안일자",
                        key: "WriteDate",
                        clickable: false,
                        styleItem: GetItemStyle(9, false),
                        type: TableItemType.Date,
                        onRender: renderDefaultStyle
                    },
                    {
                        name: "결재일자",
                        key: "ApprovalInfo.Me.ApprovalDate",
                        clickable: false,
                        styleItem: GetItemStyle(9, false),
                        type: TableItemType.Date,
                        onRender: renderDefaultStyle
                    },
                    {
                        name: "종결일자",
                        key: "ApprovalInfo.Current.ApprovalDate",
                        clickable: false,
                        styleItem: GetItemStyle(9, false),
                        type: TableItemType.Date,
                        onRender: renderDefaultStyle
                    },
                    {name: "기안부서", key: "WriteDeptName", clickable: false, styleItem: GetItemStyle(9, false), onRender: renderDefaultStyle},
                    {name: "기안자", key: "WriteNameKor", clickable: false, styleItem: GetItemStyle(9, false), onRender: renderDefaultStyle},
                    StatusHeader,
                ];

                headerCard = [
                    {...headerTable[6], styleItem: {}, classNameItem: undefined, onRender: renderTitleCard},
                    {...headerTable[2], styleItem: {}, classNameItem: undefined},
                    {...headerTable[3], styleItem: {}, classNameItem: undefined, isRight: "normal"},
                    {...headerTable[0], styleItem: {}, classNameItem: undefined, isRight: "title", onRender: renderDocumentClassCard},
                ]
            }
            //전/종결함, 집행함
            else if (_kind == LIST_KIND_COMPLETE || _kind == LIST_KIND_EXEC)
            {
                headerTable = [
                    {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(10, false)},
                    {name: "문서번호", key: "DocumentID", clickable: true, styleItem: GetItemStyle(17, true)},
                    renderTitle(27),
                    {
                        name: "기안일자",
                        key: "WriteDate",
                        clickable: false,
                        styleItem: GetItemStyle(9, false),
                        type: TableItemType.Date
                    },
                    {
                        name: "종결일자",
                        key: "ApprovalInfo.Current.ApprovalDate",
                        clickable: false,
                        styleItem: GetItemStyle(9, false),
                        type: TableItemType.Date
                    },
                    {name: "기안부서", key: "WriteDeptName", clickable: false, styleItem: GetItemStyle(9, false)},
                    {name: "기안자", key: "WriteNameKor", clickable: false, styleItem: GetItemStyle(9, false)},
                    StatusHeader,
                ];

                headerCard = [
                    {...headerTable[6], styleItem: {}, classNameItem: undefined, onRender: renderTitleCard},
                    {...headerTable[2], styleItem: {}, classNameItem: undefined},
                    {...headerTable[3], styleItem: {}, classNameItem: undefined, isRight: "normal"},
                    {...headerTable[0], styleItem: {}, classNameItem: undefined, isRight: "title", onRender: renderDocumentClassCard},
                ]
            }
            //수신참조함
            /*
            else if(_kind == LIST_KIND_REF)
            {
                headerTable = [
                    {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(10, false)},
                    {name: "문서번호", key: "DocumentID", clickable: true, styleItem: GetItemStyle(20, true)},
                    renderTitle(30),
                    {
                        name: "기안일자",
                        key: "WriteDate",
                        clickable: false,
                        styleItem: GetItemStyle(10, false),
                        type: TableItemType.Date
                    },
                    {name: "기안부서", key: "WriteDeptName", clickable: false, styleItem: GetItemStyle(10, false)},
                    {name: "기안자", key: "WriteNameKor", clickable: false, styleItem: GetItemStyle(10, false)},
                    StatusHeader,
                ];
                headerCard = [
                    {...headerTable[5], styleItem: {}, classNameItem: undefined, onRender: renderTitleCard},
                    {...headerTable[2], styleItem: {}, classNameItem: undefined},
                    {...headerTable[3], styleItem: {}, classNameItem: undefined, isRight: "normal"},
                    {...headerTable[0], styleItem: {}, classNameItem: undefined, isRight: "title", onRender: renderDocumentClassCard},
                ]
            }
             */

            //집행확인함
            else if (_kind == LIST_KIND_EXEC_ALL)
            {
                headerTable = [
                    {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(12, false)},
                    {name: "문서번호", key: "DocumentID", clickable: true, styleItem: GetItemStyle(15, true)},
                    renderTitle(21),
                    {
                        name: "기안일자",
                        key: "WriteDate",
                        clickable: false,
                        styleItem: GetItemStyle(8, false),
                        type: TableItemType.Date,
                        onRender: renderDefaultStyle
                    },
                    {
                        name: "종결일자",
                        key: "ApprovalInfo.Current.ApprovalDate",
                        clickable: false,
                        styleItem: GetItemStyle(8, false),
                        type: TableItemType.Date,
                        onRender: renderDefaultStyle
                    },
                    {name: "기안부서", key: "WriteDeptName", clickable: false, styleItem: GetItemStyle(8, false), onRender: renderDefaultStyle},
                    {name: "기안자", key: "WriteNameKor", clickable: false, styleItem: GetItemStyle(8, false), onRender: renderDefaultStyle},
                    StatusHeader,
                    {name: "집행", key: "ApprovalInfo.ExecTime", clickable: false, styleItem: GetItemStyle(11, false), onRender: (value, header, item) => renderDefaultStyle(value, header, item, dateFormat(value, "yyyy-mm-dd HH:MM"))}
                ];

                headerCard = [
                    {...headerTable[6], styleItem: {}, classNameItem: undefined, onRender: renderTitleCard},
                    {...headerTable[2], styleItem: {}, classNameItem: undefined},
                    {...headerTable[3], styleItem: {}, classNameItem: undefined, isRight: "normal"},
                    {...headerTable[0], styleItem: {}, classNameItem: undefined, isRight: "title", onRender: renderDocumentClassCard},
                ]
            }
            //상신함, (전결함), 반려함, 보류함
            else if (_kind == LIST_KIND_REQUEST || _kind == LIST_KIND_REPORT || _kind == LIST_KIND_REJECT || _kind == LIST_KIND_HOLD)
            {
                headerTable = [
                    {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(10, false), onRender: renderDefaultStyle},
                    {name: "문서번호", key: "DocumentID", clickable: true, styleItem: GetItemStyle(15, true), onRender: renderDefaultStyle},
                    renderTitle(31),
                    {
                        name: "기안일자",
                        key: "WriteDate",
                        clickable: false,
                        styleItem: GetItemStyle(9, false),
                        type: TableItemType.Date,
                        onRender: renderDefaultStyle
                    },
                    {name: "기안부서", key: "WriteDeptName", clickable: false, styleItem: GetItemStyle(10, false), onRender: renderDefaultStyle},
                    {name: "기안자", key: "WriteNameKor", clickable: false, styleItem: GetItemStyle(10, false), onRender: renderDefaultStyle},
                    StatusHeader
                ];

                headerCard = [
                    {...headerTable[5], styleItem: {}, classNameItem: undefined, onRender: renderTitleCard},
                    {...headerTable[2], styleItem: {}, classNameItem: undefined},
                    {...headerTable[3], styleItem: {}, classNameItem: undefined, isRight: "normal"},
                    {...headerTable[0], styleItem: {}, classNameItem: undefined, isRight: "title", onRender: renderDocumentClassCard},
                ]
            }
            //특정 문서 유형만 불러오기
            else if(_kind == LIST_MY_CLASS)
            {
                console.log(`MY CLASS!! ${_myClass}`)
                if(_myClass == null)
                {
                    GoErrorPage(404, "문서 형식이 지정되지 않았습니다");
                }
                else
                {
                    headerTable = [
                        {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(10, false), onRender: renderDefaultStyle},
                        {name: "문서번호", key: "DocumentID", clickable: true, styleItem: GetItemStyle(15, true), onRender: renderDefaultStyle},
                        renderTitle(20),
                        {
                            name: "기안일자",
                            key: "WriteDate",
                            clickable: false,
                            styleItem: GetItemStyle(10, false),
                            type: TableItemType.Date,
                            onRender: renderDefaultStyle
                        },
                        {
                            name: "종결일자",
                            key: "ApprovalInfo.Current.ApprovalDate",
                            clickable: false,
                            styleItem: GetItemStyle(10, false),
                            type: TableItemType.Date,
                            onRender: renderDefaultStyle
                        },
                        {name: "공급가액", key: "SupplyAMT", clickable: true, styleItem: GetItemStyle(7, true, false), onRender: ((value, header, item) => renderDefaultStyle(value, header, item, value.toLocaleString()))},
                        {name: "부가세액", key: "SupplyVAT", clickable: true, styleItem: GetItemStyle(7, true, false), onRender: ((value, header, item) => renderDefaultStyle(value, header, item, value.toLocaleString()))},
                        {name: "공급대가", key: "SupplyTOT", clickable: true, styleItem: GetItemStyle(7, true, false), onRender: ((value, header, item) => renderDefaultStyle(value, header, item, value.toLocaleString()))},
                        StatusHeader
                    ];

                    headerCard = [
                        {name: "기안자", key: "WriteNameKor", styleItem: {}, classNameItem: undefined, onRender: renderTitleCard},
                        {...headerTable[2], styleItem: {}, classNameItem: undefined},
                        {...headerTable[3], styleItem: {}, classNameItem: undefined, isRight: "normal"},
                        {...headerTable[0], styleItem: {}, classNameItem: undefined, isRight: "title", onRender: renderDocumentClassCard},
                    ]
                }
            }

            setCheckable(checkable);
        }

        setState({kind: _kind, headerTable: headerTable, headerCard: headerCard, myClass: _myClass ?? undefined});

    }, [props.kind ?? kind, props.myClass]);

    return (state.kind == null || auth.auth == null ?
        <h3 style={{textAlign:"center",padding:"5px",fontWeight:"500"}}>잘못된 접근 입니다</h3> :
        <DocumentListPageRender auth={auth.auth} isAdminMode={auth.admin} headerTable={state.headerTable} headerCard={state.headerCard} kind={state.kind} checkable={checkable} myClass={state.myClass}/>)
}

export default DocumentListPage;

type DocumentListPageRenderProps = {
    headerTable: TableItemHeader<ApprovalDocumentListData>[],
    headerCard: CardItemHeader<ApprovalDocumentListData>[],
    kind: string,
    checkable: boolean,
    myClass?: string,
    auth: AuthData,
    isAdminMode: boolean,
}
/*
type DocumenListPageRenderState = {
    total: number,
    count: number,
    search: SearchDocumentParam
}
 */

type ApprovalStatusInfoDialog = {
    docu: ApprovalDocumentListData | null
}
const DocumentListPageRender = ({headerTable, headerCard, kind, myClass, ...props}: DocumentListPageRenderProps) =>
{
    const getButtonEnable = (kind: string) => !props.isAdminMode && (kind == LIST_KIND_PROGRESS || kind == LIST_KIND_PROGRESS_ALL || kind == LIST_KIND_PROGRESS_ME) ? 0 : -1;
    const initSearchData = GET_DEFAULT_DATE();

    const tableControl = useRef<TableListControl<ApprovalDocumentListData>>(null);
    const searchControl = useRef<SearchWithDateRangeControlClass>(null);
    const navigate = useNavigate();

    //일괄 결재버튼 활성화 여부
    const [busy, setBusy] = useState(false);

    const [search, setSearch] = useState<SearchDocumentParam>(DEFAULT_DOCUMENT_SEARCH_PARAM(getSearchOptionInit(props.isAdminMode, kind)));
    const [searchOptions, setSearchOptions] = useState(getSearchOptionInit(props.isAdminMode, kind));
    const [searchOptionValues, setSearchOptionValues] = useState<SelectOptionValue[]>(getSearchOptionValueInit(props.isAdminMode, kind));

    const [_kind, setKind] = useState<string>(kind);
    const [_class, setClass] = useState<string | undefined>(myClass);
    const [checked] = useState<Map<string, any>>(new Map<string, any>());
    /**
     * -1: 버튼 안보임, 0: 버튼 비활성화, 1: 버튼 활성화
     */
    const [button, setButton] = useState(getButtonEnable(kind));
    const [approvalStateOpen, setApprovalStateOpen] = useState<ApprovalStatusInfoDialog>({docu: null});

    const pwDialog = useRef<PasswordInputModal | null>(null);

    const _onRefresh = useCallback(() =>
    {
        Search().then();
        //TODO: URL 에 검색 파라미터 생성하기
        //search.
    }, []);
    //알림 도착시 새로고침
    const onRefreshWithAlert = useCallback((alert?: AlertData | null) =>
    {
        if(alert != null && alert.Module == ApprovalModuleID) _onRefresh();
    }, []);

    useEffect(() =>
    {
        //마운트 시 알림 도착시 새로고침
        AddAlertCallback(onRefreshWithAlert);
        //전역 새로고침
        AddApprovalRefreshCallback(_onRefresh);

        //언마운트 시 알림 새로고침 비활성화
        return () =>
        {
            RemoveAlertCallback(onRefreshWithAlert)
            RemoveApprovalRefreshCallback(_onRefresh)
        };
    }, []);

    //검색 데이터가 바뀔때..
    useEffect(() =>
    {
        const matchData = (a?: Date | null, b?: Date | null) =>
        {
            if (a == null && b == null) return true;
            if (a != null && b != null) return a.getFullYear() == b.getFullYear() && a.getMonth() == b.getMonth() && a.getDate() == b.getDate();

            return false;
        }

        const TotalSearchParam = getMenuCountParam();
        let _A = false;
        let _B = false;
        if (TotalSearchParam == null) setMenuCountParam(search);
        else
        {
            _A = !matchData(TotalSearchParam.start, search.start);
            _B = !matchData(TotalSearchParam.end, search.end);
        }
        if (_A || _B) setMenuCountParam(search);

        async function run()
        {
            if (tableControl.current != null) await tableControl.current.setPageAsync(1);

            await Search(search);
        }
        run().then();
    }, [search]);

    async function changeClearAsync()
    {
        const searchOptions = getSearchOptionInit(props.isAdminMode, kind);
        const searchOptionValues = getSearchOptionValueInit(props.isAdminMode, kind);
        setSearchOptions(searchOptions);
        setSearchOptionValues(searchOptionValues);

        if (_kind != kind || _class != myClass)
        {
            checked.clear();

            setKind(kind);
            setClass(myClass);

            //버튼 숨기기/비활성화
            setButton(getButtonEnable(kind));

            //검색 초기화
            const search = DEFAULT_DOCUMENT_SEARCH_PARAM(searchOptions, true);
            setSearch(search);
            if (searchControl.current != null)
            {
                await searchControl.current.setOptionsAsync(searchOptions, searchOptionValues);
                await searchControl.current.setDataAsync(search.keyword ?? null, searchOptionValues, search.start ?? null, search.end ?? null);
            }

            //단순 새로고침
            //Search(search).then();
        }

        //setCheckable(_kind)
    }
    //목록 종류가 바뀔때...
    useEffect(() => {changeClearAsync().then()}, [kind, myClass, props.isAdminMode]);

    async function Search(_search?: SearchDocumentParam)
    {
        if (tableControl.current != null)
        {
            //갯수 0으로 초기화
            await tableControl.current.setTotalAsync(0);

            //검색시에는 테이블의 아이템, 페이지, 총 갯수 등이 초기화됨
            const total = await ApprovalDocumentListData.GetCountAsync(_kind, _search ?? search, _class);

            await tableControl.current.setTotalAsync(total ?? 0);
            await tableControl.current.refresh();
        }
    }

    const onCheck = (item: ApprovalDocumentListData, _checked: boolean, index: number) =>
    {
        //체크시 여기 호출
        if(_checked) checked.set(item.DocumentID, null);
        else checked.delete(item.DocumentID);
        setButton(checked.size == 0 ? 0 : 1);
    }

    function onCheckRender(item: ApprovalDocumentListData)
    {
        //미결함
        if(getButtonEnable(_kind) == 0) return item.ApprovalInfo.Approvalable == STATUS_INFO_AVAILABLE;
        return true;
    }

    const onRefresh = async (offset: number, count: number, page: number, total: number): Promise<TableListData<ApprovalDocumentListData>> =>
    {
        if (total > 0 && count > 0)
        {
            const list = await ApprovalDocumentListData.GetListAsync(kind, search, myClass, offset, count);
            if (list != null) return {data: list};
        }

        return {data: []};
    }

    function onApproval()
    {
        if(confirm("선택한 문서들을 알괄 결재하시겠습니까?"))
        {
            if(pwDialog.current != null) pwDialog.current.openDialog(true, null)
        }
    }
    async function actionApproval(password: string, comment: string | null)
    {
        setBusy(true);
        try
        {
            let docs = "";
            let first = true;
            checked.forEach((value, docID) =>
            {
                if(first) { docs = docID; first = false; }
                else docs += `,${docID}`;
            });

            const apiData = await SendValueAsync<APIData>(axios({
                    method: "POST",
                    url: `${URL_API_APPROVAL_ACTION_APPROVAL}/${docs}`,
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    data: {"Password": password, "Comment": comment}
                }
            ), false);

            if(apiData != null && apiData.result != API_RESULT_SUCCESS) alert(apiData.message);

            if(tableControl.current != null) tableControl.current.refresh().then();
            return true;
        }
        finally { setBusy(false) }

        return false;
    }

    return (
        <>
            <PasswordInputModal ref={pwDialog} showPassword={props.auth.setting.SimplePWUse} showComment={true} onOK={actionApproval}/>
            <ApprovalStatusProgress isOpen={approvalStateOpen.docu != null} doc={approvalStateOpen.docu} approvals={approvalStateOpen.docu ? approvalStateOpen.docu.Approvals : undefined} onClose={() => setApprovalStateOpen({docu: null})}/>
            <SearchWithDateRangeControlClass ref={searchControl} name={SEARCH_CONTROL_NAME} navigate={navigate} dateRangeTitle={_kind == LIST_KIND_TEMP ? "작성일" : "기안일"} clearParam={TableListControl.URLParamKeys}
                                             defaultOptions={searchOptions} defaultOptionValues={searchOptionValues} defaultDateStart={initSearchData.start ?? undefined} defaultDateEnd={initSearchData.end ?? undefined}
                                             onChange={(search) => setSearch(SearchDocumentParam.fromSearchData(search))}/>
            <div className="card-body">
                {button == -1 ? "" :
                    <div className="text-end mb-2 m_display">
                        <button className="btn btn-green" disabled={button == 0 || busy} onClick={onApproval}>결재</button>
                    </div>
                }
                <TableListControl<ApprovalDocumentListData> ref={tableControl} headerTable={headerTable} headerCard={headerCard} autoRefresh={false} checkable={props.checkable}
                                                        count={LIST_COUNT} maxPage={PAGE_MAX}
                                                        navigate={navigate}
                                                        onCheckRender={onCheckRender}
                                                        onChecked={onCheck}
                                                        onRefresh={onRefresh}
                                                        onItemLongClick={(data) => setApprovalStateOpen({docu: data})}
                                                        onItemClick={(value, header, data) =>
                  {
                      if (header.key == "ApprovalInfo") setApprovalStateOpen({docu: data});
                      else
                      {
                          DocumentViewOpen(data.DocumentID);
                          if (data.ReadCount == 0) data.ReadCount = 1;
                          if (tableControl.current != null) tableControl.current.doRerenderAsync().then();
                      }
                  }}/>
            </div>
        </>
    )
}
