import ApprovalLineBase, {ApprovalLineShareType} from "./ApprovalLineBase";
import {Expose, plainToInstance} from "class-transformer";
import {IsArray} from "class-validator";
import {serializeForm} from "@hskernel/hs-utils";
import axios from "axios";
import {URL_API_APPROVAL_LINE} from "../Define";

export default class ApprovalLineForm extends ApprovalLineBase
{
    /**
     * [폼] 결재라인 이름
     * @type {string}
     */
    @Expose() public FormLineName: string;
    /**
     * [폼] 결재라인 공유
     * @type {ApprovalLineShareType}
     */
    @Expose() public FormShareType: ApprovalLineShareType;

    /**
     * [폼] 결재라인 지정 목록 (사용자 ID:결재라인 종류:전결여부)
     * @type {string[]}
     */
    @Expose() @IsArray()
    public FormApproval: string[];
    /**
     * [폼] 시행자 목록 (사용자 ID)
     * @type {string[]}
     */
    @Expose() @IsArray()
    FormExecUser: string[];
    /**
     * [폼] 수신및참조 목록 (사용자 ID)
     * @type {string[]}
     */
    @Expose() @IsArray()
    FormRefUser: string[];


    public static FromForm(form: HTMLFormElement): ApprovalLineForm
    {
        const obj = serializeForm(new FormData(form));
        return plainToInstance(ApprovalLineForm, obj);
    }

    public async SaveAsync()
    {
        await axios({
            method: "POST",
            url: URL_API_APPROVAL_LINE,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: this,
            // 폼에서 [] 없애기
            formSerializer: { indexes: null },
        });
    }
}