import {isWVMode, onReceiveRNDataWVAdd, RNMessageDataWV} from "./lib/RNMessage/RNMessageWV";
import {isDebugMode, registerFCMToken} from "./Define";
//import {plainToInstance} from "class-transformer";
import AlertData from "./data/API/Alert/AlertData";
import {fireAlert, fireAlertClick, KEY_WV_ALERTS} from "./Alert";

export function InitEventHandler()
{
    onReceiveRNDataWVAdd((e: RNMessageDataWV) =>
    {
        if (isDebugMode()) console.log(`EVENT DATA: ${JSON.stringify(e)}`);
        if (e.type == 'ALERT') alert(e.data);
        else if (e.type == 'TOKEN_FCM') registerFCMToken(e.data).then();
        else if (e.type == 'NOTI')
        {
            //const alert = e.data == null ? null : plainToInstance<AlertData, any>(AlertData, e.data, {});
            const alert = AlertData.fromData(e.data);
            if (alert != null) fireAlert(alert);

            console.info('FRONTEND NOTI RECEIVED!!', alert);
        }
        else if (e.type == 'NOTI_CLICK')
        {
            const alert = AlertData.fromData(e.data);
            if (alert != null) fireAlertClick(alert);

            console.info(`FRONTEND NOTI CLICKED!!: ${JSON.stringify(alert)}`);
        }
        //else if(e.type == 'DARKMODE') ;  /* 다크모드 변경될 때 */
    });
}
