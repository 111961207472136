import {URL_API_COMPANY_DEPT} from "Define";
import UserDataBase from "./User/UserDataBase";
import {SendValueAsyncArrayType} from "API";
import {Expose} from "class-transformer";
import axios from "axios";

export class DepartmentData
{
    @Expose() public DepartmentCode: string;
    @Expose() public ParentCode: string;
    @Expose() public Name: string;
    @Expose() public Level: number;
    @Expose() public LevelSeq: number;
    /**
     *  부서명 최초 생성일자
     */
    @Expose() public FirstDate: Date;
    /**
     * 부서명 수정일자
     */
    @Expose() public UpDate: Date;

    constructor()
    {
        this.toString = this.toString.bind(this);
    }

    toString(){ return this.Name; }

    static async GetDepartmentUsers(DepartmentCode: string, isEmployee?: boolean): Promise<UserDataBase[] | null>
    {
        //console.log("deptCode: " + DepartmentCode)
        return await SendValueAsyncArrayType<UserDataBase>(axios.get(`${URL_API_COMPANY_DEPT}/${DepartmentCode}/users?IsEmployee=${isEmployee == null ? "" : isEmployee}`), true, UserDataBase);
    }
}
