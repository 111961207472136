import React, {useEffect, useState} from "react";
import {IconMail, IconPlus} from "@tabler/icons-react";
import {GetConfigAsync, LoginAsync, MailplugConfig} from "../Define";
import MailplugMailList, {GetMailListAsync} from "../data/MailplugMailList";
import {isMockupMode, printError} from "Define";
import {dateFormat, randomKey} from "@hskernel/hs-utils";
import {sleep} from "@hskernel/hs-utils-html5";

type MailPlugListWidgetProps = {
    isUse: boolean
}

const MailPlugListWidget = ({isUse}: MailPlugListWidgetProps) =>
{
    const [loading, setLoading] = useState<boolean>(false);
    const [config, setConfig] = useState<MailplugConfig | null>(null);
    const [list, setList] = useState<MailplugMailList[]>([]);

    async function Refresh()
    {
        setLoading(true);
        setList([]);
        try
        {
            const config = await GetConfigAsync();
            setConfig(config);

            const list = await GetMailListAsync(2);
            setList(list ?? []);
        }
        catch (ex) { printError(ex); }

        setLoading(false);
    }
    useEffect(() =>
    {
        if(isUse) Refresh().then();
    }, []);

    async function gotoMailView(url?: string)
    {
        if(config != null)
        {
            const src = await LoginAsync(config, url);
            //window.open(src, "_blank");

            const childWin = window.open("", "childWin");
            if(childWin == null) alert("팝업을 허용한 후 다시 시도해 주세요");
            else
            {
                childWin.location.href = src;

                await sleep(3000);
                await Refresh();
            }
        }
    }

    function getBadge(mail_read: string)
    {
        if(mail_read == "Y") return "bg-secondary-lt";
        else if(mail_read == "N") return "bg-lime-lt";
        else if(mail_read == "F") return "bg-red"; //전달
        else if(mail_read == "R") return "bg-blue"; //답장
        else return mail_read;
    }
    function getBadgeString(mail_read: string)
    {
        if(mail_read == "Y") return "읽음";
        else if(mail_read == "F") return "전달";
        else if(mail_read == "N") return "안 읽음";
        else return mail_read;
    }
    function render()
    {
        if(!isUse) return (
            <div className="list-group list-group-flush list-group-hoverable placeholder-glow"
                 style={{height: "178px"}}>
                <div className="list-group-item text-center">
                    <p>메일을 사용하지 않는 사용자입니다</p>
                </div>
            </div>
        )

        return (loading ?
            <div className="list-group list-group-flush list-group-hoverable placeholder-glow" style={{height:"178px"}}>
                <div className="list-group-item">
                    <div className="row">
                        <div className="col-auto placeholder">
                            읽음
                        </div>
                        <div className="col placeholder text-truncate">
                            <a className=" w-100">........</a>
                        </div>
                        <div className="col-auto placeholder">
                            ????-??-?? ??:??:??
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col placeholder">.....</div>
                    </div>

                </div>
                <div className="list-group-item">
                    <div className="row">
                        <div className="col-auto placeholder">
                            읽음
                        </div>
                        <div className="col placeholder text-truncate">
                            <a className=" w-100">........</a>
                        </div>
                        <div className="col-auto placeholder">
                            ????-??-?? ??:??:??
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col placeholder">.....</div>
                    </div>

                </div>
            </div>
            :
            <div className="list-group list-group-flush list-group-hoverable">
                {list.map((mail, i) => (
                    <div className="list-group-item" key={randomKey(i)} style={{cursor: 'pointer'}} onClick={() => gotoMailView(mail.return_url)}>
                        <div className="row">
                            <div className="col-auto">
                                <span className={`badge ${getBadge(mail.mail_read)}`} style={{ color: "white" }}>{getBadgeString(mail.mail_read)}</span>
                            </div>
                            <div className="col text-truncate">
                                <a>{isMockupMode() ? `테스트 메일${Math.round(Math.random() * 100)}` : mail.subject}</a>
                            </div>
                            <div className="col-auto text-muted">
                                {dateFormat(mail.getDate(), "yyyy-mm-dd HH:MM")}
                            </div>
                        </div>
                        <div className="row text-muted mt-2">
                            <div className="col">발신자 | {isMockupMode() ? 'test@test.com' : mail.mail_from}</div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
    return (
        <div className="card" style={{height:"244px"}}>
            <div className="card-header">
                <div className="col-auto">
                    <IconMail stroke={1}/>
                </div>
                &nbsp;
                <h3 className="card-title col">받은 편지함</h3>
                <a className="col-auto" onClick={() => gotoMailView()} style={{cursor: "pointer"}}>
                    <IconPlus stroke={1}/>
                </a>
            </div>
                {render()}
        </div>
    )
}

export default MailPlugListWidget;
