import LoadingCircle from "common/control/LoadingCircle";
import UserData from "data/API/User/UserData";
import React, {useEffect, useState} from "react";
import UserImageViewer from "common/control/viewer/UserImageViewer";
import { printError} from "Define";
import {useAuth} from "context/AuthContext";

const headDeco = {
    background:"#aaa",
    width:"2px",
    height:"15px",
    display:"inline-block",
    marginRight:"10px",
    marginTop:"2px",
    verticalAlign:"top"
}

type UserViewerControlProps = {
    title?: string,
    user?: UserData,
    id?: string
}
const UserViewerControl = (props: UserViewerControlProps) =>
{
    const loadText = <div className="placeholder" style={{width:"100px"}}>.....</div>;
    const [user, setUser] = useState<UserData | null>(props.user ?? null);
    const {auth} = useAuth();

    useEffect(() =>
    {
        if(user == null)
        {
            if(props.id == null) alert("잘못된 사용자 ID 입니다")
            else
            {
                const id = auth!.user.LoginID == props.id ? null : props.id;
                UserData.GetUserAsync('fullex', id)
                    .then(user => setUser(user ?? new UserData()))
                    .catch(error => printError(error));
            }
        }
    }, [])

    return (
        <div className="col-12 col-md-9 d-flex flex-column">
            <div className="card-body" style={{minHeight: "800px"}}>
                <h2 className="mb-4">{props.title ?? "상세 정보"}</h2>
                <h3 className="card-title">내 이미지 정보</h3>
                <div className="row align-items-center">
                    <div className="col-auto">
                        <span className="avatar avatar-xl">
                             {user == null ? <LoadingCircle size="60px" border={5}/> : <UserImageViewer user={user}/>}
                        </span>
                        <p className="mt-1 text-center">프로필 사진</p>
                    </div>
                    <div className="col-auto">
                        <span className="avatar avatar-xl">
                             {user == null ? <LoadingCircle size="60px" border={5}/> : ""}
                        </span>
                        <p className="mt-1 text-center">도장 이미지</p>
                    </div>
                </div>
                <hr/>
                <h3 className="card-title mt-4">상세 정보</h3>
                <div className="row g-3">
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>이름</div>
                        <p className="text-muted">{user == null ? loadText : user.Name}</p>
                    </div>
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>이름(한글)</div>
                        <p className="text-muted">{user == null ? loadText : user.NameKorean}</p>
                    </div>
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>이름(영문)</div>
                        <p className="text-muted">{user == null ? loadText : user.NameEnglish}</p>
                    </div>
                </div>
                <div className="row g-3 mt-2">
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>회사 이메일</div>
                        <p className="text-muted">{user == null ? loadText : user.EmailCompany}</p>
                    </div>
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>개인 이메일</div>
                        <p className="text-muted">{user == null ? loadText : user.EmailPrivate}</p>
                    </div>
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>담당업무</div>
                        <p className="text-muted">{user == null ? loadText : user.ResponsWork}</p>
                    </div>

                </div>
                <div className="row g-3 mt-2">
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>내선번호</div>
                        <p className="text-muted">{user == null ? loadText : user.TelCompany}</p>
                    </div>
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>내선 팩스번호</div>
                        <p className="text-muted">{user == null ? loadText : user.TelFax}</p>
                    </div>
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>휴대폰 번호</div>
                        <p className="text-muted">{user == null ? loadText : user.TelMobile}</p>
                    </div>
                </div>
                <div className="row g-3 mt-2">
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>입사일</div>
                        <p className="text-muted">{user == null ? loadText : (user.EnteringDate ? user.EnteringDate.format("yyyy년 M월 d일") : "-")}</p>
                    </div>
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>퇴사일</div>
                        <p className="text-muted">{user == null ? loadText : (user.RetirementDate ? user.RetirementDate.format("yyyy년 M월 d일") : "-")}</p>
                    </div>
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>생년월일</div>
                        <p className="text-muted">{user == null ? loadText : (user.BirthDate ? user.BirthDate.format("yyyy년 M월 d일") : "-")}</p>
                    </div>
                </div>
                <hr/>
                <h3 className="card-title mt-4">업무 정보</h3>
                <div className="row">
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>부서</div>
                        <p className="text-muted">{user == null ? loadText : `${user.DeptName}`}</p>
                    </div>
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>직급</div>
                        <p className="text-muted">{user == null ? loadText : `${user.PositionName1}`}</p>
                    </div>
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>직책</div>
                        <p className="text-muted">{user == null ? loadText : `${user.PositionName2}`}</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md">
                        <div className="form-label"><span style={headDeco}></span>재직 (예/아니오)</div>
                        <p className="text-muted">{user == null ? loadText : `${user.IsEmployment ? '예' : '아니오'}`}</p>
                    </div>
                </div>
                <br/>
            </div>
        </div>
    )
}

export default UserViewerControl;
