import React, {CSSProperties, useState, useEffect, useRef} from 'react'
import {Async} from "react-async";
import styles from './index.module.css';
import {useDropzone} from 'react-dropzone'
import {randomKey, readFile} from "utils/HSUtils";
import AttachData from "data/API/IO/AttachData";
import {COLOR_LINK_DEFAULT} from "DefineStyle";
import {Link, useNavigate} from "react-router-dom";
import {
    IconCircleXFilled,
    IconFileDigit,
    IconFileDownload,
    IconFileMusic,
    IconFileSearch,
    IconFileSpreadsheet,
    IconFileText,
    IconFileTypePdf,
    IconFileTypePpt,
    IconFileWord,
    IconFileZip,
    IconVideo
} from '@tabler/icons-react';
//import { FilePreviewOpen } from 'page/preview/FilePreview';

import fileLoadingIcon from "./image/file-loading.gif";
import fileError from "./image/file-error.png";
import {FileViewerDialog, FileViewerType} from "../FileViewerControl";
import {humanFileSize, isEmptyString} from "@hskernel/hs-utils";
import {isWVMode} from "lib/RNMessage/RNMessageWV";
import {HEADER_AUTH, sendRNData} from "Define";
import RNDataDownload from "lib/RNMessage/Data/RNDataDownload";
import {useTheme} from "../../../context/ThemeContext";

const imgStyle: CSSProperties = {
    display: "block",
    width: "auto",
    height: "100%",
    margin: "0 auto"
};
const loadingStyle: CSSProperties = {
    height: "40px",
    margin: "30px auto 0"
};
/*
type FileAttachControl = {
    name?: string,
    nameDelete?: string
}
*/
type FileAttachControlProps = {
    name?: string,
    nameDelete?: string,
    attachs?: AttachData[],
    isViewer?: boolean,
    /**
     * form 모드로 토큰값을 같이 보냅니다
     */
    authToken?: string | null,
}

const FileAttachControl = ({name, nameDelete, attachs, isViewer, authToken}: FileAttachControlProps) =>
{
    const getAuthTokenParam = () => isEmptyString(authToken) ? null : `${HEADER_AUTH}=${encodeURIComponent(authToken + '')}`;

    const Navigate = useNavigate();
    const [files, setFiles] = useState<FileAttach[]>([]);
    const [deleteFile, setDeleteFile] = useState<string[]>([]);
    const preview = useRef<FileViewerDialog | null>(null);
    const {theme} = useTheme();

    const thumbsContainerStyle: CSSProperties = {
        /*display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",*/
        marginTop: 16,
    };


    const thumbBoxStyle: CSSProperties = {
        width: 100,
        display: "block",
        marginRight: 8,
        marginBottom: 5,
        float: "left"
    }

    const thumbStyle: CSSProperties = {
        boxSizing: "border-box",
        width: 100,
        display: "inline-flex",
        height: 100,
        marginRight: 8,
    };

    const thumbInnerStyle: CSSProperties = {
        display: "flex",
        width: "100%",
        overflow: "hidden",
        border: "1px solid rgb(233 233 233)",
        position: "relative"
    };


    useEffect(() => {
        if (attachs) setFiles(attachs.map(attach => new FileAttach(null, attach)));
    }, []);

    const deleteAttach = (index: number) =>
    {
        const file = files[index];
        if (file.attach != null) setDeleteFile(deleteFile.concat(file.attach.Key));
        setFiles(files.remove(index));
    }

    const getViewerType = (kind: FileKind): FileViewerType | null  =>
    {
        switch (kind)
        {
            case 'Image': return 'Image';
            case 'PDF': return 'PDF';
            //case 'Text': return 'Text';
            case 'Audio': return 'Audio';
            case 'Video': return 'Video';
            default: return null;
        }
    }
    const getURL = (file: FileAttach, isToken?: boolean) => (isToken ?? true) ? file.attach!.setParam(getAuthTokenParam()).getURL() : file.attach!.getURL();
    const getButtonColor = () => 'black';//theme.darkMode ? 'black' : 'white';
    function showPreviewDialog(file: FileAttach)
    {
        if(preview != null && preview.current != null)
        {
            const viewerType = getViewerType(file.kind);
            if (viewerType != null)
            {
                //로컬 파일일 때
                if (file.attach != null) preview.current.open({ type: viewerType, title: file.name, fileOrURL: getURL(file)});
                else if (file.file != null) preview.current.openAsync({ type: viewerType, title: file.name, fileOrURL: file.file});
                else alert("잘못된 파일 데이터 입니다");
            }
            else alert("지원되지 않는 파일형식입니다");
        }
    }
    const getPreviewButton = (file: FileAttach) =>
    {
        const viewerType = getViewerType(file.kind);
        return viewerType == null ? null : <a onClick={() => showPreviewDialog(file)}><IconFileSearch size={32} color={getButtonColor()}/></a>;
        /*
        if(file.isImage()) return <a onClick={() => showPreviewDialog(file)}><IconPhoto size={32}/></a>;
        else if(file.type.includes("pdf")) return <a onClick={() => showPreviewDialog(file)}><IconPdf size={32}/></a>;
         */
    }

    const getDownloadLink = (file: FileAttach) => {
        if (isWVMode()) {
            const data: RNDataDownload = {
                method: 'GET',
                url: new URL(getURL(file), location.origin).href,
                mime: file.type,
                name: file.name,
                size: file.size,
                header: {"Authorization": authToken ?? ''}
            }
            return <a style={{cursor: 'pointer'}} onClick={() => sendRNData({type: 'DOWNLOAD', data: data})}><IconFileDownload size={32} color={getButtonColor()}/></a>;
        }
        else if(isEmptyString(authToken))
        {
            return <Link to={getURL(file)} download target="_blank"><IconFileDownload size={32} color={getButtonColor()}/></Link>;
        }
        else
        {
            return (
                <form method='post' target={isWVMode() ? '_self' : '_blank'} action={getURL(file)}>
                    <input type='hidden' name={HEADER_AUTH} value={authToken!}/>
                    <button type='submit'><IconFileDownload size={32} color={getButtonColor()}/></button>
                </form>
            )
        }
    }

    const onDelete = (index: number, fileName: string) =>
    {
        if (confirm(`${fileName} 파일을 삭제하시겠습니까?`)) deleteAttach(index);
    }

    return (
        <>
            <FileViewerDialog ref={preview}/>
            <h3 className="card-title">일반첨부파일(<span>{files.length}</span>개)</h3>
            <div style={isViewer ? {overflow: "hidden"} : {overflowY: "scroll", width: "calc(100vw - 90px)"}}>
                {name == null ?
                    (attachs == null || attachs.length == 0 ? "첨부파일이 존재하지 않습니다" : "") :
                    <DropZone onAdd={(data) => setFiles(files.concat(data))}/>
                }
                <div>
                    {nameDelete == null ? "" :
                        deleteFile.map((token) =>
                            <input key={randomKey()} type="hidden" name={name === null ? "FormAttachFileDelete" : `${nameDelete}[]`} value={token}/>)}
                </div>
                <div style={thumbsContainerStyle} className={styles.attach_file_container}>
                    {files.map((file, index) =>
                        <div key={randomKey()} style={thumbBoxStyle}>
                            <div style={thumbStyle} key={file.name}>
                                <div style={thumbInnerStyle} className={styles.attach_file + " attach_file"}>
                                    <div className={styles.attach_file_blur}></div>
                                    {name == null && file.attach != null ?
                                        <p className={styles.attach_file_cancel}>
                                            {/*getPreviewButton(file)*/}
                                            {/*<a onClick={() => showPreviewDialog(file)}><IconZoomScan size={32}/></a>*/}
                                            {/*<a onClick={() => showPreviewDialog(file)}><IconZoomInArea size={32}/></a>*/}
                                            { getPreviewButton(file) }
                                            { getDownloadLink(file) }

                                        </p>
                                        :
                                        <p className={styles.attach_file_cancel}>
                                            { getPreviewButton(file) }
                                            <a onClick={() => onDelete(index, file.name)}><IconCircleXFilled size={32}/></a>
                                        </p>
                                    }
                                    <Thumb file={file}/>
                                </div>
                            </div>
                            <a className={styles.file_name} download target="_blank" rel="noreferrer"
                               href={file.attach == null ? undefined : file.attach.getURL()}
                               style={file.attach == null ? undefined : {color: COLOR_LINK_DEFAULT}}>
                                {file.name}
                                <div style={{color: theme.darkMode ? 'white' : 'black', fontWeight: 'bold'}}>{`(${humanFileSize(file.size, true)})`}</div>
                            </a>
                            {name == null ? "" : <FileInput name={name} file={file}/>}
                        </div>)
                    }
                </div>
            </div>
        </>
    )
}
export default FileAttachControl;

type DropZoneProps = { onAdd: (data: FileAttach[]) => void }
const DropZone = ({onAdd}: DropZoneProps) => {
    const {getRootProps, getInputProps} = useDropzone({
        //TODO: 나중에 서버에서 사용가능한 확장자 제공하기
        //accept: {"*/*": []},
        onDrop: (acceptedFiles) => onAdd(acceptedFiles.map(file => new FileAttach(file, null)))
    });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p style={{cursor: "pointer"}}>이곳을 클릭하여 파일을 첨부하십시오.</p>
        </div>
    )
}

type ThumbProps = { file: FileAttach }
/*
type ThumbState = { data: string | null }
class Thumb extends Component<ThumbProps, ThumbState>
{
    constructor(props: ThumbProps)
    {
        super(props);
        this.state = { data: null };
    }

    componentDidMount() {
        this.props.file.readData().then(data => this.setState({ data: data ?? ""}));
    }

    render() {
        return this.props.file.type.indexOf("image/") < 0 ? <img src={fileDefaultIcon} style={imgStyle}/> :
            (this.state.data == null ?
                <img src={fileLoadingIcon} style={imgStyle}/> :
                <img src={this.state.data == "" ? fileError : this.state.data} style={imgStyle}/>
            );
    }
}
*/

const Thumb = ({file}: ThumbProps) =>
{
    switch (file.kind)
    {
        case 'Image':
            return (
                <Async promiseFn={() => file.readData()}>
                    <Async.Pending><img src={fileLoadingIcon} style={loadingStyle}/></Async.Pending>
                    <Async.Fulfilled>{(data: string | null) => <img src={data ?? fileError} style={imgStyle}/>}</Async.Fulfilled>
                </Async>);
        case 'PDF': return <IconFileTypePdf size={64}/>;
        case 'Archive': return <IconFileZip size={64}/>;
        case 'Text': return <IconFileText size={64}/>;
        case 'Video': return <IconVideo size={64}/>;
        case 'Audio': return <IconFileMusic size={64}/>;
        case 'Word': return <IconFileWord size={64}/>;
        case 'PowerPoint': return <IconFileTypePpt size={64}/>;
        case 'SpreadSheet': return <IconFileSpreadsheet size={64}/>;
        default:
            console.log(`Type: ${file.type} / Kind: ${file.kind}`);
            return <IconFileDigit size={64}/>;
    }
}

export type FileKind = "Other" | "PDF" | "Image" | "Archive" | "Text" | "Video" | "Audio" | "Word" | "PowerPoint" | "SpreadSheet";
export function getFileKind(mime: string): FileKind
{
    const checkType = (includeType: string) => mime.indexOf(includeType) >= 0;

    if (checkType('image/')) return 'Image';
    else if (checkType('/pdf')) return 'PDF';
    else if (checkType('video/')) return 'Video';
    else if (checkType('audio/')) return 'Audio';
    else if (checkType('/zip') || checkType('/x-tar') || checkType('/gzip') || checkType('/vnd.rar')) return 'Archive';
    else if (checkType('text/') || checkType('/json')) return 'Text';
    else if (checkType('/msword') || checkType('/vnd.openxmlformats-officedocument.wordprocessingml.document')) return 'Word';
    else if (checkType('/vnd.ms-powerpoint') || checkType('/vnd.openxmlformats-officedocument.presentationml.presentation')) return 'Word';
    else return 'Other';
}

class FileAttach
{
    //private fileRef = createRef<HTMLInputElement>();
    constructor(file: File | null, attach: AttachData | null)
    {
        if (file != null)
        {
            this.file = file;
            this.name = file.name;
            this.type = file.type;
            this.size = file.size;
            this.kind = getFileKind(file.type);
        }
        else if (attach != null)
        {
            this.attach = attach;
            this.name = attach.Name;
            this.size = attach.Size;
            this.type = attach.Type;
            this.kind = getFileKind(attach.Type);
        }
    }

    public file: File | null;
    public attach: AttachData | null;

    public name: string;
    public size: number;
    public type: string;
    public kind: FileKind;

    public setParam(Param: string | null)
    {
        if (this.attach != null) this.attach.setParam(Param);
        return this;
    }

    //public getIcon(){}

    private data: string | null = null;

    public async readData(): Promise<string | null> {
        if (this.data != null) return this.data;
        else
        {
            if (this.file)
            {
                try
                {
                    this.data = await readFile(this.file);
                    return this.data;
                }
                catch (error: any) { console.log('FileRead Error!!: ', error); }
            }
            else if (this.attach)
            {
                //return this.attach.getURL();
                try
                {
                    this.data = await this.attach.getDataAsync();
                    return this.data;
                }
                catch (e) { console.log(e); }
            }

            return null;
        }
    }
}

type FileInputProps = {
    name: string,
    file: FileAttach
}
const FileInput = ({name, file}: FileInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current != null && file.file != null) {
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file.file);
            inputRef.current.files = dataTransfer.files;
        }
    }, []);

    return file.file ? <input type="file" ref={inputRef} name={name === null ? "FormAttachFile" : `${name}[]`} style={{display: "none"}}/> : <></>;
}


/*

type PreviewKind = "PDF" | "IMAGE";
type PreviewState = {
    kind: PreviewKind,
    attach: AttachData
}
*/
