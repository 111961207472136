import {Expose, Type} from "class-transformer";
import BoardDataSimple from "./BoardDataSimple";
import {SendValueAsyncType} from "API";
import axios from "axios";
import {GetAPIURL} from "../Define";
import {IsArray} from "class-validator";
import BoardFileAttach from "./BoardFileAttach";

export default class BoardData extends BoardDataSimple
{
    @Expose() public Contents: string;
    @Expose() @IsArray() @Type(() => BoardFileAttach)
    public Attach: BoardFileAttach[];

    public static async GetViewAsync(Kind: string, ID: string)
    {
        return await SendValueAsyncType<BoardData>(axios.get(GetAPIURL(Kind, ID)), false, BoardData);
    }
}