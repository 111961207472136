import {Expose, Type} from "class-transformer";
import {SendAsync, SendValueAsyncArrayType} from "API";
import axios, {isAxiosError} from "axios";
import {printError, URL_API_ALERT_COUNT, URL_API_DEVICE} from "Define";
import {RNDeviceInfoData} from "lib/RNMessage/RNMessageWV";
import {isEmptyString} from "@hskernel/hs-utils";

export interface DeviceDataBase
{
    /**
     * 플랫폼 (app: 앱, webapp: 웹앱, web: 웹
     */
    Platform?: string | null;
    /**
     * 고유 ID
     */
    DeviceID?: string | null;
    /**
     * 운영체제
     */
    OS?: string | null;
    /**
     * 운영체제 (세부)
     */
    BaseOS?: string | null;
    /**
     * 제조시
     */
    Manufacturer?: string | null;
    /**
     * 이름
     */
    Name?: string | null;
    /**
     * 브랜드
     */
    Brand?: string | null;
    /**
     * 모델
     */
    Model?: string | null;
    /**
     * 시스템
     */
    System?: string | null;
    /**
     * 시스템 버전
     */
    SystemVersion?: string | null;
    /**
     * 사용자 에이전트
     */
    UserAgent?: string | null;
}

export default class DeviceData implements DeviceDataBase
{
    @Expose() public Token: string;
    @Expose() public Platform: string | null;
    @Expose() public DeviceID: string | null;
    @Expose() public OS: string | null;
    @Expose() public BaseOS: string | null;
    @Expose() public Manufacturer: string | null;
    @Expose() public Name: string | null;
    @Expose() public Brand: string | null;
    @Expose() public Model: string | null;
    @Expose() public Current: boolean;
    @Expose() public System: string | null;
    @Expose() public SystemVersion: string | null;
    @Expose() public UserAgent: string | null;
    @Expose() @Type(() => Date)
    public AccessTime: Date;
    @Expose() @Type(() => Date)
    public RegisterTime: Date;

    public static async GetDeviceListAsync(offset?: number, count?: number): Promise<DeviceData[] | null>
    {
        const param = [];
        param.push(`count=${count}`);
        param.push(`offset=${offset}`);
        return SendValueAsyncArrayType<DeviceData>(axios.get(`${URL_API_DEVICE}?${param.join('&')}`), false, DeviceData);
    }

    public static async GetCountAsync()
    {
        return parseInt((await axios.get(`${URL_API_DEVICE}/count`)).data);
    }

    public static AddDeviceAsync(data: DeviceDataBase)
    {
        return SendAsync(axios.put(`${URL_API_DEVICE}/login`, data))
    }

    public static async DeleteAsync(data: DeviceDataBase)
    {
        try { await axios.delete(`${URL_API_DEVICE}/${data.DeviceID}`); return true; }
        catch (ex) { printError(ex); return false; }
    }

    public static ConvertRNDeviceInfo(data: RNDeviceInfoData): DeviceDataBase
    {
        return {
            DeviceID: data.id,
            OS: data.os,
            BaseOS: data.base_os,
            Platform: data.platform,
            Name: data.name,
            Brand: data.brand,
            Manufacturer: data.manufacturer,
            Model: data.model,
            System: data.system,
            SystemVersion: data.version,
            UserAgent: data.useragent,
        }
    }



    public static GetBrandName(brand?: string | null)
    {
        if (isEmptyString(brand)) return '(없음)';

        switch (brand!.toLowerCase())
        {
            case 'apple': return 'Apple';
            case 'samsung': return 'Samsung';
            case 'google': return 'Google';
            case 'lge': return 'LG';
            case 'huawei': return 'Huawei';
            case 'xiaomi': return 'Xiaomi';
            default: return brand;
        }
    }



    public static GetOS(os?: string | null)
    {
        if (isEmptyString(os)) return '(알 수 없음)';
        {
            switch (os!.toLowerCase()) {
                case 'ios': return 'iOS';
                case 'macos': return 'macOS';
                case 'osx': return 'OSX';
                case 'windows': return 'Windows';
                case 'android': return 'Android';
                default: return os;
            }
        }
    }

    public static PlatformString(Platform?: string | null)
    {
        if(Platform == 'app') return '앱';
        else if(Platform == 'webapp') return '웹앱';
        else if(Platform == 'web') return '웹';

        return Platform;
    }
}
