import React, {useCallback, useEffect, useState} from "react";
import {createGlobalStyle} from 'styled-components'
import {CSSProperties} from 'react';

/**
 * 테마 데이터
 */
export type ThemeData = {
    darkMode: boolean
}

/**
 * 테마 컨텍스트 타입 지정
 */
export type ThemeContextType = {
    theme: ThemeData,
    setDarkMode: (darkMode: boolean) => void;
}

/**
 * 테마 컨텍스트 기본값 설정
 */
/* eslint @typescript-eslint/no-empty-function: "off" */
const ThemeContextDefault: ThemeContextType =
{
    theme: {darkMode: false},
    setDarkMode: (_: boolean) => { /* EMPTY */}
}
/**
 * 테마 컨텍스트 생성
 */
export const ThemeContext = React.createContext<ThemeContextType>(ThemeContextDefault);
export const bodyNode:HTMLElement | null = document.getElementById('body');


/**
 * 훅 스타일로 사용하기위해
 */
export const useTheme = () => React.useContext(ThemeContext);
export const ThemeProvider = ({children}: any) => {
    const KEY = "theme";
    const [theme, setTheme] = useState<ThemeData>(ThemeContextDefault.theme);
    const [style, setStyle] = useState<CSSProperties>();

    useEffect(() => {
        const theme = localStorage.getItem(KEY);
        setTheme(theme ? JSON.parse(theme) : ThemeContextDefault.theme)
    }, [])


    function _setTheme(theme: ThemeData) {
        localStorage.setItem(KEY, JSON.stringify(theme));
        setTheme(theme);
    }

    //기본값 적용
    const contextValue: ThemeContextType = {
        theme: theme,
        setDarkMode: useCallback((darkMode: boolean) => _setTheme({...theme, darkMode: darkMode}), []),
    };

    bodyNode?.setAttribute('data-bs-theme',theme.darkMode ? 'dark' : 'light');

    return (
        <ThemeContext.Provider value={contextValue}>
            {children}
        </ThemeContext.Provider>
    );
}