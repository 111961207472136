import React, {Component, createRef} from "react";
import {randomKey} from "utils/HSUtils";
import DocumentRefSelectModal from "./DocumentRefSelectModal";
import ApprovalDocumentListData from "../../data/ApprovalDocumentListData";

type DocumentRefSelectProps = {
    name: string,
    docs?: ApprovalDocumentListData[],
    onChange?: (docs: ApprovalDocumentListData[]) => void,
}
type DocumentRefSelectState = {
    docs: ApprovalDocumentListData[]
}
export default class DocumentRefSelectView extends Component<DocumentRefSelectProps, DocumentRefSelectState> {
    constructor(props: DocumentRefSelectProps) {
        super(props);

        this.state = {
            docs: props.docs ?? [],
        }
    }

    private refModal = createRef<DocumentRefSelectModal>();

    private modalOpen = () => {
        if(this.refModal.current) this.refModal.current.openDialogAsync(this.state.docs);
    }

    render() {
        return (
            <>
                {this.state.docs.length == 0 ? <span>선택된 문서가 없습니다.</span> : <span>{this.state.docs.length} 개의 문서가 선택되었습니다</span>}
                <button type="button" className="approval_btn" onClick={this.modalOpen}>선택</button>
                {
                    this.state.docs.map((doc, i) =>
                        <input key={randomKey(i)} type="hidden" name={`${this.props.name}[]`} value={doc.DocumentID}/>)
                }
                <DocumentRefSelectModal ref={this.refModal} onSave={(docRef) => {
                    this.setState({...this.state, docs: docRef});
                    if(this.props.onChange) this.props.onChange(docRef);
                }}/>
            </>
        );
    }

}