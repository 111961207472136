import React, {useCallback, useEffect, useState} from 'react';
import {Routes, Route, Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom';

import {
    PATH_FRONT_MAIN,
    PATH_PREVIEW_FILE,
    TITLE,
    URL_API,
    URL_FRONT_ERROR,
    URL_FRONT_LOGIN,
    URL_FRONT_LOGOUT,
    URL_FRONT,
    STORAGE_KEY_LOGIN_APP_TOKEN,
    STORAGE_KEY_LOGIN_ID,
    URL_API_ALERT_DEVICE,
    printError,
    LogoutAsync,
    registerFCMToken, unregisterFCMToken, sendRNData, toStringAny, isAppMode
} from "./Define";
import {getAuthData} from "./Auth";
import {useAuth} from "./context/AuthContext";
import Main from "./page/Main";
import LoginPage from "page/login/LoginPage";
import LogoutPage from "page/login/LogoutPage";
import {ModuleRouter, SystemRouter} from "Module";
import {ThemeProvider} from "./context/ThemeContext";
import FilePreview, {FilePreviewParam} from "page/preview/FilePreview";
import ErrorPage, {ErrorRouter} from "./page/error";
import ErrorPage404 from "./page/error/404";
import * as signalR from "@microsoft/signalr";
import {MenuUIProvider} from "./context/MenuUIContext";
import {plainToInstance} from "class-transformer";
import AlertData from "./data/API/Alert/AlertData";
import {UserRouter} from "./page/user";
import {getFirebaseToken, getVAPIDKey, isFCMSupportAsync} from "./GoogleFCM";
import {isEmptyString} from "@hskernel/hs-utils";
import ModuleMenuProperty from "./data/API/Module/Menu/ModuleMenuProperty";
import {isWVMode, setWVData} from "./lib/RNMessage/RNMessageWV";
import TestRouter from "./test/router";
import {
    AddAlertClickCallback,
    fireAlert,
    getInitAlert, KEY_WV_ALERTS,
    processAlertAsync,
    RemoveAlertClickCallback, setInitAlert
} from "./Alert";
import axios from "axios";
import * as Define from "./Define";

const NotiSignalRKey = "ReceiveAlertPush";

const LoginToken = localStorage[STORAGE_KEY_LOGIN_APP_TOKEN];
const LoginID = localStorage[STORAGE_KEY_LOGIN_ID];
//const GetAccessToken = () => LoginToken != null && LoginID != null ? `app ${LoginID}:${LoginToken}` : null;

let SignalRBuilder = new signalR.HubConnectionBuilder();
SignalRBuilder = LoginToken != null && LoginID != null ?
    SignalRBuilder.withUrl( URL_API + "/alert/com", { accessTokenFactory: () => `app ${LoginID}:${LoginToken}` }) :
    SignalRBuilder.withUrl( URL_API + "/alert/com");

const ConnectionSignalR = SignalRBuilder
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Error)
    .build();

/**
 *
 * @return {Promise<signalR.HubConnection>}
 * @constructor
 */
export async function ConnectNotiSignalRAsync()
{
    try
    {
        ConnectionSignalR.on(NotiSignalRKey, function (alertData)
        {
            const alert = plainToInstance<AlertData, any>(AlertData, alertData, {});
            fireAlert(alert);
        });
        await ConnectionSignalR.start();
        return ConnectionSignalR;
    }
    catch (e){ console.log(e); }
}

export async function DisconnectNotiSignalRAsync()
{
    try
    {
        ConnectionSignalR.off(NotiSignalRKey);
        await ConnectionSignalR.stop();
    }
    catch (e){ console.error(e); }
}

export async function ConnectNotiFirebase()
{
    //TODO: 로그인 시 알림 토큰 등록하게 변경하기
    if(await isFCMSupportAsync())
    {
        navigator.serviceWorker.ready.then(async (registration) =>
        {
            console.debug(`ServiceWorkerRegistratio: ${toStringAny(registration)}`);

            const subscription = await registration.pushManager.getSubscription();
            if (subscription != null)
            {
                const subscribe = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: getVAPIDKey(),
                });
                console.debug('subscription => ', subscribe.toJSON());
            }
        });

        const token = await getFirebaseToken();
        if(!isEmptyString(token)) await registerFCMToken(token);
    }
    else console.warn("FCM 지원 안함!!");
}

export async function DisconnectNotiFirebase()
{
    //if(firebase.messaging.isSupported())
    try
    {
        navigator.serviceWorker.ready.then(swr =>
        {
            console.log("DisconnectNotiFirebase")
            swr.pushManager.getSubscription()
                .then(subscription =>
                {
                    if(subscription != null)
                        subscription.unsubscribe().then()
                });
        });
    }
    catch (e){ console.error(e); }

    const token = await getFirebaseToken();
    await unregisterFCMToken(token);
}

const AuthCheck = () =>
{
    const {setAuth, setMenuProperty} = useAuth();
    //react component 에 async-await 쓰고 싶을 때, useAsync() => https://anywaydevlog.tistory.com/36
    //로그인 정보 없을 경우 로그인 페이지로 redirect하기 => https://gaemi606.tistory.com/entry/React-%EB%A1%9C%EA%B7%B8%EC%9D%B8-%EC%A0%95%EB%B3%B4-%EC%97%86%EC%9D%84-%EA%B2%BD%EC%9A%B0-%EB%A1%9C%EA%B7%B8%EC%9D%B8-%ED%8E%98%EC%9D%B4%EC%A7%80%EB%A1%9C-redirect%ED%95%98%EA%B8%B0-react-router-PrivateRoute

    const [authState, setAuthState] = useState<number | null>(null);
    const location = useLocation();
    const _Navigate = useNavigate();

    const onAlertClick = useCallback((alert: AlertData) =>
    {
        processAlertAsync(alert)
            .then(success =>
            {
                //미처리 시 메세지 보여주기
                if (!success) window.alert(alert.Message);
            });
    }, []);

    useEffect(() =>
    {
        refresh().then();

        return () =>
        {
            RemoveAlertClickCallback(onAlertClick);
        }
    }, []);

    async function refresh()
    {
        setAuthState(0);
        try
        {
            const auth = await getAuthData(location, _Navigate);
            if(auth == null)
            {
                //LogoutAsync(false).then().finally(() => setAuthState(-1));
                setAuthState(-1);
            }
            else
            {
                const menu = await ModuleMenuProperty.GetMenuAsync();
                setMenuProperty(menu ?? ModuleMenuProperty.GetDefault());

                setAuth(auth);
                document.title = `${auth.company.Name} ${TITLE}`;
                if(isWVMode()) sendRNData({type: 'TOKEN_FCM', data: null});
                else
                {
                    //앱 모드이면 FCM, 아니면 SingalR
                    if(isAppMode()) ConnectNotiFirebase().then();
                    else ConnectNotiSignalRAsync().then();
                }

                setAuthState(1);
            }
        }
        catch (ex)
        {
            setAuthState(-1)
        }
    }

    const waitPage = (message?: string) =>
    {
        return (
            <div>
                <div className="page page-center">
                    <div className="container container-slim py-4">
                        <div className="text-center" style={{marginTop:"300px"}}>
                            <div className="text-muted mb-3" style={{fontSize:"16px"}}>{message ?? "페이지 준비 중입니다"}<br/>잠시만 기다려주세요.</div>
                            <div className="progress progress-sm">
                                <div className="progress-bar progress-bar-indeterminate"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const render = () =>
    {
        const isLoginPage = location.pathname.indexOf(URL_FRONT_LOGIN) == 0;

        //인증되지 않은 상태면...
        if(authState == -1)
        {
            RemoveAlertClickCallback(onAlertClick);
            if (isLoginPage) return <LoginPage/>
            else
            {
                const callback = !isLoginPage && location.pathname.indexOf(URL_FRONT_LOGOUT) != 0 && location.pathname != URL_FRONT ?
                    `?callback=${decodeURIComponent(window.location.pathname + window.location.search)}` :
                    '';
                return <Navigate to={URL_FRONT_LOGIN + callback} replace={true}/>
            }
        }
        else if (authState == 1)
        {
            AddAlertClickCallback(onAlertClick);
            const InitAlert = getInitAlert();
            if (InitAlert != null)
            {
                onAlertClick(InitAlert);
                setInitAlert(null);
            }
            return isLoginPage ? <Navigate to={URL_FRONT} replace={true}/> : <Outlet/>;
        }
        else
        {
            RemoveAlertClickCallback(onAlertClick);
            return waitPage("페이지를 불러오는 중 입니다...");
        }
    }

    return authState == null ? waitPage() : render();
}

/*
const AdminCheck = () =>
{
    const {auth} = useAuth();
    const getAdmin = () => auth!.user.IsAdmin ? <Outlet/> : <Navigate to={PathMaker(URL_FRONT_ERROR, "noadmin")}/>
    return auth == null ? "" : getAdmin();
}
*/

export const App = () => {
    const location: any = useLocation();

    //Routes 2개 쓰면 'cannot find location...' 경고 계속 뜸!!
    return (
        <div className='App'>
            <ThemeProvider>
                <MenuUIProvider>
                    <Routes>
                        <Route path={URL_FRONT_LOGIN} element={<LoginPage/>}/>
                        <Route path={URL_FRONT_LOGOUT} element={<LogoutPage/>}/>
                        {ErrorRouter()}
                        {TestRouter()}
                        <Route path={URL_FRONT} element={AuthCheck()}>
                            {UserRouter()}
                            <Route path={PATH_FRONT_MAIN} element={<Main/>}/>
                            <Route path={PATH_PREVIEW_FILE + `/:${FilePreviewParam}`} element={<FilePreview/>}/>
                            {SystemRouter(location).map((router) => router)}
                            {ModuleRouter(location)}
                        </Route>
                        <Route path={"*"} element={<ErrorPage404/>}/>
                    </Routes>
                </MenuUIProvider>
            </ThemeProvider>
        </div>
    )
}
export default App;
