import React from 'react';
import {pdfjs} from "react-pdf";
// Core viewer
import {SpecialZoomLevel, Viewer} from '@react-pdf-viewer/core';

// Locales
import ko_KR from "@react-pdf-viewer/locales/lib/ko_KR.json";

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Plugins
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout/lib';

import {IFileViewerProps} from "./IFileViewer";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = (props: IFileViewerProps) =>
{
    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const getURL = () => props.fileURL.charAt(1) == "/" ? location.origin + props.fileURL : props.fileURL;

    return (
        <div style={{height:"calc(100vh - 100px)"}}>
            <Viewer fileUrl={getURL()} defaultScale={SpecialZoomLevel.PageFit} localization={ko_KR}
                    plugins={[
                        // Register plugins
                        defaultLayoutPluginInstance,
                    ]}
            />
        </div>
    )
}

export default PDFViewer;