import React,{useState} from "react";
import styles from '../style/Calendar.module.css';
import ScheduleData from '../data/ScheduleData';
import {IconEdit, IconTrash} from '@tabler/icons-react';
import CalendarWrite from "./CalendarWrite";
import {dateFormat} from "@hskernel/hs-utils";
import {useAuth} from "context/AuthContext";
import {printError} from "Define";

type CalendarViewProps = {
    key: string,
    data: ScheduleData,
    /**
     *
     * @param {ScheduleData | null} schedule (null 이면 일정이 삭제됨)
     */
    onChange: (schedule: ScheduleData | null) => void,
}
const CalendarView = (props: CalendarViewProps) =>
{
    const [changeForm, setChangeForm] = useState<boolean>(false);
    const [data, setData] = useState<ScheduleData>(props.data);
    const [busy, setBusy] = useState(false);
    const auth = useAuth();

    //TODO: onSave 호출 시 PlaceHolder 표시 후 View 표시
    function onSave(schedule: ScheduleData)
    {
        setData(schedule);
        setChangeForm(false);
        props.onChange(schedule);
    }
    function onDeleteAsync(ID: string)
    {
        setBusy(true);
        ScheduleData.DeleteScheduleAsync(ID)
            .then()
            .catch(e => printError(e))
            .finally(() =>
            {
                setBusy(false);
                props.onChange(null);
            })
    }

    return changeForm?
        <CalendarWrite data={data} onCancel={() => setChangeForm(false)} onSave={onSave}/>
        :
        <li className={styles.day_plan_li} style={{borderTop: `6px solid ${data.Color ? data.Color : "transparent"}`}}>
            <div className={styles.day_plan_info}>
                <h4>{data.Title}</h4>
                <p>{data.Contents}</p>
                <div className={styles.location}>위치 : {data.Location ? data.Location : "없음"}</div>
                <div className={styles.date_pick}>
                    기간 : {dateFormat(data.StartDate,"yyyy-mm-dd HH:MM") + ' ~ ' + dateFormat(data.EndDate,"yyyy-mm-dd HH:MM")}
                </div>
                {auth.auth == null || auth.auth.user.LoginID != data.LoginID ? "" :
                    <div className="mt-2">
                        <button disabled={busy} onClick={() => setChangeForm(true)} className={styles.btn_edit}><IconEdit stroke={1}/></button>
                        <button disabled={busy} onClick={() => onDeleteAsync(data.ID)} className={styles.btn_delete}><IconTrash stroke={1}/></button>
                    </div>
                }
            </div>
        </li>


}
export default CalendarView;
