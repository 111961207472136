// Initialize Firebase
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, isSupported} from "firebase/messaging";

export const isFCMSupportAsync = () => isSupported();

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey:  process.env.REACT_APP_FCM_API_KEY,
    authDomain: "easygroupware-1.firebaseapp.com",
    projectId: "easygroupware-1",
    storageBucket: "easygroupware-1.appspot.com",
    messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FCM_APP_ID,
    measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID
};


export const firebaseApp = initializeApp(firebaseConfig);
export const getVAPIDKey = () => process.env.REACT_APP_VAPID_KEY;

export const getFirebaseMessaging = () => getMessaging(firebaseApp);
export const getFirebaseToken = () => getToken(getFirebaseMessaging(), {
    vapidKey: getVAPIDKey(),
});
