import 'reflect-metadata';
import {Expose, Type} from "class-transformer";
import axios from "axios";
import {URL_API_ALERT, URL_API_ALERT_COUNT, URL_API_ALERT_LIST, URL_API_ALERT_READ, URL_API_ALERT_VIEW} from "Define";
import {SendAsync, SendValueAsyncArrayType, SendValueAsyncType} from "API";

export default class AlertData
{
    @Expose() public ID: string;
    @Expose() public Level: number;
    @Expose() public IsRead?: boolean;
    @Expose() public Module: string | null;
    @Expose() public Category: string | null;
    @Expose() public Type: string | null;
    @Expose() public Title: string | null;
    @Expose() public Message: string;
    @Expose() public Data: string | null;
    @Expose() @Type(() => Date)
    public CreateTime: Date;
    @Expose() @Type(() => Date)
    public ReadTime?: Date | null;

    public static fromData(data: any)
    {
        if (data == null) return null;
        else
        {
            try
            {
                const alert = new AlertData();
                alert.ID = data.ID;
                alert.Level = data.Level;
                alert.IsRead = data.IsRead;
                alert.Module = data.Module;
                alert.Category = data.Category;
                alert.Type = data.Type;
                alert.Title = data.Title;
                alert.Message = data.Message;
                alert.Data = data.Data;
                if (data.CreateTime != null) alert.CreateTime = new Date(data.CreateTime);
                if (data.ReadTime != null) alert.ReadTime = new Date(data.ReadTime);

                return alert;
            }
            catch (e)
            {
                console.error(e);
                return null;
            }
        }
        //return plainToInstance<AlertData, any>(AlertData, data, { excludeExtraneousValues: false, enableImplicitConversion: true });
    }

    public static async GetCountAsync(isRead?: boolean): Promise<number>
    {
        return parseInt((await axios.get(URL_API_ALERT_COUNT +`?IsRead=${isRead ?? ''}`)).data);
    }

    public static async GetListAsync(isRead?: boolean, offset = 0, count = -1): Promise<AlertData[] | null>
    {
        const param = [`IsRead=${isRead ?? ''}`];
        if(offset > 0) param.push(`offset=${offset}`);
        if(count > 0) param.push(`count=${count}`);
        return await SendValueAsyncArrayType<AlertData>(axios.get(URL_API_ALERT_LIST +`?${param.join("&")}`), false, AlertData);
    }

    public static async GetViewAsync(AlertID: string)
    {
        return await SendValueAsyncType<AlertData>(axios.get(`${URL_API_ALERT_VIEW}/${AlertID}`), false, AlertData);
    }

    public static async MarkReadAsync(AlertIDs: string[])
    {
        return await SendAsync(axios.post(`${URL_API_ALERT_READ}/${AlertIDs.join(",")}`));
    }

    public static async DeleteAsync(AlertIDs: string[])
    {
        return await SendAsync(axios.delete(`${URL_API_ALERT}/${AlertIDs.join(",")}`));
    }

    public static async DeleteAllAsync()
    {
        return await SendAsync(axios.delete(`${URL_API_ALERT}/all`));
    }
}
