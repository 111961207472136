import UserDataBase, {UserSearchData, UserSearchKinds} from "data/API/User/UserDataBase";
import React, {Component, createRef} from "react";
import {DepartmentData} from "data/API/DepartmentData";
import UserData from "data/API/User/UserData";
import SearchControl, {getSearchOptionValueFromEvent, SearchOptionEvent} from "../../SearchControl";
import {randomKey} from "utils/HSUtils";
import {isEmptyString, parseBoolean} from "@hskernel/hs-utils";


type UserListAddControlProps = {
    onSelect: (users: UserDataBase[]) => void,
    deptCode?: string
}
type UserListAddState = {
    users: UserDataBase[] | null,
    check: Map<string, UserDataBase>,
    search?: UserSearchData,
    deptCode?: string,
    isEmployee?: boolean | null,
}
export default class UserListAddControl extends Component<UserListAddControlProps, UserListAddState>
{
    private checkAll = createRef<HTMLInputElement>();

    constructor(props: UserListAddControlProps)
    {
        super(props);
        this.clearCheck = this.clearCheck.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.setDeptCodeAsync = this.setDeptCodeAsync.bind(this);
        this.setSearchAsync = this.setSearchAsync.bind(this);
        this.handleSingleCheck = this.handleSingleCheck.bind(this);
        this.handleAllCheck = this.handleAllCheck.bind(this);
        this.onSearchAsync = this.onSearchAsync.bind(this);
        this.onSelectedOptionsAsync = this.onSelectedOptionsAsync.bind(this);
        this.state = {
            users: [],
            check: new Map<string, UserDataBase>(),
        }
    }
    private clearCheck(users?: UserDataBase[], search?: UserSearchData, deptCode?: string)
    {
        this.setState({...this.state, search: search, deptCode: deptCode, users: users == undefined ? this.state.users : users, check: new Map<string, UserDataBase>()});
        //if(this.checkAll.current) this.checkAll.current.checked = false;
    }

    async componentDidMount()
    {
        if(this.props.deptCode != null) await this.setDeptCodeAsync(this.props.deptCode);
    }

    /**
     * 부서코드로 목록 불러오기
     * @param deptCode
     * @param isEmployee undefined 면 state 값 호출, null 이면 모두 불러오기
     */
    async setDeptCodeAsync(deptCode: string, isEmployee?: boolean | null)
    {
        isEmployee = isEmployee === undefined ? this.state.isEmployee : isEmployee;
        const users = await DepartmentData.GetDepartmentUsers(deptCode, isEmployee ?? undefined);
        this.clearCheck(users ?? [], undefined, deptCode);
    }

    /**
     * 검색으로 목록 불러오기
     * @param search 검색할 사용자명
     */
    async setSearchAsync(search?: UserSearchData)
    {
        const users = await UserData.GetUsersBaseAsync(search);
        this.clearCheck(users ?? [], search ?? {}, undefined);
    }

    private onSelect()
    {
        const arr: UserDataBase[] = [];
        this.state.check.forEach((value: UserDataBase) => arr.push(value));
        this.props.onSelect(arr);
        this.clearCheck(undefined, this.state.search, this.state.deptCode);
    }


    // 체크박스 단일 선택
    private handleSingleCheck(checked: boolean, LoginID: string)
    {
        if(this.state.users != null)
        {
            const filter = this.state.users.filter((user) => user.LoginID == LoginID);
            // 단일 선택 시 체크된 아이템을 배열에 추가
            if (checked)
            {
                filter.map((user) => this.state.check.set(user.LoginID, user));
                this.setState({...this.state, check: this.state.check});
            }
            // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
            else
            {
                filter.map((user) => this.state.check.delete(user.LoginID));
                this.setState({...this.state, check: this.state.check});
            }
        }
    }

    // 체크박스 전체 선택
    private handleAllCheck()
    {
        if (this.checkAll.current?.checked)
        {
            if(this.state.users != null)
            {
                const map = new Map<string, UserDataBase>();
                this.state.users.map((user) => map.set(user.LoginID, user));
                this.setState({...this.state, check: map});
            }
        }
        else this.clearCheck();
    }

    private async onSearchAsync(keyword: string | null, optionEvents: SearchOptionEvent[])
    {
        const search = UserDataBase.toSearchData(keyword, getSearchOptionValueFromEvent(optionEvents));
        await new Promise<void>(resolve => this.setState({...this.state, search: search}, resolve));
        await this.setSearchAsync(search);
    }

    private async onSelectedOptionsAsync(index: number, optionEvent: SearchOptionEvent)
    {
        if (index == 1)
        {
            //검색 인스턴스가 있으면 검색 ㄱㄱ
            if(this.state.search != null) return true;
            //부서코드가 존재하면
            else if(this.state.deptCode != null)
            {
                let isEmployee: boolean | null = null;
                const optionValue = optionEvent?.value;
                if(!isEmptyString(optionValue)) isEmployee = parseBoolean(optionValue);

                await new Promise<void>(resolve => this.setState({...this.state, isEmployee: isEmployee}, resolve));
                await this.setDeptCodeAsync(this.state.deptCode, isEmployee);
            }

            return false;
        }
        return true;
    }


    render()
    {
        return (
            <>
                <div className="col-12 row">
                    <SearchControl options={UserSearchKinds} onSelectedOptions={this.onSelectedOptionsAsync} onSearch={this.onSearchAsync}/>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-md-12 col-xl py-3">
                        <button className="btn btn-outline-mute w-100" onClick={this.onSelect}>추가</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="table_card">
                            <table className="common_table table table-vcenter card-table" style={{borderTop: "2px solid #333"}}>
                                <thead>
                                <tr>
                                    <th className="w-1">
                                        <input ref={this.checkAll} type="checkbox" className="form-check-input m-0 align-middle" aria-label="전체선택"
                                               onChange={this.handleAllCheck}
                                               checked={this.state.check.size == this.state.users?.length && this.state.users.length > 0}/>
                                    </th>
                                    <th style={{ fontSize: '15px', textAlign: "center" }}>사원 선택</th>
                                </tr>
                                </thead>
                            </table>
                            <div className="table_wrap">
                                <table className="common_table table table-vcenter card-table">
                                    <tbody>
                                    {this.state.users?.map((data: UserDataBase, i: number) => {
                                        return (
                                            <tr key={randomKey(i)}>
                                                <td className="w-1">
                                                    <input type="checkbox" className="form-check-input m-0 align-middle" aria-label="사용자 선택"
                                                           onChange={(e) => this.handleSingleCheck(e.target.checked, data.LoginID)}
                                                           checked={this.state.check.has(data.LoginID)}/>
                                                </td>
                                                <td>{data.DeptName === null ? "[(없음)]" : `[${data.DeptName}]`} {data.Name} {UserDataBase.getPosition(data)}</td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}