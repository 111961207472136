import React, {Component} from "react";

export const viewerFitWidthStyle: React.CSSProperties = {
    width: "100%",
    maxHeight: "fit-content",
}

export const viewerFinContentStyle: React.CSSProperties = {
    width: '100%',
    height: 'calc(100vh - 100px)'
}

export type IFileViewerProps = {
    fileURL: string,
    fileName?: string
}

type IFileViewerState = {
    fileURL: string
}

export default abstract class IFileViewer extends Component<IFileViewerProps, IFileViewerState>
{
    constructor(props: IFileViewerProps) {
        super(props);
        this.state = {
            fileURL: props.fileURL,
        }
    }

    public getFileURL() { return this.state.fileURL; }
    public setFileURL(fileURL: string)
    {
        this.setState({...this.state, fileURL: fileURL});
    }

    abstract render(): JSX.Element;
}