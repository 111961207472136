import React, {useEffect, useRef, useState} from "react";
import LoadingCircle from "common/control/LoadingCircle";
import UserDataBase from "data/API/User/UserDataBase";
import axios from "axios";
import {DefaultUserImage} from "Define";

const DefaultImage = DefaultUserImage;

type ProfileImageInputControlProps = {
    loginID?: string,
    name?: string,
    onBusy: (busy: boolean) => void,
}

const ProfileImageInputControl = ({loginID, name, onBusy}: ProfileImageInputControlProps) =>
{
    const COMMAND_DELETE = "DELETE";

    const [isBusy, setBusy] = useState(false);
    useEffect(() => { if(onBusy != null) onBusy(isBusy); }, [isBusy]);

    const [image, setImage] = useState<string | undefined>(undefined);
    const [data, setData] = useState<string | undefined>(undefined);
    useEffect(() =>
    {
        if(loginID != null)
        {
            setBusy(true);
            axios.get(UserDataBase.getImageURL(loginID), {responseType: 'blob'})
                .then(response => setImage(response.status == 204 ? DefaultImage : URL.createObjectURL(response.data)))
                .catch(() => setImage(DefaultImage))
                .finally(() => setBusy(false));
        }
    }, []);

    const onImageDelete = () =>
    {
        setImage(DefaultImage);
        setData(COMMAND_DELETE);
    }

    const onImageChange = (input: HTMLInputElement) =>
    {
        if(input.files != null && input.files.length > 0)
        {
            const file = input.files[0];
            const reader = new FileReader();
            if(reader != null)
            {
                reader.readAsDataURL(file);
                reader.onloadstart = reader.onprogress = () => setBusy(true);
                //reader.onabort = () => setBusy(false);
                reader.onload = ()=>
                {
                    setBusy(false);
                    const result = reader.result;
                    if(typeof result == 'string')
                    {
                        if(result.length > 1024 * 1024 * 10) alert('이미지는 10MB 까지만 허용됩니다');
                        else
                        {
                            setData(result);
                            setImage(result);
                        }
                    }
                };
                reader.onerror = (error) =>
                {
                    setBusy(false);
                    console.log(error);
                }
            }
        }
        //aaa
    }
    const profileStyle = {
        width:"100%",
        height:"100%"
    }

    const InputImage = useRef<HTMLInputElement | null>(null);
    //const getImage = () => image == null || image == COMMAND_DELETE ? DefaultImage : image;
    return (
        <div className="row align-items-center">
            <input type="hidden" name={name} value={data}/>
            <div className="col-auto">
                <span className="avatar avatar-xl" style={{overflow:"hidden"}}>
                    { isBusy ? <LoadingCircle size="60px" border={5}/> : <img src={image ?? DefaultImage} style={profileStyle} alt="프로필 이미지"/> }
                </span>
            </div>
            <div className="col-auto">
                <input ref={InputImage} type="file" style={{display: "none"}} accept="image/*" onChange={(e) => onImageChange(e.currentTarget)}/>
                <button type="button" className="btn" disabled={isBusy} style={{cursor: 'pointer'}} onClick={() => { if(InputImage.current != null) InputImage.current.click() }}>사진 변경</button>
            </div>
            <div className="col-auto">
                <button type="button" className="btn btn-ghost-danger" disabled={isBusy} style={{cursor: 'pointer'}} onClick={onImageDelete}>사진 삭제</button>
            </div>
        </div>
    )
}

export default ProfileImageInputControl;