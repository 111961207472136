import {URL_API} from "Define";

export type ScheduleShareType = "priv" | "group" | "share";

export const ID = "calendar";

export const ID_ALL = "all";
export const ID_PUBLIC = "pub";
export const ID_GROUP = "group";
export const ID_PRIVATE = "priv";

export const API_SCHEDULE = URL_API + "/calendar/schedule";
export const API_SCHEDULES = API_SCHEDULE + "/{Type}/{Year}/{Month}/{Day}";