import {PathMaker, PathMakerMulti, randomKey} from "@hskernel/hs-utils";
import {Link, useLocation} from "react-router-dom";
import styles from './TopHeader.module.css';
import React, {useEffect, useState} from 'react';
//import { IconCategory2 } from '@tabler/icons-react';
import {Info as Modules} from "../Module";
import ModuleInfo, {checkModuleKindMatch, ModuleMenu} from 'modules/ModuleInfo';

import MenuArrowImage from './resource/menu-arrow.png';
import {useAuth} from "../context/AuthContext";
import {GetIcon, GetPath} from "./SideMenu";
import {useTheme} from "../context/ThemeContext";

type MenuProps = {
    pathParent: string,
    admin: boolean,
}

type DetailMenuProps = MenuProps & {
    menus: ModuleMenu[],
}
function DetailMenu({menus, pathParent, admin}: DetailMenuProps)
{
    return (
        <div className="dropdown-menu">
            {menus.map((menu) =>
            {
                return (menu.hide || !checkModuleKindMatch(admin, menu.admin) ? "" :
                    <Link to={PathMaker(pathParent, menu.path)} key={randomKey()} className="dropdown-item" style={{backgroundColor: "var(--table-top-color)"}}>
                        {menu.title}
                    </Link>
                )
            })}
        </div>
    )
}

type DropSubMenuProps = MenuProps & {
    module: ModuleInfo
}
function DropSubMenu({module, pathParent, admin}: DropSubMenuProps) {
    return (
        <div className="dropdown-menu">
            <div className="dropdown-menu-columns">
                <div className="dropdown-menu-column">
                    {module.category.map((category) => { return category.hide || !checkModuleKindMatch(admin, category.admin) ? "" :
                        (
                            <div key={randomKey()} className="dropend" style={{backgroundColor: "var(--table-title)"}}>
                                <a className={category.menu.length > 0 ? "dropdown-item dropdown-toggle" : "dropdown-item"} href="#sidebar-cards" data-bs-toggle="dropdown" data-bs-auto-close="outside"  role="button" aria-expanded="false">
                                    <img src={MenuArrowImage}/>
                                     {category.title}
                                </a>
                                <DetailMenu menus={category.menu} admin={admin} pathParent={PathMaker(pathParent, category.path ?? "")}/>
                            </div>
                        )
                    })}

                </div>
            </div>
        </div>
    )
}

type SideMenuMobile = {
    isClick?: boolean,

    onIsClick?: React.Dispatch<React.SetStateAction<boolean>>
}
function SideMenuMobile({isClick,onIsClick}: SideMenuMobile)
{
    const {admin} = useAuth();
    const {theme, setDarkMode} = useTheme();

    const location = useLocation();

    useEffect(() => reset(),[location]);
    useEffect(() => console.log(isClick), [isClick])

    function reset()
    {
        if(onIsClick != null) onIsClick(false);
    }

    return (
        <header className={`navbar-expand-md ${styles.mobile_header}`}>
            <div className={`navbar-collapse collapse ${styles.navbar_menu}`}  style={{height:"100%!important",display:"block"}}>
                {isClick? <div className="navbar">
                    <div className="container-xl">
                        <ul className="navbar-nav">
                            {Modules.map((module) => {
                                return checkModuleKindMatch(admin, module.admin) ? (
                                    <li key={randomKey()} className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" role="button" aria-expanded="false">
                                            {GetIcon(module, theme.darkMode, true)}&nbsp;
                                            <span className="nav-link-title">{module.title}</span>
                                        </a>
                                        <DropSubMenu module={module} admin={admin} pathParent={GetPath(module.id)}/>
                                    </li>
                                ) : ""
                            })}
                        </ul>
                    </div>
                </div>:""}
            </div>
        </header>
    );
}

export default SideMenuMobile;