import {dateFormat} from "utils/date.format";
import React, {useEffect, useRef, useState} from "react";
import {isEmptyString} from "../../utils/HSUtils";

export const pureDate = (date: Date, offsetHour?: number) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), offsetHour ?? 0, 0, 0, 0);
export const calcDate = (day_add: number, baseDate?: Date) =>
{
    let date: DateRangeControlState = {start: null, end: null};
    if(day_add >= 0)
    {
        const now = baseDate ?? new Date();
        date = {
            start: pureDate(now.addDays(-day_add)),
            end: pureDate(now)
        }
    }
    return date;
}

type DateRangeControlProps = {
    disabled?: boolean,
    onChange: (start: Date | undefined, end: Date | undefined) => void | Promise<void>;
    start?: Date | null,
    end?: Date | null,
    startTitle?: string,
    endTitle?: string,
}

export type DateRangeControlState =
{
    start: Date | null,
    end: Date | null,
}
const DateRangeControl = ({onChange, start, end, startTitle, endTitle, ...props}: DateRangeControlProps) => {

    const getDateString = (_date?: Date | null) => _date == null ? '' : dateFormat(_date, 'yyyy-mm-dd');

    const [dateBefore, setDateBefore] = useState<DateRangeControlState>({start: start ?? null, end: end ?? null});
    const [date, _setDate] = useState<DateRangeControlState>(dateBefore);
    const [isBusy, setIsBusy] = useState(false);

    const startControl = useRef<HTMLInputElement>(null);
    const endControl = useRef<HTMLInputElement>(null);

    function setDate(date: DateRangeControlState, raiseEvent = true)
    {
        setDateBefore(date);
        _setDate(date);
        if (raiseEvent && onChange != null)
        {
            setIsBusy(true);
            Promise.resolve(onChange(date.start ?? undefined, date.end ?? undefined)).then()
                .finally(() => setIsBusy(false));
        }
    }

    useEffect(() =>
    {
        const _date = {start: start ?? null, end: end ?? null};
        if (checkDate(_date)) setDate(_date, false);
    }, [start, end]);

    //오늘 월수 계산
    /**
     *
     * @param {number} day_add
     */
    const countDate = (day_add: number) =>
    {
        const date = calcDate(day_add);
        setDate(date);
        if(startControl.current) startControl.current.value = getDateString(date.start);
        if(endControl.current) endControl.current.value = getDateString(date.end);
    }
    const checkDate = (date: DateRangeControlState) =>
    {
        if(date.start != null && date.end != null)
        {
            if(date.start > date.end) {alert("시작날짜는 끝날짜보다 클 수 없습니다"); return false;}
            else if(date.end < date.start) {alert("끝 날짜는 끝날짜보다 클 수 없습니다"); return false;}
        }

        return true;
    }

    const isDisabled = () => isBusy || props.disabled;

    //function debug(value?: any) { console.log(value ?? "RENDER!!"); return ""; }
    return (
        <div className="row">
            <div className="col-lg-4 col-sm-6 col-md-6" style={{marginBottom:"10px"}}>
                <div className="input-group">
                    <span className="input-group-text bg-primary text-primary-fg">{startTitle ?? "시작"}</span>
                    <input ref={startControl} className="form-control" type="date" disabled={isDisabled()} placeholder={(startTitle ?? "시작") + "날짜 선택"}
                           value={ getDateString(date.start)}
                           onChange={(e) =>
                           {
                               const newDate = {...date, start: isEmptyString(e.target.value) ? null : pureDate(new Date(e.target.value))};
                               if (checkDate(newDate)) setDate(newDate);
                               else e.currentTarget.value = getDateString(dateBefore.start);
                           }}/>
                </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6" style={{marginBottom:"10px"}}>
                <div className="input-group">
                    <span className="input-group-text bg-primary text-primary-fg">{endTitle ?? "끝"}</span>
                    <input ref={endControl} className="form-control" type="date" disabled={isDisabled()} placeholder={(endTitle ?? "시작") + "날짜 선택"}
                           value={getDateString(date.end)}
                           onChange={(e) =>
                           {
                               const newDate = {...date, end: isEmptyString(e.target.value) ? null : pureDate(new Date(e.target.value))};
                               if (checkDate(newDate)) setDate(newDate);
                               else e.currentTarget.value = getDateString(dateBefore.end);
                           }}/>
                </div>
            </div>
            <div className="col-lg-auto col-sm-12 col-md-12" style={{marginBottom:"10px"}}>
                <input type="button" className="btn btn-secondary" value="전체" disabled={isDisabled()} onClick={() => countDate(-1)}/>&nbsp;
                {/*<input type="button" className="btn btn-secondary" value="오늘" disabled={isDisabled()} onClick={() => countDate(0)}/>&nbsp;*/}
                <input type="button" className="btn btn-secondary" value="일주일" disabled={isDisabled()} onClick={() => countDate(7)}/>&nbsp;
                <input type="button" className="btn btn-secondary" value="한달" disabled={isDisabled()} onClick={() => countDate(31)}/>&nbsp;
                <input type="button" className="btn btn-secondary" value="일년" disabled={isDisabled()} onClick={() => countDate(365)}/>
            </div>
        </div>
    )
}
export default DateRangeControl;
