import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, isAxiosError} from "axios";
import {GoErrorPage, URL_FRONT, URL_FRONT_LOGIN, URL_FRONT_LOGOUT} from "./Define";
import {ClassConstructor, plainToClass, plainToInstance} from "class-transformer";
import {ErrorPageParam} from "./page/error/Base";
import {NavigateFunction} from "react-router";

export const API_RESULT_SUCCESS = "success";
export const API_RESULT_WARNING = "warning";
export const API_RESULT_FAIL = "fail";

export class APIData
{
    public result: string;
    public message: string;
    public code: string;
    public data?: any | null;

    public static fromData(data: any | null): APIData | null
    {
        if(data != null)
        {
            if(typeof data == "string")
            {
                try { return APIData.fromData(JSON.parse(data)); } catch (e) { /*  Empty */ }
            }
            else if(typeof data == "object")
            {
                if(data.result != undefined && data.code != undefined && data.message != undefined)
                {
                    const _data = new APIData();
                    _data.result = data.result;
                    _data.code = data.code;
                    _data.message = data.message;
                    _data.data = data.data;
                    return _data;
                }
            }
        }

        return null;
    }
}

export class ProcessErrorResult
{
    constructor(message: string)
    {
        this.message = `요청을 처리하는 중에 오류가 발생하였습니다\n\n${message}`;
        this.code = 0;
        this.process = false;
    }
    /**
     *
     */
    public message: string;
    /**
     *
     */
    public code: number;
    /**
     * 오류가 처리되었는지에대한 여부
     */
    public process: boolean;
}

/**
 * 오류를 처리합니다 (ErrorPageParam 이 null 이 아니면 오류 페이지로 보내고, null 이면 메세지만 띄웁니다)
 */
type ProcessErrorHandler = (code: number, data: APIData | null) => {error?: ErrorPageParam | null, navigate?: NavigateFunction};

/**
 *
 * @param {Promise<AxiosResponse<T, any>>} response
 * @param {boolean} process404 404 오류를 처리할지 여부입니다. false 면 null 을 반환합니다
 * @param {any} Type 변환할 형식을 지정합니다
 * @param {ProcessErrorHandler} ErrorHandler 오류를 처리합니다
 * @constructor
 */
export function SendValueAsyncType<T>(response:  Promise<AxiosResponse<T, any>>, process404: boolean, Type: ClassConstructor<T>, ErrorHandler?: ProcessErrorHandler): Promise<T | null>
{
    return new Promise<T | null>((resolve, reject) =>
    {
        SendValueAsync<T>(response, process404, ErrorHandler)
            .then(
                (data) =>
                {
                    const instance = plainToInstance<T, any>(Type, data);
                    //console.log(data);
                    //console.log(instance);
                    resolve(instance);
                },
                (error) => reject(error));
    });
}
export function SendValueAsyncArrayType<T>(response: Promise<AxiosResponse<T[], any>>, process4xx: boolean, Type: ClassConstructor<T>, ErrorHandler?: ProcessErrorHandler): Promise<T[] | null>
{
    return new Promise<T[] | null>((resolve, reject) =>
    {
        SendValueAsync<T[]>(response, process4xx, ErrorHandler).then(
            (data) =>
            {
                if(data != null && Array.isArray(data))
                {
                    const arr = [];
                    for(let i = 0; i < data.length; i++)
                    {
                        //excludeExtraneousValues는 class에서 @Expose / @Exclude를 제외한 모든 값들을 제거하는 옵션이다.
                        arr[i] = plainToInstance<T, any>(Type, data[i], { excludeExtraneousValues: false, enableImplicitConversion: true });
                    }
                    resolve(arr);
                }
                else resolve(null);
            },
            (error) => reject(error));
    });
}

/**
 * 간단히 요청만 보내서 2xx 면 true 그 외에는 false 를 반환합니다
 * @param {Promise<AxiosResponse<APIData, any>>} response
 * @param {ProcessErrorHandler} ErrorHandler 오류를 처리합니다
 * @returns {Promise<boolean>}
 * @constructor
 */
export async function SendAsync(response: Promise<AxiosResponse>, ErrorHandler?: ProcessErrorHandler)
{
    try { await response; return true; }
    catch (e)
    {
        if(isAxiosError(e)) ProcessResponseError(e, false, false, ErrorHandler);
        return false;
    }
}

/**
 *
 * @param {Promise<AxiosResponse<T, any>>} response
 * @param {boolean} process4xx
 * @param {ProcessErrorHandler} errorHandler 오류 처리기 입니다 (해당 오류가 처리 되었다고 판단되면 null을 반환합니다)
 * @returns {Promise<T | null>}
 * @constructor
 */
export function SendValueAsync<T>(response: Promise<AxiosResponse<T, any>>, process4xx: boolean, errorHandler?: ProcessErrorHandler): Promise<T | null>
{
    return new Promise<T | null>((resolve, reject) =>
    {
        response.then(
            (data) => resolve(data.data),
            (error: Error) =>
            {
                const errorRes = ProcessResponseError(error, true, process4xx, errorHandler);
                if (errorRes == null) resolve(null);
                else
                {
                    if(errorRes.code == 404)
                    {
                        if(process4xx) GoErrorPage(404, error.message);
                        else resolve(null);
                    }
                    else if(errorRes.code == 403)
                    {
                        if(process4xx) GoErrorPage(403, error.message)
                        else
                        {
                            alert(errorRes.message);
                            resolve(null);
                        }
                    }

                    reject(error);
                }
            });
    });
}

/**
 *
 * @param {Error} error
 * @param {boolean} showMessage
 * @param {boolean} process4xx
 * @param {ProcessErrorHandler} errorHandler
 * @constructor
 */
export function ProcessResponseError(error: Error, showMessage: boolean, process4xx: boolean, errorHandler?: ProcessErrorHandler): ProcessErrorResult | null
{
    const result = new ProcessErrorResult(error.message);
    if(isAxiosError(error))
    {
        const data = error.response!.data as APIData;
        console.info(data);

        //Math.floor(errorRes.code / 100) == 2
        result.code = error.response!.status;

        if (errorHandler != null)
        {
            const callbackValue = errorHandler(result.code, data);
            if (callbackValue.error != null)
            {
                GoErrorPage(result.code, callbackValue.error, callbackValue.navigate);
                return null;
            }
        }

        if (result.code === 502)
        {
            result.message = "서버를 찾을 수 없습니다. 문의바랍니다";
            result.process = true;
            GoErrorPage(502, error.message);
        }
        else if (result.code == 500) {
            result.message = "서버 오류가 발생했습니다\n\n" + error.message;
            result.process = true;
            GoErrorPage(500, error.message);
        }
        else if (result.code == 400)
        {
            result.message = data != null && data.result != null ? data.message : "일부 값이 잘못되었습니다";
            result.process = true;
        }
        else if (result.code == 413)
        {
            result.message = data != null && data.result != null ? data.message : "첨부파일 총합 및 본문의 크기가 너무 큽니다";
            result.process = true;
        }
        else if (result.code == 403)
        {
            result.message = data != null && data.result != null ? data.message : "접근이 거부되었습니다";
            result.process = true;
        }
        else if (result.code == 401)
        {
            result.message = "인증이 만료되었습니다. 다시 로그인해주시기 바랍니다";
            result.process = true;

            //location.reload();

            const callback = location.pathname.indexOf(URL_FRONT_LOGIN) != 0 && location.pathname.indexOf(URL_FRONT_LOGOUT) != 0 && location.pathname != URL_FRONT ?
                `?callback=${encodeURIComponent(window.location.pathname + window.location.search)}` :
                '';
            location.href = URL_FRONT_LOGIN + callback;
        }
        if (showMessage && !((result.code == 404 || result.code == 403) || process4xx)) alert(result.message);
    }

    return result;
}
