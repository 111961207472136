import "reflect-metadata";
import {Expose, Type} from "class-transformer";
import axios from "axios";
import * as Define from "../Define";
import {SendValueAsync, SendValueAsyncArrayType} from "API";
import {isNullorWhiteSpace, PathMaker, dateFormat} from "@hskernel/hs-utils";
import {LIST_COUNT, URL_API_APPROVAL_DOCU_COUNT} from "../Define";
import ApprovalInfo from "./ApprovalInfo";
import ApprovalStatus from "./ApprovalStatus";
import {IsArray} from "class-validator";
import UserDataBase from "data/API/User/UserDataBase";
import {AxiosHeader, HEADER_ADMIN} from "Define";
import ApprovalDocumentERPBase from "./ApprovalDocumentERPBase";
import {SearchDocumentParam} from "../info/approval";

export const SEARCH_KIND_TITLE = "Title";
export const SEARCH_KIND_DOC = "DocID";
export const SEARCH_KIND_WRITER = "Writer";

export const ListKind = {
     /**
      * 결재요청문서
      */
     Request: "request",
     /**
      * 임시저장문서
      */
     Temp: "temp"
}

export type ApprovalDocumentListTotalData = {
     [key: string]: number;
}

export default class ApprovalDocumentListData extends ApprovalDocumentERPBase
{
     @Expose() public ApprovalString: string;
     @Expose() public LoginID: string;
     @Expose() public DocumentID: string;
     @Expose() public DocumentClass: string;
     @Expose() public DocumentClassName: string;
     /**
      * 문서상태 [0:저장 → 1:상신 → 2:결재 진행중 → 3:결재 보류중 → 4: 결재 반려 → 5:종결]
      */
     @Expose() public DocumentStatus: string;
     @Expose() public DocumentComment: string;
     @Expose() @Type(() => Date)
     public WriteDate?: Date;
     @Expose() @Type(() => Date)
     public ReportDate?: Date | null;
     @Expose() public WriteName?: string;
     @Expose() public WriteNameKor?: string;
     @Expose() public WriteDeptName?: string;
     @Expose() public WriteDeptCode?: string;
     @Expose() public WritePositionName1?: string;
     @Expose() public WritePositionName2?: string;
     @Expose() public EmployeeCode?: string;
     @Expose() public IsRefUser?: boolean;
     @Expose() public IsExecUser?: boolean;
     @Expose() @Type(() => Date)
     public ExecDate: Date | null;
     @Expose() public Title: string;
     @Expose() public IsDocumentRef?: boolean;
     @Expose() public IsAttachFile?: boolean;
     @Expose() public ApprovalNo?: number;
     @Expose() public AgreementNo?: number;
     @Expose() @Type(() => ApprovalInfo)
     @Expose() public ApprovalInfo: ApprovalInfo;
     @Expose() @IsArray() @Type(() => ApprovalStatus)
     @Expose() public Approvals: ApprovalStatus[];
     @Expose() public Comments: number;
     /**
      * 로그인한 사용자가 해당 문서를 열람한 횟수
      */
     @Expose() public ReadCount: number;

     public getUserData(): UserDataBase
     {
          const user = new UserDataBase();
          user.LoginID = this.LoginID;
          user.Name = this.WriteName ?? null;
          user.NameKorean = this.WriteNameKor ?? null;
          user.DeptCode = this.WriteDeptCode ?? null;
          user.DeptName = this.WriteDeptName ?? null;
          user.PositionName1 = this.WritePositionName1 ?? null;
          user.PositionName2 = this.WritePositionName2 ?? null;
          return user;
     }

     public static GetCountAsync(kind: string, search: SearchDocumentParam, Class?: string, isAdminMode?: boolean)
     {
          const param = search.getAPIParams();
          if (Class != null) param.push(`class=${Class}`);

          const headers: AxiosHeader = isAdminMode == null ? {} : {[HEADER_ADMIN]: `${isAdminMode}`};
          return SendValueAsync<number>(axios.get(PathMaker(Define.URL_API_APPROVAL_DOCU_COUNT, kind) + `?${param.join('&')}`, {headers: headers}), false);
     }
     public static GetListAsync(kind: string, search?: SearchDocumentParam, Class?: string | null, offset?: number | null, count?: number | null, isAdminMode?: boolean) : Promise<ApprovalDocumentListData[] | null>
     {
          const param = search == undefined ? [] : search.getAPIParams();
          if(Class != null) param.push(`class=${Class}`);
          param.push(`offset=${offset ?? 0}`);
          param.push(`count=${count ?? LIST_COUNT}`);

          const url = PathMaker(Define.URL_API_APPROVAL_DOCU_LIST, kind) + `?${param.join('&')}`;
          const headers: AxiosHeader = isAdminMode == null ? {} : {[HEADER_ADMIN]: `${isAdminMode}`};

          const request = axios.get<ApprovalDocumentListData[]>(url, {headers: headers});
          return SendValueAsyncArrayType<ApprovalDocumentListData>(request, false, ApprovalDocumentListData);
          //return await SendValueAsync<ApprovalDocumentList[]>(axios.get<ApprovalDocumentList[]>(Define.URL_API_APPROVAL_DOCU_LIST + '?keyword=' + keyword + '&count=' + count + '&offset=' + offset), false);
     }

     public static GetCountTotalAsync(Kinds: string | string[], search?: SearchDocumentParam | null, Class?: string | null, isAdminMode?: boolean)
     {
          const param = search == null ? [] : search.getAPIParams();
          if (Class != null) param.push(`class=${Class}`);
          if (Array.isArray(Kinds)) Kinds.forEach(kind => param.push(`request=${kind}`));
          else param.push(`request=${Kinds}`);

          const url = URL_API_APPROVAL_DOCU_COUNT + `?${param.join('&')}`;
          const headers: AxiosHeader = isAdminMode == null ? {} : {[HEADER_ADMIN]: `${isAdminMode}`};
          return SendValueAsync<ApprovalDocumentListTotalData>(axios.get(url, {headers: headers}), false);
     }
}
