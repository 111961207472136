import ApprovalLineMultiSelect, {
    ApprovalLineMultiData,
    ConvertApprovalLineData
} from "../component/selector/ApprovalLineMultiSelect";
import AuthData from "Auth";
import React, {FormEvent, useRef, useState} from "react";
import RadioGroup from "common/component/Radio/RadioGroup";
import Radio from "common/component/Radio";
import {ApprovalLineShareType} from "../data/ApprovalLineBase";
import ApprovalLineData from "../data/ApprovalLineData";
import ApprovalLineForm from "../data/ApprovalLineForm";
import {printError} from "Define";
import {isEmptyString} from "@hskernel/hs-utils";
import {APPROVER_DIV_APPROVAL, APPROVER_DIV_EXEC, APPROVER_DIV_REF, MAX_APPROVALS} from "../Define";
import { ApprovalSelectorData } from "common/control/selector/internal/ISelector";
import ApprovalLineExtra from "../type/ApprovalLineExtra";
import {APPROVER_KIND_APPROVAL} from "../data/ApprovalStatus";

type ApprovalSetModalProps = {
    auth: AuthData,
    onClose: (isSuccess: boolean) => void,
    data?: ApprovalLineData,
}

const ApprovalSetModal: React.FC<ApprovalSetModalProps> = ({auth, onClose, data}) =>
{
    const Selector = useRef<ApprovalLineMultiSelect | null>(null);

    const [busy, setBusy] = useState(false);

    function onCancel()
    {
        if(confirm("정말 취소하시겠습니까?")) onClose(false);
    }

    function onSubmit(e: FormEvent<HTMLFormElement>)
    {
        e.preventDefault();

        if(Selector.current != null)
        {
            const form = ApprovalLineForm.FromForm(e.currentTarget);
            const data = Selector.current.getData();
            form.FormApproval = data.Approver.map(value => `${value.user.LoginID}:${value.kind}:${value.extra.final}`);
            form.FormRefUser = data.UserRef.map(value => value.user.LoginID);
            form.FormExecUser = data.UserExec.map(value => value.user.LoginID);

            //유효성 검사하기
            if(isEmptyString(form.FormLineName)) { alert("결재라인명은 비어있을 수 없습니다"); return false; }
            if(form.FormApproval.length == 0) { alert("결재자는 1명이상 선택해야 합니다"); return false; }
            if(form.FormApproval.length > MAX_APPROVALS) { alert(`결재자는 ${MAX_APPROVALS}명이하여야 합니다`); return false; }

            setBusy(true);
            form.SaveAsync()
                .then(() => onClose(true))
                .catch(e => printError(e, "저장에 실패하였습니다"))
                .finally(() => { setBusy(false); });
        }

    }


    return (
        <form className="card" onSubmit={onSubmit}>
            <input type="hidden" name="LineName" value={data == null ? undefined : data.LineName}/>
            <div className="card-header">
                    <div className="col">결재라인 등록</div>
                    <div className="col-auto ms-auto d-print-none">
                        <button type="submit" className="btn btn-primary" disabled={busy}>저장</button>&nbsp;&nbsp;
                        <button type="button" className="btn" disabled={busy} onClick={onCancel}>취소</button>
                    </div>
            </div>
            <div className="card-body">
                <table className="approval_line_table">
                    <tr>
                        <th>구분</th>
                        <td>
                            <RadioGroup name="ShareType" value={data == null ? "priv" : data.ShareType}>
                                <Radio classNameLabel="form-check form-check-inline" classNameRadio="form-check-input" value="priv">
                                    <span className="form-check-label">개인</span>
                                </Radio>
                                <Radio classNameLabel="form-check form-check-inline" classNameRadio="form-check-input" value="dept">
                                    <span className="form-check-label">부서</span>
                                </Radio>
                                <Radio classNameLabel="form-check form-check-inline" classNameRadio="form-check-input" value="common">
                                    <span className="form-check-label">공통</span>
                                </Radio>
                            </RadioGroup>
                        </td>
                    </tr>
                </table>
                <table className="approval_line_table">
                    <tr>
                        <th>결재라인명</th>
                        <td>
                            <input type="text" name="FormLineName" className="form-control" defaultValue={data == null ? undefined : data.LineName}/>
                        </td>
                    </tr>
                </table>
            </div>
                <ApprovalLineMultiSelect ref={Selector} initData={ConvertApprovalLineData(data)} auth={auth}
                                         showFinal={false} isLineSetting={true}/>
        </form>
    );
}
export default ApprovalSetModal;