import React from "react";
import IFileViewer, {viewerFinContentStyle} from "./IFileViewer";

export default class VideoPlayer extends IFileViewer
{
    render() {
        return (
            <video controls style={viewerFinContentStyle}>
                <source src={this.props.fileURL}/>
            </video>
        )
    }
}