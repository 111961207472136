import React, {Component, useState} from "react";
import {IconArrowLeft, IconHome} from "@tabler/icons-react";
import {onBackClick, URL_FRONT} from "Define";
import qs, {ParsedQs} from 'qs';
import {isEmptyString, joinEx, parseBoolean} from "@hskernel/hs-utils";
import {Location} from "react-router";
import {useLocation} from "react-router-dom";

function getParam(params: ParsedQs | any | undefined, location: Location | undefined, name: string): string | undefined
{
    let param = params == null ? undefined : params[name];
    if (isEmptyString(param))
    {
        if (location != null && location.state != null) param = location.state[name]
    }

    if (param != null)
    {
        if (typeof param === 'string') return isEmptyString(param) ? undefined : param;
        if (Array.isArray(param)) return joinEx(param, '\n');

        return param.toString();
    }
    return undefined;
}

/*
function getParam(params: any | undefined, name: string): string | undefined
{
    if (params == null) return undefined;

    const param = params[name];
    if (param == null) return undefined;
    if (typeof param === 'string') return isEmptyString(param) ? undefined : param;
    if (Array.isArray(param)) return joinEx(param, '\n');

    return param.toString()
}
 */

export type ErrorPageParam = {
    title?: string,
    message?: string,
    errorCode?: string,
    hideHome?: boolean,
    hideBack?: boolean,
}
type ErrorPageState = {
    param: ErrorPageParam,
    urlParam?: ParsedQs,
}

export default abstract class ErrorPageBase extends Component<ErrorPageParam, ErrorPageState>
{
    protected constructor(props: ErrorPageParam)
    {
        super(props);

        this.state = {
            param: props,
            urlParam: isEmptyString(location.search) ? undefined : qs.parse(location.search, {ignoreQueryPrefix: true})
        }
    }

    render = () => <ErrorView {...this.state}/>;
}

const ErrorView = ({param, urlParam}: ErrorPageState) =>
{
    const _location = useLocation();

    const _hideBack = getParam(urlParam, _location, 'hideBack');
    const _hideHome = getParam(urlParam, _location, 'hideHome');

    const state: ErrorPageParam = {
        errorCode: param.errorCode ?? getParam(urlParam, _location, 'code'),
        title: getParam(urlParam, _location, 'title') ?? param.title,
        message: getParam(urlParam, _location, 'message') ?? param.message,
        hideBack: _hideBack == null ? param.hideBack : parseBoolean(_hideBack),
        hideHome: _hideHome == null ? param.hideHome : parseBoolean(_hideHome)
    };

    return (
        <div className="page page-center">
            <div className="container-tight py-4">
                <div className="empty">
                    <div className="empty-header">{state.errorCode}</div>
                    <p className="empty-title">{state.title ?? '오류가 발생 하였습니다'}</p>
                    {state.message == null ? null : <p className="empty-subtitle text-secondary" style={{whiteSpace: 'pre-line'}}>{state.message}</p>}
                    <div className="empty-action">
                        {
                            state.hideBack ? null :
                                <a className="btn btn-primary" onClick={() => onBackClick(true)} style={{width: '120px', marginBottom: 5}}>
                                    <IconArrowLeft stroke={2}/>
                                    &nbsp;뒤로 가기
                                </a>
                        }
                        <br/>
                        {
                            state.hideHome ? null :
                                <a className="btn btn-secondary" onClick={() => location.replace(URL_FRONT)} style={{width: '120px'}}>
                                    <IconHome stroke={2}/>
                                    &nbsp;홈으로
                                </a>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}