import {isEmptyString, serializeForm} from "@hskernel/hs-utils";
import {Expose, plainToInstance} from "class-transformer";
import {API_SCHEDULE} from "../Define";
import {SendValueAsyncType} from "API";
import axios from "axios";
import ScheduleData from "./ScheduleData";
import ScheduleDataBase from "./ScheduleDataBase";

export default class ScheduleFormData extends ScheduleDataBase
{
    @Expose() public Contents: string | null;
    @Expose() public Location: string | null;

    public static FromForm(form: HTMLFormElement): ScheduleFormData
    {
        const obj = serializeForm(new FormData(form));
        return plainToInstance(ScheduleFormData, obj);
    }

    public async SaveAsync()
    {
        const send = axios({
            method: isEmptyString(this.ID) ? "PUT" : "POST",
            url: isEmptyString(this.ID) ? API_SCHEDULE : `${API_SCHEDULE}/${this.ID}`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: this,
            // 폼에서 [] 없애기
            formSerializer: { indexes: null },
        });
        return (await SendValueAsyncType<ScheduleData>(send, true, ScheduleData))!;
    }
}
