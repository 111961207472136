import {Route} from "react-router-dom";
import React from "react";
import {ID, ID_ALL, ID_GROUP, ID_PRIVATE, ID_PUBLIC} from "./Define";
import CalendarPage from "./page/CalendarPage";

const CalendarRouter = () =>
{
    return (
        <Route path={ID}>
            <Route path={""} element={<CalendarPage/>}/>
            <Route path={ID_PRIVATE} element={<CalendarPage share="priv"/>}/>
            <Route path={ID_GROUP} element={<CalendarPage share="group"/>}/>
            <Route path={ID_PUBLIC} element={<CalendarPage share="share"/>}/>
        </Route>
    )
}

export default CalendarRouter;