import {Expose, Type} from "class-transformer";
import {ScheduleShareType} from "../Define";

export default class ScheduleDataBase
{
    @Expose() public ID: string;
    @Expose() public ShareType: ScheduleShareType;
    @Type(() => Date)
    @Expose() public StartDate: Date;
    @Type(() => Date)
    @Expose() public EndDate: Date;
    @Expose() public Title: string | null;
    @Expose() public Color: string | null;
}