import React, {createRef} from 'react';
import Modal from 'react-modal';
import {Component} from "react";
import ApprovalDocumentListData, {SEARCH_KIND_DOC, SEARCH_KIND_TITLE} from "../../data/ApprovalDocumentListData";
import TableListControl, {GetItemStyle, TableListData} from "common/control/TableListControl";
import {LIST_COUNT, LIST_KIND_COMPLETE, LIST_KIND_REF, LIST_KIND_REJECT} from "../../Define";
import {StatusHeader} from "../../page/document/DocumentListPage";
import {DocumentViewOpen} from "../../page/document/DocumentViewPage";
import ApprovalStatusProgress from "../ApprovalStatusProgress";
import {
    getSearchOptionValueFromEvent,
    SearchOptionProps,
} from "common/control/SearchControl";

import {SelectOptionData, SelectOptionValue} from "common/component/SelectOption";
import {GET_DEFAULT_DATE, SearchDocumentParam} from "../../info/approval";
import {
    DEFAULT_SEARCH_DATE_FULL,
    SearchWithDateRangeControlClass,
    SearchWithDateRangeFullData
} from "common/control/SearchWithDateRangeControl";



//전/종결함의 문서만 불러오도록 정책 설정
const searchOption: SearchOptionProps[] = [
    {
        autoSearch: false,
        options: [
            new SelectOptionData("제목", SEARCH_KIND_TITLE),
            new SelectOptionData("문서번호", SEARCH_KIND_DOC)
        ]
    },
    {
        autoSearch: true,
        options: [
            new SelectOptionData("전결함", LIST_KIND_COMPLETE),
            new SelectOptionData("반려함", LIST_KIND_REJECT),
            new SelectOptionData("수신참조", LIST_KIND_REF),
            /*
            new SearchKind("기결함", LIST_KIND_DECISION),
            new SearchKind("미결함", LIST_KIND_PROGRESS),
            new SearchKind("상신함", LIST_KIND_REQUEST),
            */
        ]
    }
]
const searchOptionDefault = [
    searchOption[0].options[0].Value,
    searchOption[1].options[0].Value,
]

type DocumentRefModalProps = {
    isOpen?: boolean,
    onSave: (docRef: ApprovalDocumentListData[]) => void
}
type DocumentRefModalState = {
    isOpen: boolean,
    search: SearchWithDateRangeFullData,

    data: Map<string, ApprovalDocumentListData>,

    stateDoc: ApprovalDocumentListData | null,
}

export default class DocumentRefSelectModal extends Component<DocumentRefModalProps, DocumentRefModalState> {
    private table_list = createRef<TableListControl<ApprovalDocumentListData>>();
    private table_select = createRef<TableListControl<ApprovalDocumentListData>>();
    private INIT_DATE = GET_DEFAULT_DATE();

    private header = [
        {name: "문서번호", key: "DocumentID", clickable: true, styleItem: GetItemStyle(15, true)},
        {name: "문서분류", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(15, false)},
        {name: "제목", key: "Title", clickable: true, styleItem: GetItemStyle(30, true)},
        {name: "기안자", key: "WriteNameKor", clickable: false, styleItem: GetItemStyle(10, false)},
        StatusHeader,
    ]
    //private searchOptionValue: SearchOptionValue[] = this.searchOption.map(option => option.options[0].Value);

    constructor(props: DocumentRefModalProps) {
        super(props);

        this.state = {
            isOpen: props.isOpen ?? false,
            data: new Map<string, ApprovalDocumentListData>(),
            search: DEFAULT_SEARCH_DATE_FULL(undefined, this.INIT_DATE, searchOption, searchOptionDefault, true),

            stateDoc: null
        }

        this.openDialogAsync = this.openDialogAsync.bind(this);
        this.closeDialog = this.closeDialog.bind(this);

        this.onSearchAsync = this.onSearchAsync.bind(this);
        this.onRefreshList = this.onRefreshList.bind(this);

        this.Add = this.Add.bind(this);
        this.Remove = this.Remove.bind(this);
    }

    public openDialogAsync = async (docs?: ApprovalDocumentListData[]) => {
        const data = this.state.data;
        console.log(this.state.search);
        if (docs != null)
        {
            docs.forEach((doc) => {
                if (!data.has(doc.DocumentID)) data.set(doc.DocumentID, doc);
            })
        }
        await new Promise<void>(resolve => this.setState({...this.state, data: data, isOpen: true}, resolve));
        await this.onSearchAsync();
    }
    public closeDialog = (save: boolean) => {
        if (save) this.props.onSave(Array.from(this.state.data.values()))
        else if (!confirm("정말 취소 하시겠습니까?")) return;
        this.setState({...this.state, isOpen: false});
    }

    private async onSearchAsync(search?: SearchWithDateRangeFullData, isSetState = false)
    {
        const _search = search ?? this.state.search;

        if (isSetState) await new Promise<void>(resolve => this.setState({...this.state, search: _search}, resolve));
        if (this.table_list.current != null)
        {
            const total = await ApprovalDocumentListData.GetCountAsync(_search.options[1]!.value, SearchDocumentParam.fromSearchData(_search));
            await this.table_list.current.setTotalAsync(total ?? 0);
            await this.table_list.current.refresh();
        }
    }

    private async onRefreshList(offset: number, count: number, page: number, total: number): Promise<TableListData<ApprovalDocumentListData>>
    {
        //console.log(`onRefresh(): ${offset} / ${count} / ${page} / ${total}`);
        const kind = this.state.search.options[1]?.value;
        if (total > 0 && kind != null)
        {
            const search = SearchDocumentParam.fromSearchData(this.state.search);
            const list = await ApprovalDocumentListData.GetListAsync(kind, search, null, offset, count)
            if (list != null) return {data: list};
        }

        return { data: [] };
    }
    //참조문서 목록 테이블에 표시
    private onRefreshSelect = async (offset: number, count: number, page: number, total: number): Promise<TableListData<ApprovalDocumentListData>> => {
        let _pass = 0;
        const items: ApprovalDocumentListData[] = [];
        this.state.data.forEach(value =>
        {
            if (_pass >= offset) items.push(value);
            else _pass++;
        });

        return {data: items};
    }

    private async Add() {
        if (this.table_list.current && this.table_select.current) {
            const data: ApprovalDocumentListData[] = this.table_list.current.getCheckedData();
            if (data.length > 0) {
                //체크해제
                this.table_list.current.checkAll(false);
                data.forEach((value) => {
                    if (!this.state.data.has(value.DocumentID))
                        this.state.data.set(value.DocumentID, value);
                });
                this.setState({...this.state, data: this.state.data});

                await this.table_select.current.setTotalAsync(data.length);
                await this.table_select.current.refresh();
            }
        }
    }

    private async Remove() {
        if (this.table_select.current) {
            const data: ApprovalDocumentListData[] = this.table_select.current.getCheckedData();
            if (data.length > 0) {
                data.forEach((value) => this.state.data.delete(value.DocumentID));
                this.setState({...this.state, data: this.state.data});

                await this.table_select.current.setTotalAsync(data.length);
                await this.table_select.current.refresh();
            }
        }
    }

    render() {
        const modalStyle = {
            content: {
                background: "var(--tblr-bg-surface)"
            },
            overlay: {
                background: "rgba(0,0,0,0.5)",
                zIndex:"2"
            }
        }
        return (
            <Modal isOpen={this.state.isOpen} style={modalStyle} ariaHideApp={false}>
                <ApprovalStatusProgress isOpen={this.state.stateDoc != null} doc={this.state.stateDoc}
                                        approvals={this.state.stateDoc ? this.state.stateDoc.Approvals : undefined}
                                        onClose={() => this.setState({...this.state, stateDoc: null})}/>
                <div className="card">
                    <div className="card-header">
                        <p style={{width: "20%"}}>참조문서</p>
                        <div style={{width: "80%", textAlign: "right"}}>
                            <div className="btn btn-primary" onClick={() => this.closeDialog(true)}>저장</div>
                            &nbsp;
                            <div className="btn" onClick={() => this.closeDialog(false)}>취소</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <SearchWithDateRangeControlClass
                                dateRangeTitle={"기안일"}
                                defaultOptions={searchOption} defaultDateStart={this.INIT_DATE.start ?? undefined} defaultDateEnd={this.INIT_DATE.end ?? undefined}
                                onChange={(data) => this.onSearchAsync(data, true)}/>
                        </div>
                    </div>
                    <div className="card-body" style={{background: "var(--table-title)"}}>
                        <div className="row">
                            <p className="col-lg-6" style={{marginBottom: "0", lineHeight: "36px"}}>참조문서 선택</p>
                            <div className="col-lg-6" style={{textAlign: "right"}}>
                                <button type="button" className="btn btn-primary" onClick={this.Add}>추가</button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <TableListControl ref={this.table_list} autoRefresh={false} headerTable={this.header} checkable={true}
                                          onRefresh={this.onRefreshList} count={LIST_COUNT}
                                          onItemClick={(value, header, data) => {
                                              if (header.key == "ApprovalInfo") this.setState({...this.state, stateDoc: data}); else DocumentViewOpen(data.DocumentID)
                                          }}/>
                    </div>
                    <div className="card-body" style={{background: "var(--table-title)"}}>
                        <div className="row">
                            <p className="col-lg-6" style={{marginBottom: "0", lineHeight: "36px"}}>참조문서 목록</p>
                            <div className="col-lg-6" style={{textAlign: "right"}}>
                                <button type="button" className="btn btn-primary" onClick={this.Remove}>삭제</button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <TableListControl ref={this.table_select} autoRefresh={true} headerTable={this.header} checkable={true}
                                      onRefresh={this.onRefreshSelect} count={LIST_COUNT}
                                      onItemClick={(value, header, data) => {
                                          if (header.key == "ApprovalInfo") this.setState({...this.state, stateDoc: data}); else DocumentViewOpen(data.DocumentID)
                                      }}/>
                    </div>


                </div>
            </Modal>
        )
    }
}
