import styles from "./TopHeader.module.css";
import iconMoreDark from "./resource/menu-more-dark.png";
import iconMore from "./resource/menu-more.png";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {useTheme} from "../context/ThemeContext";
import ModuleInfo, {ModuleMenu} from "../modules/ModuleInfo";
import {GetIcon, GetPath} from "./SideMenu";
import {PathMaker, PathMakerMulti, randomKey} from "@hskernel/hs-utils";
import {Async} from "react-async";
import {getRouteModuleState} from "../Module";
import {useAuth} from "../context/AuthContext";

type SideMenuShortcutProps = {
    module: ModuleInfo,
    menu?: ModuleMenu,
}

type MenuItem = {
    menu: ModuleMenu,
    focus: boolean,
    url: string,
}
type Menus = {
    show: MenuItem[],
    hide: MenuItem[],
}

const SideMenuShortcut = (props: SideMenuShortcutProps) =>
{
    const {theme, setDarkMode} = useTheme();
    const {admin} = useAuth();

    const [dropSwitch,setDropSwitch]  = useState(false);

    function getMenus(): Menus
    {
        const show: MenuItem[] = [];
        const hide: MenuItem[] = [];
        props.module.category.forEach(category =>
        {
            category.menu.forEach(menu =>
            {
                if(!menu.hide)
                {
                    const url = PathMakerMulti(GetPath(props.module.id), category.path ?? '', menu.path);
                    const shortcut = menu.shortcut ? menu.shortcut(admin) : false;
                    if (shortcut == 'show') show.push({menu: menu, url: url, focus: props.menu == menu});
                    else if(shortcut == 'hide') hide.push({menu: menu, url: url, focus: props.menu == menu});
                }
            })
        });
        return {show: show, hide: hide};
    }

    function render()
    {
        const menus = getMenus();

        const moduleState = getRouteModuleState(window.location.pathname);
        function getMenuShowButton(item: MenuItem, i: number, num?:number | undefined)
        {
            if(item.menu == null) return "";
            else
            {
                const number = num ?? 5;
                let isMatch = false;
                if(moduleState.current != null && moduleState.current.Menu != null)
                    isMatch = Object.is(item.menu, moduleState.current.Menu) || item.menu.id == moduleState.current.Menu.id;

                let icon = GetIcon(item.menu, theme.darkMode);
                icon = icon ?? GetIcon(props.module, theme.darkMode);
                //styles.mobile_quick_menu_focus
                return (
                    <Link key={randomKey(i)} className={`${styles.mobile_quick_menu} ${isMatch ? styles.focus : ""}`} to={item.url} style={{width:`${number <= MENU_MAX && 100/(number+1)}%`}}>
                        <div className={styles.menu_icon}>
                            {icon}
                        </div>
                        <p className={styles.menu_title}>{item.menu.title}</p>
                    </Link>)
            }
        }

        function getMenuHideButton(item: MenuItem)
        {
            let icon = GetIcon(item.menu, theme.darkMode);
            icon = icon ?? GetIcon(props.module, theme.darkMode);

            return (
                <Link to={item.url} className={styles.quick_drop_li}>
                    {icon}
                    <p>{item.menu.title}</p>
                    {/*<span>0</span>*/}
                </Link>
            )
        }

        const MENU_MAX = 3;
        const MENU_WIDTH = 75;
        return (menus.show.length > 0 || menus.hide.length > 0 ?
            <div className="shortcut_mt">
                <div className={`navbar-expand-md ${styles.navbar_menu} ${styles.quick_header}`}>
                    <div className={styles.nav_over_box} style={{width:`${menus.show.length > MENU_MAX && MENU_WIDTH * (menus.show.length + 1)}px`}}>
                        {menus.show.map((item, i) => item.menu.onRender == null ? getMenuShowButton(item, i) :
                            <Async key={randomKey(i)} promiseFn={() => item.menu.onRender!(item.menu, true)}>
                                <Async.Pending>{getMenuShowButton(item, i, menus.show.length)}</Async.Pending>
                                <Async.Fulfilled>{(menu: ModuleMenu) => getMenuShowButton({
                                    ...item,
                                    menu: menu
                                }, i, menus.show.length)}
                                </Async.Fulfilled>
                            </Async>)
                        }
                        {menus.hide.length == 0 ? "" :
                            <div className={styles.mobile_quick_menu} onClick={() => setDropSwitch(!dropSwitch)} style={{width:`${menus.show.length <= 3 && 100/(menus.show.length+1)}%`}}>
                                <div className={styles.menu_icon}>
                                    <img src={theme.darkMode ? iconMoreDark : iconMore}/>
                                </div>
                                <p className={styles.menu_title}>더보기</p>
                            </div>
                        }
                    </div>
                </div>
                {menus.hide.length == 0 ? "" :
                    <div className={styles.quick_drop_menu} style={dropSwitch ? {height:"auto"} : {height:"0"}}>
                        {menus.hide.map(menu => getMenuHideButton(menu))}
                    </div>
                }

            </div> : <></>
        )
    }
    return render();
}

export default SideMenuShortcut;
