import {Expose, Type} from "class-transformer";
import { joinEx } from "@hskernel/hs-utils";
import {PAGE_COUNT} from "Define";
import {SendValueAsync, SendValueAsyncArrayType} from "API";
import axios from "axios";
import {SearchControlData} from "common/control/SearchControl";
import {getSearchAPIParams} from "Search";
import {GetAPICountURL, GetAPIURL} from "../Define";

export default class BoardDataSimple
{
    @Expose() public LoginID: string;
    @Expose() public WriterName: string;
    @Expose() public Views: number;
    @Expose() public AttachCount: number;
    @Expose() public ID: number;
    @Expose() public Title: string;
    @Expose() @Type(() => Date)
    public WriteDate: Date;
    @Expose() @Type(() => Date)
    public ModifyDate: Date | null;
    public Comments: number;

    public static async GetListAsync(BoardKind: string, search?: SearchControlData, offset = 0, count = PAGE_COUNT)
    {
        const param = search == undefined ? [] : getSearchAPIParams(search);
        param.push(`count=${count}`);
        param.push(`offset=${offset}`);
        return await SendValueAsyncArrayType<BoardDataSimple>(axios.get(GetAPIURL(BoardKind) + `?${joinEx(param, '&')}`), true, BoardDataSimple);
    }
    public static async GetCountAsync(BoardKind: string, search?: SearchControlData)
    {
        const param = search == undefined ? [] : getSearchAPIParams(search);
        return await SendValueAsync<number>(axios.get(GetAPICountURL(BoardKind) + `?${joinEx(param, '&')}`), true);
    }

    public static DeleteAsync = (BoardKind: string, BoardID: string) => SendValueAsync(axios.delete(GetAPIURL(BoardKind, BoardID)), true);
}