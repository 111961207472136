import React, { useContext } from "react";
import RadioContext from "./RadioContext";

type RadioProps = {
    classNameLabel?: string,
    classNameRadio?: string,
    children?: JSX.Element | JSX.Element[] | string | undefined,
    value?: string,
    name?: string,
    defaultChecked?: boolean,
    disabled?: boolean,
}

const Radio = (props: RadioProps) =>
{
    const group = useContext(RadioContext);

    return (
        <label className={props.classNameLabel}>
            <input type="radio" className={props.classNameRadio} value={props.value}  defaultChecked={props.defaultChecked}
                   disabled={props.disabled || group.disabled}
                   name={props.name ?? group.name}
                   checked={group.value !== undefined ? props.value === group.value : undefined}
                   onChange={(e) => group.onChange && group.onChange(e.target.value)}/>
            {props.children}
        </label>
    );
}

export default Radio;