import AlertData from "./data/API/Alert/AlertData";
import {sendModuleData} from "./Module";

export const KEY_WV_ALERTS = 'Alerts';

/**
 * 초기화 되기전 알림 버퍼 입니다
 * @type {null}
 */
let InitAlert: AlertData | null = null;
export const getInitAlert = () => InitAlert;
export const setInitAlert = (alert: AlertData | null) => InitAlert = alert;

type AlertCallbackType = (alert: AlertData | null) => void;
const _AlertCallback: AlertCallbackType[] = [];
export function AddAlertCallback(onAlert: AlertCallbackType)
{
    for(let i = 0; i < _AlertCallback.length; i++)
    {
        if(_AlertCallback[i] === onAlert) return;
    }
    _AlertCallback.push(onAlert);
}
export function RemoveAlertCallback(onAlert: AlertCallbackType)
{
    for(let i = _AlertCallback.length - 1; i >= 0; i--)
        if(_AlertCallback[i] == onAlert) _AlertCallback.remove(i);
}

type AlertClickCallbackType = (alert: AlertData) => void;
const _AlertClickCallback: AlertClickCallbackType[] = [];
export function AddAlertClickCallback(onAlert: AlertClickCallbackType)
{
    for(let i = 0; i < _AlertClickCallback.length; i++)
    {
        if(_AlertClickCallback[i] === onAlert) return;
    }
    _AlertClickCallback.push(onAlert);
}
export function RemoveAlertClickCallback(onAlert: AlertClickCallbackType)
{
    for(let i = _AlertClickCallback.length - 1; i >= 0; i--)
        if(_AlertClickCallback[i] == onAlert) _AlertClickCallback.remove(i);
}

/**
 * 알립니다
 * @param alert
 */
export function fireAlert(alert: AlertData | null)
{
    if (_AlertCallback.length > 0) _AlertCallback.map((callback) => callback(alert));
}
/**
 * 알림 클릭을 처리합니다
 * @param alert
 */
export function fireAlertClick(alert: AlertData | null)
{
    if (alert != null)
    {
        if (_AlertClickCallback.length > 0) _AlertClickCallback.map((callback) => callback(alert));
        else InitAlert = alert;
    }
}

/**
 * 알림을 처리 합니다
 * @param {AlertData} alert 알림 데이터 입니다
 * @returns {boolean} 알림 처리 결과 입니다
 */
export async function processAlertAsync(alert: AlertData)
{
    try
    {
        await AlertData.MarkReadAsync([alert.ID]);
        fireAlert(null);
        return alert.Module != null && alert.Data != null ? sendModuleData(alert.Module, alert.Data) : false;
    }
    catch (e)
    {
        console.error(`Fail to mark as read alert!!: ${JSON.stringify(e)}`);
        return false;
    }
}
