import axios from "axios";
import 'reflect-metadata';
import {URL_API_APPROVAL_DOCU} from "../../Define";
import ApprovalDocumentForm from "../ApprovalDocumentForm";
import DocumentHSAIDA from "./DocumentHSAIDA";
import {ProcessResponseError} from "API";
import {isNullorWhiteSpace} from "utils/HSUtils";
import {Expose, Type} from "class-transformer";

export default class DocumentFormHSAIDA extends ApprovalDocumentForm
{
    @Expose() public CollaboDeptName1?: string | null;
    @Expose() public CollaboDeptName2?: string | null;

    @Type(() => Date)
    public IssueStartDate?: Date | null;
    @Type(() => Date)
    public IssueEndDate?: Date | null;
    @Expose() public JuminNumber?: string | null;
    @Expose() public IssueName?: string | null;
    @Expose() public IssueRelation?: string | null;

    constructor()
    {
        super();
        this.Apply = this.Apply.bind(this);
        this.CheckValid = this.CheckValid.bind(this);
        this.ApplyForm = this.ApplyForm.bind(this);
    }

    public Apply(docData: DocumentHSAIDA)
    {
        super.Apply(docData);
        this.CollaboDeptName1 = docData.CollaboDeptName1;
        this.CollaboDeptName2 = docData.CollaboDeptName2;

        this.IssueStartDate = docData.IssueStartDate;
        this.IssueEndDate = docData.IssueEndDate;
        this.JuminNumber = docData.JuminNumber;
        this.IssueName = docData.IssueName;
        this.IssueRelation = docData.IssueRelation;
    }
    public ApplyForm(docFormData: FormData): any
    {
        const form = super.ApplyForm(docFormData);
        if(form.CollaboDeptName1 !== undefined) this.CollaboDeptName1 = form.CollaboDeptName1;
        if(form.CollaboDeptName2 !== undefined) this.CollaboDeptName2 = form.CollaboDeptName2;

        if(form.IssueStartDate !== undefined) this.IssueStartDate = form.IssueStartDate;
        if(form.IssueEndDate !== undefined) this.IssueEndDate = form.IssueEndDate;
        if(form.JuminNumber !== undefined) this.JuminNumber = form.JuminNumber;
        if(form.IssueName !== undefined) this.IssueName = form.IssueName;
        if(form.IssueRelation !== undefined) this.IssueRelation = form.IssueRelation;
        return form;
    }

    public CheckValid(DocumentStatus: string, ERPText?: string | null): string | null
    {
        const message = super.CheckValid(DocumentStatus, ERPText);
        if(message == null)
        {
            //업무 협조전
            if(this.DocumentClass == "1010")
            {
                if (isNullorWhiteSpace(this.CollaboDeptName1)) return "협조의뢰부서는 비어있을 수 없습니다";
                if (isNullorWhiteSpace(this.CollaboDeptName2)) return  "협력요청부서는 비어있을 수 없습니다";
            }
            //경조비 지급 신청서
            else if (this.DocumentClass == "1050")
            {
                if (this.IssueStartDate == null) return "사유발생 시작일은 비어있을 수 없습니다";
                if (this.IssueEndDate == null) return "사유발생 종료일은 비어있을 수 없습니다";
                if (this.IssueStartDate > this.IssueEndDate) return "사유시작 시작일은 종료일보다 클 수 없습니다";
                if (isNullorWhiteSpace(this.IssueName))  return "신청인 이름은 필수사항 입니다";
                if (isNullorWhiteSpace(this.JuminNumber)) return "신청인 주민등록번호는 필수사항 입니다";
                if (isNullorWhiteSpace(this.IssueRelation)) return "신청인 관계는 필수사항 입니다";
            }
            else return null;
        }
        return message;
    }

    public Submit(password?: string, comment?: string | null, isReport?: boolean): Promise<any | null>
    {
        const send = axios({
            method: "POST",
            url: `${URL_API_APPROVAL_DOCU}?IsReport=${isReport}`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: {...this, Password: password, Comment: comment},
            // 폼에서 [] 없애기
            formSerializer: { indexes: null },
        });

        return new Promise<any>((resolve, reject) =>
        {
            send.catch((error: Error) => reject(ProcessResponseError(error, true, true)));
            send.then((response) => resolve(response.data));
        });
    }

    public static FromForm(data: FormData)
    {
        /*
        const docObj = serializeForm(data);
        const doc = plainToInstance<DocumentFormHSAIDA, any>(DocumentFormHSAIDA, docObj, {
            excludeExtraneousValues: true,
            exposeUnsetFields: false,
        });
        if(docObj.FormAttachFile != undefined) doc.FormAttachFile = docObj.FormAttachFile;
         */

        const doc = new DocumentFormHSAIDA();
        doc.ApplyForm(data);
        return doc;
    }
}