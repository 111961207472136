import {Expose, Type} from "class-transformer";
import axios from "axios";
import {LIST_COUNT, URL_API_APPROVAL_PRINT_LOG} from "../Define";
import {API_RESULT_SUCCESS, API_RESULT_WARNING, APIData, SendValueAsync, SendValueAsyncArrayType} from "API";

export default class ApprovalPrintLogData
{
    /**
     * 해당 항목 작성자
     * @type {UserDataBase}
     */
    @Expose() public LoginID: string;
    /**
     * 인쇄 일시
     * @type {Date}
     */
    @Type(() => Date)
    @Expose() public PrintTime: Date;
    /**
     * 인쇄자
     * @type {string}
     */
    @Expose() public PrintName: string;
    /**
     * 인쇄자 (현지화)
     * @type {string}
     */
    @Expose() public PrintNameKor: string;
    /**
     * 인쇄자 부서명
     * @type {string}
     */
    @Expose() public PrintDeptName: string;
    /**
     * 인쇄자 직급
     * @type {string}
     */
    @Expose() public PrintPositionName1: string;
    /**
     * 인쇄자 직책
     * @type {string}
     */
    @Expose() public PrintPositionName2: string;

    public static async GetCountAsync(DocumentID: string): Promise<number | null>
    {
        return SendValueAsync<number>(axios.get(`${URL_API_APPROVAL_PRINT_LOG}/count/${DocumentID}`), false);
    }

    public static async GetListAsync(DocumentID: string, offset?: number, count?: number): Promise<ApprovalPrintLogData[] | null>
    {
        const param = [];
        param.push(`offset=${offset ?? 0}`);
        param.push(`count=${count ?? LIST_COUNT}`);
        return SendValueAsyncArrayType<ApprovalPrintLogData>(axios.get(`${URL_API_APPROVAL_PRINT_LOG}/list/${DocumentID}?${param.join('&')}`), false, ApprovalPrintLogData);
    }

    public static async AddListAsync(DocumentID: string): Promise<boolean>
    {
        const result = await SendValueAsync<APIData>(axios.put(`${URL_API_APPROVAL_PRINT_LOG}/${DocumentID}`), false);
        if(result == null || result.result == API_RESULT_SUCCESS || result.result == API_RESULT_WARNING) return true;
        else
        {
            alert(result.message);
            return false;
        }
    }
}