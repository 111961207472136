import React, {useEffect, useRef, useState} from "react";
import {IconPasswordUser, IconPasswordMobilePhone, IconUserCog, IconUserEdit} from '@tabler/icons-react';
import {isEmptyString, parseBoolean, randomKey} from "@hskernel/hs-utils";
import UserDataSimple from "data/API/User/UserDataSimple";
import UserPasswordDialog from "../../dialog/UserPasswordDialog";
import UserSettingsDialog from "../../dialog/UserSettingsDialog";
import UserInfoDialog from "../../dialog/UserInfoDialog";
import LoadingCircle from "common/control/LoadingCircle";
import PageControl, {calcCount, calcOffset, getPageFromParam} from "common/control/PageControl";
import UserImageViewer from "common/control/viewer/UserImageViewer";
import {UserSimpleViewDialog} from "common/control/viewer/UserSimpleViewer";
import UserData from "data/API/User/UserData";
import {useNavigate} from "react-router-dom";
import UserCountData from "data/API/User/UserCountData";
import SearchControl, {
    getDefaultSearch,
    getSearchOptionValueFromEvent, SearchControlClass,
    SearchOptionEvent,
    SearchOptionValue
} from "common/control/SearchControl";
import {
    fromSearch,
    fromSearchData,
    UserSearchData,
    UserSearchKinds
} from "data/API/User/UserDataBase";
import {PAGE_MAX} from "Define";

const AdminUserListPage = () =>
{
    const name = undefined;
    const UserSearchKindDefaults: SearchOptionValue[] = [
        UserSearchKinds[0].options[0].Value,
        UserSearchKinds[1].options[1].Value,
    ]

    const COUNT = 5;
    const Navigate = useNavigate();

    const UserPassword = useRef<UserPasswordDialog | null>(null);
    const UserPasswordSimple = useRef<UserPasswordDialog | null>(null);
    const UserSettings = useRef<UserSettingsDialog | null>(null);
    const UserInfo = useRef<UserInfoDialog | null>(null);
    const UserView = useRef<UserSimpleViewDialog | null>(null);
    const Page = useRef<PageControl | null>(null);
    const openDialog = (dialog: React.MutableRefObject<any>, user?: UserDataSimple) => { if(dialog.current != null) dialog.current.openDialog(user); }

    const [search, setSearch] = useState<UserSearchData>(fromSearchData(getDefaultSearch(name, undefined, UserSearchKinds, UserSearchKindDefaults, false)));
    const [users, setUsers] = useState<UserDataSimple[] | null>(null);
    const [total, setTotal] = useState<UserCountData | null>(null);
    const [isBusy, setBusy] = useState(true);
    const getTotal = () => total == null ? 0 : total.Total;
    const getCount = () => total == null ? 0 : total.Count;
    const getLimit = () => total == null ? 0 : total.Limit;

    async function refresh(search: UserSearchData, offset: number, count: number, clear: boolean)
    {
        if(clear) setUsers(null);
        const users = await UserData.GetUsersAsync(search, offset, count, true);
        setUsers(users);
        return true;
    }
    async function onSearchAsync(keyword?: string | null, events?: SearchOptionEvent[])
    {
        setBusy(true);

        const _keyword = keyword ?? search.keyword;
        const _search: UserSearchData = events ? fromSearch(_keyword, events) : {...search, keyword: _keyword};

        try
        {
            setSearch(_search);
            setUsers(null);

            const userCount = await UserData.GetUsersCountAsync(_search) ?? new UserCountData();
            setTotal(userCount);

            if(userCount.Count > 0)
            {
                const page = getPageFromParam();
                const offset = calcOffset(userCount.Count, page, COUNT);
                const count = calcCount(userCount.Count, page, COUNT);
                await refresh(_search, offset, count, false);
            }
            else setUsers([]);
        }
        finally {
            setBusy(false);
        }
    }

    useEffect(() => { if(Page.current != null) Page.current?.setTotalAsync(getCount()).then(); }, [total]);
    useEffect(() => { onSearchAsync().then(); }, []);

    return (
        <>
            <UserSimpleViewDialog detail={true} showButton={true} showXButton={true} header="사원 정보" ref={UserView} />
            <UserInfoDialog ref={UserInfo} onRefresh={() => onSearchAsync().then()}/>
            <UserPasswordDialog ref={UserPassword} isSimplePW={false}/>
            <UserPasswordDialog ref={UserPasswordSimple} isSimplePW={true} />
            <UserSettingsDialog ref={UserSettings}/>
            <div className="card-body">
                <p className="mb-2">사원 수 : {total == null ? <LoadingCircle size="10px"/> : `${getCount()} [전체: ${getTotal()} / ${getLimit()}]`}</p>
                <SearchControlClass defaultOptions={UserSearchKinds} defaultOptionValue={UserSearchKindDefaults} onSearch={onSearchAsync} Navigate={Navigate} />
                <div className="card">
                    <div className="card-header">
                        <div className="col">{/*<input type="checkbox" className="form-check-input"/>*/}</div>
                        <div className="col-auto">
                            <button type="button" className="btn" disabled={isBusy} onClick={() => openDialog(UserInfo)}>사원 추가</button>&nbsp;&nbsp;
                            {/*<button type="button" className="btn" disabled={isBusy}>삭제</button>*/}
                        </div>
                    </div>
                    <div className="list-group list-group-flush">
                        {users == null ?
                            Array(5).fill(
                                    <div className="list-group-item placeholder-glow">
                                        <div className="row align-items-center">
                                            {/*<div className="col-auto"><input type="checkbox" className="form-check-input"/></div>*/}
                                            <div className="col-auto">
                                                <a href="#">
                                                    <span className="avatar placeholder"></span>
                                                </a>
                                            </div>
                                            <div className="col text-truncate">
                                                <div>
                                                    <a href="#" className="text-reset">
                                                        <span className="placeholder">....</span>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <span style={{cursor: "pointer"}} className="placeholder"></span>&nbsp;&nbsp;
                                                <span style={{cursor: "pointer"}} className="placeholder"></span>&nbsp;&nbsp;
                                                <span style={{cursor: "pointer"}} className="placeholder"></span>
                                            </div>
                                        </div>
                                        <hr className="mb-3 mt-3"/>
                                        <div className="row">
                                            <div className="col-auto">
                                                <b>직급:</b> <span className="placeholder">......</span>
                                            </div>
                                            <div className="col-auto">
                                                <b>직책:</b> <span className="placeholder">......</span>
                                            </div>
                                            <div className="col-auto">
                                                <b>부서:</b> <span className="placeholder">......</span>
                                            </div>
                                            <div className="col-auto">
                                                <b>이메일:</b> <span className="placeholder">.........</span>
                                            </div>
                                            <div className="col-auto">
                                                <b>전화:</b> <span className="placeholder">.........</span>
                                            </div>
                                        </div>
                                    </div>
                            )

                            :
                            users.map((user, i) =>
                                <ListItem key={randomKey(i)}
                                          user={user}
                                          onPassword={() => openDialog(UserPassword, user)}
                                          onPasswordSimple={() => openDialog(UserPasswordSimple, user)}
                                          onSetting={() => openDialog(UserSettings, user)}
                                          onView={() => openDialog(UserView, user)}
                                          onInfo={() => openDialog(UserInfo, user)}/>)
                        }
                    </div>
                </div>
                <PageControl ref={Page} navigate={Navigate} count={COUNT} max={PAGE_MAX} total={getCount()} onChange={(page) => refresh(search, calcOffset(getCount(), page, COUNT), calcCount(getCount(), page, COUNT), true)}/>
            </div>
        </>
    )
}

export default AdminUserListPage;

type ListItemProps = {
    user: UserDataSimple,
    onPassword: () => void,
    onPasswordSimple: () => void,
    onSetting: () => void,
    onInfo: () => void,
    onView: () => void,
}

const ListItem = (props: ListItemProps) =>
{
    return (
        <div className="list-group-item">
            <div className="row align-items-center">
                {/*<div className="col-auto"><input type="checkbox" className="form-check-input"/></div>*/}
                <div className="col-auto">
                    <span className="avatar">
                        <UserImageViewer user={props.user}/>
                    </span>
                </div>
                <div className="col text-truncate">
                    <div>
                        <a className="text-reset" style={{cursor: 'pointer'}} onClick={props.onView}>{props.user.toString()}&nbsp;<span className="badge bg-indigo-lt">{props.user.DeptName}</span></a>
                    </div>
                </div>
                <div className="col-auto pc_set_btn">

                    <span style={{cursor: "pointer"}} onClick={props.onPasswordSimple}><IconPasswordMobilePhone stroke={1}/></span>&nbsp;&nbsp;
                    <span style={{cursor: "pointer"}} onClick={props.onPassword}><IconPasswordUser stroke={1}/></span>&nbsp;&nbsp;
                    <span style={{cursor: "pointer"}} onClick={props.onSetting}><IconUserCog stroke={1}/></span>&nbsp;&nbsp;
                    <span style={{cursor: "pointer"}} onClick={props.onInfo}><IconUserEdit stroke={1}/></span>
                </div>
            </div>

            <div className="row mobile_set_btn">
                <hr className="mb-3 mt-3"/>
                <span style={{cursor: "pointer"}} onClick={props.onPasswordSimple}><IconPasswordMobilePhone stroke={1}/></span>&nbsp;&nbsp;
                <span style={{cursor: "pointer"}} onClick={props.onPassword}><IconPasswordUser stroke={1}/></span>&nbsp;&nbsp;
                <span style={{cursor: "pointer"}} onClick={props.onSetting}><IconUserCog stroke={1}/></span>&nbsp;&nbsp;
                <span style={{cursor: "pointer"}} onClick={props.onInfo}><IconUserEdit stroke={1}/></span>
            </div>
            <hr className="mb-3 mt-3"/>
            <div className="row">
                <div className="col-auto">
                    <b>직급:</b> <span className="text-muted">{props.user.PositionName1}</span>
                </div>
                <div className="col-auto">
                    <b>직책:</b> <span className="text-muted">{props.user.PositionName2}</span>
                </div>
                <div className="col-auto">
                    <b>부서:</b> <span className="text-muted">{props.user.DeptName}</span>
                </div>
                <div className="col-auto">
                    <b>이메일:</b> <span className="text-muted">{props.user.EmailCompany}</span>
                </div>
                <div className="col-auto">
                    <b>전화:</b> <span className="text-muted">{props.user.TelCompany}</span>
                </div>
            </div>
        </div>
    )
}
