import React, {useEffect, useRef, useState} from "react";
import TableListControl, {
    CardItemHeader,
    GetItemStyle,
    TableItemHeader,
    TableItemType,
    TableListData
} from "common/control/TableListControl"
import ModuleMenuGroup from "data/API/Module/Menu/ModuleMenuGroup";
import ModuleMenuItemDialog from "../../../dialog/ModuleMenuItemDialog";
import ModuleMenuGroupDialog from "../../../dialog/ModuleMenuGroupDialog";

const ModuleMenuPage = ( ) => {
    const PAGE_COUNT = 10;
    const PAGE_MAX = 5;
    const tableControl = useRef<TableListControl<ModuleMenuGroup>>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [data, setData] = useState<ModuleMenuGroup>();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const header:  TableItemHeader<ModuleMenuGroup>[] = [
        {name: "이름", key: "Name", clickable: true, styleItem: GetItemStyle(20, true)},
        {name: "ID", key: "GroupID", clickable: true, styleItem: GetItemStyle(20, true)},
        {name: "설명", key: "Description", clickable: true, styleItem: GetItemStyle(30, true)},
        {name: "기본값", key: "IsDefault", clickable: true, styleItem: GetItemStyle(15, true), onRender: (value) => value? <span>예</span>:<span>아니오</span>},
        {name: "편집", key: "EditMenu", clickable: true, styleItem: GetItemStyle(15, true), onRender: (value) => <button className="badge bg-primary text-primary-fg ms-auto">메뉴 편집</button>},
    ]

    const headerCard: CardItemHeader<ModuleMenuGroup>[] = [
        {...header[0], styleItem: {}, onRender: (value,header,item) => <>{item.IsDefault?<span className="badge bg-lime-lt">기본</span> : ""} <b>{value}</b></>},
        {...header[1], styleItem: {}, onRender: (value) => <span>ID : {value}</span>},
        {...header[2], styleItem: {}, onRender: (value) => <span>설명 : {value}</span>},
        {...header[4], styleItem: {}, isRight: "title", clickable: true, onRender: (value) => <button className="badge bg-primary text-primary-fg ms-auto">메뉴 편집</button>},
    ];


    async function onRefresh(): Promise<TableListData<ModuleMenuGroup>>
    {
        const list = await ModuleMenuGroup.getListAsync();
        return {data: list ?? []};
    }
    function clickList(header: TableItemHeader<ModuleMenuGroup>,data: ModuleMenuGroup)
    {
        if(header.key == "EditMenu") setIsMenuOpen(true);
        else
        {
            setIsOpen(true);
            setData(data);
        }
    }

    function onClose(refresh: boolean)
    {
        setIsOpen(false);
        if (refresh && tableControl.current != null) tableControl.current.refresh().then();
    }

    return (
        <>
            <ModuleMenuItemDialog isOpen={isMenuOpen} close={() => setIsMenuOpen(false)}/>
            <ModuleMenuGroupDialog isOpen={isOpen} data={data} onClose={onClose}/>
            <div className="card-body">
                <div className="card">
                    <div className="card-header">권한 목록</div>
                    <div className="card-body">
                        <TableListControl<ModuleMenuGroup>
                            ref={tableControl} headerTable={header} headerCard={headerCard} onRefresh={onRefresh}
                            autoRefresh={true} maxPage={PAGE_MAX} count={PAGE_COUNT} onItemClick={(value, header, data) => clickList(header, data)}/>
                    </div>
                    {/*<div className="list-group list-group-flush list-group-hoverable">
                                    {data.map((data, i) =>
                                        <div className="list-group-item" key={randomKey(i)}>
                                            <div className="text-truncate">
                                                <a href="#"
                                                   className="text-reset d-block text-truncate"><b>{data.Name}</b> {data.IsDefault ?
                                                    <span className="badge bg-lime-lt">기본</span> : ""}</a>
                                                <div className="text-secondary text-truncate mt-n1">ID
                                                    : {data.GroupID}</div>
                                                <div className="text-secondary text-truncate mt-n1">내용
                                                    : {data.Description}</div>
                                            </div>
                                        </div>
                                    )
                                    }
                                </div>*/}
                </div>
            </div>
        </>
    )
}
export default ModuleMenuPage;
