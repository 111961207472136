import React, {Component, useState} from "react";
import Modal from 'react-modal';
import DeviceData from "data/API/Device/DeviceData";
import {IconX} from "@tabler/icons-react";
import {dateFormat} from "@hskernel/hs-utils";

const modelStyle = {
    content: {
        maxWidth: "500px",
        height:"450px",
        margin: "100px auto",
        background: "transparent",
        border:"none"
    },
    overlay: {
        background: "rgba(0,0,0,0.5)",
        zIndex: "9"
    }
}

type DeviceViewDialogProps = {
    device?: DeviceData,
    /**
     *
     * @param {DeviceData} device
     * @returns {Promise<boolean>} 창 닫기 여부 입니다
     */
    onDelete: (device: DeviceData) => Promise<boolean>
}

type DeviceViewDialogState = {
    isOpen: boolean,
    device: DeviceData
}


const TimeTo = (date?: Date) => date == null ? null : dateFormat(date, "yyyy-mm-dd TT hh:MM:ss");
export default class DeviceViewDialog extends Component<DeviceViewDialogProps, DeviceViewDialogState>
{
    constructor(props: DeviceViewDialogProps) {
        super(props);
        this.state = {
            isOpen: false,
            device: props.device ?? new DeviceData()
        }

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    public openDialog(device: DeviceData){ this.setState({...this.state, device: device, isOpen: true}); }
    private closeDialog(){ this.setState({...this.state, isOpen: false}); }

    render()
    {
        return (
            <Modal ariaHideApp={false} isOpen={this.state.isOpen} style={modelStyle}>
                 <DeviceViewDialogView device={this.state.device} onClose={this.closeDialog} onDelete={() => this.props.onDelete(this.state.device)}/>
            </Modal>
        )
    }
}

type DeviceViewDialogViewProps = {
    device?: DeviceData | null,
    onClose: () => void,
    onDelete: () => Promise<boolean>,
}

const DeviceViewDialogView = ({device, onClose, ...props}: DeviceViewDialogViewProps) =>
{
    const filter = (text?: string | null) => text == null ? '-' : text;

    const [busy, setBusy] = useState(false);

    function onDelete()
    {
        if(confirm('정말 해당 기기를 삭제 하시겠습니까?\n해당 기기에서 로그아웃 됩니다'))
        {
            setBusy(true);
            props.onDelete()
                .then(close => { if(close) onClose(); })
                .finally(() => setBusy(false));
        }
    }

    return (
        <div className="card">
            <h3 className="card-header">
                <div className="col">기기 정보 {device?.Current ? '(현재 기기)' : ''}</div>
                <div className="col-auto" onClick={onClose}>
                    <button className="btn btn-ghost-secondary"><IconX/></button>
                </div>
            </h3>
            <div className="card-body">
                <h3 className="card-title" style={{fontWeight: 'bold'}}>기본 정보</h3>
                <div className="row g-2">
                    <div className="col-md">
                        <div className="form-label">이름</div>
                        <b>{filter(device?.Name)}</b>
                    </div>
                    <div className="col-md">
                        <div className="form-label">종류</div>
                        <b>{filter(DeviceData.PlatformString(device?.Platform))}</b>
                    </div>
                </div>
                <br/>
                <h3 className="card-title mt-3" style={{fontWeight: 'bold'}}>타임스탬프</h3>
                <div className="row g-2">
                    <div className="col-md">
                        <div className="form-label">접속 시간</div>
                        <b>{filter(TimeTo(device?.AccessTime))}</b>
                    </div>
                    <div className="col-md">
                        <div className="form-label">등록 시간</div>
                        <b>{filter(TimeTo(device?.RegisterTime))}</b>
                    </div>
                </div>
                <h3 className="card-title mt-3" style={{fontWeight: 'bold'}}>세부 정보</h3>
                <div className="row g-2">
                    <div className="col-md">
                        <div className="form-label">모델</div>
                        <b>{filter(device?.Model)}</b>
                    </div>
                    <div className="col-md">
                        <div className="form-label">브랜드</div>
                        <b>{filter(DeviceData.GetBrandName(device?.Brand))}</b>
                    </div>
                </div>
                <div className="row g-2 mt-1">
                    <div className="col-md">
                        <div className="form-label">운영체제</div>
                        <b>{filter(DeviceData.GetOS(device?.OS))}</b>
                    </div>
                    <div className="col-md">
                        <div className="form-label">운영체제 (베이스)</div>
                        <b>{filter(DeviceData.GetOS(device?.BaseOS))}</b>
                    </div>
                </div>
                <div className="row g-2 mt-2">
                    <div className="col-md">
                        <div className="form-label">시스템</div>
                        <b>{filter(device?.System)}</b>
                    </div>
                    <div className="col-md">
                        <div className="form-label">시스템 버전</div>
                        <b>{filter(device?.SystemVersion)}</b>
                    </div>
                </div>
                <div className="row g-2 mt-2">
                    <div className="col-md">
                        <div className="form-label">사용자 에이전트</div>
                        <b>{filter(device?.UserAgent)}</b>
                    </div>
                    <div className="col-md"/>
                </div>
                <br/>
            </div>
            <div className="card-footer bg-transparent mt-auto">
                <div className="row">
                    <div className="col">
                        <button type="button" className="btn btn-danger" disabled={busy || device == null} onClick={onDelete}>삭제 </button>
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-primary" disabled={busy} onClick={onClose}>확인</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
