//import {Link} from 'react-router-dom';
import React from "react";
import ModuleInfo from "modules/ModuleInfo";
import {PathMakerMulti} from "utils/HSUtils";
import {URL_FRONT_MODULE} from "Define";
import { GetIcon } from "../SideMenu";
import styles from "./MainModuleButton.module.css"

type MainModuleButtonType = {
    module: ModuleInfo,
    admin: boolean,
    isModule: boolean,
    darkMode: boolean,
}

/**
 * 메인화면에 모듈 바로가기 버튼 UI
 * @param module
 * @param admin
 * @param isModule
 * @constructor
 */
function MainModuleButton({module, admin, isModule, darkMode}: MainModuleButtonType)
{

    let modulePath = module.defaultPath == null ? null : module.defaultPath(admin);
    modulePath = modulePath ?? module.id;
    return (
        <div className={`col-sm-6 col-lg-2 ${styles.box_width}`}>
            <a href={ isModule ? PathMakerMulti(URL_FRONT_MODULE, modulePath) : modulePath} className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-auto icon-margin">
                             <span className="bg-primary text-white avatar">
                                   {GetIcon(module, darkMode, false)}
                             </span>
                        </div>
                        <div className="col">
                            <div className="subheader">{module.name}</div>
                                <h3 style={{marginBottom: '0'}}>{module.title}</h3>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
}

export default MainModuleButton;