import {Expose, Type} from "class-transformer";
import {SendValueAsyncArrayType} from "API";
import axios, {isAxiosError} from "axios";
import {printError, URL_API_MODULE_MENU_GROUP} from "Define";
import {isEmptyString} from "@hskernel/hs-utils";

export interface ModuleMenuGroupForm
{
    GroupID: string;
    Name: string;
    Description?: string;
    IsDefault: boolean;
}

export default class ModuleMenuGroup implements ModuleMenuGroupForm
{
    @Expose() GroupID: string;
    @Expose() Name: string;
    @Expose() Description: string;
    @Expose() IsDefault: boolean;
    @Expose() @Type(() => Date)
    public CreateTime: Date;

    public static async getListAsync()
    {
        return await SendValueAsyncArrayType<ModuleMenuGroup>(axios.get(URL_API_MODULE_MENU_GROUP), true, ModuleMenuGroup);
    }

    public static async saveAsync(data: ModuleMenuGroupForm)
    {
        const emptyID = isEmptyString(data.GroupID);
        try
        {
            await axios({
                method: emptyID ? 'PUT' : 'POST',
                url: URL_API_MODULE_MENU_GROUP + (emptyID ? '' : `/${data.GroupID}`),
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: data,
            });

            return true;
        }
        catch (e) { printError(e); return false; }
    }
}
