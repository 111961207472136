import ErrorPageBase, {ErrorPageParam} from "./Base";

export default class ErrorPage404 extends ErrorPageBase
{
    constructor(props?: ErrorPageParam)
    {
        super({
            errorCode: '404',
            title: props?.title ?? '페이지를 찾을 수 없습니다',
            message: props?.message,
            hideBack: props?.hideBack,
            hideHome: props?.hideHome,
        });
    }
}