import React, { useState} from "react";
import Modal from "react-modal";

type UserAccessDialogProps = {
    isOpen : boolean,
    close: () => void
}


const ModuleMenuItemDialog = ({isOpen,close}:UserAccessDialogProps) => {

    const modalStyle = {
        content: {
            width: "300px",
            height:"fit-content",
            margin: "60px auto",
            background: "var(--tblr-bg-surface)",
            inset:0,
            padding:0
        },
        overlay: {
            background: "rgba(0,0,0,0.5)",
            zIndex: "9",
        }
    }

    return(
        <Modal ariaHideApp={false} isOpen={isOpen} style={modalStyle}>
                <div className="card">
                    <div className="card-header">
                        편집
                    </div>
                    <div className="card-body">
                    </div>
                    <div className="card-footer">
                        <button type="submit" className="btn ms-auto" onClick={close}>취소</button>&nbsp;&nbsp;
                        <button type="submit" className="btn btn-primary ms-auto">저장</button>
                    </div>
                </div>

            </Modal>
    )
}
export default ModuleMenuItemDialog;
