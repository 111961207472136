import 'reflect-metadata';
import {Expose, Type} from "class-transformer";
import ApprovalDocument from "../ApprovalDocument";
import {isWindowOpen, URL_API_APPROVAL_DOCU} from "../../Define";
import {SendValueAsyncType} from "API";
import axios from "axios";
import {PathMaker} from "utils/HSUtils";
import {NavigateFunction} from "react-router";

export default class DocumentHSAIDA extends ApprovalDocument
{
    @Expose() public CollaboDeptName1?: string;
    @Expose() public CollaboDeptName2?: string;

    @Type(() => Date)
    public IssueStartDate?: Date;
    @Type(() => Date)
    public IssueEndDate?: Date;
    @Expose() public JuminNumber?: string;
    @Expose() public IssueName?: string;
    @Expose() public IssueRelation?: string;

    public static async GetDocument(DocumentID: string, process404: boolean, RefDocumentID?: string | null, navigate?: NavigateFunction): Promise<DocumentHSAIDA | null>
    {
        let url = PathMaker(URL_API_APPROVAL_DOCU, DocumentID);
        if (RefDocumentID != null) url += `?RefDoc=${RefDocumentID}`;
        return await SendValueAsyncType<DocumentHSAIDA>(axios.get(url), process404, DocumentHSAIDA, (code, data) =>
        {
            if (process404)
            {
                if (code == 404) return {navigate: navigate, error: {message: '해당 문서를 찾을 수 없습니다', hideHome: isWindowOpen()}};
                if (code == 403) return {navigate: navigate, error: {message: '해당 문서를 열람할 권한이 없습니다', hideHome: isWindowOpen()}};
            }
            return {navigate: navigate}
        });
    }
}