import AttachData from "data/API/IO/AttachData";
import {Expose} from "class-transformer";
import {isEmptyString} from "@hskernel/hs-utils";
import {URL_API_APPROVAL_DOCU_FILE} from "../Define";

export default class ApprovalDocumentAttachData extends AttachData
{
    @Expose() public DocumentID?: string;

    public getURL = () => isEmptyString(this.CDNURL) ? this.getURLWithParams(`${URL_API_APPROVAL_DOCU_FILE}/${this.Key}`): this.CDNURL;
}
