import React, {useEffect, useRef, useState} from "react";
import DepartmentPickModal from "common/control/DepartmentPickControl/dialog";
import SimpleTextInput from "../../common/component/SimpleTextInput";

type DeptSelectInputProps = {
    name?: string,
    deptCode?: string,
    deptName?: string,
}
type DeptSelectInputState = {
    deptCode: string,
    deptName: string,
}
const DeptSelectInput = (props: DeptSelectInputProps) =>
{
    const [dept, setDept] = useState<DeptSelectInputState | null>(props.deptCode == null ? null : {deptCode: props.deptCode, deptName: props.deptName ?? ''});
    const deptDialog = useRef<DepartmentPickModal | null>(null);

    return (
        <>
            <DepartmentPickModal ref={deptDialog} immediately={true} deptCode={dept?.deptCode} onSelect={(e) => {
                setDept({deptCode: e.DepartmentCode, deptName: e.Name});
                if(deptDialog.current != null) deptDialog.current.closeDialog();
            }}/>
            <div className="input-group mb-2">
                <input type="hidden" name={props.name} value={dept?.deptCode}/>
                <SimpleTextInput className="form-control" readOnly={true} placeholder="부서이름" value={dept?.deptName}/>
                <button type="button" className="btn" onClick={() => {if(deptDialog.current != null) deptDialog.current.openDialog()}}>변경</button>
            </div>
        </>
    )
}

export default DeptSelectInput;
