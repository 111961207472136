import React, {Component} from "react";
import Modal from "react-modal";
import UserDataBase from "data/API/User/UserDataBase";
import UserDataSimple from "data/API/User/UserDataSimple";
import { IconMail, IconPhone } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import UserImageViewer from "./UserImageViewer";
import LoadingCircle from "../LoadingCircle";

type UserSimpleViewerDialogProps = {
    detail?: boolean,
    /**
     * 헤더에 표시할 문구
     */
    header?: string,
    showButton?: boolean,
    showXButton?: boolean,
    onClick?: (user: UserDataSimple) => void,
}

type UserSimpleViewDialogState = {
    isOpen: boolean,
    user: UserDataSimple,
}
export class UserSimpleViewDialog extends Component<UserSimpleViewerDialogProps, UserSimpleViewDialogState>
{
    constructor(props: UserSimpleViewerDialogProps) {
        super(props);

        this.state = {
            isOpen: false,
            user: new UserDataSimple()
        }
    }

    public openDialog(user: UserDataSimple){ this.setState({...this.state, isOpen: true, user: user}); }
    public closeDialog() { this.setState({...this.state, isOpen: false}); }


    render() {
        const modalStyle = {
            content: {
                maxWidth: "320px",
                margin: "0 auto",
                height:"fit-content",
                background: "var(--tblr-bg-surface)"
            },
            overlay: {
                background: "rgba(0,0,0,0.5)",
                zIndex: "9"
            }
        }
        return (
            <Modal isOpen={this.state.isOpen} style={modalStyle}>
                <UserSimpleViewControl {...this.props} user={this.state.user} onClose={() => this.closeDialog()}/>
            </Modal>
        );
    }
}

type UserSimpleViewerProps = UserSimpleViewerDialogProps & {
    user: UserDataSimple | null,
    onClose?: () => void,
}
const UserSimpleViewControl = ({user, detail, header, showButton, showXButton, ...props }: UserSimpleViewerProps) =>
{
    const onClickEnable = () => props.onClick != null && user != null;
    return (
        <>
            <div className="card mb-2">
                {header == null ? "" :
                    <div className="card-header">
                        <div className="col">{header}</div>
                        {showXButton ? <div className="col-auto"><button className="btn" onClick={props.onClose}>닫기</button></div> : ""}
                    </div>
                }
                <div className="card-body p-4 text-center">
                    <span className="avatar avatar-xl mb-3 rounded">
                        {user == null ? <LoadingCircle size="24px" border={3}/> : <UserImageViewer user={user}/>}
                    </span>
                    <h3 className="m-0 mb-1">
                        {user ?
                            <a style={onClickEnable() ? {cursor: "pointer"} : undefined} onClick={() => { if(onClickEnable()) props.onClick!(user) }}>
                                {user.NameKorean}{user.NameKorean != user.Name ? ` (${user.Name})` : ""}
                            </a> :
                            <div className="placeholder col-3" />
                        }
                    </h3>
                    <div className="text-secondary">{user ? UserDataBase.getPosition(user) : <div className="placeholder col-3" />}</div>
                    <div className="mt-3">
                        <span className="badge bg-green-lt">{user ? user.DeptName : <div className="placeholder col-3" />}</span>
                    </div>
                </div>
                {showButton ?
                    <div className="d-flex">
                        {user == null ?
                            <>
                                <div className="card-btn">
                                    <div className="placeholder col-3" />
                                </div>
                                <div className="card-btn">
                                    <div className="placeholder col-3" />
                                </div>
                            </>:
                            <>
                                <Link className="card-btn" to={`mailto:${user.EmailCompany}`}>
                                    <IconMail className="icon icon-tabler"/>메일
                                </Link>
                                <Link className="card-btn" to={`tel:${user.TelCompany}`}>
                                    <IconPhone className="icon icon-tabler"/>전화
                                </Link>
                            </>
                        }
                    </div>
                    :""
                }
            </div>
            {detail ?
                <div className="card mb-2">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-4">이메일</div>
                                    <div className="col-8">{user ? user.EmailCompany : <div className="placeholder col-3" />}</div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-4">전화</div>
                                    <div className="col-8">{user ? user.TelCompany : <div className="placeholder col-3" />}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-4">퇴사</div>
                                    <div className="col-8">{user ? (user.IsEmployment ? "아니오" : "예") : <div className="placeholder col-3" />}</div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-4">관리자</div>
                                    <div className="col-8">{user ? (user.IsAdmin ? "예" : "아니오") : <div className="placeholder col-3" />}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :""
            }
        </>
    )
}

export default UserSimpleViewControl;