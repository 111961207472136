import styled from "styled-components";

export const ListContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  background: #f8f7f3;
  padding: 10px;
`;

export const EntryContainer = styled.div`
  width: 100%;
  padding-left: 20px;
`;

export const Root = styled.div`
  font-weight: 700;
`;
