import React, {useCallback, useEffect, useState} from "react";

/**
 * 메뉴 UI 데이터
 */
export type MenuUIData = {
    hideAll?: boolean,
    hideSide?: boolean,
    hideTop?: boolean,
    hideShortcut?: boolean,
    hideLocate?: boolean
}

/**
 * 메뉴 UI 컨텍스트 유형 지정
 */
export type MenuUIContextType = {
    menuUI: MenuUIData,
    setMenuUI: (menuUI: MenuUIData) => void;
}

/**
 * 메뉴 UI 기본값 설정
 */
/* eslint @typescript-eslint/no-empty-function: "off" */
const MenuUIContextDefault: MenuUIContextType =
{
    menuUI: {hideAll: false, hideSide: false, hideTop: false, hideLocate: false},
    setMenuUI: (_: MenuUIData) => { /* EMPTY */ }
}
/**
 * 메뉴 UI 컨텍스트 생성
 */
export const MenuUIContext = React.createContext<MenuUIContextType>(MenuUIContextDefault);


/**
 * 훅 스타일로 사용하기위해
 */
export const useMenuUI = () => React.useContext(MenuUIContext);
export const MenuUIProvider = ({children}: any) =>
{
    const [_menuUI, setMenuUI] = useState<MenuUIData>(MenuUIContextDefault.menuUI);

    function _setMenuUI(menuUI: MenuUIData)
    {
        setMenuUI({
            hideAll: menuUI.hideAll ?? false,
            hideTop: menuUI.hideTop ?? false,
            hideSide: menuUI.hideSide ?? false,
            hideShortcut: menuUI.hideShortcut ?? false,
            hideLocate: menuUI.hideLocate ?? false
        })
    }

    //기본값 적용
    const contextValue: MenuUIContextType = {
        menuUI: _menuUI,
        //setMenuUI: _setMenuUI,
        setMenuUI: useCallback((menuUI: MenuUIData) => _setMenuUI(menuUI), []),
    };

    return (
        <MenuUIContext.Provider value={contextValue}>
            {children}
        </MenuUIContext.Provider>
    );
}