import { IconCalendar } from "@tabler/icons-react";
import ModuleInfo, {AdminMenuKind} from "modules/ModuleInfo";
import React from "react";

import {ID, ID_GROUP, ID_PRIVATE, ID_PUBLIC} from "./Define";

const Info: ModuleInfo =
    {
        id: ID,
        name: "Calendar",
        title: "일정",
        //icon: require("./resource/icon.png"),
        onRenderIcon: (darkMode, isSmall) => <IconCalendar/>,
        admin: AdminMenuKind.UserOnly,
        category: [
            {
                id: "manage",
                title: "일정관리",
                path: "",
                open: true,
                menu: [
                    {
                        id: "all",
                        title: "전체일정 보기",
                        path: "",
                        popup: false,
                    },
                    {
                        id: "private",
                        title: "내 일정 보기",
                        path: ID_PRIVATE,
                        popup: false,
                    },
                    {
                        id: "group",
                        title: "부서 일정 보기",
                        path: ID_GROUP,
                        popup: false,
                    },
                    {
                        id: "public",
                        title: "회사 일정 보기",
                        path: ID_PUBLIC,
                        popup: false,
                    }
                ]
            }
        ]
    }

export default Info;