import React, {useEffect, useState} from "react";
import BoardDataSimple from "../data/BoardDataSimple";
import {Link, useNavigate} from "react-router-dom";
import {BoardKindtoName, GetBoardURL, URL_BOARD_LIST} from "../Define";
import {PathMaker, randomKey} from "@hskernel/hs-utils";
import {IconClipboardList, IconPlus} from "@tabler/icons-react";
import {isMockupMode} from "Define";

type BoardListWidgetProps = {
    boardKind: string,
    count?: number
}

//TODO: placeholder 적용하기
const BoardListWidget = ({boardKind, count}: BoardListWidgetProps) =>
{
    const now = new Date();
    count = count ?? 3;
    const Navigate = useNavigate();

    function isToday(date: Date){ return now.getFullYear() == date.getFullYear() && now.getMonth() == date.getMonth() && now.getDay() == date.getDay(); }

    const [list, setList] = useState<BoardDataSimple[] | null>(null);
    useEffect(() =>
    {
        //const search = getDefaultSearch(undefined, undefined, undefined, undefined, false);
        BoardDataSimple.GetListAsync(boardKind, undefined, 0, count)
            .then(list => setList(list ?? []))
            .catch((e: any) => setList([]))
    }, [])


    return (
        <div className="card">
            <div className="card-header">
                <IconClipboardList className="icon icon-tabler"/>
                &nbsp;
                <h2 className="card-title col">{BoardKindtoName(boardKind)}</h2>
                <a className="col-auto text-muted" onClick={() => Navigate(PathMaker(URL_BOARD_LIST, boardKind))} style={{cursor: 'pointer'}}>
                    <IconPlus className="icon icon-tabler" stroke="currentColor"/>
                </a>
            </div>
            <div className="list-group list-group-flush list-group-hoverable" style={{height: '15rem'}}>
                {list == null ?
                    <>
                    <div className="list-group-item placeholder-glow">
                        <div className="row align-items-center">
                            <div className="col text-truncate">
                                <div className="d-block text-truncate mt-n1 placeholder">.....
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item placeholder-glow">
                        <div className="row align-items-center">
                            <div className="col text-truncate">
                                <div className="d-block text-truncate mt-n1 placeholder">.....
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list-group-item placeholder-glow">
                        <div className="row align-items-center">
                            <div className="col text-truncate">
                                <div className="d-block text-truncate mt-n1 placeholder">.....
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                    :
                    list.map((data, i) =>
                        <div className="list-group-item" key={randomKey(i)}>
                            <div className="row align-items-center">
                                <div className="col text-truncate">
                                    <div className="d-block text-truncate mt-n1">
                                        {isToday(data.WriteDate) ? <span className="badge bg-orange" style={{marginRight:"5px",color:"white"}}>N</span> : "" }
                                        <Link to={GetBoardURL(boardKind, data.ID, false)}>{isMockupMode() ? `테스트 글${Math.round(Math.random() * 100)}` : data.Title}</Link>
                                    </div>
                                    <div className="row text-muted">
                                        <div className="col">{data.WriteDate.format("yyyy년 mm월 dd일")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default BoardListWidget;
