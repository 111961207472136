import React, {useEffect, useState,} from 'react';
import {Link, useLocation} from 'react-router-dom';
import MainModuleButton from 'component/main/MainModuleButton';
//import dummy from 'data/Menu.json';
import {randomKey} from "utils/HSUtils";
import 'css/style.css';
import {Info as ModuleInfo} from "Module";
import TopHeader from "component/TopHeader";
import {useAuth} from "../context/AuthContext";
import {checkModuleKindMatch, checkModuleMenu} from "../modules/ModuleInfo";
import BoardListWidget from "../modules/board/widget/BoardListWidget";
import DocumentListWidget from "../modules/approval/widget/DocumentListWidget";
import { IconCalendarDue } from '@tabler/icons-react';
import MailPlugListWidget from "../modules/mailplug/widget/MailPlugListWidget";
import SideMenuMobile from "../component/SideMenuMobile";
import CalendarWidget from "../modules/calendar/widget/CalendarWidget";
import {backPreventURL} from "../Define";
import {useTheme} from "../context/ThemeContext";

function Main()
{
    const auth = useAuth();
    const {theme} = useTheme();
    const CalendarClick = (date, sender) =>
    {
        console.log(date);
    }

    const [menuIsClick, setMenuIsClick] = useState(false);

    useEffect(() =>
    {
        if(window.Notification != null) window.Notification.requestPermission().then();
    }, []);

    return auth.auth == null ? <></> : (
        <div>
            <TopHeader backPreventURL={backPreventURL} onIsClick={setMenuIsClick} isClick={menuIsClick}/>
            <SideMenuMobile isClick={menuIsClick} location={location} onIsClick={setMenuIsClick}/>
            <div className="page-wrapper main_margin_top">
                <div className="page-header d-print-none">
                    <div className="container-xl">
                        <div className="row g-2 align-items-center">
                            <div className="col">
                                {/* Page pre-title */}
                                <div className="page-pretitle">
                                    {auth.admin ? "관리자 모드" : "메인" }
                                </div>
                                <h2 className="page-title">
                                    {auth.admin ? "관리자 메뉴" : "메인 메뉴"}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-deck row-cards">
                        {ModuleInfo.map((module) =>
                            checkModuleMenu(auth.menuProperty, module.id, "", false, module.admin, auth.admin) ?
                                <MainModuleButton key={randomKey()} module={module} darkMode={theme.darkMode} admin={auth.admin} isModule={true}/> : "")}
                        {/*getModuleAdmin(auth.admin, module.admin) ? <MainModuleButton key={randomKey()} module={SettingInfo} admin={auth.admin} isModule={false}/> : ""*/}
                    </div>
                </div>
               {/* <br/>
                <div className="container-xl">
                    <div className="card">
                        <div className="card-header">
                            <IconBell size={24} stroke={1}/>
                            &nbsp;
                            <h2 className="card-title col">새 알림</h2>
                            <div className="col-auto">
                                <a className="btn btn-primary">전체확인</a>
                            </div>
                        </div>

                        <div className="list-group list-group-flush list-group-hoverable overflow-auto"
                             style={{maxHeight: '17rem'}}>
                            {dummy.alarms.map((alarm) => {
                                return (
                                    <NewAlarm key={randomKey()} category={alarm.category} name={alarm.name} contents={alarm.contents} isModal={false}/>
                                );
                            })}
                        </div>
                    </div>
                </div>*/}
                <br/>

                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12">
                            <DocumentListWidget/>
                            <br/>
                            <MailPlugListWidget isUse={auth.auth.setting.EmailUse}/>
                            <br/>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <IconCalendarDue stroke={1}/>
                                    &nbsp;
                                    <h3 className="card-title col">일정 달력</h3>
                                </div>
                                <div className="card-body" style={{height: '441px'}}>
                                    <CalendarWidget share={'priv'}/>
                                    {/*<hs-calendar id="calendar24" showLunar="true" showNohands="true" showMemorialDay="true" onmonthchanged="setNoHand(date)"></hs-calendar>
                                    <Calendar onClick={CalendarClick}/>*/}
                                </div>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>

                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <BoardListWidget boardKind="qa_system" count={3}/>
                            <br/>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <BoardListWidget boardKind="notice" count={3}/>
                            <br/>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <BoardListWidget boardKind="event_family" count={3}/>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
