import { createContext } from "react";

export type RadioContextProps = {
    onChange?: (value: string) => void,
    disabled?: boolean,
    value?: string,
    name?: string,
}

const RadioContext = createContext<RadioContextProps>({
    disabled: false,
    value: undefined,
});
export default RadioContext;