import 'reflect-metadata';
import ApprovalDocumentTypeData from "./ApprovalDocumentTypeData";
import ApprovalDocumentBase from "./ApprovalDocumentBase";
import ApprovalStatus from "./ApprovalStatus";
import {Expose, Type} from "class-transformer";
import { IsArray } from "class-validator";
import ApprovalInfo from "./ApprovalInfo";
import ApprovalDocumentListData from "./ApprovalDocumentListData";
import ApprovalDocumentBaseSimple from "./ApprovalDocumentBaseSimple";
import ApprovalDocumentAttachData from "./ApprovalDocumentAttachData";
import {PathMaker} from "@hskernel/hs-utils";
import {isWindowOpen, URL_API_APPROVAL_DOCU} from "../Define";
import {SendValueAsyncType} from "../../../API";
import axios from "axios";
import {NavigateFunction} from "react-router";

export default class ApprovalDocument extends ApprovalDocumentBase
{
    @Expose() public Text: string;
    @Expose() public ERPText: string;

    @Expose() @IsArray() @Type(() => ApprovalStatus)
    public Approval: ApprovalStatus[] = [];
    @Expose() @IsArray() @Type(() => ApprovalStatus)
    public ExecUser: ApprovalStatus[] = [];
    @Expose() @IsArray() @Type(() => ApprovalStatus)
    public RefUser: ApprovalStatus[] = [];
    @Expose() @IsArray() @Type(() => ApprovalDocumentListData)
    public DocumentRef: ApprovalDocumentListData[] = [];
    @Expose() @IsArray() @Type(() => ApprovalDocumentBaseSimple)
    public RefDocument: ApprovalDocumentBaseSimple[] = [];
    @Expose() @IsArray() @Type(() => ApprovalDocumentAttachData)
    public Attach: ApprovalDocumentAttachData[] = [];
    @Expose()  @Type(() => ApprovalInfo)
    public ApprovalInfo: ApprovalInfo;

    public static async GetDocument(DocumentID: string, process404: boolean, RefDocumentID?: string | null, navigate?: NavigateFunction): Promise<ApprovalDocument | null>
    {
        let url = PathMaker(URL_API_APPROVAL_DOCU, DocumentID);
        if (RefDocumentID != null) url += `?RefDoc=${RefDocumentID}`;
        return await SendValueAsyncType<ApprovalDocument>(axios.get(url), process404, ApprovalDocument, (code, data) =>
        {
            if (process404)
            {
                if (code == 404) return {navigate: navigate, error: {message: '해당 문서를 찾을 수 없습니다', hideHome: isWindowOpen()}};
                if (code == 403) return {navigate: navigate, error: {message: '해당 문서를 열람할 권한이 없습니다', hideHome: isWindowOpen()}};
            }
            return {navigate: navigate}
        });
    }

    static GetTypeList():Promise<ApprovalDocumentTypeData[] | null> { return ApprovalDocumentTypeData.GetDocumentTypeList(); }
}