import {Expose} from "class-transformer";
import {IsArray} from "class-validator";
import axios from "axios";
import {URL_API_APPROVAL_LINE_DOCU} from "../Define";
import {ApprovalLineMultiData} from "../component/selector/ApprovalLineMultiSelect";

export default class ApprovalLineFormBase
{
    /**
     * [폼] 결재라인 지정 목록 (사용자 ID:결재라인 종류:전결여부)
     * @type {string[]}
     */
    @Expose() @IsArray()
    public FormApproval: string[];
    /**
     * [폼] 시행자 목록 (사용자 ID)
     * @type {string[]}
     */
    @Expose() @IsArray()
    public FormExecUser: string[];
    /**
     * [폼] 수신및참조 목록 (사용자 ID)
     * @type {string[]}
     */
    @Expose() @IsArray()
    public FormRefUser: string[];

    public SaveAsync(docuClass: string)
    {
        return axios({
            method: "POST",
            url: `${URL_API_APPROVAL_LINE_DOCU}/${docuClass}`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: this,
            // 폼에서 [] 없애기
            formSerializer: { indexes: null },
        });
    }

    public static FromMultiData(data: ApprovalLineMultiData)
    {
        const form = new ApprovalLineFormBase();
        form.FormApproval = data.Approver.map(data => `${data.user.LoginID}:${data.kind}:${data.extra.final}`);
        form.FormRefUser = data.UserRef.map(data => data.user.LoginID);
        form.FormExecUser = data.UserExec.map(data => data.user.LoginID);
        return form;
    }
}