import React, {useEffect, useRef, useState,} from "react";
import Modal from 'react-modal';
import {useAuth} from "context/AuthContext";
import UserDataBase from "data/API/User/UserDataBase";
import {printError} from "Define";
import SearchControl, {SearchOptionProps} from "common/control/SearchControl";
import TableListControl, {GetItemStyle, TableItemHeader, TableListData} from "common/control/TableListControl";
import ApprovalLineSetModal from "../../component/ApprovalLineSetModal";
import ApprovalLineData from "../../data/ApprovalLineData";
import {GetApprovalLineShareText} from "../../data/ApprovalLineBase";
import UserDataLineBase from "../../data/UserDataLineBase";
import {ApproverDivision} from "../../Define";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import {SelectOptionData} from "../../../../common/component/SelectOption";

const LineSettingPage = () => {
    const [openModal, setOpenModal] = useState(false);
    const {auth} = useAuth();

    const Table = useRef<TableListControl<ApprovalLineData> | null>(null);

    const [busy, setBusy] = useState(false);
    const [item, setItem] = useState<ApprovalLineData | undefined>(undefined);
    const [modaltem, setModalItem] = useState<ApprovalLineData | undefined>(undefined);
    const getIsMobile = () => window.innerWidth <= 577;
    const [isMobile, setIsMobile] = useState(getIsMobile());

    const onWindowResize = useWindowResize();
    onWindowResize(() =>
    {
        setIsMobile(getIsMobile());
    });


    const searchKind: SearchOptionProps[] = [
        {
            autoSearch: false,
            options: [
                new SelectOptionData("제목", "title")
            ]
        }
    ]

    const TableHeaderList: TableItemHeader<ApprovalLineData>[] = [
        {name: "범위", key: "ShareType", clickable: false, styleItem: GetItemStyle(20, false), onRender: (value => GetApprovalLineShareText(value))},
        {name: "결재라인명", key: "LineName", clickable: true, styleItem: GetItemStyle(80, true)},
    ]
    const headerCard = [
        {...TableHeaderList[0], styleItem: {}, classNameItem: undefined},
        {...TableHeaderList[1], styleItem: {}, classNameItem: undefined},
    ]

    function open(item?: ApprovalLineData)
    {
        setModalItem(item);
        setOpenModal(true);
    }
    function remove(item?: ApprovalLineData)
    {
        if(item != null && confirm(`정말 결재라인 ${item.LineName} 을(를) 삭제하시겠습니까?`))
        {
            setBusy(true);
            ApprovalLineData.GetApprovalLineDeleteAsync(item.Writer.LoginID, item.LineName)
                .then(() =>
                {
                    setItem(undefined);
                    if(Table.current != null) Table.current.refresh();
                })
                .catch((e) => printError(e))
                .finally(() => setBusy(false));
        }
    }

    async function onRefresh(offset: number, count: number, total: number): Promise<TableListData<ApprovalLineData>>
    {
        try
        {
            const total = await ApprovalLineData.GetApprovalLineCountAsync();
            if(Table.current != null) await Table.current?.setTotalAsync(total!);
            const list = await ApprovalLineData.GetApprovalLineListAsync(false, offset, count);
            return {data: list ?? []};
        }
        catch (ex){ printError(ex); return {data: []}; }
    }

    function onClose(success: boolean)
    {
        setOpenModal(false);
        if(success && Table.current != null) Table.current.refresh();
    }

    const modalStyle = {
            content: {
               background: "transparent",
                width:`${window.innerWidth <= 577? "calc(100% - 40px)":"1000px"}`,
                margin:"0 auto",
                padding:0,
                border:0,
                inset:`${window.innerWidth <= 577? "20px":"40px"}`,
                height:"fit-content"
            },
            overlay: {
                background: "rgba(0,0,0,0.5)",
                zIndex:"99"
            }
        }
    return (
        auth == null ? <div>잘못된 접근입니다</div> :
        <>
            <Modal isOpen={openModal} ariaHideApp={false} style={modalStyle}>
                <ApprovalLineSetModal auth={auth} data={modaltem} onClose={onClose}/>
            </Modal>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div style={{display: "none"}}><SearchControl options={searchKind} onSearch={() => console.log("ads")}/></div>
                    </div>
                    <div className="col-lg-8 col-sm-6">
                        <div className="row_margin">
                            <div className="text-end">
                                <button type="button" className="btn" disabled={busy} onClick={() => open()}>등록</button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn" disabled={item == undefined || busy} onClick={() => open(item)}>수정</button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn" disabled={item == undefined || busy} onClick={() => remove(item)}>삭제</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-4 col-sm-12" style={{marginBottom: "10px"}}>
                        <div className="card">
                            <div className="card-header">
                                결재라인 지정목록
                            </div>
                            <div className="card-body">
                                <TableListControl<ApprovalLineData> ref={Table} count={10} autoRefresh={true} checkable={false} headerTable={TableHeaderList} headerCard={headerCard} onRefresh={onRefresh} onItemClick={(value, header, data) => setItem(data)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-sm-12">
                        <div className="card">
                            <div className="card-header">결제라인 상세 {item == null ? "" : `(${item.LineName})`}</div>
                            {item == null ? <div className="card-body"><p className="text-center">결재라인을 선택해 주세요</p></div> : <ApprovalLineViewer data={item} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default LineSettingPage;

type ApprovalLineViewerProps = {
    data?: ApprovalLineData
}
const ApprovalLineViewer = ({data}: ApprovalLineViewerProps) =>
{
    const [tab, setTab] = useState(1);

    const TableApproval = useRef<TableListControl<UserDataLineBase> | null>(null);
    const TableExec = useRef<TableListControl<UserDataBase> | null>(null);
    const TableRef = useRef<TableListControl<UserDataBase> | null>(null);

    const TableNormalHeader: TableItemHeader<UserDataBase>[] = [
        {name: "이름", key: "Name", clickable: false, styleItem: GetItemStyle(60, false), onRender: (value, header, item) => item.toString()},
        {name: "부서", key: "DeptName", clickable: true, styleItem: GetItemStyle(30, false)},
    ]
    const NormalHeaderCard = [
        {...TableNormalHeader[0], styleItem: {}, classNameItem: undefined},
        {...TableNormalHeader[1], styleItem: {}, classNameItem: undefined},
    ]

    const TableApprovalHeader: TableItemHeader<UserDataLineBase>[] = [
        {name: "결재종류", key: "Division", clickable: false, styleItem: GetItemStyle(10, false), onRender: (value => ApproverDivision(value))},
        {name: "이름", key: "Name", clickable: false, styleItem: GetItemStyle(70, false), onRender: (value, header, item) => item.toString()},
        {name: "부서", key: "DeptName", clickable: true, styleItem: GetItemStyle(20, false)},
    ]
    const ApprovalHeaderCard = [
        {...TableApprovalHeader[0], styleItem: {}, classNameItem: "member_name"},
        {...TableApprovalHeader[1], styleItem: {}, classNameItem: "member_name"},
        {...TableApprovalHeader[2], styleItem: {}, classNameItem: "member_name"},
    ]

    useEffect(() =>
    {
        if(TableApproval.current != null) TableApproval.current.refresh();
        if(TableExec.current != null) TableExec.current.refresh();
        if(TableRef.current != null) TableRef.current.refresh();
    }, [data]);

    return (
        <>
            <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs" data-bs-toggle="tabs" role="tablist" style={{cursor: "pointer"}}>
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${tab == 1 ? 'active' : ''}`} role="tab" onClick={() => setTab(1)}>결재</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${tab == 2 ? 'active' : ''}`} role="tab" onClick={() => setTab(2)}>수신참조</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${tab == 3 ? 'active' : ''}`} role="tab" onClick={() => setTab(3)}>시행</a>
                    </li>
                </ul>
            </div>
            <div className="card-body">
                <div className="tab-content docu_list_table">
                    <div className={`tab-pane ${tab == 1 ? 'active show' : ''}`} role="tabpanel">
                        <div className="over_wrap">
                            <TableListControl<UserDataLineBase> ref={TableApproval} autoRefresh={false} headerTable={TableApprovalHeader} headerCard={ApprovalHeaderCard} count={20} onRefresh={() => {return {data: data == null ? [] : data.Approval}}} />
{/*                            <table className="common_table table table-vcenter card-table" style={{ borderBottom: "1px solid #dcdcdf" }}>
                                <thead>
                                <tr>
                                    <th>이름</th>
                                    <th>결재종류</th>
                                    <th>회사</th>
                                    <th>부서</th>
                                    <th>직급</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td colSpan={5} style={{textAlign: "center"}}>데이터가 존재하지 않습니다</td>
                                </tr>
                                </tbody>
                            </table>*/}
                        </div>
                    </div>
                    <div className={`tab-pane ${tab == 2 ? 'active show' : ''}`} role="tabpanel">
                        <div className="over_wrap">
                            <TableListControl<UserDataBase> ref={TableRef} autoRefresh={false} headerTable={TableNormalHeader} headerCard={NormalHeaderCard} count={100} onRefresh={() => {return {data: data == null ? [] : data.RefUser}}} />
                        </div>
                    </div>
                    <div className={`tab-pane ${tab == 3 ? 'active show' : ''}`} role="tabpanel">
                        <div className="over_wrap">
                            <TableListControl<UserDataBase> ref={TableExec} autoRefresh={false} headerTable={TableNormalHeader} headerCard={NormalHeaderCard} count={100} onRefresh={() => {return {data: data == null ? [] : data.ExecUser}}} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
