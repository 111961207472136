import UserDataBase from "./UserDataBase";
import {Expose, plainToInstance} from "class-transformer";
import {BrowserStorageMapper} from "lib/BrowserStorage";
import {SendValueAsyncType} from "API";
import axios from "axios";
import * as Define from "Define";
import {serializeForm} from "@hskernel/hs-utils";

export default class UserDataSimple extends UserDataBase
{
    @Expose() public NameEnglish?: string;
    @Expose() public ResponsWork: string | null;
    @Expose() public EmailCompany: string | null;
    @Expose() public TelCompany: string | null;
    @Expose() public TelFax: string | null;
    @Expose() public IsAdmin: boolean;
    @Expose() public IsEmployment: boolean;
    @Expose() public ProfileImageThumb?: string;

    public Apply(user: UserDataSimple)
    {
        super.Apply(user);
        this.NameEnglish = user.NameEnglish;
        this.ResponsWork = user.ResponsWork;
        this.EmailCompany = user.EmailCompany;
        this.TelCompany = user.TelCompany;
        this.TelFax = user.TelFax;
        this.IsAdmin = user.IsAdmin;
        this.IsEmployment = user.IsEmployment;
        return this;
    }

    public static Parse(data: any): UserDataSimple | null
    {
        return new UserDataSimple().Apply(data);
    }

    public static async GetCurrentUser(isProfileImage: boolean): Promise<UserDataSimple | null> { return await SendValueAsyncType<UserDataSimple>(axios.get<UserDataSimple>(`${Define.URL_API_USER}?IsProfileImageThumb=${isProfileImage ?? ''}`), true, UserDataSimple); }
}

//https://blog.banksalad.com/tech/typescript-local-storage/
export class UserDataSimpleMapper implements BrowserStorageMapper<UserDataSimple>
{
    fromJson(json: string | null): UserDataSimple | null { return json == null ? null : UserDataSimple.Parse(JSON.parse(json)); }
    toJson(target: UserDataSimple): string { return JSON.stringify(target); }
}