import React, {Component, FormEvent} from "react";
import Modal from 'react-modal';
import UserDataSimple from "data/API/User/UserDataSimple";
import {printError} from "Define";
import axios from "axios";
import { URL_API_LOGIN_PASSWORD } from "../Define";
import {serializeForm} from "@hskernel/hs-utils";

const passwordStyle = {
    content: {
        maxWidth: "320px",
        height:"450px",
        margin: "100px auto",
        background: "transparent",
        border:"none"
    },
    overlay: {
        background: "rgba(0,0,0,0.5)",
        zIndex: "9"
    }
}

type EditPasswordDialogProps = {
    isSimplePW?: boolean
}

type EditPasswordDialogState = {
    user?: UserDataSimple,
    isOpen: boolean,
    isBusy: boolean,
}

type Password = {
    pw: string,
    pw_confirm: string,
}
export default class UserPasswordDialog extends Component<EditPasswordDialogProps, EditPasswordDialogState>
{
    constructor(props: EditPasswordDialogProps) {
        super(props);
        this.state = {
            isOpen: false,
            isBusy: false,
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    private async onSubmit(e: FormEvent<HTMLFormElement>)
    {
        e.preventDefault();
        let open = true;

        if(this.state.user == null) alert("사원이 지정되지 않았습니다");
        else
        {
            const form = serializeForm(new FormData(e.currentTarget)) as Password;
            try
            {
                if(form.pw != form.pw_confirm) { alert("비밀번호와 비밀번호 확인이 일치하지 않습니다"); return; }

                this.setState({...this.state, isBusy: true});

                await axios.post(`${URL_API_LOGIN_PASSWORD}/${this.state.user.LoginID}?IsSimplePW=${this.props.isSimplePW ?? false}`, { ChangePW: form.pw }, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                alert("성공적으로 비밀번호를 변경하였습니다");
                open = false;
            }
            catch (ex: any){ printError(ex); }
            finally { this.setState({...this.state, isBusy: false, isOpen: open}); }
        }
    }

    public openDialog(user: UserDataSimple){ this.setState({...this.state, user: user, isOpen: true}); }
    private closeDialog(){ this.setState({...this.state, isOpen: false}); }

    render()
    {
        return (
            <Modal ariaHideApp={false} isOpen={this.state.isOpen} style={passwordStyle}>
                {this.state.user == null ?
                    <h1>잘못된 접근입니다</h1>
                    :
                    <form className="card" onSubmit={this.onSubmit}>
                        <div className="card-header">
                            <p className="col">{this.state.user.NameKorean}{this.props.isSimplePW ? " 간편" : ""} 비밀번호 변경</p>{/*
                <div className="col-auto">
                    <button className="btn" style={{float: "right"}}>닫기</button>
                </div>*/}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <p className="mb-2">변경할{this.props.isSimplePW ? " 간편" : ""} 비밀번호</p>
                                <div className="input-group mb-2">
                                    <input type="password" name="pw" className="form-control" placeholder={this.props.isSimplePW ? "간편 비밀번호 입력" : "숫자/영문자/특수문자 포함하여 10자리 이상"}/>
                                </div>
                                <p className="mb-2">변경할{this.props.isSimplePW ? " 간편" : ""} 비밀번호 확인</p>
                                <div className="input-group mb-2">
                                    <input type="password" name="pw_confirm" className="form-control" placeholder={this.props.isSimplePW ? "간편 비밀번호 다시 입력" : "비밀번호 다시 입력"}/>
                                </div>

                            </div>

                        </div>
                        <div className="card-footer text-end">
                            <input type="submit" className="btn btn-primary" disabled={this.state.isBusy} value="변경"/>&nbsp;&nbsp;
                            <button type="button" className="btn" disabled={this.state.isBusy} onClick={this.closeDialog}>취소</button>
                        </div>
                    </form>
                }
            </Modal>
        )
    }
}