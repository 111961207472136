import {Expose} from "class-transformer";

export default class LoginData
{
    @Expose() public IP: string;

    static GetCurrentLoginData(): LoginData
    {
        return new LoginData();
    }
}