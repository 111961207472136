import React from "react";
import {Component} from "react";
import { dateFormat } from "utils/date.format";
import {isEmptyString, isNullorWhiteSpace} from "utils/HSUtils";

export const isoDateTime = "yyyy-mm-dd'T'HH:MM:ss";
export const isoUtcDateTime = "UTC:yyyy-mm-dd'T'HH:MM:ss'Z'";
export const defaultDateTime = "yyyy-mm-dd HH:MM:ss";

type DateTimeControlProps = {
    onChange?: (value: string | null, valueDate: Date | null, sender: DateTimeControl) => void,
    /**
     * 시간 형식 (
     */
    format?: string,
    name?: string,
    value?: string | Date | null,
    /**
     * 폼 컨트롤 값이 json 형식의 시간값인지 여부입니다 (시간형식은 무시됩니다)
     */
    jsonType?: boolean,
    type?: string,
    className?: string
}
type DateTimeControlState = {
    Date: string | null,
    Time: string | null,
}

function _getDateString(state: DateTimeControlState)
{
    const value = `${state.Date} ${state.Time}`;
    return isNullorWhiteSpace(value) ? null : value;
}
function _getDate(state: DateTimeControlState)
{
    const value = _getDateString(state);
    return value == null ? null : new Date(value);
}

export default class DateTimeControl extends Component<DateTimeControlProps, DateTimeControlState>
{
    constructor(props: DateTimeControlProps)
    {
        super(props);
        this.getDateString = this.getDateString.bind(this);
        this.getDate = this.getDate.bind(this);
        this.toJSON = this.toJSON.bind(this);
        this.setDateTime = this.setDateTime.bind(this);

        this.getState = this.getState.bind(this);
        this.setDateTimeState = this.setDateTimeState.bind(this);
        this._getValue = this._getValue.bind(this);

        this.state = this.getState(props.value);
    }

    private getState(value?: string | Date | null): DateTimeControlState
    {
        let date: Date | null = null;
        const type = typeof value;
        if(type == 'undefined') date = new Date();
        else if(type == 'string' && !isEmptyString(type)) date = new Date(type);
        else if(value as Date) date = value as Date;

        return {
            Date: date == null ? "" : dateFormat(date, "yyyy-mm-dd"),
            Time: date == null ? "" : dateFormat(date, "HH:MM")
        }
    }
    public getDateString = () => dateFormat(this.getDate(), this.props.format ?? defaultDateTime);
    public readonly getDate = () => _getDate(this.state);

    public toJSON(): string | null
    {
        const date = this.getDate();
        return date == null ? null : date.toJSON();
    }
    public setDateTime(value: string | Date | null)
    {
        const state = this.getState(value);
        this.setState(state);
    }

    private setDateTimeState(state: DateTimeControlState)
    {
        this.setState(state);
        if(this.props.onChange != null) this.props.onChange(this.getDateString(), this.getDate(), this);
    }

    private readonly _getValue = (state: DateTimeControlState) => this.props.jsonType ? this.toJSON() : this.getDateString();

    render() {
        return (
            <>
                <input type="hidden" name={this.props.name} value={this._getValue(this.state) ?? undefined}/>
                <input type="date" className={this.props.className} value={this.state.Date ?? undefined} onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                {
                    const Time = isEmptyString(e.target.value) ? '' : this.state.Time;
                    this.setDateTimeState({Time: Time, Date: e.target.value})}
                }/>
                <input type="time" className={this.props.className} value={this.state.Time ?? undefined} onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                {
                    if(isEmptyString(this.state.Date)) alert("날짜를 먼저 지정해주세요");
                    else this.setDateTimeState({...this.state, Time: e.target.value});
                }}/>
            </>
        )
    }
}