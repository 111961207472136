import Calendar, { CalendarDay } from "lib/hs-calendar-react";
import React, {forwardRef, useImperativeHandle} from "react";
import {useEffect, useRef, useState } from "react";
import ScheduleDataBase from "../data/ScheduleDataBase";
import { ScheduleShareType } from "../Define";
import ScheduleData from "../data/ScheduleData";
import {dateFormat} from "@hskernel/hs-utils";
import LoadingCircle from "../../../common/control/LoadingCircle";
import {CSSProperties} from "styled-components";

type CalendarControlProps = {
    share?: ScheduleShareType,
    showLunar?: boolean,
    showMemorialDay?: boolean,
    onClick?: (date: Date, sender: CalendarDay) => void,
}

export type ICalendarControl = {
    refresh: (year?: number, month?: number) => void,
}

const CalendarControl = forwardRef<ICalendarControl, CalendarControlProps>((props: CalendarControlProps, ref: React.ForwardedRef<any>) =>
{
    const dataToHTMLString = (data: ScheduleDataBase) => `<div style="background-color: ${data.Color ?? ''}">${data.Title}</div>`;
    //private calendar = useRef<TUICalendar | null>(null);
    const calendar = useRef<Calendar | null>(null);

    const [busy, setBusy] = useState(false);
    const [date, setDate] = useState<Date>(new Date());
    const [data, setData] = useState<ScheduleDataBase[]>([]);

    useEffect(() =>
    {
        refresh();
    }, [props.share, date]);

    useEffect(() =>
    {
        if(calendar.current != null)
        {
            const map = new Map<string, string>();
            data.map(list =>
            {
                const key_start = dateFormat(list.StartDate, "yyyy-mm-dd");
                const key_end = dateFormat(list.EndDate, "yyyy-mm-dd");
                function getSchedule(date_str: string)
                {
                    let html = map.get(date_str);
                    if(html == null) html = "";
                    html += dataToHTMLString(list);
                    map.set(date_str, html);
                }
                getSchedule(key_start);
                if(key_start != key_end)
                {
                    //start - end 해서 getDay() 가 0 보다 크면 차이만큼 반복문 돌려서 할당
                    //소수점 이상 올림
                    const diff_day = Math.ceil(list.EndDate.getDiffDay(list.StartDate));
                    for (let i = 1; i <= diff_day; i++)
                    {
                        const newDate = new Date(list.StartDate.getFullYear(), list.StartDate.getMonth(), list.StartDate.getDate()).addDays(i);
                        getSchedule(dateFormat(newDate, "yyyy-mm-dd"));
                    }
                }

                //console.log(map)
            });

            const date = calendar.current.getCurrentFirstDate();
            calendar.current.setDate(date, map);
        }
    }, [data]);

    function refresh(year?: number, month?: number)
    {
        if(calendar != null && calendar.current != null)
        {
            if (year == null) year = date.getFullYear();
            if (month == null) month = date.getMonth() + 1;

            setBusy(true);
            //calendar.current.clearHTMLAll();
            ScheduleData.GetSchedulesSimpleAsync(props.share ?? null, year, month, 0)
                .then(datas =>
                {
                    datas = datas ?? [];
                    setData(datas);
                })
                .finally(() => setBusy(false));
        }
    }

    useImperativeHandle(ref, () => ({
        // 부모에서 사용하고 싶었던 함수
        refresh,
    }));

    const LoadingBox:CSSProperties= {
        position:"absolute",
        width:"100%",
        zIndex:996,
        borderRadius:"10px",
        filter:"blur(2px)"

    }
    const LoadingStyle:CSSProperties= {
        position:"absolute",
        top:"50%",
        transform:"translateY(-50%)",
        zIndex:999,
        width:"100%",
        textAlign:"center"
    }
    return (
        <div style={{position:"relative",height:"512px"}}>
            {busy ? <div style={LoadingStyle}><LoadingCircle size={"50px"} border={3} text={"일정을 로드중입니다..."}/></div> : ""}
            <div style={busy ? LoadingBox : {}}>
                <Calendar ref={calendar}
                          onClick={props.onClick}
                          showLunar={props.showLunar ?? true}
                          showMemorialDay={props.showMemorialDay ?? false}
                          onMonthChange={(year, month) => setDate(new Date(year, month - 1, 1))}/>
            </div>
        </div>
    )
});

CalendarControl.displayName = "CalendarControl";
export default CalendarControl;
