import React, {CSSProperties, useEffect, useRef, useState} from "react";
import TableListControl, {TableItemHeader, TableItemType, TableListData} from "common/control/TableListControl";
import {sleep} from "../utils/HSUtils";
import {useNavigate} from "react-router-dom";

type TestData = {
    akey: string,
    value: string,
    date: Date
}

function GetItemStyle(width: number, clickable?: boolean): CSSProperties { return {
    width: `${width}%`,
    textAlign: "center",
    borderRight: "1px solid #ccc",
    cursor: clickable ? "pointer" : "default"
}}

const TableViewTest = () =>
{
    const header: TableItemHeader<TestData>[] = [
        {
            name: "키",
            styleItem : GetItemStyle(20, true),
            key: "akey",
            clickable: true,
        },
        {
            name: "값",
            styleItem: GetItemStyle(20, true),
            key: "value",
            clickable: true,
        },
        {
            name: "타임스탬프",
            styleItem: GetItemStyle(60),
            key: "date",
            type: TableItemType.DateTime,
            clickable: false,
        }
    ];

    const navigate = useNavigate();
    const table = useRef<TableListControl<TestData>>(null);

    function onRefresh(offset: number, count: number, page: number, total: number): TableListData<TestData>
    {
        console.log(`onRefresh(): ${offset} / ${count} / ${page} / ${total}`);

        const data: TestData[] = [
            {akey: "AAA", value: "AAA1", date: new Date()},
            {akey: "BBB", value: "BBB1", date: new Date()},
            {akey: "BBB", value: "BBB1", date: new Date()},
            {akey: "BBB", value: "BBB1", date: new Date()},
            {akey: "BBB", value: "BBB1", date: new Date()},
            {akey: "BBB", value: "BBB1", date: new Date()},
            {akey: "BBB", value: "BBB1", date: new Date()},
            {akey: "BBB", value: "BBB1", date: new Date()},
            {akey: "BBB", value: "BBB1", date: new Date()}
        ];
        return {data: data}
    }

    function onClick(key: string, value: any)
    {
        console.log(`onClick(): ${key} / ${value}`)
    }

    function onCheck(item: object, checked: boolean, index: number)
    {
        console.log(item);
    }

    useEffect(() =>
    {
        async function _Delay()
        {
            console.log("2초 대기중...");
            await sleep(2000);

            console.log("대기 완료");
        }

        _Delay().then(() => table.current?.setTotalAsync(100).then());
    }, [])

    return (
        <TableListControl ref={table} autoRefresh={true} onRefresh={onRefresh} onItemClick={onClick} onChecked={onCheck} headerTable={header} navigate={navigate}/>
    )
}

export default TableViewTest;
