import React, {CSSProperties, useEffect, useState} from "react";
import ApprovalInfo from "../data/ApprovalInfo";
import ApprovalStatus, {APPROVER_KIND_AGREE} from "../data/ApprovalStatus";
import {dateFormat, isEmptyString, randomKey} from "@hskernel/hs-utils";
import {
    STATUS_FINAL,
    STATUS_HOLD, STATUS_PROGRESS,
    STATUS_REJECT, STATUS_REPORT, STATUS_SAVE
} from "../data/ApprovalDocumentBase";
import ApprovalDocumentListData from "../data/ApprovalDocumentListData";
import ApprovalDocument from "../data/ApprovalDocument";
import {STATUS_APPROVAL_FINAL} from "../data/ApprovalStatusBaseData";
import {IconX} from "@tabler/icons-react";
import {SendValueAsyncArrayType} from "API";
import axios from "axios";
import {URL_API_APPROVAL_APPROVALS} from "../Define";
import {isMockupMode} from "../../../Define";

const dateMask = "yyyy-mm-dd HH:MM:ss";

type ApprovalStatusProgressProps = {
    isOpen?: boolean,
    doc: ApprovalDocument |  ApprovalDocumentListData | null,
    approvals?: ApprovalStatus[],
    onClose: () => void,
}
type ApprovalStatusProgressState = {
    info: ApprovalInfo,
    approvals: ApprovalStatus[],
}

/**
 * 결재 상태 컨트롤
 * @param {boolean | undefined} isOpen
 * @param {ApprovalDocument | ApprovalDocumentListData | null} doc
 * @param {ApprovalStatus[] | undefined} approvals
 * @param {() => void} onClose
 * @returns {JSX.Element}
 * @constructor
 */
const ApprovalStatusProgress = ({isOpen, doc, approvals, onClose}: ApprovalStatusProgressProps) =>
{
    const getTitle = (approval: ApprovalStatus, isComplete: boolean, postfix: boolean) =>
    {
        const kind = approval.Kind == APPROVER_KIND_AGREE ? "합의" : "결재";
        let title = null;
        if(approval.ApprovalStatus == STATUS_HOLD) title = "보류";
        else if(approval.ApprovalStatus == STATUS_REJECT) title = "반려";
        else if(approval.ApprovalStatus == STATUS_FINAL) title = "전결";
        else if(isComplete) title = "종결";
        return title == null ? kind : (postfix ? `${title} [${kind}자]` : title);
    }
    /**
     *
     * @param approvals
     * @param info
     * @param disable true 면 강제로 비활성화합니다
     */
    const getItem = (approvals: ApprovalStatus[], info: ApprovalInfo, disable: boolean) =>
    {
        if(doc != null)
        {
            let index = -2;
            /*
            if(!disable)
            {
                approvals.forEach((approval, i) =>
                {
                    if (info.Current != null && approval.Approver.LoginID == info.Current.Approver.LoginID)
                    {
                        if((i == approvals.length - 1 && approval.ApprovalStatus == STATUS_APPROVAL_COMPLETE) ||
                            approval.ApprovalStatus == STATUS_FINAL ||
                            approval.ApprovalStatus == STATUS_HOLD ||
                            approval.ApprovalStatus == STATUS_REJECT) index = i;
                        else index = i - 1;
                        return;
                    }
                });
            }
            */
            return approvals.map((approval, i) =>
            {
                const isMatch = info.Current != null && approval.Approver.LoginID == info.Current.Approver.LoginID;

                if (isMatch) index = i;
                const isCancel = (doc.DocumentStatus == STATUS_FINAL || doc!.DocumentStatus == STATUS_REJECT) && index > -2 && i > index;
                const isComplete = approvals.length == i + 1 && approval.ApprovalStatus != STATUS_APPROVAL_FINAL;
                const isProgress = index == i && (doc.DocumentStatus == STATUS_REPORT || doc.DocumentStatus == STATUS_PROGRESS);

                function approvalText()
                {
                    let title = getTitle(approval, isComplete, true);
                    if(isProgress) title += " 진행중...";

                    title += ` (${approval.toString()}`;
                    if(isMatch && info.Instead != null) title += ` → [대결] ${info.Instead.Instead!.toString()}`;
                    title += ")";
                    return title;
                }

                return (
                    <li key={randomKey(i)} className={`step-item ${/*!disable && */index == i ? "active" : ""}`} style={{textDecoration: isCancel ? "line-through" : "none", textDecorationColor: undefined}}>
                        <div className="h4 m-0">{approvalText()}</div>
                        <div className="text-secondary">
                            {approval.ApprovalDate == null ? (isCancel ? "(해당 없음)" : `대기중...`) : `${getTitle(approval, isComplete, false)}일: ${dateFormat(approval.ApprovalDate, dateMask)}`}
                            <br/>
                            열람일: {approval.ReadingDate == null ? "없음" : dateFormat(approval.ReadingDate, dateMask)}
                            <br/>
                            {approval.ApprovalComment == null ? "" : `의견: ${approval.ApprovalComment}`}
                        </div>
                    </li>
                )
            })
        }
    }

    const [state, setState] = useState<ApprovalStatusProgressState | null>(null); //doc == null || approvals == null ? null : { info: doc.ApprovalInfo, approvals: approvals }
    //const [state, setState] = useState<ApprovalStatusProgressState | null>(null);

    useEffect(() =>
    {
        async function getApprovals()
        {
            if(doc != null)
            {
                const approvals = await SendValueAsyncArrayType<ApprovalStatus>(axios.get(`${URL_API_APPROVAL_APPROVALS}/${doc.DocumentID}`), false, ApprovalStatus)
                setState({info: doc.ApprovalInfo, approvals: approvals ? approvals : []});
            }
        }

        //if(_state != null) setState(_state);
        if(isOpen && doc != null)
        {
            if(approvals == null) getApprovals().then();
            else setState({info: doc.ApprovalInfo, approvals: approvals})
        }
        else setState(null);
    }, [isOpen]);

    const getElement = () =>
        doc == null ? <div>무언가 잘못 되었습니다. 새로고침을 해주세요!!</div> :
        state == null ?
            <ul className="steps steps-vertical">
                <p><b>{doc ? doc.Title : ""}</b></p>
                기다려 주십시오...
            </ul>
            :
            <ul className="steps steps-vertical">
                <p><b>{doc ? doc.Title : ""}</b></p>
                <li className={`step-item ${doc.DocumentStatus == STATUS_SAVE ? "active" : ""}`}>
                    <div className="h4 m-0">저장</div>
                    <div className="text-secondary">기안일: {doc.WriteDate == null ? "" : dateFormat(doc.WriteDate, dateMask)}</div>
                </li>
                <li className={`step-item ` /* ${doc.DocumentStatus == STATUS_REPORT ? "active" : ""} */}>
                    <div className="h4 m-0">상신</div>
                    {state.approvals.length > 0 && state.approvals[0].ReceiveDate ? <div className="text-secondary">상신일: {dateFormat(state.approvals[0].ReceiveDate, dateMask)}</div> : ""}
                    {!isEmptyString(doc.DocumentComment) ? <div className="text-secondary">상신의견: {doc.DocumentComment}</div> : ""}
                </li>
                {getItem(state.approvals, state.info, doc.DocumentStatus == STATUS_SAVE || doc.DocumentStatus == STATUS_REPORT)}
            </ul>

    return (
        <>
            <div style={isOpen ? PopBackStyles : PopupBackCancel} onClick={onClose}></div>
            <div style={isOpen ? PopStyles : PopupCancel}>
                <div className="card">
                    <div className="card-header">
                        <div className="col"><b>[ {isMockupMode() ? '테스트-2024-000001' : (doc == null ? "" : `${doc.DocumentID}`)} ]</b> 의 결재 처리 상태</div>
                        <div className="col-auto" style={{cursor: "pointer"}} onClick={onClose}>
                            <IconX size={20} stroke="currentColor" strokeWidth={1} color="gray"/>
                        </div>
                    </div>
                    <div className="card-body" style={{overflowY:"scroll",maxHeight:"570px"}}>
                        {getElement()}
                    </div>
                </div>
            </div>
        </>
    )
}
export default ApprovalStatusProgress;

const PopStyles: CSSProperties = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "400px",
    zIndex: "10",
    transition: '0.3s'
}
const PopupCancel: CSSProperties = {
    top: "-500px",
    transition: "0.3s",
    position: "fixed",
    width: "400px",
    height: "400px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: "10"
}
const PopupBackCancel: CSSProperties = {
    display: "none"
}
const PopBackStyles: CSSProperties = {
    background: "rgba(0,0,0,0.5)",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: "9"
}
