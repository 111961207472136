import React, {useEffect, useState} from "react";
import {GetConfigAsync, LoginAsync, MailplugConfig} from "../Define";
import {printError, sendRNData} from "Define";
import {useNavigate} from "react-router-dom";
import LoadingCircle from "../../../common/control/LoadingCircle";
import { IconAlertCircle } from '@tabler/icons-react';
import {isWVMode} from "../../../lib/RNMessage/RNMessageWV";
import {isAxiosError} from "axios";
import {useAuth} from "context/AuthContext";

const MSG_UNUSE = "메일을 사용하지 않는 사용자 입니다.\r\n관리부서에 문의하시기 바랍니다";

const MailPlugMailRedirect = () =>
{
    const {auth} = useAuth();
    const Navigate = useNavigate();
    const [busy, setBusy] = useState(false);
    const [message, setMessage] = useState<string | null>(null);
    const [config, setConfig] = useState<MailplugConfig | null>(null);
    const [url, setURL] = useState<string>("");
    useEffect(() =>
    {
        async function Init()
        {
            setBusy(true);
            try
            {
                const config = await GetConfigAsync();
                setConfig(config);

                if(config != null)
                {
                    const src = await LoginAsync(config);
                    //window.open(src, "_blank");
                    if (isWVMode())
                    {
                        setMessage("잠시만 기다려 주세요...");
                        sendRNData({type: 'OPEN', data: {url: src}});
                        Navigate(-1);
                    }
                    else
                    {
                        const childWin = window.open("", "childWin");
                        if(childWin == null) setMessage("팝업을 허용한 후 새로고침 해주세요");
                        else
                        {
                            childWin.location.href = src;
                            Navigate(-1);
                        }


                        /*
                        try
                        {
                            let xhr = new XMLHttpRequest();
                            xhr.withCredentials = false;
                            xhr.open("GET", src);
                            xhr.send();

                            const response = await axios.request({
                                method: "GET",
                                url: src,
                                maxRedirects: 2,
                                validateStatus: null,
                                withCredentials: false,
                                headers: { "Origin": "",  "Referer": "" }
                            });
                            if(response.status >= 300 && response.status < 400 )
                            {
                                console.log(response.headers);
                            }
                            else setURL(src);
                        }
                        catch (e){ console.log(e); }
                         */

                        //setURL(src);
                        //setLoading(false);
                    }
                }
            }
            catch (ex)
            {
                if(isAxiosError(ex))
                {
                    if (ex.response != null && ex.response.data != null && ex.response.data.message != null)
                    {
                        if(ex.response.data.message.indexOf('Code: 9') != -1) { setMessage(MSG_UNUSE); return; }
                    }
                }
                printError(ex);
            }
        }

        const useMail = auth != null && auth.setting.EmailUse;
        if(useMail) Init().then().finally(() => setBusy(false));
        else setMessage(MSG_UNUSE);
    }, []);

    function getView()
    {
        if(config == null && message == null) return <h1>오류가 발생했습니다</h1>;
        else
        {
            return (
                <div className="card-body">
                    <h3 className="text-red text-center" style={{height:"400px",marginTop:"300px"}}><IconAlertCircle/> {message}</h3>
                </div>
            )
            /*
            return(
            <>
                <a href={url}>{url}</a>
                <iframe sandbox="" referrerPolicy="no-referrer" width="100%" height="1024px" src={url}></iframe>
            </>);
             */
            //return <iframe width="100%" height="1024px" src={src}></iframe>
        }
    }
    return busy ?
        <div className="card-body text-center">
            <div style={{height:"400px",marginTop:"300px"}}>
                <LoadingCircle size={"50px"} border={5}/>
                <p>로그인 중입니다...</p>
            </div>
        </div>
        :
        getView();
}

export default MailPlugMailRedirect;
