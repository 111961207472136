import {Expose, Type} from "class-transformer";

export const STATUS_APPROVAL_PROGRESS = "2";
export const STATUS_APPROVAL_HOLD = "3";
export const STATUS_APPROVAL_REJECT = "4";
export const STATUS_APPROVAL_FINAL = "5";
export const STATUS_APPROVAL_COMPLETE = "6";

export default class ApprovalStatusBaseData
{
     @Expose() public IsInstead: boolean;
     /**
      * 결재상태
      */
     @Expose() public ApprovalStatus: string | null;
     /**
      * 수신 일자
      * @type {Date | null}
      */
     @Expose() @Type(() => Date)
     public ReceiveDate: Date | null;
     /**
      * 열람 일자
      * @type {Date | null}
      */
     @Expose() @Type(() => Date)
     public ReadingDate: Date | null;
     /**
      * 결재/합의/시행 일자
      * @type {Date | null}
      */
     @Expose() @Type(() => Date)
     public ApprovalDate: Date | null;
     /**
      * 결재/합의 의견
      * @type {string | null}
      */
     @Expose() public ApprovalComment: string | null;
     /**
      * 수정 날짜
      * @type {Date | null}
      */
     @Expose() @Type(() => Date)
     public ModifyDate: Date | null;
     /**
      * 수정인
      * @type {string | null}
      */
     @Expose() public ModifyComment: string | null;
}