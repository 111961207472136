import React, {CSSProperties, useState} from "react";
import {isMobileUI} from "Define";
import { IconCaretDownFilled ,IconCaretUpFilled} from '@tabler/icons-react';
import styles from './index.module.css';
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import {randomKey} from "@hskernel/hs-utils";

export type SplitButtonData = {
    text: string,
    onClick: () => Promise<void> | void,
    disabled?: boolean,
    className?: string,
    style?: CSSProperties,
}

type SplitButtonProps = {
    button: SplitButtonData,
    subButtons?: SplitButtonData[],
}

const SplitButton = (props: SplitButtonProps) =>
{
    const [_isMobile, setIsMobile] = useState(isMobileUI());
    const [dropAct, setDropAct] = useState(false);
    const isDropdownEnable = () => props.subButtons != null && props.subButtons.length > 0;

    const onWindowResize = useWindowResize();
    onWindowResize(() => setIsMobile(isMobileUI()));

    function printListBtn() {
        if(isDropdownEnable()) setDropAct(!dropAct);
    }

    return (
        <div className={styles.btn_print + " btn d_none"} style={{position: "relative"}}>
            <AwaitableButton isSub={false} button={props.button}/>
            {_isMobile ?
                <>
                    {props.subButtons != null && props.subButtons.map((button, i) => <AwaitableButton key={randomKey(i)} isSub={false} button={button}/>)}
                </>
                :
                <>
                <div className={styles.print_list_btn} onClick={printListBtn}>
                    {dropAct ? <IconCaretUpFilled size={22}/> : <IconCaretDownFilled size={22}/>}
                </div>
                    { dropAct &&
                        <div className={styles.dropdown} onClick={() => setDropAct(false)}>
                            {props.subButtons != null && props.subButtons.map((button, i) => <AwaitableButton key={randomKey(i)} isSub={true} button={button}/>)}
                        </div>
                    }
                </>
            }
            </div>
    )
}

export default SplitButton;

type AwaitableButtonProps = {
    button: SplitButtonData,
    isSub: boolean,
}
const AwaitableButton = ({button, isSub}: AwaitableButtonProps) =>
{
    const [isBusy, setIsBusy] = useState(false);

    function onClick()
    {
        setIsBusy(true);
        Promise.resolve(button.onClick())
            .then()
            .finally(() => setIsBusy(false));
    }

    /* TODO: <div> 에 disable 처리 필요!! */
    return isSub ?
        <div className={button.className ?? styles.dropdown_content + " card"}> <a onClick={onClick}>{button.text}</a></div> :
        <button type="button" disabled={isBusy || button.disabled} className={button.className ?? styles.print_btn_basic} onClick={onClick}>{button.text}</button>
}
