/* .https://velog.io/@hannah3406/ReactTypescript-%ED%8A%B8%EB%A6%AC%EA%B5%AC%EC%A1%B0-UI-%EA%B5%AC%ED%98%84%ED%95%98%EA%B8%B0-1 */

import React, {Component, CSSProperties, EventHandler, MouseEventHandler} from "react";
import TreeViewData from "./Data/TreeViewData";
import {EntryContainer, ListContainer, Root} from "./Component/UI";
import {ItemButton, ItemPlusMinus} from "./Component/Buttons";
import {randomKey} from "@hskernel/hs-utils";


export type TreeViewEvent<T> = {
    data: TreeViewData<T>,
    instance: TreeViewItemUI<T>,
}

export type TreeViewControlProps<T> = {
    data: TreeViewData<T>[],
    showCheck?: boolean,
    style?: CSSProperties,
    onClick?: (event: TreeViewEvent<T>) => void | Promise<void>
    onCheck?: (event: TreeViewEvent<T>) => void | Promise<void>
}

export default class TreeViewControl<T> extends Component<TreeViewControlProps<T>>
{
    constructor(props: TreeViewControlProps<T>) {
        super(props);
    }

    render()
    {
        return <TreeViewItemUI<T> {...this.props} depth={0}/>
    }
}

function TreeViewRender<T>(props: TreeViewControlProps<T>)
{
    return (
    <div style={props.style}>
        <ListContainer>
            {}
        </ListContainer>
    </div>)
}

type TreeViewItemUIProps<T> = TreeViewControlProps<T> & {
    depth: number,
    parent?: TreeViewItemUI<T>,
    //onSubCheckChanged?: () => void,
}
type TreeViewItemUIState = {
    /*None*/
}
class TreeViewItemUI<T> extends Component<TreeViewItemUIProps<T>, TreeViewItemUIState>
{
    constructor(props: TreeViewItemUIProps<T>)
    {
        super(props);

        this.getItemUI = this.getItemUI.bind(this);
        this.onCheckChanged = this.onCheckChanged.bind(this);
    }

    /**
     * 상위 항목을 가져옵니다
     */
    getParent = () => this.props.parent;

    getItems = () => this.props.data;


    private getItemUI(data: TreeViewData<T>)
    {
        console.log(this.props.depth);
        return (
            <div style={{width: '100%', paddingLeft: `${this.props.depth * 20}px`}}>
                <ItemButton>
                    <ItemPlusMinus>+</ItemPlusMinus>
                    {!this.props.showCheck ? <input type='checkbox' onChange={() => this.onCheckChanged({data: data, instance: this}, false)} /> : <></>}
                    {data.Icon}{data.Title}
                </ItemButton>
            </div>
        )
    }

    setCheck(check: boolean)
    {

    }

    getCheck()
    {

    }

    getCheckAll()
    {

    }

    private onCheckChanged(item: TreeViewEvent<T>, isSub: boolean)
    {

    }

    render() {
        return (
            <div>
                {this.props.data.map((data, index) =>
                    <>
                        {this.getItemUI(data)}
                        {data.Child && data.Child.length > 0 ?
                            <TreeViewItemUI<T> {...this.props} key={randomKey(index)} parent={this}
                                               data={data.Child}
                                               depth={this.props.depth + 1}
                                               showCheck={this.props.showCheck}
                                               onCheck={(item) => this.onCheckChanged(item, true)}/>
                            : ''}
                    </>)
                }
            </div>
        )
    }
}


/**
 *
 *             <div>
 *                 <span>+</span>
 *                 🍄 children
 *             </div>
 */