import React, {useRef, useState} from "react";
import SearchWithDateRangeControl, {
    DateRangeData,
    DEFAULT_SEARCH_DATE_FULL,
    getDefaultDateRangeData,
    SearchWithDateRangeControlClass, SearchWithDateRangeFullData
} from "../common/control/SearchWithDateRangeControl";
import {useNavigate} from "react-router-dom";
import {SelectOptionData, SelectOptionValue} from "../common/component/SelectOption";
import {
    getDefaultSearch,
    getSearchOptionValueFromEvent, SearchControlClass, SearchOptionEvent,
    SearchOptionProps,
    SearchOptionValue
} from "../common/control/SearchControl";
import {getDateString} from "../Define";
import {
    DEFAULT_DOCUMENT_SEARCH_PARAM, GET_DEFAULT_DATE,
    getSearchOptionInit, SEARCH_CONTROL_NAME,
    SearchDocumentParam
} from "../modules/approval/info/approval";
import {dateFormat} from "@hskernel/hs-utils";

const SearchDocTest = () =>
{
    const name = SEARCH_CONTROL_NAME;
    const kind = 'complete';
    const options = getSearchOptionInit(false, kind);
    const defaultDate = GET_DEFAULT_DATE();

    const searchControl = useRef<SearchWithDateRangeControlClass | null>(null);
    const [search, setSearch] = useState<SearchDocumentParam>(DEFAULT_DOCUMENT_SEARCH_PARAM(options));

    const navigate = useNavigate();

    return (
        <>
            <SearchWithDateRangeControlClass
                ref={searchControl}
                name={name} navigate={navigate} dateRangeTitle='시작일'
                defaultKeyword={search.keyword}
                defaultDateStart={defaultDate.start}
                defaultDateEnd={defaultDate.end}
                defaultOptions={options}
                onSelectedOptions={(seq, event) => {
                    console.log(`${seq}: ${event?.value}`);
                    return false;
                }}
                onChange={(data) => setSearch(SearchDocumentParam.fromSearchData(data))}/>
            <p>Keyword: {search.keyword}</p>
            <p>keywordKind: {search.keywordKind}</p>
            <p>Start: {dateFormat(search.start ?? null, 'yyyy-mm-dd')}</p>
            <p>End: {dateFormat(search.end ?? null, 'yyyy-mm-dd')}</p>

            <button onClick={() => {
                const data = DEFAULT_SEARCH_DATE_FULL(name, defaultDate, options, undefined, true);
                const optionValues = getSearchOptionValueFromEvent(data.options);

                searchControl.current?.setDataAsync(data.keyword ?? null, optionValues, data.start ?? null, data.end ?? null).then();
            }}>Clear
            </button>
        </>
    )
}

export default SearchDocTest;