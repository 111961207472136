import React from 'react';
import {randomKey} from "utils/HSUtils";
import {ISelector} from "./ISelector";
import UserDataBase from "data/API/User/UserDataBase";

export default class UserListSelect extends ISelector<any, any>
{
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-6 col-sm-4 col-md-3 col-xl py-3">
                        <a className="btn btn-outline-mute w-100" onClick={this.removeHandle}>
                            삭제
                        </a>
                    </div>
                </div>
                <div className="table_m_over">
                    <table className="table table-vcenter card-table common_table"
                           style={{borderTop: "2px solid #333"}}>
                        <thead>
                        <tr>
                            <th className="w-1">
                                <input type="checkbox" className="form-check-input m-0 align-middle" aria-label="전채선택"
                                       onChange={(e) => this.handleAllCheck(e.target.checked)}
                                       checked={this.state.check.size == this.state.data.size && this.state.data.size > 0}/>
                            </th>
                            <th style={{textAlign: "center"}}>이름</th>
                        </tr>
                        </thead>
                    </table>
                    <div className="table_wrap">
                        <table className="table table-vcenter card-table common_table">
                            <tbody>
                            {this.getData().map((data, i) => {
                                return (
                                    <tr key={randomKey(i)}>
                                        <td className="w-1">
                                            { data.constable ? "" :
                                                <input type="checkbox" className="form-check-input m-0 align-middle" aria-label="이 항목 선택"
                                                       onChange={(e) => this.handleSingleCheck(e.target.checked, data.user.LoginID)}
                                                       checked={this.state.check.has(data.user.LoginID)}/>
                                            }
                                        </td>
                                        <td>[{this.props.company}]-[{data.user.DeptName}] {data.user.Name} {UserDataBase.getPosition(data.user)}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}