import {Route} from "react-router-dom";
import React from "react";
import TableViewTest from "./TableViewTest";
import TreeViewTest from "./TreeViewTest";
import SearchDateTest from "./SearchDateTest";
import SSOTokenTest from "./SSOTokenTest";
import SearchTest from "./SearchTest";
import SearchDocTest from "./SearchDocTest";

const TestRouter = () =>
{
    return (
        <Route path='test'>
            <Route path='table' element={<TableViewTest/>}/>
            <Route path='tree' element={<TreeViewTest/>}/>
            <Route path='searchdate' element={<SearchDateTest/>}/>
            <Route path='search' element={<SearchTest/>}/>
            <Route path='searchdoc' element={<SearchDocTest/>}/>
            <Route path='sso' element={<SSOTokenTest/>}/>
        </Route>
    )
}

export default TestRouter;
