import React, {useEffect, useState} from "react";
import {IconPlus,IconClipboardText} from "@tabler/icons-react";
import ApprovalDocumentListData from "../data/ApprovalDocumentListData";
import {LIST_KIND_PROGRESS, URL_APPROVAL_LIST} from "../Define";
import {PathMaker, randomKey} from "@hskernel/hs-utils";
import {STATUS_INFO_AVAILABLE} from "../data/ApprovalInfo";
import {useTheme} from "context/ThemeContext";
import {useNavigate} from "react-router-dom";
import {DocumentViewOpen} from "../page/document/DocumentViewPage";
import {isMockupMode} from "Define";

type DocumentListWidget = {
    docKind?: string,
}

function ApprovalDocListWidget(props: DocumentListWidget)
{
    const COUNT = 2;
    const docKind = props.docKind ?? LIST_KIND_PROGRESS; //기본 미결함

    const {theme} = useTheme();
    const Navigate = useNavigate();

    const [list, setList] = useState<ApprovalDocumentListData[] | null>(null);
    useEffect(() =>
    {
        ApprovalDocumentListData.GetListAsync(docKind, undefined, null, 0, COUNT, false)
            .then(list => setList(list ?? []))
            .catch((e: any) => setList([]))
    }, [])

    return (
        <div className="card" style={{height:"244px"}}>
            <div className="card-header">
                <div className="col-auto">
                    <IconClipboardText stroke={1}/>
                </div>
                &nbsp;
                <h3 className="card-title col">전자결재 (미결함)</h3>
                <a className="col-auto" onClick={() => Navigate(PathMaker(URL_APPROVAL_LIST, docKind))} style={{cursor: 'pointer'}}>
                    <IconPlus stroke={1}/>
                </a>
            </div>
            <div className="list-group list-group-flush list-group-hoverable placeholder-glow">
                    {list == null ?
                        <>
                            <div className="list-group-item">
                                <div className="row">
                                    <div className="col-auto placeholder">
                                        읽음
                                    </div>
                                    <div className="col placeholder text-truncate">
                                        <a className=" w-100">........</a>
                                    </div>
                                    <div className="col-auto placeholder">
                                        ????-??-?? ??:??:??
                                    </div>
                                </div>
                                <div className="row mt-2">
                            <div className="col placeholder">.....</div>
                        </div>
                            </div>
                            <div className="list-group-item">
                                <div className="row">
                                    <div className="col-auto placeholder">
                                        읽음
                                    </div>
                                    <div className="col placeholder text-truncate">
                                        <a className=" w-100">........</a>
                                    </div>
                                    <div className="col-auto placeholder">
                                        ????-??-?? ??:??:??
                                    </div>
                                </div>
                                <div className="row mt-2">
                            <div className="col placeholder">.....</div>
                        </div>
                            </div>
                        </>

                        :
                        list.map((doc, i) =>
                        {
                            const approvalable = doc.ApprovalInfo.Approvalable == STATUS_INFO_AVAILABLE;
                            return (
                                    <div className="list-group-item" key={randomKey(i)} onClick={() => DocumentViewOpen(doc.DocumentID)}>
                                        <div className="row">
                                            <div className="col-auto">
                                                {/*<span className="badge bg-blue-lt" style={{marginRight:"5px",color:"white"}}>{getListString(docKind)}</span>*/}
                                                <span className="badge bg-blue-lt" style={{color: "white"}}>{doc.DocumentClassName}</span>
                                            </div>
                                            <div className="col text-truncate">
                                                <a style={{cursor: 'pointer'}}>{isMockupMode() ? '테스트 별정 (사원)' : doc.getUserData().toString()} [{isMockupMode() ? '(문서 번호)' : doc.DocumentID}]</a>
                                            </div>
                                            <div className="col-auto">
                                                {/*결재 {approvalable ? "가능" : "불가능"}*/}
                                                <span className={`badge ${approvalable ? 'bg-lime-lt' : 'bg-red-lt'}`} style={{color: 'white'}}>{doc.ApprovalInfo.toString()}</span>
                                            </div>
                                        </div>
                                        <div className="row text-muted mt-2">
                                            <div className="col">문서제목 | {isMockupMode() ? '테스트 문서' : doc.Title}</div>
                                            <div className="col-auto">{doc.WriteDate!.format('yyyy-mm-dd')}</div>
                                        </div>

                                    </div>
                            )
                        })
                    }
            </div>
        </div>
    );
}

export default ApprovalDocListWidget;
