import React, {CSSProperties, useEffect, useRef, useState} from "react";
import ScheduleData from "../data/ScheduleData";
import {ScheduleShareType} from "../Define";
import ScheduleDataBase from "../data/ScheduleDataBase";
import LoadingCircle from "common/control/LoadingCircle";
import Calendar, {CalendarDay} from "lib/hs-calendar-react/index";
import 'css/calendar-main.css';
import {dateFormat} from "utils/date.format";

type CalendarWidgetProps = {
    share?: ScheduleShareType | null
}
type ScheduleMap = {
    html : string,
    num : number
}

const CalendarWidget = ({share}:CalendarWidgetProps) => {

    const [date, setDate] = useState<Date>(new Date());
    const [data, setData] = useState<ScheduleDataBase[]>([]);
    const [busy, setBusy] = useState(false);

    const calendar = useRef<Calendar | null>(null);


    useEffect(() =>
    {
        refresh(date.getFullYear(), date.getMonth() + 1);
    }, [date]);

    useEffect(()=> {
        if(calendar.current != null)
        {
            const map = new Map<string, number>();
            const sendMap = new Map<string, string>();
            data.map(list =>{
                const key_start = dateFormat(list.StartDate, "yyyy-mm-dd");
                const key_end = dateFormat(list.EndDate, "yyyy-mm-dd");
                const dataToHTMLString = (num?:number) => `<div class="main_plan_num">+${num? num + 1 : 0}</div>`;

                function getScheduleNum(date_str: string)
                {
                    let num = map.get(date_str);
                    if(num == null) num = 0;
                    num += 1;
                    map.set(date_str,num);
                }
                getScheduleNum(key_start);
                sendMap.set(key_start,dataToHTMLString(map.get(key_start)));
                if(key_start != key_end)
                {
                    //start - end 해서 getDay() 가 0 보다 크면 차이만큼 반복문 돌려서 할당
                    //소수점 이상 올림
                    const diff_day = Math.ceil(list.EndDate.getDiffDay(list.StartDate));
                    for (let i = 1; i <= diff_day; i++)
                    {
                        const newDate = new Date(list.StartDate.getFullYear(), list.StartDate.getMonth(), list.StartDate.getDate()).addDays(i);
                        const day = dateFormat(newDate, "yyyy-mm-dd");
                        getScheduleNum(day);
                        sendMap.set(day,dataToHTMLString(map.get(day)));
                    }
                }

            })

            const date = calendar.current.getCurrentFirstDate();
            calendar.current.setDate(date,sendMap);
        }
    },[data])

    function refresh(year: number, month: number)
    {
        if(calendar != null && calendar.current != null)
        {
            setBusy(true);
            //calendar.current.clearHTMLAll();
            ScheduleData.GetSchedulesSimpleAsync(share ?? null, year, month, 0)
                .then(datas =>
                {
                    datas = datas ?? [];
                    setData(datas);
                })
                .finally(() => setBusy(false));
        }
    }

    const LoadingBox:CSSProperties= {
        position:"absolute",
        width:"100%",
        zIndex:996,
        borderRadius:"10px",
        filter:"blur(2px)"

    }
    const LoadingStyle:CSSProperties= {
        position:"absolute",
        top:"50%",
        transform:"translateY(-50%)",
        zIndex:999,
        width:"100%",
        textAlign:"center"
    }

    function CalendarClick(date: Date, sender: CalendarDay)
    {
        //Calendar 날짜 클릭시 일정 데이터 불러오기
        setDate(date);
    }

    return (
        <>
        {busy ? <div style={LoadingStyle}><LoadingCircle size={"50px"} border={3} text={"일정을 로드중입니다..."}/></div> : ""}
            <div style={busy ? LoadingBox : {}} className="main_calendar">
                <Calendar ref={calendar}
                          onClick={CalendarClick}
                          showLunar={false}
                          showMemorialDay={false}
                          onMonthChange={(year, month) => setDate(new Date(year, month - 1, 1))}/>
            </div>
        </>
    )
}
export default CalendarWidget;