import React from "react";
import Icon from "./resource/icon.png";
import { PathMakerMulti } from "@hskernel/hs-utils";
import ModuleInfo, { AdminMenuKind } from "modules/ModuleInfo";
import { IconSettings } from "@tabler/icons-react";

export const SettingsModuleID = "setting";
export const DEFAULT_SETTINGS_PATH = PathMakerMulti(SettingsModuleID, "user", "list");
const SettingsInfo: ModuleInfo =
{
    id: SettingsModuleID,
    name: "Setting",
    title: "시스템 설정",
    //icon: Icon,
    onRenderIcon: (darkMode, isSmall) => <IconSettings/>,
    defaultPath: () => DEFAULT_SETTINGS_PATH,
    admin: AdminMenuKind.AdminOnly,
    category: [
        {
            id: "user",
            title: "사원 설정",
            path: "user",
            open: true,
            menu: [
                {
                    id: "list",
                    title: "사원 관리",
                    path: "list",
                    popup: false,
                },

            ]
        },
        {
            id: "module",
            title: "모듈 설정",
            path: "module",
            open: true,
            menu: [
                {
                    id: "menu",
                    title: "메뉴 권한 관리",
                    path: "menu",
                    popup: false,
                }
            ]
        }
    ]
}

export default SettingsInfo;
