import ApprovalStatusBaseData from "./ApprovalStatusBaseData";
import UserDataBase from "data/API/User/UserDataBase";
import {Expose, Type} from "class-transformer";
import { isEmptyString } from "@hskernel/hs-utils";

export const APPROVER_KIND_APPROVAL = "Approval";
export const APPROVER_KIND_AGREE =  "Agreement";
export const APPROVER_KIND_EXEC = "Exec";
export const APPROVER_KIND_REF = "Ref";
export const APPROVER_KIND_SHARE = "Share";

export default class ApprovalStatus extends ApprovalStatusBaseData
{
    /**
     * 인덱스 (순서)
     */
    @Expose() public Index: number;
    /**
     * 결재 상태 종류 (결재, 합의, 수신및참조, 합의)
     */
    @Expose() public Kind: string;
    /**
     * 전결 여부 (결재/함의에서만 씀)
     */
    @Expose() public IsFinal = false;
    @Expose() @Type(() => UserDataBase)
    public Approver: UserDataBase;
    @Expose() @Type(() => UserDataBase)
    public Instead?: UserDataBase;
    @Expose() @Type(() => UserDataBase)
    public Modify?: UserDataBase;

    public toString = () => this.ToString(true);
    public ToString = (showPosition?: boolean) =>
    {
        let position = "";
        if(showPosition ?? true)
        {
            if(this.Instead == null)
            {
                if(isEmptyString(this.Approver.PositionName1)) position = this.Approver.PositionName2 ?? "";
                else if(isEmptyString(this.Approver.PositionName2)) position = this.Approver.PositionName1 ?? "";
                else position = this.Approver.PositionName1 ?? "";
            }
            else
            {
                if(isEmptyString(this.Instead.PositionName1)) position = this.Instead.PositionName2 ?? "";
                else if(isEmptyString(this.Instead.PositionName2)) position = this.Instead.PositionName1 ?? "";
                else position = this.Instead.PositionName1 ?? "";
            }
            if(!isEmptyString(position)) position = ` ${position}`;
        }
        return this.Instead == null ?
            `${this.Approver.Name}${position}`:
            `${this.Instead.Name}${position} (대결)`;
    }
}