import ErrorPageBase, {ErrorPageParam} from "./Base";

export default class ErrorPage403 extends ErrorPageBase
{
    constructor(props?: ErrorPageParam)
    {
        super({
            errorCode: '403',
            title: props?.title ?? '접근이 거부 되었습니다',
            message: props?.message ?? '해당 작업을 수행할 권한이 없습니다',
            hideBack: props?.hideBack,
            hideHome: props?.hideHome,
        });
    }
}