import {ApprovalSelectorData} from "common/control/selector/internal/ISelector";
import React, {Component, createRef, useEffect, useRef} from "react";
import {isEmptyString, randomKey} from "@hskernel/hs-utils";
import {getImageAsync} from "@hskernel/hs-utils-html5";
import ApprovalStatus, {APPROVER_KIND_AGREE} from "../../data/ApprovalStatus";
import {dateFormat} from "utils/date.format";
import {
    STATUS_APPROVAL_COMPLETE,
    STATUS_APPROVAL_FINAL,
    STATUS_APPROVAL_HOLD,
    STATUS_APPROVAL_PROGRESS,
    STATUS_APPROVAL_REJECT
} from "../../data/ApprovalStatusBaseData";
import styles from "./index.module.css";
import StatusRejectIcon from './image/status-reject.png';
import StatusFinalIcon from './image/status-final.png';
import StatusHoldIcon from './image/status-hold.png';
import StatusApprovalIcon from './image/status-approval.png';
import StatusAggrementIcon from './image/status-agreement.png';
import StatusCompleteIcon from './image/status-complete.png';
import StatusEmptyIcon from './image/status-empty.png';
import {GetApproverKindText} from "../../data/ApprovalDocumentBase";
import ApprovalLineExtra from "../../type/ApprovalLineExtra";
import html2canvas from "html2canvas";
import {isMockupMode} from "../../../../Define";

const FONT_SIZE = "9pt";
const FONT_SIZE_INNER = "8pt";

export type ApprovalSelectorDataEx = ApprovalSelectorData<ApprovalLineExtra> & {
    index: number;
    /**
     * 결재/반려/보류/전결 일시
     */
    date?: Date | null;
    /**
     * 결재 상태
     */
    status?: string | null;
}
type ApprovalLineControlProps = {
    kind: string,
    members: ApprovalSelectorDataEx[],
    onClick?: () => void;
    placeHolder?: string;
}
export default class ApprovalLineControl extends Component<ApprovalLineControlProps>
{
    //TODO: 나중에 대결이면 대결자를 대신 표시해야하는가?
    public static FromApprovalStatus = (Status: ApprovalStatus[], kind: string, onClick?: () => void, placeHolder?: string): ApprovalLineControlProps => { return {
        kind: kind,
        members: Status
            .filter(data => data.Kind == kind)
            .map((data) => {return {...new ApprovalSelectorData(data.Approver, data.Kind), index: data.Index, date: data.ApprovalDate, status: data.ApprovalStatus, extra: {final: data.IsFinal ?? false}}}),
        onClick: onClick,
        placeHolder: placeHolder}};

    constructor(props: ApprovalLineControlProps) {
        super(props);
    }

    render()
    {
        const repeatNum = this.props.members.length > 5 ? 10 : 5;
        return (
            <>
                <td rowSpan={2} className={styles.form_head}>{GetApproverKindText(this.props.kind)}</td>
                <td rowSpan={2} className={styles.approval_member_row} onClick={this.props.onClick}>
                    {this.props.members.length > 0 ?
                        <>
                            {Array(repeatNum).fill("").map((v,index) =>
                            {
                                const member = index < this.props.members.length ? this.props.members[index] : null;
                                const isLast = index == this.props.members.length - 1;
                                return <HolderView key={randomKey(index)} member={member} length={this.props.members.length} isLast={isLast}/>
                            })}
                        </>
                        :
                        this.props.placeHolder ?? '결재라인을 선택해주세요.'}
                </td>
            </>
        )
    }
}

type HolderViewProps = {
    member: ApprovalSelectorDataEx | null,
    length: number,
    isLast: boolean,
}
const HolderView = ({member, length, isLast}: HolderViewProps) =>
{
    function getApprovalStatusSimpleText(member: ApprovalSelectorDataEx, isLast: boolean)
    {
        switch (member.status)
        {
            case STATUS_APPROVAL_HOLD: return "보류";
            case STATUS_APPROVAL_REJECT: return "반려";
            case STATUS_APPROVAL_FINAL: return "전결";
            case STATUS_APPROVAL_COMPLETE:
                if(member.kind == APPROVER_KIND_AGREE) return "";
                else return isLast ? "종결" : "";
            default: return "";
        }
    }
    function getApprovalStatusText(member: ApprovalSelectorDataEx, isLast: boolean)
    {
        switch (member.status)
        {
            case STATUS_APPROVAL_HOLD: return "보류";
            case STATUS_APPROVAL_REJECT: return "반려";
            case STATUS_APPROVAL_FINAL: return "전결";
            case STATUS_APPROVAL_COMPLETE:
                if(member.kind == APPROVER_KIND_AGREE) return "합의";
                else return isLast ? "종결" : "결재";
            default: return undefined;
        }
    }
    function getApprovalStatusImage(member: ApprovalSelectorDataEx, isLast: boolean)
    {
        switch (member.status)
        {
            case STATUS_APPROVAL_HOLD: return StatusHoldIcon;
            case STATUS_APPROVAL_REJECT: return StatusRejectIcon;
            //case STATUS_APPROVAL_FINAL: return StatusFinalIcon;
            case STATUS_APPROVAL_FINAL:
            case STATUS_APPROVAL_COMPLETE:
                if(member.kind == APPROVER_KIND_AGREE) return  StatusAggrementIcon;
                else return isLast ? StatusCompleteIcon : StatusApprovalIcon;
            default: return undefined;
        }
    }

    /**
     *
     * @param {ApprovalSelectorDataEx} member
     * @param {boolean} isLast
     * @param {boolean} stampOnly 도장 전용 모드
     * @returns {JSX.Element | null}
     * @private
     */
    function getStamp(member:  ApprovalSelectorDataEx, isLast: boolean, stampOnly?: boolean)
    {
        if(member.status == null || member.status == STATUS_APPROVAL_PROGRESS) return null;

        if(stampOnly || member.status == STATUS_APPROVAL_HOLD) return <img src={getApprovalStatusImage(member, isLast)} alt={member.user.Name ?? ""}/>;
        else return member.status == STATUS_APPROVAL_HOLD ? null : <StampView name={member.user.Name} width="60" height="46"/>
    }

    const status = member == null ? "" : getApprovalStatusText(member, isLast);
    const mockupHideDate = member == null ? true : (member.status == null || member.status == STATUS_APPROVAL_PROGRESS) && isMockupMode();
    const date = member == null || member.date == null || mockupHideDate ? undefined : dateFormat(member.date, "yyyy.mm.dd");
    /**
     * 도장이 찍혀야하는지 여부입니다
     * @type {boolean}
     */
    const isEnd = member == null ? false : (member.status != null && member.status != STATUS_APPROVAL_HOLD);
    const getStampEdge = (member: ApprovalSelectorDataEx) =>
    {
        let status = <p>&nbsp;</p>;
        if(member.extra != null && member.extra.final) status = <p className={styles.doc_state}>전결</p>;
        else
        {
            const stausText = getApprovalStatusSimpleText(member, isLast);
            if(!isEmptyString(stausText)) status = <p className={styles.doc_state}>{stausText}</p>;
        }
        return(
        <>
            {status}
            {/*<p style={{fontSize: FONT_SIZE_INNER}}>{dateFormat(member.date, "yyyy.mm.dd")}</p>*/}
            {/*{this.getApprovalStatusText(member, index == this.props.members.length - 1)}*/}
            <p>{date}</p>
        </>)
    }

    return (
        <div style={{fontSize: FONT_SIZE}} className={`${styles.approval_member} ${length <= 5 ? styles.approval_member_top:""}`}>
            <div style={{height:"20px",position:"relative"}}>
                {member == null ? "" : <p className={styles.approval_num}>{member.index}</p>}
                <p className={styles.approval_position} style={{width: member == null ? "100%" : undefined}}>{member == null ? undefined : member.user.PositionName1}</p>
            </div>
            <div className={styles.approval_member_confirm + " approval_member_confirm"}>
                <div className={styles.approval_confirm_img + " approval_confirm_img"}>
                    {/*<img src={this.getApprovalStatusImage(member, isLast)} alt={}/>*/}
                    {member == null ? undefined : getStamp(member, isLast, true)}
                </div>
                <div className={styles.approval_date + " approval_date"} style={{fontSize: FONT_SIZE_INNER}}>
                    {member == null ? "" : getStampEdge(member) }
                </div>
                <span>
                    {member == null ? "" : member.user.Name}
                    {/*member.status == STATUS_HOLD || member.status == STATUS_REJECT || member.status == STATUS_FINAL ? ` [${GetDocumentStatusText(member.status)}]` : "" */}
                </span>
            </div>
        </div>
    )
}

type StampViewProps = {
    name?: string | null,
    width?: number | string,
    height?: number | string
}
const StampViewCanvas = ({name, width, height}: StampViewProps) =>
{
    const stampRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() =>
    {
        async function Init()
        {
            const _name = name ?? "";
            const canvas = stampRef.current;
            if(canvas != null)
            {
                const ctx = canvas.getContext('2d');
                const width = canvas.width, height = canvas.height;
                if(ctx != null)
                {
                    const img = await getImageAsync(StatusEmptyIcon);
                    ctx.drawImage(img, 10, 3);
                    ctx.textAlign = "center";
                    ctx.fillText(_name, width/2, height / 2, width);
                }
            }
        }
        Init().then();
    }, [name]);

    return <canvas ref={stampRef} width={width} height={height}></canvas>
}

const StampView = ({name, width, height}: StampViewProps) =>
{
    const _name = name ?? "";
    //TODO: a 태그 넘어가면 ... 주기
    function getNameWithEmptyStamp()
    {
        return (
            <div style={{width: width ?? undefined, height: height ?? undefined}} className={styles.approval_stamp_name}>
                <img src={StatusEmptyIcon} alt={_name}/>
                <a style={{fontSize: FONT_SIZE}}>{_name}</a>
            </div>
        )
    }

    return getNameWithEmptyStamp();
}
