import React, {Component, useCallback, useEffect, useRef, useState} from "react";
import styles from './SideMenu.module.css';
import {PathMaker, randomKey} from "@hskernel/hs-utils";
import {useAuth} from "context/AuthContext";
import $ from "jquery";
import {Link} from "react-router-dom";
import {Info as Modules} from "Module";
import {URL_FRONT_MODULE} from "../Define";
import ModuleInfo, {
    getMenuDisplayByPath,
    checkModuleKindMatch,
    ModuleMenu,
    ModuleMenuCategory, isMenuDisplay, checkModuleMenu
} from "../modules/ModuleInfo";
import {IconChevronDown} from "@tabler/icons-react";
import {Async} from "react-async";
import LoadingCircle from '../common/control/LoadingCircle';
import {useTheme} from "../context/ThemeContext";
import ModuleMenuProperty from "../data/API/Module/Menu/ModuleMenuProperty";
import AlertData from "../data/API/Alert/AlertData";
import {AddAlertCallback, RemoveAlertCallback} from "../Alert";
import {ModuleMenuSubData} from "../data/API/Module/Menu/ModuleMenuData";


export const GetPath = (path: string) => PathMaker(URL_FRONT_MODULE, path);

export function GetIcon(render: ModuleInfo | ModuleMenu, darkMode: boolean, isSmall?: boolean)
{
    const icon = render.onRenderIcon == null ? null : render.onRenderIcon(darkMode, isSmall ?? false);
    if(icon == null) return undefined;
    else if(typeof icon == "string") return <img className={isSmall ? "icon" : ""} src={icon} alt={typeof render.title == "string" ? render.title : undefined}></img>

    return icon;
}

/**
 * 해당 경로가 무슨 모듈인지 가져옵니다
 * @constructor
 */
export function GetModuleFromPath(isModule: boolean): ModuleInfo | null {
    const path = isModule ? window.location.pathname.substring(URL_FRONT_MODULE.length + 1) : "";
    const index = path.indexOf('/');
    let id = path;
    if (index > 0) id = path.substring(0, index);

    for (let i = 0; i < Modules.length; i++)
        if (Modules[i].id == id) return Modules[i];

    return null;
}

const SideMenu = () =>
{
    const clickRefresh = true;

    const module = GetModuleFromPath(true);
    const {admin, menuProperty} = useAuth();
    const {theme, setDarkMode} = useTheme();
    const menuListUI = useRef<MenuList | null>(null);

    //알림 도착시 새로고침
    const onNotiReceived = useCallback((alert: AlertData | null) =>
    {
        if (module != null &&
            alert != null && alert.Module == module.id &&
            module.onNotiReceive != null && module.onNotiReceive(alert))
        {
            if (menuListUI.current != null) menuListUI.current.onRefreshAsync().then();
        }
    }, []);

    useEffect(() =>
    {
        //마운트 시 알림 도착시 새로고침
        AddAlertCallback(onNotiReceived);

        //언마운트 시 알림 새로고침 비활성화
        return () => RemoveAlertCallback(onNotiReceived);
    }, []);

    function actionEnter() {
        $(".drop_nav").stop().animate({"width": 200}, 300);
    }

    function actionLeave() {
        $(".drop_nav").stop().animate({"width": 0}, 300);
    }

    /**
     * 사이드바 아이콘 표시
     * @param {boolean} admin
     * @return {Element[]}
     */
    const getMenuNavTab = (admin: boolean) =>
    {
        return Modules.map((module, i) =>
        {
            const checkKind = checkModuleKindMatch(admin, module.admin);
            if(checkKind)
            {
                const display = getMenuDisplayByPath(menuProperty, module.id, "", admin);
                if (isMenuDisplay(display))
                {
                    return (
                        <a key={randomKey(i)} href={GetPath(module.id)} className={styles.icon_list}>
                            <span style={{color:"#fff"}}>{GetIcon(module, theme.darkMode)}</span>
                        </a>
                    );
                }
            }

            return <></>;
        })
    }
    /**
     * 사이드바 메뉴 표시
     * @param {boolean} admin
     * @returns {(React.JSX.Element | string)[]}
     */
    const getMenuDropNav = (admin: boolean) =>
    {
        return Modules.map((module, i) =>
        {
            const checkKind = checkModuleKindMatch(admin, module.admin);
            if(checkKind)
            {
                const display = getMenuDisplayByPath(menuProperty, module.id, "", admin);
                if(isMenuDisplay(display))
                {
                    return (
                        <a key={randomKey(i)} href={GetPath(module.id)} className={styles.drop_list}>
                            {module.title}
                        </a>
                    );
                }
            }

            return "";
        })
    }

    return (
        /* todo: 사이드 메뉴 높이 조절 필요 */
        <aside className={styles.navigation}>
            <div className={styles.aside_menu} onMouseEnter={actionEnter} onMouseLeave={actionLeave}>
                <div className={styles.nav_tab}>
                    {getMenuNavTab(admin)}
                </div>

                <div className={`${styles.drop_nav} drop_nav`}>
                    {getMenuDropNav(admin)}
                </div>
            </div>

            <div className={styles.nav_detail}>
                <div className={styles.nav_title}>
                    <h2>{module?.title ?? ""}</h2>
                </div>

                <div className={styles.menu_list}>
                    {<MenuList ref={menuListUI} module={module} admin={admin} menuProperty={menuProperty} clickRefresh={clickRefresh}/>}
                </div>
            </div>
        </aside>
    )
}

export default SideMenu;


type MenuListProps = {
    module: ModuleInfo | null;
    admin: boolean;
    menuProperty: ModuleMenuProperty,
    clickRefresh: boolean,
}
type MenuListState = {
    menuLoading: boolean,
    module?: ModuleInfo | null;
    admin: boolean;
}
class MenuList extends Component<MenuListProps, MenuListState>
{
    constructor(props: MenuListProps)
    {
        super(props);
        this.state = {
            ...props,
            menuLoading: false
        }

        this.onRefreshAsync = this.onRefreshAsync.bind(this);
        this.onClickCategory = this.onClickCategory.bind(this);
        this.getMenuCategory = this.getMenuCategory.bind(this);
    }

    async componentDidMount()
    {
        await this.onRefreshAsync();
    }


    private onClickCategory(category: ModuleMenuCategory, menu?: ModuleMenu)
    {
        if (this.state.module != null && this.state.module.onClick != null && this.state.module.onClick(category, menu)) return true;

        if (this.props.clickRefresh) this.onRefreshAsync().then();
        return false;
    }

    private getMenuCategory(module: ModuleInfo, admin: boolean)
    {
        return module.category.map((category, i) =>
        {
            return checkModuleMenu(this.props.menuProperty, module.id, category.id, category.hide, category.admin, admin) ?
                <Category key={randomKey(i)} category={category} menuProperty={this.props.menuProperty} admin={admin} parentPath={GetPath(module.id)} moduleID={module.id} onClick={menu => this.onClickCategory(category, menu)}/> :
                <></>;
        })
    }

    public async onRefreshAsync(module?: ModuleInfo | null, admin?: boolean)
    {
        const _module = module ?? this.state.module;
        const _admin = admin ?? this.state.admin;
        if (_module != null && _module.onRenderInit != null)
        {
            //상태값 변경
            await new Promise<void>((resolve) => this.setState({module: _module, admin: _admin, menuLoading: true}, () => resolve()));

            const prop = this.props.menuProperty.GetMenuData(admin);
            let data: ModuleMenuSubData | undefined = undefined;
            Object.keys(prop).forEach(id =>
            {
                if (_module!.id == id && prop[id].Display == "Show")
                {
                    data = prop[id].Sub;
                    return;
                }
            });
            //onInit 호출
            try { await _module.onRenderInit(_admin, data, this.onRefreshAsync); }
            //상태값 변경
            finally { await new Promise<void>((resolve) => this.setState({...this.state, menuLoading: false}, () => resolve())); }
        }
    }

    render ()
    {
        const module = this.state.module;
        if (module != null)
        {
            if (checkModuleMenu(this.props.menuProperty, module.id, "", false, module.admin, this.state.admin))
            {
                return this.state.menuLoading ? <LoadingCircle size="30px"/> : <>{this.getMenuCategory(module, this.state.admin)}</>;
            }
        }

        return <></>;


        /*
        return module.onRenderInit == null ? getMenuCategory(module, admin) : (
            <Async promiseFn={module.onRenderInit}>
                <Async.Pending></Async.Pending>
                <Async.Fulfilled>{() => getMenuCategory(module, admin)}</Async.Fulfilled>
            </Async>
        )*/
    }
}

type CategoryProps = {
    category: ModuleMenuCategory,
    menuProperty: ModuleMenuProperty,
    parentPath: string,
    admin: boolean,
    moduleID: string,
    onClick: (menu?: ModuleMenu) => boolean,
    clickRefresh?: boolean,
}
const Category = ({category, menuProperty, parentPath, admin, moduleID, ...props}: CategoryProps) =>
{
    type MenuReclusive = {
        parent: ModuleMenu,
        current: ModuleMenu,
    }

    const [lastRefresh, setLastRefresh] = useState(Math.random());
    function onClick(menu?: ModuleMenu)
    {
        if (props.onClick != null && props.onClick(menu)) return true;

        if (props.clickRefresh) setLastRefresh(Math.random());
        return false;
    }
    function onCategoryClick(e: React.MouseEvent<HTMLParagraphElement>)
    {
        if (onClick()) e.preventDefault();
    }

    const renderMenu = (menu: ModuleMenu, i: number, isLoading: boolean) => <Menu key={randomKey(i)} isLoading={isLoading} pathParent={PathMaker(parentPath, category.path ?? "")} menu={menu} onClick={() => onClick(menu)}/>;
    function getMenuList(menu: ModuleMenu, i: number)
    {
        const menuID = `${category.id}\\${menu.id}`;
        if (checkModuleMenu(menuProperty, moduleID, menuID, menu.hide, menu.admin, admin))
        {
            return menu.onRender ?
                // @ts-ignore
                <Async key={randomKey(i)} promiseFn={() => Promise.resolve(menu.onRender(menu, false, admin))}>
                    <Async.Pending>{renderMenu(menu, i, true)}</Async.Pending>
                    <Async.Fulfilled>{(menu: ModuleMenu) => renderMenu(menu, i, false)}</Async.Fulfilled>
                </Async> :
                renderMenu(menu, i, false)
        }
    }

    const [mount, setMount] = useState(false);
    useEffect(() => { console.log("Category MOUNT!!"); setMount(true) }, []);

    //TODO: open 변수로펼치기림/닫힘여부 확인해서 적용하기
    return (
        <div>
            <a>
                <div className={styles.menu_category}>
                    <IconChevronDown size={20}/>
                    <p onClick={onCategoryClick}>&nbsp;{category.title}</p>
                </div>
            </a>
            {mount && category.menu.length > 0 &&
                <div className={styles.detail_menu} key={lastRefresh}>
                    {category.menu.map((menu, i) => getMenuList(menu, i))}
                </div>
            }
        </div>
    );
}

type MenuProps = {
    menu: ModuleMenu,
    pathParent: string,
    isLoading: boolean,
    onClick: () => boolean,
}
const Menu = ({menu, pathParent, isLoading, ...props}: MenuProps) =>
{
    const path = PathMaker(pathParent, menu.path);
    async function onClick(e: React.MouseEvent<HTMLAnchorElement>)
    {
        if (!isLoading && props.onClick != null && props.onClick()) e.preventDefault();
    }
    //MenuPath.set(path, this)
    //TODO: 하위메뉴 있으면 하위메뉴 적용하기
    return menu.placeholder ? <div className="card placeholder-glow"><div className="placeholder col-8"/></div> :
        <Link to={path} onClick={onClick}>
            -&nbsp;{menu.title}{isLoading ? <LoadingCircle/> : ""}
        </Link>
}
/*
const menuListData = {
    write: {
        categoryMenu: "전자작성",
        submenu: ["결재양식", "결재요청문서", "임시보관문서"]
    },
    approval: {
        categoryMenu: "결재문서",
        submenu: ["상신함", "미결함", "전결함", "기결함", "반려함", "보류함", "수신참조함", "시행함", "시행확인문서", "공문발송문서"],
    },
    setting: {
        categoryMenu: "결재설정",
        submenu: ["결재라인설정", "대결자설정", "하위결재함관리"],
    },
    takeover: {
        categoryMenu: "인수인계",
        submenu: ["인수인계관리", "인수인계문서"],
    },
    all: {
        categoryMenu: "전체편지함",
        submenu: [],
    },
    receive: {
        categoryMenu: "보낸편지함",
        submenu: [],
    },
    send: {
        categoryMenu: "받은편지함",
        submenu: [],
    },
    spam: {
        categoryMenu: "스팸함",
        submenu: [],
    },
    store: {
        categoryMenu: "보관함",
        submenu: [],
    },
    daily: {
        categoryMenu: "일정관리",
        submenu: ["일정 전체보기", "개인일정", "공유일정", "환경설정"],
    },
    resource: {
        categoryMenu: "자원관리",
        submenu: ["자원캘린더", "나의 예약현황"],
    },
    todo: {
        categoryMenu: "할일관리",
        submenu: ["노트"],
    },
    report: {
        categoryMenu: "업무보고",
        submenu: ["보낸보고서", "받은보고서", "참조보고서", "공개보고서"],
    },
    newBoard: {
        categoryMenu: "최근게시판",
        submenu: [],
    },
    newNotice: {
        categoryMenu: "최근공지사항",
        submenu: [],
    },
    companyBoard: {
        categoryMenu: "사내게시판",
        submenu: ["공지함", "전산문의"],
    },
    activity: {
        categoryMenu: "나의활동",
        submenu: ["게시글", "내가 쓴 댓글", "스크랩", "임시보관함"],
    },
    favorite: {
        categoryMenu: "즐겨찾기",
        submenu: [],
    },
    attendance: {
        categoryMenu: "인사&근태",
        submenu: ["개인근태현황", "개인연차현황", "근태신청현황"],
    },
    prove: {
        categoryMenu: "증명서",
        submenu: ["증명서신청", "증명서발급"],
    },
    cash: {
        categoryMenu: "지출결의관리",
        submenu: ["지출결의 현황", "카드사용 현황", "세금계산서"],
    }
}*/

