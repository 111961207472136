//Copyright ⓒ HSKernel All Right Reserved
//https://github.com/gpo04174

import React, { Component } from "react";
import { NavigateFunction } from "react-router-dom";
import {getParam} from "@hskernel/hs-utils";
import {setParam} from "@hskernel/hs-utils-html5";

const URL_PARAM_KEY_PAGE = 'page';

export function getPageFromParam()
{
    let page = 1;
    try { page = parseInt(getParam(URL_PARAM_KEY_PAGE) ?? '1'); } catch (e) { /* NULL */ }
    return page;
}

export type PaginationProps = {
    /**
     * 항목 총 갯수
     */
    total: number,
    /**
     * 페이지당 보여줄 항목 갯수 (기본값: 10)
     */
    count?: number,
    /**.
     * 페이지가 보여질 갯수 (기본값: 5)
     */
    max?: number,
    /**
     * 버튼 클릭시 호출됨 (True면 진행 False면 진행하지 않음)
     * @param page
     */
    onChange: (page: number, event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<boolean>,
    navigate?: NavigateFunction,
}

type PaginationStateData = {
    /**
     * 항목 총 갯수
     */
    total: number,
    /**
     * 한 페이지에 보여줄 항목 갯수
     */
    count: number,
    /**
     * 페이지가 보여질 갯수
     */
    max: number,
    /**
     * 현재 페이지
     */
    page: number,
    /**
     * 이전버튼 페이지
     */
    page_previous: number;
    /**
     * 다음버튼 페이지
     */
    page_next: number;
    /**
     * 이전버튼 활성화 여부
     */
    isPrevious: boolean,
    /**
     * 다음버튼 활성화 여부
     */
    isNext: boolean,
    /**
     * 버튼 목록
     */
    pages: number[]
}

class PaginationState
{
    data: PaginationStateData;

    setData(data: PaginationStateData)
    {
        this.data = data;
        return this;
    }

    constructor(total?: number, count?: number, max?: number)
    {
        const _total = total ?? 1;
        const _count = Math.max(count ?? 10, 1);
        const _max = Math.max(max ?? 5, 1);

        this.data = {
            total: _total,
            count: _count,
            max: _max,
            page: getPage(_total, _count),
            page_previous: -1,
            page_next: -1,
            isPrevious: false,
            isNext: false,
            pages: [1]
        }

        this.setData = this.setData.bind(this);
    }
}

export default class PageControl extends Component<PaginationProps, PaginationState>
{
    public static URLParamKeys = [URL_PARAM_KEY_PAGE];

    _isMounted = false;

    constructor(props: PaginationProps) {
        super(props);

        this.setDataAsync = this.setDataAsync.bind(this);

        this.setTotalAsync = this.setTotalAsync.bind(this);
        this.getTotal = this.getTotal.bind(this);
        this.setCountAsync = this.setCountAsync.bind(this);
        this.getCount = this.getCount.bind(this);
        this.setMaxAsync = this.setMaxAsync.bind(this);
        this.getMax = this.getMax.bind(this);
        this.setPageAsync = this.setPageAsync.bind(this);
        this.getPage = this.getPage.bind(this);
        this.Refresh = this.Refresh.bind(this);
        this._Refresh = this._Refresh.bind(this);
        this.onPageClick = this.onPageClick.bind(this);


        //상태 초깃값 설정
        this.state = new PaginationState(props.total, props.count, props.max);
        this.state =  new PaginationState().setData(this.Refresh());
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    private readonly setDataAsync = (data: PaginationStateData) =>
    {
        const state = new PaginationState().setData(data);
        return new Promise<void>((resolve, reject) =>
        {
            this.setState(state, () => resolve());
        })
    }

    /**
     * 항목 총 갯수 설정 (페이지가 초기화됩니다)
     * @param total 총 갯수
     * @constructor
     */
    public setTotalAsync = (total: number) =>
    {
        const data = this._Refresh(Math.max(total, 0), this.state.data.count, this.state.data.max, getPageFromParam());
        return this.setDataAsync(data);
    }
    /**
     *
     */
    public getTotal = () => this.state.data.total;
    /**
     * 페이지당 보여줄 항목 갯수 설정 (페이지가 초기화됩니다)
     * @param count 항목 갯수
     * @constructor
     */
    public setCountAsync = (count: number) => this.setDataAsync(this._Refresh(this.state.data.total, Math.max(count, 1), this.state.data.max, getPageFromParam()));
    /**
     *
     */
    public getCount = () => this.state.data.count;
    /**
     * 페이지가 보여질 갯수 설정 (페이지가 초기화됩니다)
     * @param max 갯수
     * @constructor
     */
    public setMaxAsync = (max: number) => this.setDataAsync(this._Refresh(this.state.data.total, this.state.data.total, Math.max(max, 1), getPageFromParam()));
    /**
     *
     */
    public getMax = () => this.state.data.max;
    /**
     *
     * @param page
     */
    public setPageAsync = (page: number) => this.setDataAsync(this.Refresh(page));
    /**
     *
     */
    public getPage = () => this.state.data.page;

    private _Refresh(total: number, count: number, max: number, page: number): PaginationStateData
    {
        //console.log(total)
        const pages: number[] = [];
        const state = {
            total: total,
            //총 갯수
            count: count,
            //한번에 보여줄 페이지 갯수
            max: max,
            //현재 페이지
            page: getPage(total, count, page),
            //이전 페이지
            page_previous: -1,
            //페이지의 끝이면 다음 버튼 비활성화 아니라면 활성화
            page_next: -1,
            //처음 페이지면 이전 버튼 비활성화 아니라면 활성화
            isPrevious: false,
            //다음 페이지
            isNext: false,
        }

        //총 페이지
        const block_total = calcMaxPage(total, count); //Math.max(this.state.page, Math.ceil(this.state.total / this.state.count))

        //기준 페이지
        const block_now = Math.floor((state.page - 1) / state.max) * state.max;
        //현재 페이지에서의 최대 범위
        let block_max = 1;

        //처음 페이지면 다음 버튼 비활성화 아니라면 활성화
        if (block_now + state.max > block_total) {
            block_max = Math.max(1, block_total - block_now);
            state.isNext = false;
        }
        else {
            block_max = state.max;
            state.page_next = block_now + state.max + 1;
            state.isNext = true;
        }

        //버튼 추가
        for (let i = block_now; i < block_max + block_now; i++)
            pages.push(i + 1);

        //처음 페이지면 이전 버튼 비활성화 아니라면 활성화
        if (block_now >= state.max) {
            state.page_previous = block_now;
            state.isPrevious = true;
        }
        else {
            state.isPrevious = false;
        }

        return {
            ...state,
            pages: pages
        };
    }
    public Refresh = (page?: number) => this._Refresh(this.state.data.total, this.state.data.count, this.state.data.max, page ?? this.state.data.page);

    onPageClick(page: number)
    {
        if (this._isMounted)
        {
            this.setDataAsync(this.Refresh(page)).then();

            console.log(setParam({[URL_PARAM_KEY_PAGE]: page}))
            //버튼 클릭시 page 파라미터 삽입
            if(this.props.navigate != undefined)
            {
                this.props.navigate({
                    pathname: window.location.pathname,
                    search: setParam({[URL_PARAM_KEY_PAGE]: page}),
                })
            }
        }
    }

    render()
    {
        //Download SVG icon from http://tabler-icons.io/i/chevron-left
        return <PageControlView state={this.state.data} onChange={this.props.onChange} onPageClick={this.onPageClick}/>
    }
}

type PageControlViewProps = {
    state: PaginationStateData,
    onChange: (page: number, event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<boolean>,
    onPageClick: (page: number) => void,
}
const PageControlView = ({state, ...props}: PageControlViewProps) =>
{
    return (
        <div className="d-flex align-items-center" style={{paddingTop: "10px", paddingBottom: "10px"}}>
            <ul className="pagination ms-auto">
                <li className={`page-item ${state.isPrevious ? null : "disabled"}`}>
                    <button className="page-link" aria-disabled="false" style={{cursor: "pointer"}}
                        onClick={async (event) =>
                        {
                            if (await props.onChange(state.page_previous, event))
                                props.onPageClick(state.page_previous);
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <polyline points="15 6 9 12 15 18"/>
                        </svg>
                        이전
                    </button>
                </li>
                &nbsp;
                {
                    state.pages.map((page: number, i: number) =>
                    (
                        <li key={"page_btn_" + page}
                            className={page === state.page ? "page-item active" : "page-item"}>
                            <button
                                onClick={async (event) =>
                                {
                                    if (page != state.page && await props.onChange(page, event))
                                        props.onPageClick(page);
                                }}
                                className="page-link" style={{cursor: "pointer"}}>{page}
                            </button>
                        </li>
                    ))
                }
                &nbsp;
                <li className={`page-item ${state.isNext ? null : "disabled"}`}>
                    <button
                        onClick={async (event) =>
                        {
                            if (await props.onChange(state.page_next, event))
                                props.onPageClick(state.page_next);
                        }}
                        className="page-link" style={{cursor: "pointer"}}>
                        다음
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <polyline points="9 6 15 12 9 18"/>
                        </svg>
                    </button>
                </li>
            </ul>
        </div>
    )
}


/**
 * 총 페이지 갯수를 계산합니다
 */
export function calcMaxPage(total: number, count: number)
{
    total = Math.max(total, 0);
    count = Math.max(count, 0);
    return Math.ceil(total / Math.max(count, 1));
}

/**
 * 불러올 실제 갯수를 계산합니다
 * @param total
 * @param page
 * @param count
 */
export function calcCount(total: number, page: number, count: number)
{
    total = Math.max(total, 0);
    count = Math.max(count, 0);
    page = Math.max(page, 1);

    const maxPage = Math.ceil(total / count);
    let result = 0;
    if (page < maxPage) result = count;
    else if (page == maxPage)
    {
        result = total % count;
        if (result == 0) result = count;
    }

    console.log(`total: ${total} / count: ${count} / page: ${page} / max: ${maxPage} / result: ${result}`);
    return result;
}

/**
 * 해당 페이지에 대한 오프셋을 계산합니다
 * @param total
 * @param page
 * @param count
 */
export function calcOffset(total: number, page: number, count: number)
{
    total = Math.max(total, 0);
    count = Math.max(count, 0);
    page = Math.max(page - 1, 0);

    const maxPage = Math.ceil(total / count);
    return page >= maxPage ? total : page * count;
}

/**
 * 현재 페이지를 가져옵니다
 */
export function getPage(total: number, count: number, page?: number): number
{
    let _page = 1;
    if (page == undefined)
    {
        //URL page 파라미터로 받은거 처리
        try
        { _page = parseInt(getParam(URL_PARAM_KEY_PAGE) ?? '1'); }
        catch
        { /*Empty*/ }
    } else _page = page;

    //최대 페이지보다 큰 페이지는 없소이다...
    _page = Math.min(_page, calcMaxPage(total, count));
    //1보다 작은 페이지는 없소이다....
    _page = Math.max(1, _page);

    return _page;
}
