import React, {Component, createRef, useEffect, useRef, useState} from "react";
import HSCalendar, {CalendarDay} from "lib/hs-calendar-react";
//import 'react-calendar/dist/Calendar.css'; // css import
//import ReactCalendar from "react-calendar";
//import TUICalendar from "@toast-ui/react-calendar";
//import '@toast-ui/calendar/dist/toastui-calendar.min.css';
//import 'tui-date-picker/dist/tui-date-picker.css';
//import 'tui-time-picker/dist/tui-time-picker.css';
import styles from '../style/Calendar.module.css';
import ScheduleData  from '../data/ScheduleData';
import {randomKey} from "@hskernel/hs-utils";
import CalendarView from "../component/CalendarView";
import {IconPlus,IconEdit,IconTrash, IconMinus} from '@tabler/icons-react';
import {dateFormat} from "utils/date.format";
import CalendarWrite from "../component/CalendarWrite";
import {ScheduleShareType} from "../Define";
import ScheduleDataBase from "../data/ScheduleDataBase";
import CalendarControl, {ICalendarControl} from "../component/CalendarControl";

type CalendarViewPageProps = {
    share?: ScheduleShareType,
}

type CalendarViewPageState = {
    isOpen : boolean,
    changeForm:boolean,
    date : Date,
}

const dayNames: [string, string, string, string, string, string, string] = ['일', '월', '화', '수', '목', '금', '토'];

const CalendarPage = ({share}: CalendarViewPageProps) =>
{
    const [date, setDate] = useState<Date>(new Date());
    const [isOpen, setIsOpen] = useState(true);

    const [showLunar, setShowLunar] = useState(true);
    const [showMD, setShowMD] = useState(false);

    const CalendarRef = useRef<ICalendarControl | null>(null);

    function CalendarClick(date: Date, sender: CalendarDay)
    {
        //Calendar 날짜 클릭시 일정 데이터 불러오기
        setDate(date);
        setIsOpen(true);
    }

    function onRefresh()
    {
        if (CalendarRef.current != null) CalendarRef.current.refresh();
    }

    return (
        <div className="card-body">
        <div className={styles.day_option} style={{display: "none"}}>
            <div className="row">
                <label className="form-check mb-0 col-auto">
                    <input className="form-check-input" type="checkbox" checked={showLunar} onChange={e => setShowLunar(e.target.checked) }/>
                    <span className="form-check-label" >음력 표시&nbsp;&nbsp;</span>
                </label>
                <label className="form-check mb-0 col">
                    <input type="checkbox" className="form-check-input" checked={showMD} onChange={e => setShowMD(e.target.checked) }/>
                    <span className="form-check-label">휴일 표시</span>
                </label>
            </div>
        </div>
        <br/>
        <div className="row">
            <div className="col-lg-8 col-md-12">
                <CalendarControl ref={CalendarRef} onClick={CalendarClick} showLunar={showLunar} showMemorialDay={showMD} share={share}/>
            </div>
            <CalendarViewer date={date} share={share} isOpen={isOpen} onClose={() => setIsOpen(false)} onRefresh={onRefresh}/>
            {/*<TUICalendar ref={this.calendar}
                                 view='month'
                                 height='600px'
                                 useFormPopup={false}
                                 usageStatistics={false}
                                 useDetailPopup={true}
                                 month={{dayNames: this.dayNames}}
                                 week={{dayNames: this.dayNames}}/>*/}


        </div>
        </div>)
}
export default CalendarPage;

type CalendarViewerProps = {
    date: Date,
    share?: ScheduleShareType
    isOpen?: boolean,
    onClose?: () => void,
    onRefresh?: () => void,
}
const CalendarViewer = ({date, share, ...props}: CalendarViewerProps) =>
{
    const [open, setOpen] = useState(props.isOpen ?? false);
    const [data, setData] = useState<ScheduleData[]>([]);
    const [busy, setBusy] = useState(false);
    const [writerBusy, setWriterBusy] = useState(false);
    const [isClick, setIsClick] = useState(false);

    async function refresh(date: Date)
    {
        if(props.onRefresh != null) props.onRefresh();

        setBusy(true);
        try
        {
            const data = await ScheduleData.GetSchedulesAsync(share ?? null, date.getFullYear(), date.getMonth() + 1, date.getDate(), true);
            setData(data ?? []);
        }
        finally { setBusy(false); }
    }

    useEffect(() => {refresh(date).then();}, [date]);
    useEffect(() => setOpen(props.isOpen ?? false), [props.isOpen]);

    function onClose()
    {
        setOpen(false);
        if(props.onClose) props.onClose();
    }

    function onCancel()
    {
        if(confirm("작성한 내용은 삭제됩니다."))
            setIsClick(false);
    }
    function onAddClick()
    {
        if(isClick) onCancel();
        else setIsClick(true);
    }

    return (
        <>
            <div className={styles.black_box} style={{display: open ? "block" : "none"}} onClick={onClose}>이 영역을 클릭해 창 닫기</div>
            <div className={`col-lg-4 col-md-12 ${styles.plan_box}`} style={{display: open ? "block" : "none"}}>
                <div className={styles.day_plan}>
                    <div className={styles.day_plan_head}>
                        <h3>{dateFormat(date, "yyyy년 mm월 dd일")}</h3><span>의 일정</span>
                        <div className={styles.button}>
                            <button onClick={onAddClick} disabled={writerBusy} className={styles.btn_plan_maker}>
                                {isClick ? <IconMinus stroke={2}/> : <IconPlus stroke={2}/>}
                            </button>
                        </div>
                    </div>
                    <div className={styles.day_plan_wrap}>
                        <ul className={styles.day_plan_ul}>
                            {isClick && <CalendarWrite start={date} onCancel={onCancel} onSave={onClose} onBusy={(isBusy) => setWriterBusy(isBusy)}/>}
                            {
                                busy ?
                                    <li className={styles.day_plan_li + " placeholder-glow"}>
                                        <div className={styles.day_plan_info}>
                                            <h4 className="placeholder" style={{display:"block"}}>.....</h4>
                                            <p className="placeholder" style={{display:"block"}}>.....</p>
                                            <div className={styles.location + " placeholder"} style={{display:"block"}}>......</div>
                                            <div className={styles.date_pick}><span className="placeholder" style={{display:"block"}}>.....</span>
                                            </div>
                                            {/*
                                                 <div className={styles.day_plan_control}>
                                                     <a><IconEdit stroke={1}/></a>&nbsp;&nbsp;
                                                     <a><IconTrash stroke={1}/></a>
                                                 </div>*/}
                                        </div>
                                    </li> :
                                    data.length == 0 ?
                                        <p style={{textAlign: 'center'}}>일정이 없습니다.</p>
                                        :
                                        data.map((data,i) => <CalendarView key={randomKey(i)} data={data} onChange={() => refresh(date).then()}/> )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
