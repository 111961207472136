import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "./context/AuthContext";
import * as serviceWorkerRegistration from './lib/serviceWorkerRegistration';
 import {BrowserRouter} from "react-router-dom";
import Init from "./Init";

/*
connection.start({ transport: ['webSockets','longPolling'] }).then(function () {
    console.log(connection);
}).catch(function (err) {
    return console.error(err.toString());
});
*/

//초기화
Init();

export const rootNode = document.getElementById('root');

ReactDOM.createRoot(rootNode).render(
    <AuthProvider>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
