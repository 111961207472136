import { Expose } from "class-transformer";
import {SendValueAsync, SendValueAsyncArrayType} from "API";
import axios from "axios";
import {PAGE_COUNT, URL_API_LOGIN_HISTORY} from "Define";

export default class LoginHistoryData
{
    @Expose() public ID: string;
    @Expose() public IP: string;
    @Expose() public Time: Date;
    @Expose() public Success: boolean;

    public static async GetListAsync(offset?: number, count?: number)
    {
        if(offset == null) offset = 0;
        if(count == null) count = PAGE_COUNT;
        return await SendValueAsyncArrayType<LoginHistoryData>(axios.get(`${URL_API_LOGIN_HISTORY}?offset=${offset}&count=${count}`), false, LoginHistoryData);
    }

    public static GetCountAsync = () => SendValueAsync<number>(axios.get(`${URL_API_LOGIN_HISTORY}/count`), false);
}