import React, {FormEvent, useEffect, useRef, useState} from 'react';
import {useAuth} from "context/AuthContext";
import ApprovalInsteadData from "../../data/ApprovalInsteadData";
import {dateFormat} from "utils/date.format";
import {ProcessErrorResult} from "API";
import UserSelectModal from "common/control/selector/UserSelectModal";
import UserDataBase from "data/API/User/UserDataBase";
import './InsteadSettingPage.css';

const InsteadSettingPage = () =>
{
    const {auth} = useAuth();
    const modal = useRef<UserSelectModal>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [user, setUser] = useState<UserDataBase | null>(null);
    const [StartDate, setStartDate] = useState<Date | null>(null);
    const [EndDate, setEndDate] = useState<Date | null>(null);
    const [Reason, setReason] = useState<string | null>(null);
    const [IsUse, setIsUse] = useState<boolean | null>(null);

    function setState(instead?: ApprovalInsteadData | null)
    {
        if(instead == null) instead = new ApprovalInsteadData();
        setUser(instead.Instead);
        setStartDate(instead.StartDate);
        setEndDate(instead.EndDate);
        setReason(instead.Reason);
        setIsUse(instead.IsUse);

        setIsLoading(false);
    }
    function refresh(){ setIsUse(true); ApprovalInsteadData.GetInsteadAsync().then((instead) => setState(instead)); }
    useEffect(() => refresh(), []);

    const onSubmit = (e: FormEvent<HTMLFormElement>) =>
    {
        e.preventDefault();

        const data = ApprovalInsteadData.FromFormData(new FormData(e.currentTarget));
        const message = data.Check();
        if(message == null)
        {
            setIsLoading(true);
            data.Submit()
                .catch((error: ProcessErrorResult) => alert(error.message))
                .then(() => refresh())
        }
        else alert(message);
    }

    const onDelete = () =>
    {
        if(confirm("정말 대결자 설정을 지우시겠습니까?"))
        {
            setIsLoading(true);
            ApprovalInsteadData.Delete().then(() => setState());
        }
    }

    const onModalOpen = () =>
    {
        if(modal.current != null) modal.current.openDialog((() =>
        {
            const tmp: UserDataBase[] = [];
            if(user != null) tmp.push(user);
            return tmp;
        })());
    }

    return isLoading || auth == null ?
        <div className="card-body">
            <div className="row">
                <form className="query-wrap">
                    <div className="card">
                        <div className="card-header">
                            대결자 설정
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">대결자</label>
                                        <div className="input-group mb-2">
                                            <div className="placeholder col-12 mb-3 mt-3"></div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">사유</label>
                                        <div className="placeholder col-12 mb-3 mt-3"></div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">활성화</label>
                                        <div>
                                            <label className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="IsUse" value="true"/>
                                                <span className="form-check-label placeholder">예</span>
                                            </label>
                                            <label className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="IsUse" value="false"/>
                                                <span className="form-check-label placeholder">아니오</span>
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">대결기간(시작)</label>
                                        <div className="placeholder col-12 mb-3 mt-3"></div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">대결기간(끝)</label>
                                        <div className="placeholder col-12 mb-3 mt-3"></div>
                                    </div>
                                    <div className="mb-3" style={{float: "right"}}>
                                        <div className="placeholder">총 기간: &nbsp;일</div>
                                        <div className="placeholder">D-Day: &nbsp;일</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="card-footer text-end">
                        </div>
                    </div>
                </form>

            </div>
        </div>
        :
        <div className="card-body">
            <UserSelectModal ref={modal} auth={auth} max={1} title="대결자 선택" onSave={(users) => setUser(users.length > 0 ? users[0] : null)}/>

                <form className="query-wrap" onSubmit={onSubmit}>
                    <div className="card">
                        <div className="card-header">
                            대결자 설정
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">대결자</label>
                                        <div className="input-group mb-2">
                                            <input type="text" className="form-control" value={user ? `[${user.DeptName}] ${user.toString()}` : ""} placeholder="대결자"/>
                                            <input type="hidden" name="InsteadLoginID" value={user ? user.LoginID : ""}/>
                                            <button className="btn" type="button" onClick={onModalOpen}>선택</button>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">사유</label>
                                        <input type="text" className="form-control" name="Reason" value={Reason || ""} maxLength={100} onChange={(e) => setReason(e.target.value)}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">활성화</label>
                                        <div>
                                            <label className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="IsUse" value="true" checked={IsUse == null ? false : IsUse} onChange={(e) => setIsUse(e.target.checked)}/>
                                                <span className="form-check-label">예</span>
                                            </label>
                                            <label className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="IsUse" value="false" checked={IsUse == null ? true : !IsUse}/>
                                                <span className="form-check-label">아니오</span>
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label">대결기간(시작)</label>
                                        <input type="datetime-local" name="StartDate" className="form-control" value={StartDate != null ? dateFormat(StartDate, "yyyy-mm-dd HH:MM") : ""} onChange={(e) => setStartDate(new Date(e.target.value))}/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">대결기간(끝)</label>
                                        <input type="datetime-local" name="EndDate" className="form-control" value={EndDate != null ? dateFormat(EndDate, "yyyy-mm-dd HH:MM") : ""} onChange={(e) => setEndDate(new Date(e.target.value))}/>
                                    </div>
                                    <div className="mb-3" style={{float: "right"}}>
                                        <div>총 기간: &nbsp;{StartDate != null && EndDate != null ? Math.round(EndDate.getDiffDay(StartDate)) : "-"} 일</div>
                                        <div>D-Day: &nbsp;{StartDate != null ? Math.round(StartDate.getDiffDay(new Date())) : "-"} 일</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="card-footer text-end">
                            <input type="submit" className="btn btn-primary" value="저장"/>&nbsp;&nbsp;
                            <input type="button" className="btn" value="지우기" onClick={onDelete}/>
                        </div>
                    </div>
                </form>
                {/*user == null ? "" : <UserSimpleViewer user={user}/>*/}
        </div>
}
export default InsteadSettingPage;