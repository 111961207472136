import React, {Component, useEffect, useState} from "react";
import {isEmptyString, randomKey} from "@hskernel/hs-utils";
import {NavigateFunction} from "react-router";
import qs from "qs";


/**
 * number: 인덱스, string: 값
 */
export type SelectOptionValue = number | string | undefined;

/**
 * 검색 타입
 */
export class SelectOptionData {
    constructor(Title: string, Value: string) {
        this.Title = Title;
        this.Value = Value;
    }

    public Title: string;
    public Value: string;

    public static getIndexFromValues(options: SelectOptionData[], value: string | undefined): number | undefined
    {
        if (value != null)
        {
            for (let i = 0; i < options.length; i++)
                if (options[i].Value == value) return i;
        }

        return undefined;
    }
}


const checkIndex = (options: SelectOptionData[], index: number) => index > -1 && index < options.length;
function initIndex(options: SelectOptionData[], value: SelectOptionValue, name?: string, navigate?: NavigateFunction): number
{
    if (options != null && options.length > 0 && value != null)
    {
        //값이 숫자 (인덱스) 이고, 범위 안에 있으면...
        if (typeof value === 'number')
        {
            if (value < options.length) return value;
        }
        //값이 문자열 (데이터) 이고, 목록 중 일치하면...
        else
        {
            for (let i = 0; i < options.length; i++)
                if (options[i].Value == value) return i;
        }

        if (name != null && navigate != null)
        {
            const query = qs.parse(location.search, { ignoreQueryPrefix: true });
            for (let i = 0; i < options.length; i++)
                if (options[i].Value == query[name]) return i;
        }
    }
    return -1;
}
function setURLParam(options: SelectOptionData[], index: number, navigate?: NavigateFunction, name?: string)
{
    if (navigate != null && !isEmptyString(name) && checkIndex(options, index))
    {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true });
        params[name!] = options[index].Value;
        navigate({ pathname: location.pathname, search: qs.stringify(params, { skipNulls: true })});
    }
}

type SelectOptionProps = {
    options: SelectOptionData[],
    onSelect: (value: SelectOptionData, index: number, name: string | undefined) => void | Promise<void>,
    /**
     * 컨트롤 이름 입니다 (undefined 면 값을 가져오거나 설정하지 않습니다)
     */
    name?: string,
    disabled?: boolean,
    value?: SelectOptionValue,
    /**
     * 네비게이션을 주면 해당 네비게이션에서 파라미터를 설정합니다
     */
    navigate?: NavigateFunction,
    /**
     * URL 파라미터에서 값을 가져 올지의 여부
     */
    initParam?: boolean,
    /**
     * 선택 관리 활성화 여부 입니다
     */
    manageSelect?: boolean,
}

type SelectOptionState = {
    index: number,
    isBusy: boolean,
}
export class SelectOptionClass extends Component<SelectOptionProps, SelectOptionState>
{
    constructor(props: SelectOptionProps) {
        super(props);

        const name = props.initParam ? props.name : undefined;
        const navigate = props.initParam ? props.navigate : undefined;
        this.state = {
            index: initIndex(props.options, props.value, name, navigate),
            isBusy: false,
        };

        this._onSelectAsync = this._onSelectAsync.bind(this);
        this.setSelectAsync = this.setSelectAsync.bind(this);
        this.getSelectValue = this.getSelectValue.bind(this);
        this.getSelectIndex = this.getSelectIndex.bind(this);
    }

    public setSelectAsync = (value: SelectOptionValue) => new Promise<void>(r => this.setState({...this.state, index: initIndex(this.props.options, value)}, r));
    public getSelectIndex = () => this.state.index;
    public getSelectValue = () => this.props.options[this.getSelectIndex()].Value;

    _onSelectAsync = async (indexString: string) =>
    {
        const index = parseInt(indexString);
        if (this.props.options != null && index >= 0)
        {
            await new Promise<void>(r => this.setState({...this.state, isBusy: true, index: index}, r));
            setURLParam(this.props.options, index, this.props.navigate, this.props.name);
            try { this.props.onSelect(this.props.options[index], index, this.props.name); }
            finally { await new Promise<void>(r => this.setState({...this.state, isBusy: false}, r)); }
        }
    }

    render()
    {
        return this.props.options != null && this.props.options.length > 0 ?
            <div className="col-auto" style={{paddingRight:"0",paddingLeft:"0.3rem"}}>
                {this.props.name != null && checkIndex(this.props.options, this.state.index) ? <input type="hidden" name={this.props.name} value={this.props.options[this.state.index].Value}/> : ''}
                <select className="form-select" style={{width: "100px!important"}} value={this.state.index} disabled={this.state.isBusy || this.props.disabled}
                        onChange={(e) => this._onSelectAsync(e.currentTarget.value).then()}>
                    {this.props.options.map((kind, i) => <option key={randomKey(i)} value={i} style={{textAlign: "left"}}>{kind.Title}</option>)}
                </select>
            </div>
            :
            <></>;
    }
}

const SelectOption = ({options, onSelect, ...props}: SelectOptionProps) =>
{
    const [index, setIndex] = useState<number>(-1);
    const [init, setInit] = useState(true);
    const [isBusy, setIsBusy] = useState(false);

    function selectIndex(index: number, setURL: boolean)
    {
        console.log(`SELECT: ${index} / NAME: ${props.name} / PVALUE: ${props.value}`)
        if (options != null)
        {
            setIndex(index);
            if (setURL) setURLParam(options, index, props.navigate, props.name);
            //kinds.map((_, i) => kinds[i].Select = i == index);
        }
    }

    useEffect(() =>
    {
        const name = init && props.initParam ? props.name : undefined;
        const navigate = init && props.initParam ? props.navigate : undefined;

        const aa = initIndex(options, props.value, name, navigate);
        selectIndex(aa, false);

        if (init) setInit(false);
    }, [props.value]);

    const _onSelect = (indexString: string) =>
    {
        const index = parseInt(indexString);
        if (options != null && index >= 0)
        {
            selectIndex(index, true);
            try
            {
                setIsBusy(true);
                onSelect(options[index], index, props.name);
            }
            finally { setIsBusy(false); }
        }
    }

    return options != null && options.length > 0 ?
        <div className="col-auto" style={{paddingRight:"0",paddingLeft:"0.3rem"}}>
            {props.name != null && checkIndex(options, index) ? <input type="hidden" name={props.name} value={options[index].Value}/> : ''}
            <select className="form-select" style={{width: "100px!important"}} value={index} disabled={isBusy || props.disabled}
                    onChange={(e) => _onSelect(e.currentTarget.value)}>
                {options.map((kind, i) => <option key={randomKey(i)} value={i} style={{textAlign: "left"}}>{kind.Title}</option>)}
            </select>
        </div>
        :
        <></>;
}

export default SelectOption;