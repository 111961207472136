import {isEmptyString, PathMaker, PathMakerMulti} from "@hskernel/hs-utils";
import {URL_API, URL_FRONT_MODULE} from "Define";

export const ID = "board";

export const URL_BOARD_ROOT = PathMaker(URL_FRONT_MODULE, ID);

export const URL_API_BOARD = URL_API + "/board";

export const MENU_BOARD_LIST = "";
export const MENU_BOARD_WRITE = "write";
export const MENU_BOARD_VIEW = '';
export const URL_BOARD_LIST = PathMaker(URL_BOARD_ROOT, MENU_BOARD_LIST);

//공지사항
export const BOARD_KIND_NOTICE = "notice";
//경조사
export const BOARD_KIND_EVENT_FAMILY = "event_family"
//동호회
export const BOARD_KIND_CLUB = "club";
//인사발령
export const BOARD_KIND_HR_PERSONAPP = "hr_personapp";
//전산문의
export const BOARD_KIND_QA_SYSTEM = "qa_system";
//더보기 컴포넌트
export const TEMP_COMPONENT = 'temp';

export function BoardKindtoName(BoardKind: string)
{
    switch (BoardKind)
    {
        case BOARD_KIND_NOTICE: return "공지사항";
        case BOARD_KIND_EVENT_FAMILY: return "경조사";
        case BOARD_KIND_CLUB: return "동호회";
        case BOARD_KIND_HR_PERSONAPP: return "인사발령";
        case BOARD_KIND_QA_SYSTEM: return "전산문의";
        default: return BoardKind;
    }
}

export const GetBoardURL = (BoardKind: string, BoardID?: string | number, param?: boolean) =>
{
    let url = PathMakerMulti(URL_BOARD_ROOT, BoardKind, BoardID == null ? "" : BoardID.toString());
    const search = document.location.search;
    if(param && !isEmptyString(search)) url += search;
    return url;
}
export const GetBoardWriteURL = (BoardKind: string, BoardID: string | number) => PathMakerMulti(URL_BOARD_ROOT, BoardKind, MENU_BOARD_WRITE, BoardID.toString());
export const GetAPIURL = (BoardKind: string, BoardID?: string | number) => PathMakerMulti(URL_API_BOARD, BoardKind, BoardID == null ? "" : BoardID.toString());
export const GetAPICountURL = (BoardKind: string) => PathMakerMulti(URL_API_BOARD, BoardKind, "count");

export const GetAPIFileDownURL = (BoardKind: string, Key: string) => PathMakerMulti(URL_API_BOARD, BoardKind, "file", Key);