import UserSettingsData from "./UserSettingsData";
import {Expose, plainToInstance} from "class-transformer";
import {SendValueAsyncType} from "API";
import axios, {formToJSON} from "axios";
import {URL_API_USER_SETTING} from "Define";

export default class UserSettingsAdminData extends UserSettingsData
{
    @Expose() public PasswordErrorCount?: number;
    @Expose() public SimplePWErrorCount?: number;
    @Expose() public ERPUse: boolean;
    @Expose() public EmailUse: boolean;
    @Expose() public UsageStop: boolean;

    public static fromForm(form: HTMLFormElement)
    {
        const obj = formToJSON(form);
        return plainToInstance<UserSettingsAdminData, any>(UserSettingsAdminData, obj, {
            excludeExtraneousValues: true,
            exposeUnsetFields: false,
        });
    }

    public SaveAdminAsync(LoginID: string)
    {
        return axios.post(`${URL_API_USER_SETTING}/${LoginID}`, this, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    public static GetSettingsAsync(LoginID: string): Promise<UserSettingsAdminData | null>
    {
        return SendValueAsyncType<UserSettingsAdminData>(axios.get(`${URL_API_USER_SETTING}/${LoginID}`), true, UserSettingsAdminData);
    }
}