import 'reflect-metadata';
import {Expose, plainToInstance,  Type} from "class-transformer";
import UserDataBase from "data/API/User/UserDataBase";
import {ProcessResponseError, SendValueAsync, SendValueAsyncType} from "API";
import axios from "axios";
import { URL_API_APPROVAL_INSTEAD} from "../Define";
import {isNullorWhiteSpace, serializeForm} from "utils/HSUtils";
import {IsBoolean} from "class-validator";

/**
 * 대결자 정보 데이터
 */
export default class ApprovalInsteadData
{
    /**
     * 사용여부
     */
    @Expose() @IsBoolean()
    public IsUse = false;
    /**
     * 시작일시
     */
    @Expose() @Type(() => Date)
    public StartDate: Date | null = null;
    /**
     * 종료일시
     */
    @Expose() @Type(() => Date)
    public EndDate: Date | null = null;
    /**
     * 대결자
     */
    @Expose() @Type(() => UserDataBase)
    public Instead: UserDataBase | null = null;
    /**
     * 대결자 [사용자 ID] (폼 전용)
     */
    @Expose() public InsteadLoginID: string | null = null;
    /**
     * 대결 사유
     */
    @Expose() public Reason: string | null = null;

    /**
     * 현재 시간으로 부터 남은 일수 계산
     */
    public getRemainDay = () => this.StartDate ? Math.round(this.StartDate.getDiffDay(new Date())) : 0;
    /**
     * 시작날짜와 끝날짜의 차이 일수 계산
     */
    public getDayDuration = () => this.StartDate && this.EndDate ? Math.round(this.StartDate.getDiffDay(this.EndDate)) : 0;

    public Check(): string | null
    {
        if (isNullorWhiteSpace(this.InsteadLoginID)) return "대결자를 지정해주세요";
        if (this.StartDate == null) return "시작날짜를 지정해 주세요"
        else if (this.EndDate == null) return "끝날짜를 지정해 주세요";
        else if (this.StartDate > this.EndDate) return "시작날짜는 끝날짜보다 클 수 없습니다";
        else if (isNullorWhiteSpace(this.Reason)) return "대결사유를 작성해주세요";
        return null;
    }

    public Submit = async () =>
    {
        const send = axios({
            method: "POST",
            url: URL_API_APPROVAL_INSTEAD,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: this
        });

        return new Promise<void>((resolve, reject) =>
        {
            send.catch((error: Error) => reject(ProcessResponseError(error, true, false)));
            send.then((response) => resolve(response.data));
        });
    }


    /**
     * 대결자 정보 지우기
     * @constructor
     */
    public static Delete = () => SendValueAsync(axios.delete(URL_API_APPROVAL_INSTEAD), true);
    
    public static GetInsteadAsync = () => SendValueAsyncType<ApprovalInsteadData>(axios.get(URL_API_APPROVAL_INSTEAD), false, ApprovalInsteadData)

    public static FromFormData(formData: FormData)
    {
        const obj = serializeForm(formData);
        return plainToInstance<ApprovalInsteadData, any>(ApprovalInsteadData, obj, {
            excludeExtraneousValues: true,
            exposeUnsetFields: false,
        })
    }
}