import {SendValueAsync, SendValueAsyncArrayType} from "API";
import axios from "axios";
import {LIST_COUNT, URL_API_APPROVAL_HANDOVER, URL_API_APPROVAL_HANDOVER_COUNT} from "../Define";
import {SearchWithDateRangeData} from "common/control/SearchWithDateRangeControl";

export default class ApprovalHandoverData
{


    public static async GetCount(search?: SearchWithDateRangeData)
    {
        const param = search ? search.getAPIParams() : [];
        console.log(search)
        console.log(param)
        return SendValueAsync<number>(axios.get(`${URL_API_APPROVAL_HANDOVER_COUNT}?${param.join('&')}`), false);
    }
    public static GetList(search: SearchWithDateRangeData, offset?: number | null, count?: number | null) : Promise<ApprovalHandoverData[] | null>
    {
        const param = search.getAPIParams()
        param.push(`offset=${offset ?? 0}`);
        param.push(`count=${count ?? LIST_COUNT}`);
        return SendValueAsyncArrayType<ApprovalHandoverData>(axios.get<ApprovalHandoverData[]>(`${URL_API_APPROVAL_HANDOVER}?${param.join('&')}`), false, ApprovalHandoverData);
    }
}