import React, { useState } from "react";
import RadioContext, {RadioContextProps} from "./RadioContext";

type RadioGroupProps = RadioContextProps & {
    label?: string,
    children?: JSX.Element | JSX.Element[],
    disabled?: boolean,
    /**
     * 자동으로 check 상태값을 변경할지 여부입니다
     */
    autoCheck?: boolean
}

const RadioGroup = ({label, children, ...props}: RadioGroupProps) =>
{
    if(props.autoCheck == null) props.autoCheck = props.value != null;

    const [value, setValue] = useState<string | undefined>(props.value);
    const onChange = !props.autoCheck ? props.onChange : (value: string) =>
    {
        setValue(value);
        if(props.onChange != null) props.onChange(value);
    }

    return (
        <fieldset>
            <legend>{label}</legend>
            <RadioContext.Provider value={{...props, onChange: onChange, value: value}}>{children}</RadioContext.Provider>
        </fieldset>
    );
}

export default RadioGroup;