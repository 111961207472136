import Modal from "react-modal";
import {Component} from "react";
import React from "react";
import DepartmentPickControl from "./index";
import {DepartmentData} from "data/API/DepartmentData";

type DepartmentPickModalProps = {
    onSelect?: (dept: DepartmentData) => void,
    deptCode?: string,
    immediately?: boolean,
}
type DepartmentPickModalState = {
    isOpen: boolean;
    dept?: DepartmentData,
    deptCode?: string,
}

export default class DepartmentPickModal extends Component<DepartmentPickModalProps, DepartmentPickModalState>
{
    constructor(props: DepartmentPickModalProps) {
        super(props);

        this.state = {
            isOpen: false,
            deptCode: props.deptCode,
        }

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    public openDialog(){ this.setState({...this.state, isOpen: true}); }
    public closeDialog(){ this.setState({...this.state, isOpen: false}); }

    private onSelect(e: DepartmentData | null)
    {
        this.closeDialog();
        if(e != null && this.props.onSelect) this.props.onSelect(e);
    }
    render() {
        const modalStyle = {
            content: {
                width:"340px",
                height:"fit-content",
                margin:"40px auto",
                background: "var(--tblr-bg-surface)"
            },
            overlay: {
                background: "rgba(0,0,0,0.5)",
                zIndex:"11"
            }
        }

        return (
            <Modal isOpen={this.state.isOpen} style={modalStyle}>
                <div className='card'>
                    <div className="card-header">
                        부서 선택
                    </div>
                    <div className="card-body" style={{overflowY:"scroll",height:"300px"}}>
                        <DepartmentPickControl deptCode={this.props.deptCode} onSelect={(dept, isInit) =>
                        {
                            if(!isInit)
                            {
                                if(this.props.immediately) this.onSelect(dept);
                                else this.setState({...this.state, dept: dept, deptCode: dept.DepartmentCode});
                            }
                        }}/>
                    </div>
                    <div className="card-footer">
                        {this.props.immediately ? "" : <button type="button" className="btn btn-primary" disabled={this.state.dept == null} onClick={() => this.onSelect(this.state.dept!)}>확인</button>}
                        &nbsp;&nbsp;
                        <button type="button" className="btn" onClick={() => this.closeDialog()}>취소</button>
                    </div>
                </div>

            </Modal>
        )
    }
}