import UserDataSimple from "data/API/User/UserDataSimple";
import LoginData from "./data/API/LoginData";
import CompanyData from "./data/API/CompanyData";
import {URL_FRONT_LOGIN, URL_FRONT_ERROR} from "./Define";
import axios, {AxiosError} from "axios";
import * as Define from "./Define";
import UserSettingsAdminData from "./data/API/User/UserSettingsAdminData";
import {getParam, isEmptyString, PathMaker} from "@hskernel/hs-utils";
import { NavigateFunction, Location } from "react-router";
import {plainToInstance} from "class-transformer";

export default class AuthData
{
    public user: UserDataSimple;
    public setting: UserSettingsAdminData;
    public login: LoginData;
    public company: CompanyData;
}

const AuthBypassPath: string[] = [
    URL_FRONT_LOGIN,
    URL_FRONT_ERROR
]


//현재 경로가 인증을 우회하는지 여부입니다
export function isAuthBypass(): boolean
{
    for (const bypass in AuthBypassPath) {
        if(bypass.startsWith(location.pathname)) return true;
    }

    return false;
}

/*
export function isLogin(): boolean
{
    Define.
}
*/

export async function getAuthData(location: Location<any>, navigate: NavigateFunction): Promise<AuthData | null>
{
    const SSOTokenKey = "sso_token";
    try
    {
        let header = {};
        const SSOToken = getParam(SSOTokenKey);
        if(!isEmptyString(SSOToken))
        {
            header = {...header, Authorization: `SSO ${SSOToken}`}
            //const response = await axios.post(`${URL_API_LOGIN_SSO}?LoginMode=${mode}`, form);
        }

        //const root = window.location.origin;
        const url = `${Define.URL_API_USER}?IsProfileImageThumb=true`;
        const response = await axios.get(url, {headers: header});

        if (response.data != null)
        {
            const data = plainToInstance<UserDataSimple, any>(UserDataSimple, response.data);
            if(!isEmptyString(SSOToken))
            {
                const params = new URLSearchParams(location.search);
                const params_new: string[] = [];
                params.forEach((value, name) =>
                {
                    if(name != SSOTokenKey) params_new.push(`${name}=${value}`)
                });

                navigate(`${location.pathname}?${params_new.joinEx("&")}`, {replace: true});

                //푸쉬 메세시 재등록 및 로그인 모드가 APP 이면 재 로그인
            }

            return {
                user: data,
                setting: (await UserSettingsAdminData.GetSettingsAsync(data.LoginID))!,
                login: LoginData.GetCurrentLoginData(),
                company: await CompanyData.GetCompany(),
            }
        }

        return null;
    }
    catch (ex)
    {
        if(ex instanceof AxiosError)
        {
            if (ex.code === 'ERR_NETWORK') alert("네트워크 연결을 확인해주세요");
            if (ex.response != null)
            {
                if (ex.response.status == 401) return null;
                else alert("로그인 확인에 실패하였습니다\n\n" + ex.message);
            }
        }

        console.error(ex);
        return null;
    }
}
