import UserDataSimple from "data/API/User/UserDataSimple";
import UserDataBase from "data/API/User/UserDataBase";
import {DefaultUserImage} from "Define";
import ImageViewerControl, {imgStyle} from "../ImageViewerControl";
import React from "react";
import {isEmptyString} from "@hskernel/hs-utils";

type UserImageViewerType = {
    user: UserDataSimple,
}

const UserImageViewer = ({user}: UserImageViewerType) =>
{
    return (user.ProfileImageThumb == null ?
            <ImageViewerControl src={UserDataBase.getImageThumbURL(user.LoginID)} defaultImage={DefaultUserImage} alt={user.Name ?? ""}/> :
            <img src={isEmptyString(user.ProfileImageThumb) ? DefaultUserImage : `data:image/*;base64,${user.ProfileImageThumb}`} style={imgStyle} alt={user.Name ?? ""}/>
    )
}

export default UserImageViewer;
