import React, {useEffect, useState} from "react";

type InputRadioYNProps = {
    name?: string,
    value?: boolean,
    onChange?: (value: boolean) => void
}

const InputRadioYN = (props: InputRadioYNProps) =>
{
    const [check, setCheck] = useState<boolean | undefined>(props.value);
    useEffect(() => { /*console.log(check);*/ if(check != undefined && props.onChange != null) props.onChange(check ?? false) }, [check])

    return (
        <div>
            <input type='hidden' name={props.name} value={ check == null ? undefined : (check ? "true" : "false") }/>
            <label className="form-check form-check-inline">
                <input type="radio" className="form-check-input" onChange={() => setCheck(true)} checked={check}/>
                <span className="form-check-label">예</span>
            </label>
            <label className="form-check form-check-inline">
                <input type="radio" className="form-check-input" onChange={() => setCheck(false)} checked={check == undefined ? undefined : !check}/>
                <span className="form-check-label">아니오</span>
            </label>
        </div>
    )
}

export default InputRadioYN;