import React, {Component, createRef, useEffect, useRef, useState} from "react";
import qs from "qs";
import DateRangeControl, {calcDate} from "common/control/DateRangeControl";
import SearchControl, {
    getDefaultSearch, getSearchOptionEventFromValue,
    getSearchOptionValueFromEvent, makeSearchKey,
    makeSearchOptionParamKey,
    makeSearchParamKey,
    SearchControlClass,
    SearchControlData,
    SearchOptionEvent,
    SearchOptionProps,
    SearchOptionValue,
    setSearchParam
} from "common/control/SearchControl";
import {isNullorWhiteSpace,} from "utils/HSUtils";
import {checkGetValue, dateFormat} from "@hskernel/hs-utils";
import {NavigateFunction} from "react-router-dom";
import {SelectOptionValue} from "../component/SelectOption";

const PARAM_SEARCH_DATE_START = 'start';
const PARAM_SEARCH_DATE_END = 'end';

export type DateRangeData = {
    start: Date | null,
    end: Date | null,
}
export type SearchWithDateRangeFullData = SearchControlData & DateRangeData;

export function makeDateRangeData(start: Date | undefined | null, end: Date | undefined | null): DateRangeData | undefined
{
    if (start === undefined && end === undefined) return undefined;
    return { start: start ?? null, end: end ?? null };
}

function getDateFromString(dateString?: any): Date | undefined
{
    if (dateString == null) return undefined;

    const date = new Date(dateString.toString());
    return date.toString() == 'Invalid Date' ? undefined : date;
}
export function getDefaultDateRangeData(name: string | undefined, start?: Date | null, end?: Date | null, ignoreURL = false): DateRangeData
{
    const data: DateRangeData = {
        start: start ?? null,
        end: end ?? null,
    };
    if (!ignoreURL)
    {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true });
        const _start = params[makeSearchKey(PARAM_SEARCH_DATE_START, name)];
        const _end = params[makeSearchKey(PARAM_SEARCH_DATE_END, name)];
        data.start = _start == 'null' ? null : checkGetValue(getDateFromString(_start), data.start, null);
        data.end = _end == 'null' ? null : checkGetValue(getDateFromString(_end), data.end, null);
    }
    return data;
}

export function DEFAULT_SEARCH_DATE_FULL(name: string | undefined, defaultDate: DateRangeData | undefined, options: SearchOptionProps[] | undefined, optionValues?: SelectOptionValue[], ignoreURL = false): SearchWithDateRangeFullData
{
    const _search = getDefaultSearch(name, undefined, options, optionValues, ignoreURL);
    const _date = getDefaultDateRangeData(name, defaultDate?.start, defaultDate?.end, ignoreURL);
    return {
        keyword: _search.keyword,
        start: _date.start,
        end: _date.end,
        options: _search.options
    }
}

/**
 *
 * @param data
 * @param name 컨트롤 이름
 * @param defaultOptionValues
 * @param defaultDate
 * @param Navigate
 * @param clearParam 검색 데이터가 바뀔 때 해당 URL 파라미터 키값을 지웁니다, null 일시 검색데이터 파라미터를 제외한 모든 키를 지웁니다
 */
export function setSearchWithDateRangeParam(data: SearchWithDateRangeFullData, name: string | undefined, defaultOptionValues?: SelectOptionValue[], defaultDate?: DateRangeData, Navigate?: NavigateFunction, clearParam?: string[] | null): qs.ParsedQs
{
    const params = setSearchParam({keyword: data.keyword ?? '', options: data.options}, name, defaultOptionValues, undefined, clearParam);

    let _start: string | null | undefined = data.start == null ? undefined : dateFormat(data.start, 'yyyy-mm-dd');
    let _end: string | null | undefined = data.end == null ? undefined : dateFormat(data.end, 'yyyy-mm-dd');
    if (defaultDate != undefined)
    {
        //기본은 파라미터가 존재하지 않으면 빈값, 그러나 defaultDate 의 값이 null 이면
        //
        const _start_default = defaultDate.start == null ? null : dateFormat(defaultDate.start, 'yyyy-mm-dd');
        const _end_default = defaultDate.end == null ? null : dateFormat(defaultDate.end, 'yyyy-mm-dd');
        if (_start_default !== _start && data.start == null) _start = null;
        else if (_start_default == _start) _start = undefined;
        if (_end_default !== _end && data.end == null) _end = null;
        else if (_end_default == _end) _end = undefined;
    }

    params[makeSearchKey(PARAM_SEARCH_DATE_START, name)] = _start === null ? 'null' : _start;
    params[makeSearchKey(PARAM_SEARCH_DATE_END, name)] = _end === null ? 'null' : _end;

    if (Navigate != null) Navigate({ pathname: location.pathname, search: qs.stringify(params, { skipNulls: true })});
    return params;
}

export const CALC_DATE = (name?: string, daysGap: number | undefined = 365, ignoreURL = false) =>
{
    if (daysGap != undefined)
    {
        const date = calcDate(daysGap);
        return getDefaultDateRangeData(name, date.start, date.end, ignoreURL);
    }

    return getDefaultDateRangeData(name, undefined, undefined, ignoreURL);
}

type SearchWithDateRangePropsBase = {
    name?: string,
    disabled?: boolean,
    dateRangeTitle: string,
    navigate?: NavigateFunction,
    onInit?: (data: SearchWithDateRangeFullData) => void | Promise<void>,
    onChange: (data: SearchWithDateRangeFullData) => void | Promise<void>,
    /**
     *
     * @param value
     * @return {boolean} 자동검색 활성화 시 true 면 자동검색, false 면 자동검색 안함
     */
    onSelectedOptions?: (seq: number, option: SearchOptionEvent) => boolean,
    /**
     * 초기화 시 URL 에서 파라미터 불러오기 / 설정하기 여부
     */
    initParam?: boolean,
    /**
     * 검색 데이터가 바뀔 때 해당 URL 파라미터 키값을 지웁니다, null 일시 검색데이터 파라미터를 제외한 모든 키를 지웁니다
     */
    clearParam?: string[] | null,
}
type SearchWithDateRangeProps = SearchWithDateRangePropsBase & {
    options?: SearchOptionProps[],
    keyword?: string | null,
    dateStart?: Date,
    dateEnd?: Date,
    optionValue?: SelectOptionValue[],
}
type SearchWithDateRangeClassProps = SearchWithDateRangePropsBase & {
    defaultOptions?: SearchOptionProps[],
    defaultKeyword?: string | null,
    defaultDateStart?: Date | null,
    defaultDateEnd?: Date | null,
    defaultOptionValues?: SelectOptionValue[],
}

type SearchWithDateRangeState = {
    isBusy: boolean,
    keyword: string | null,
    start: Date | null,
    end: Date | null,
    options?: SearchOptionProps[],
    optionValues: SearchOptionValue[],
    defaultOptionValues?: SearchOptionValue[],
}

export class SearchWithDateRangeControlClass extends Component<SearchWithDateRangeClassProps, SearchWithDateRangeState>
{
    searchControl = createRef<SearchControlClass>();

    constructor(props: SearchWithDateRangeClassProps) {
        super(props);

        const _search = getDefaultSearch(props.name, props.defaultKeyword, props.defaultOptions, props.defaultOptionValues, props.initParam == false);
        const _date = getDefaultDateRangeData(props.name, props.defaultDateStart, props.defaultDateEnd, props.initParam == false);
        const _optionValues = _search.options.map((o) => o?.value);

        this.state = {
            keyword: _search.keyword,
            options: props.defaultOptions,
            optionValues: _optionValues,
            defaultOptionValues: _optionValues,
            start: _date.start ?? null,
            end: _date.end ?? null,
            isBusy: false,
        }

        this.onSearchAsync = this.onSearchAsync.bind(this);

        if (props.onInit != null) props.onInit({keyword: _search.keyword, options: _search.options, start: _date.start, end: _date.end});
    }

    setBusyAsync = async (isBusy: boolean) => await new Promise<void>((r) => this.setState({...this.state, isBusy: isBusy}, r));
    getKeyword = () => this.searchControl.current?.getKeyword() ?? this.state.keyword;
    getDefaultDate = () => makeDateRangeData(this.props.defaultDateStart, this.props.defaultDateEnd);

    async onSearchAsync(keyword?: string | null, optionEvents?: SearchOptionEvent[], start?: Date | null, end?: Date | null)
    {
        const data: SearchWithDateRangeFullData = {
            keyword: checkGetValue(keyword, this.state.keyword, null),
            options: optionEvents ?? getSearchOptionEventFromValue(this.props.defaultOptions, this.state.optionValues),
            start: checkGetValue(start, this.state.start, null),
            end: checkGetValue(end, this.state.end, null),
        }

        await new Promise<void>(r => this.setState({...this.state, keyword: data.keyword, start: data.start ?? null, end: data.end ?? null, optionValues: getSearchOptionValueFromEvent(data.options)}, r));

        if (this.props.onChange != null) await Promise.resolve(this.props.onChange(data));

        if (this.props.navigate != null) setSearchWithDateRangeParam(data, this.props.name, this.props.defaultOptionValues, this.getDefaultDate(), this.props.navigate, this.props.clearParam);
    }


    /**
     * 검색 옵션을 변경합니다
     * @param options
     * @param defaultOptionValues
     * @param optionValues
     */
    public async setOptionsAsync(options: SearchOptionProps[] | null | undefined, defaultOptionValues?: SearchOptionValue[] | null, optionValues?: SearchOptionValue[] | null)
    {
        const _options = checkGetValue(options, this.state.options, undefined);
        const _defaultOptionValues = checkGetValue(defaultOptionValues, this.state.defaultOptionValues, undefined);
        const _optionValues = checkGetValue(optionValues, this.state.optionValues, _defaultOptionValues);

        const _state = getDefaultSearch(this.props.name, this.state.keyword, _options, _defaultOptionValues, true);
        const _newOptionValues = getSearchOptionEventFromValue(_options, _optionValues);

        const newDefaultOptionValues = getSearchOptionValueFromEvent(_state.options);
        const newOptionValues = getSearchOptionValueFromEvent(_newOptionValues);
        await new Promise<void>(r => this.setState({...this.state, options: _options, defaultOptionValues: newDefaultOptionValues, optionValues: newOptionValues}, r));

        if (this.searchControl.current != null) await this.searchControl.current.setOptionsAsync(options, optionValues);

        if (this.props.navigate != null)
        {
            const fullData: SearchWithDateRangeFullData = {
                ..._state,
                start: this.state.start,
                end: this.state.end,
            }
            setSearchWithDateRangeParam(fullData, this.props.name, newOptionValues, this.getDefaultDate(), this.props.navigate, this.props.clearParam);
        }

        return newOptionValues;
    }

    public async setDataAsync(keyword?: string | null, optionValues?: SearchOptionValue[] | null, start?: Date | null, end?: Date | null)
    {
        const _keyword = checkGetValue(keyword, this.getKeyword(), undefined);
        const _start = checkGetValue(start, this.state.start, null);
        const _end = checkGetValue(end, this.state.end, null);
        const _optionValues = checkGetValue(optionValues, this.state.optionValues, []);

        const _state = getDefaultSearch(this.props.name, _keyword, this.props.defaultOptions, _optionValues, true);
        const data: SearchWithDateRangeFullData = {
            keyword: _state.keyword,
            options: _state.options,
            start: _start,
            end: _end,
        }

        await new Promise<void>(r => this.setState({...this.state, keyword: _state.keyword, start: _start, end: _end, optionValues: getSearchOptionValueFromEvent(_state.options)}, r));

        if (this.searchControl.current != null) await this.searchControl.current.setDataAsync(keyword, optionValues);

        if (this.props.navigate != null) setSearchWithDateRangeParam(data, this.props.name, this.props.defaultOptionValues, this.getDefaultDate(), this.props.navigate);

        return data;
    }

    public clearDataAsync = () => this.setDataAsync(null, null, null, null);

    render() {
        return (
            <div className="card-body">
                <div className="row">
                    <DateRangeControl
                        disabled={this.state.isBusy || this.props.disabled}
                        start={this.state.start} end={this.state.end}
                        startTitle={`${this.props.dateRangeTitle}(시작)`}
                        endTitle={`${this.props.dateRangeTitle}(끝)`}
                        onChange={(start, end) => this.onSearchAsync(undefined, undefined, start ?? null, end ?? null)}/>
                </div>
                <br/>
                <div className="row">
                    <SearchControlClass
                        ref={this.searchControl}
                        name={this.props.name}
                        disabled={this.state.isBusy || this.props.disabled}
                        defaultKeyword={this.state.keyword}
                        defaultOptions={this.state.options}
                        defaultOptionValue={this.state.optionValues}
                        onSearch={(keyword, optionsValues) => this.onSearchAsync(keyword, optionsValues, undefined, undefined)}
                        onSelectedOptions={this.props.onSelectedOptions}
                        initParam={false}
                        colWidth={6}/>
                </div>
            </div>
        )
    }
}

const SearchWithDateRangeControl = (props: SearchWithDateRangeProps) =>
{
    const [isBusy, setIsBusy] = useState(false);

    const [init, setInit] = useState(true);
    const [date, setDate] = useState<DateRangeData>(getDefaultDateRangeData(props.name));
    const [keyword, setKeyword] = useState<string | null>(props.keyword ?? null);
    const [optionEvents, setOptionEvents] = useState<SearchOptionEvent[]>([]);

    //const [kinds, setKinds] = useState<SearchKindOption | undefined>();
    //const [kindsAdd, setKindsAdd] = useState<SearchKindOption[]>([]);
    function onSearch(_start?: Date | null, _end?: Date | null, _keyword?: string | null, _optionEvents?: SearchOptionEvent[])
    {
        //const _optionValues = getSearchOptionValueFromEvent(optionEvents);
        const date = getDefaultDateRangeData(props.name, _start, _end, true);

        const fullData: SearchWithDateRangeFullData =
        {
            keyword: checkGetValue(_keyword, keyword, null),
            start: checkGetValue(_start, date.start, null),
            end: checkGetValue(_end, date.end, null),
            options: _optionEvents ?? optionEvents,
        };

        setKeyword(fullData.keyword);
        setDate(date);

        setIsBusy(true);
        Promise.resolve(props.onChange(fullData)).then()
            .finally(() =>
            {
                setIsBusy(false);
                if (props.navigate != null)
                {
                    const searchState = setSearchWithDateRangeParam(fullData, props.name, props.optionValue, undefined, props.navigate, props.clearParam);
                    props.navigate({ pathname: location.pathname, search: qs.stringify(searchState, { skipNulls: true })});
                }
            })
    }

    function onSelectedOptions(seq: number, optionEvent: SearchOptionEvent)
    {
        const _optionEvents = [...optionEvents];
        _optionEvents[seq] = optionEvent;
        setOptionEvents(_optionEvents);
        return props.onSelectedOptions == null ? true : props.onSelectedOptions(seq, optionEvent);
    }

    useEffect(() =>
    {
        const date = getDefaultDateRangeData(props.name, props.dateStart, props.dateEnd, !init);
        const searchData = getDefaultSearch(props.name, props.keyword, props.options, props.optionValue, !init);

        setDate(date);
        setKeyword(searchData.keyword);
        setOptionEvents(searchData.options);

        if (init)
        {
            setInit(false);
        }
        else
        {
            if (props.navigate != null)
            {
                const fullData: SearchWithDateRangeFullData = {keyword: searchData.keyword, start: date.start, end: date.end, options: optionEvents};
                const searchState = setSearchWithDateRangeParam(fullData, props.name, props.optionValue, undefined, props.navigate, props.clearParam);
                props.navigate({ pathname: location.pathname, search: qs.stringify(searchState, { skipNulls: true })});
            }
        }
    }, [props.options, props.keyword, props.optionValue]);

    //useEffect(() => console.log(kinds), [kinds]);
    //useEffect(() => console.log(kindsAdd), [kindsAdd]);

    return (
        <div className="card-body">
            <div className="row">
                <DateRangeControl
                    disabled={isBusy || props.disabled}
                    start={date.start} end={date.end}
                    startTitle={`${props.dateRangeTitle}(시작)`}
                    endTitle={`${props.dateRangeTitle}(끝)`}
                    onChange={(start, end) => onSearch(start ?? null, end ?? null, undefined, undefined)}/>
            </div>
            <br/>
            <div className="row">
                <SearchControl
                    name={props.name}
                    disabled={isBusy || props.disabled}
                    keyword={keyword}
                    options={props.options}
                    optionValue={getSearchOptionValueFromEvent(optionEvents)}
                    onSearch={(keyword, optionsValues) => onSearch(undefined, undefined, keyword, optionsValues)}
                    onSelectedOptions={onSelectedOptions}
                    initParam={false}
                    colWidth={6}/>
            </div>
        </div>
    )
}

export default SearchWithDateRangeControl;