import React, {useEffect} from "react";
import UserViewerControl from "component/user/UserViewerControl";
import {useAuth} from "context/AuthContext";

const UserInfoPage = () =>
{
    const {auth} = useAuth();

    useEffect(() => {
        //TODO: 나중에 비밀번호 입력 구현
    }, []);

    return auth == null ?
        <h1>잘못된 접근 입니다</h1> :
        <UserViewerControl title="마이페이지" user={auth.user}/>
}

export default UserInfoPage;