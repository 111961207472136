import React from "react";
import TreeView from "../common/component/TreeView";
import TreeViewControl from "../common/control/TreeViewControl";
import TreeViewData from "../common/control/TreeViewControl/Data/TreeViewData";

const data: TreeViewData<any>[] = [
    {
        Title: '(주)Test',
        Data: '010',
        Child: [{
            Title: 'DDDD',
            Data: '010156'
        }],
    },
    {
        Title: '(주)해성아이다',
        Data: '100',
        Child: [{
            Title: '사장',
            Data: '100200',
        },
        {
            Title: '관리총괄',
            Data: '100300',
            Child: [{
                Title: '비서실',
                Data: '100300100'
            }],
        },
        {
            Title: '관리본부',
            Data: '100400',
            Child: [{
                Title: '인사총무팀',
                Data: '100400100'
            },
            {
                Title: '재무회계팀',
                Data: '100400200'
            },
            {
                Title: '구매팀',
                Data: '100400300'
            }]
        },
        {
            Title: '생산본부',
            Data: '100500'
        }]
    }
]

const TreeViewTest = () =>
{
    return <TreeViewControl data={data}/>
}

export default TreeViewTest;
