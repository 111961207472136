import ErrorPageBase, {ErrorPageParam} from "./Base";

export default class ErrorPage502 extends ErrorPageBase
{
    constructor(props?: ErrorPageParam)
    {
        super({
            errorCode: '502',
            title: props?.title ?? '서버를 찾을 수 없습니다',
            message: props?.message,
            hideBack: props?.hideBack ?? true,
            hideHome: props?.hideHome,
        });
    }
}