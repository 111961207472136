import axios, {AxiosInstance, AxiosRequestHeaders} from "axios";
import "url-search-params-polyfill";
import StatusEmptyIcon from "./img/status-empty.png";

/**
 * 파일을 비동기식으로 읽습니다
 * @param {File} file
 * @return {Promise<string | null>}
 * @constructor
 */
function readFileAsync(file)
{
    return new Promise(function (resolve, reject)
    {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
        {
            if(reader.result == null) resolve(null);
            else if(typeof reader.result == "string") resolve(reader.result);
            else if(typeof reader.result == "object") resolve(ArrayToBase64(reader.result));
        }
        reader.onerror = (error) => reject(error);
    });
}

File.prototype.readFileAsync = function (){ return readFileAsync(this); }

/**
 * https://stackoverflow.com/a/55348259/7080663
 * @param {number} ms
 * @returns {Promise<void>}
 */
export const sleep = (ms) => new Promise(function(resolve){setTimeout(resolve, ms);})

/**
 *
 * @param {ArrayLike<number> | ArrayBufferLike} data
 * @return {string}
 * @constructor
 */
function ArrayToBase64(data)
{
    let binary = '';
    const bytes = new Uint8Array(data);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
}

/**
 *
 * @param {...{string: any}[]} params
 * @returns {string}
 */
export function setParam(...params)
{
    const authResult = new URLSearchParams(window.location.search);
    for(let i = 0; i < params.length; i++)
    {
        const key = Object.keys(params[i])[0];
        authResult.set(key, params[i][key]);
    }
    return authResult.toString();
}

/**
 *
 * @param {string} text 텍스트
 * @param {string} font '폰트 (예: '30px Arial')
 * @param {number} x
 * @param {number} y
 */
export function textToImageBase64(text, font, x, y)
{
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.font = font;
    ctx.fillText(text, x, y);
    return canvas.toDataURL();
}

/**
 *
 * @param {string} font '30px Arial'
 * @param {number} x
 * @param {number} y
 */
String.prototype.toImageBase64 = (font, x, y) => textToImageBase64(this, font, x, y);


/**
 * Remove the functions from the state. They can't been pushed into the history.
 * https://localcoder.org/uncaught-domexception-failed-to-execute-pushstate-on-history-function
 */
export function cleanStateForHistory(state)
{
    const stateWithNoFunctions = {};
    for (const key of Object.keys(state)) {
        if (typeof key !== "function") {
            stateWithNoFunctions[key] = state[key];
        }
    }
    return stateWithNoFunctions;
}

//Axios
/**
 * https://stackoverflow.com/questions/70689305/customizing-date-serialization-in-axios
 * @param {*} data
 * @returns {*}
 * @constructs
 */
export function dateTransformerRequest(data)
{
    if (data instanceof Date) {
        // do your specific formatting here
        return data.toJSON()
    }
    if (Array.isArray(data)) {
        return data.map(dateTransformerRequest)
    }
    if (typeof data === 'object' && data !== null) {
        return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, dateTransformerRequest(value)]))
    }
    return data;
}
/**
 * https://stackoverflow.com/questions/73044657/how-to-cast-parse-axios-rest-response-field-from-string-to-date-within-react
 * @param {*} data
 * @param {axios.AxiosRequestHeaders} headers
 * @return {* | Date}
 */
export function dateTransformerResponse(data, headers)
{
    if(headers["Content-Type"] === "application/json")
    {
        console.log(data);
        const dateKeyRx = /date/i;

        return JSON.parse(data, (key, value) =>
            dateKeyRx.test(key) ? new Date(value) : value
        );
    }

    return data;
}

/**
 *
 * @returns {axios.AxiosInstance}
 */
export function axiosHS()
{
    return axios.create({
        //transformRequest: [dateTransformerRequest, ...(axios.defaults.transformRequest)],
        transformResponse: [dateTransformerResponse, ...(axios.defaults.transformResponse)]
    });
}

/**
 *
 * @param {string} src
 * @returns {Promise<HTMLImageElement>}
 */
export function getImageAsync(src)
{
    return new Promise((resolve, reject) =>
    {
        const img = new Image();
        img.onload = () => resolve(img);
        img.oncancel = () => reject();
        img.src = StatusEmptyIcon;
    });
}