//https://junheedot.tistory.com/entry/%EC%98%88%EC%A0%9C%EB%A1%9C-%EB%B0%B0%EC%9A%B0%EB%8A%94-react-context

import React, {useCallback, useEffect, useState} from "react";
import AuthData from "../Auth";
import LoginData from "data/API/LoginData";
import UserDataSimple from "data/API/User/UserDataSimple";
import {parseBoolean} from "../utils/HSUtils";
import UserSettingsAdminData from "../data/API/User/UserSettingsAdminData";
import axios from "axios";
import {HEADER_ADMIN} from "../Define";
import ModuleMenuProperty from "../data/API/Module/Menu/ModuleMenuProperty";

/**
 * 인증 컨텍스트 유형 지장
 */
export interface AuthContextType {
    auth: AuthData | null;
    admin: boolean;
    menuProperty: ModuleMenuProperty;
    setAuth: (auth: AuthData | null) => void;
    setSetting: (setting: UserSettingsAdminData) => void;
    setUser: (user: UserDataSimple) => void;
    setLogin: (login: LoginData) => void;
    setAdmin: (admin: boolean) => boolean;
    setMenuProperty: (menu: ModuleMenuProperty) => void;
}

/**
 * 인증 컨텍스트 기본값 설정
 */
/* eslint @typescript-eslint/no-empty-function: "off" */
export const AuthContextDefault: AuthContextType =
{
    auth: null,
    admin: false,
    menuProperty: new ModuleMenuProperty(),
    setAuth: (auth: AuthData | null) => {},
    setSetting: (setting: UserSettingsAdminData) => {},
    setUser: (user: UserDataSimple) => {},
    setLogin: (login: LoginData) => {},
    setAdmin: (admin: boolean) => false,
    setMenuProperty: (menu: ModuleMenuProperty) => {},
}

/**
 * 인증 컨텍스트 생성
 */
export const AuthContext = React.createContext<AuthContextType>(AuthContextDefault);

/**
 * 인증 컨텍스트 훅으로 사용될 수 있게
 */
export function useAuth() { return React.useContext(AuthContext); }

export const AuthProvider = ({ children }: any) =>
{
    const KEY_ADMIN = "AdminMode";
    const getAdminMode = () => parseBoolean(localStorage.getItem(KEY_ADMIN));
    const setAdminMode = (admin: boolean) => localStorage.setItem(KEY_ADMIN, admin ? "true" : "false");

    const getIsAdmin = (admin: boolean, auth?: AuthData | null) => admin && auth != null && auth.user.IsAdmin;

    const [_auth, _setAuth] = useState<AuthData | null>(AuthContextDefault.auth);
    const [_admin, _setAdmin] = useState<boolean>(false);
    const [_menu, _setMenu] = useState<ModuleMenuProperty>(ModuleMenuProperty.GetDefault());
    const setAuth = useCallback((auth: AuthData | null) =>
    {
        _setAuth(auth);
        setAdmin(getAdminMode(), auth, false);
    }, []);

    /**
     * 
     * @param {boolean} admin
     * @param {AuthData | null} auth
     * @param {boolean} showMessage
     * @returns {boolean} 관리자 모드 전환 성공 여부
     */
    const setAdmin = (admin: boolean, auth?: AuthData | null, showMessage?: boolean) =>
    {
        let success = false;

        auth = auth ?? _auth;
        const isAdmin = getIsAdmin(admin, auth);
        if(admin)
        {
            if(isAdmin) success = true;
            else if(showMessage == null || showMessage) alert("관리자 권한이 부여되지않은 사용자입니다");
        }
        else success = true;

        _setAdmin(isAdmin);
        setAdminMode(isAdmin);
        axios.defaults.headers.common[HEADER_ADMIN] = `${isAdmin}`;
        return success;
    };

    const setUser = useCallback((user: UserDataSimple) => { if(_auth) _setAuth({..._auth, user: user}) }, [_auth]);
    const setLogin = useCallback((login: LoginData) => { if(_auth) _setAuth({..._auth!, login: login}) }, [_auth]);
    const setSetting = useCallback((setting: UserSettingsAdminData) => { if(_auth) _setAuth({..._auth!, setting: setting}) }, [_auth]);

    const setMenuProperty = useCallback((menu: ModuleMenuProperty) => _setMenu(menu), [_menu]);

    const contextValue: AuthContextType = {
        auth: _auth,
        admin: _admin,
        menuProperty: _menu,
        setAdmin: useCallback((admin: boolean) => setAdmin(admin, _auth, true), [_auth]),
        setAuth: setAuth,
        setUser: setUser,
        setSetting: setSetting,
        setLogin: setLogin,
        setMenuProperty: setMenuProperty,
    };

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}