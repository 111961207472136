import Modal from "react-modal";
import React, {Component, createRef, useRef, useState} from "react";
import {isEmptyString} from "../../utils/HSUtils";

type PasswordInputState = {
    isOpen?: boolean,
    data?: any | null,
}

type PasswordInputProps = PasswordInputState & {
    showPassword: boolean
    onOK: (password: string, comment: string | null, data?: any | null) => boolean |  Promise<boolean>,
    onCancel?: (comment: string | null) => void,
    showComment?: boolean,
    requireComment?: boolean,
    comment?: string | null,
}

export default class PasswordInputModal extends Component<PasswordInputProps, PasswordInputState>
{
    constructor(props: PasswordInputProps) {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.state = {...props}
    }
    
    public openDialog = (isOpen: boolean, data?: any | null) => this.setState({isOpen: isOpen, data: data});

    render()
    {
        const modalStyle =
        {
            content: {
                width: "320px",
                height: "380px",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                background:"var(--tblr-bg-surface)"
            },
            overlay: {
                background: "rgba(0,0,0,0.5)",
                zIndex:"9"
            }
        }
        return (
            <Modal style={modalStyle} isOpen={this.state.isOpen ?? false} ariaHideApp={false}>
                <PasswordInputInner {...this.props} data={this.state.data} onClose={() => this.setState({...this.state, isOpen: false})}/>
            </Modal>
        );
    }
}

type PasswordInputInnerType = PasswordInputProps & {
    onClose: () => void
}

const PasswordInputInner = (props: PasswordInputInnerType) =>
{
    const [busy, setBusy] = useState(false);
    const [comment, setComment] = useState(props.comment);
    const input = useRef<HTMLInputElement | null>(null);

    const onOK = async () =>
    {
        const password = input.current == null ? "" : input.current.value;
        if(props.showPassword && isEmptyString(password)) alert("비밀번호를 입력해 주세요");
        else if(props.requireComment && isEmptyString(comment)) alert("의견은 필수입니다");
        else
        {
            setBusy(true);
            try
            {
                if(await props.onOK(password, comment ?? null, props.data))
                    props.onClose();
            }
            finally { setBusy(false); }
        }
    }
    async function onEnter(e: React.KeyboardEvent<HTMLInputElement>)
    {
        const key = e.key || e.keyCode;
        if (key === 'Enter' || key === 13) await onOK();
    }

    function getDialogTitle()
    {
        if(props.showPassword) return "비밀번호를 입력해주세요";
        else if(props.showComment) return "의견을 입력해 주세요";
        else return "확인해 주세요";
    }
    
    return (
        <>
            <h2 className="h2 text-center mb-4 mt-6">{getDialogTitle()}</h2>
            <div className="mb-2">
                {props.showComment ?
                    <>
                        <div className="input-group input-group-flat">
                            <textarea className="form-control" placeholder={`${props.requireComment ? "" : "필요시 "}의견 입력 바랍니다`} maxLength={1000} autoFocus={!props.showPassword} defaultValue={props.comment ?? ""}
                                      onChange={(e) => setComment(e.target.value)}/>
                        </div>
                    </> : ""
                }
                {props.showPassword ?
                    <div className="input-group input-group-flat">
                        <input ref={input} type="password" className="form-control" placeholder="비밀번호 입력" autoFocus={true} onKeyDown={onEnter}/>
                    </div> : ""
                }
            </div>
            <div className="mt-3">
                <button type="submit" className="btn btn-primary w-100" disabled={busy} onClick={onOK}>확인</button>
                <button type="button" className="btn mt-2 w-100" disabled={busy}
                        onClick={() => { props.onClose(); if(props.onCancel != null) props.onCancel(comment ?? null); }}
                        style={{textAlign:"center", color:"#206bc4",cursor:"pointer"}}>취소</button>
            </div>
        </>
    )
}