import {Expose} from "class-transformer";
import ApprovalDocumentBaseSimple from "./ApprovalDocumentBaseSimple";

export default class ApprovalDocumentERPBase extends ApprovalDocumentBaseSimple
{
    /**
     * ERP 연동 문서코드
     */
    @Expose() public ERPDocumentID: string;

    /**
     *
     * @type {number | null} 공급가액
     */
    @Expose() public SupplyAMT: number | null;
    /**
     *
     * @type {number | null} 부가세액
     */
    @Expose() public SupplyVAT: number | null;
    /**
     *
     * @type {number | null} 공급대가
     */
    @Expose() public SupplyTOT: number | null;
}