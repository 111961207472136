import React, {useEffect, useRef, useState} from "react";
import SearchWithDateRangeControl, {
    DEFAULT_SEARCH_DATE_FULL,
    SearchWithDateRangeControlClass, SearchWithDateRangeFullData
} from "../common/control/SearchWithDateRangeControl";
import {
    SEARCH_KIND_DOC,
    SEARCH_KIND_TITLE,
    SEARCH_KIND_WRITER
} from "../modules/approval/data/ApprovalDocumentListData";
import {useNavigate} from "react-router-dom";
import {SelectOptionData, SelectOptionValue} from "../common/component/SelectOption";
import {
    getDefaultSearch,
    getSearchOptionValueFromEvent, SearchControlClass, SearchOptionEvent,
    SearchOptionProps,
    SearchOptionValue
} from "../common/control/SearchControl";
import {getDateString} from "../Define";

const SearchDateTest = () =>
{
    const name = undefined;
    const searchKind: SearchOptionProps[] = [{
            autoSearch: false,
            options: [
                new SelectOptionData("제목", SEARCH_KIND_TITLE),
                new SelectOptionData("기안자", SEARCH_KIND_WRITER),
                new SelectOptionData("문서번호", SEARCH_KIND_DOC),
            ]
        },
        {
            autoSearch: false,
            options: [
                new SelectOptionData("A", 'AAA'),
                new SelectOptionData("B", 'BBB'),
                new SelectOptionData("C", 'CCC'),
            ]
        },
        {
            autoSearch: true,
            options: [
                new SelectOptionData("D", 'DDD'),
                new SelectOptionData("E", 'EEE'),
                new SelectOptionData("F", 'FFF'),
            ]
        },
    ];
    const searchKindDefaults = [
        searchKind[0].options[1].Value,
        searchKind[1].options[0].Value,
        searchKind[2].options[0].Value,
    ]

    const defaultSearch = DEFAULT_SEARCH_DATE_FULL(name, undefined, searchKind);
    const searchControl = useRef<SearchWithDateRangeControlClass | null>(null);
    const [keyword, setKeyword] = useState<string | null>(defaultSearch.keyword);
    const [start, setStart] = useState<Date | null>(defaultSearch.start);
    const [end, setEnd] = useState<Date | null>(defaultSearch.end);


    const [options, setOptions] = useState<SearchOptionProps[]>(searchKind);
    const [optionDefault, setOptionDefault] = useState<SelectOptionValue[]>(searchKindDefaults);
    const [optionValues, setOptionValues] = useState<SearchOptionValue[]>(getSearchOptionValueFromEvent(defaultSearch.options));

    const navigate = useNavigate();

    useEffect(() => {
        console.log(defaultSearch)
    }, []);

    async function forceSetOptions(options?: SearchOptionProps[] | null, defaultOptionValues?: SearchOptionValue[] | null)
    {
        if(searchControl.current != null)
        {
            await searchControl.current.setOptionsAsync(options, defaultOptionValues);
            const state = await searchControl.current.clearDataAsync();
            const optionValues = getSearchOptionValueFromEvent(state.options);
            setOptions(options ?? []);
            setOptionDefault(optionValues);
            setOptionValues(optionValues);
            setKeyword(state.keyword);
        }
    }

    return (
        <>
            <SearchWithDateRangeControlClass
                ref={searchControl}
                name={name} navigate={navigate} dateRangeTitle='시작일'
                defaultKeyword={keyword}
                defaultDateStart={defaultSearch.start}
                defaultDateEnd={defaultSearch.end}
                defaultOptions={searchKind}
                defaultOptionValues={searchKindDefaults}
                onSelectedOptions={(seq, event) => {
                    console.log(`${seq}: ${event?.value}`);
                    return false;
                }}
                onChange={(data) => {
                    setKeyword(data.keyword);
                    setStart(data.start);
                    setEnd(data.end);
                    setOptionValues(getSearchOptionValueFromEvent(data.options));
                }}/>
            <p>Keyword: {keyword}</p>
            <p>keywordKind: {optionValues[0]}</p>
            <p>Start: {getDateString(start)}</p>
            <p>End: {getDateString(end)}</p>
            <p>AddParam: {JSON.stringify(optionValues)}</p>

            <button onClick={() => {
                const data = DEFAULT_SEARCH_DATE_FULL(name, undefined, options, undefined, true);
                const optionValues = getSearchOptionValueFromEvent(data.options);
                setKeyword(data.keyword);
                setStart(data.start);
                setEnd(data.end);
                setOptionValues(optionValues);

                searchControl.current?.setDataAsync(data.keyword ?? null, optionValues, data.start ?? null, data.end ?? null).then();
            }}>Clear
            </button>

            <button onClick={() => {
                const op = [...searchKind];
                op.splice(0, 1);

                const opv = [...searchKindDefaults];
                opv.splice(0, 1);

                forceSetOptions(op, opv).then();
            }}>검색 변경1
            </button>

            <button onClick={() => {
                const op = [...searchKind];
                op[0].options.splice(1, 1);

                forceSetOptions(op).then();
            }}>검색 변경2
            </button>
        </>
    )
}

export default SearchDateTest;