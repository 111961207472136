import UserDataBase, { UserSearchData } from "data/API/User/UserDataBase";
import React, {Component, createRef} from "react";
import {DepartmentData} from "data/API/DepartmentData";
import UserData from "data/API/User/UserData";
import {randomKey} from "@hskernel/hs-utils";

type UserSelectControlProps = {
    onSingleSelect?: (user: UserDataBase) => void,
    showCheck: boolean,
    deptCode?: string,
}
type UserSelectControlState = {
    users: UserDataBase[] | null,
    check: Map<string, UserDataBase>,
}

export default class UserListSelectEx extends Component<UserSelectControlProps, UserSelectControlState>
{
    private checkAll = createRef<HTMLInputElement>();

    constructor(props: UserSelectControlProps)
    {
        super(props);
        this.Save = this.Save.bind(this);
        this.clearCheck = this.clearCheck.bind(this);
        this.setDeptCodeAsync = this.setDeptCodeAsync.bind(this);
        this.setSearchAsync = this.setSearchAsync.bind(this);
        this.handleSingleCheck = this.handleSingleCheck.bind(this);
        this.handleAllCheck = this.handleAllCheck.bind(this);
        this.getText = this.getText.bind(this);
        this.onClick = this.onClick.bind(this);
        this.state = {
            users: [],
            check: new Map<string, UserDataBase>(),
        }
    }

    async componentDidMount()
    {
        if(this.props.deptCode != null) await this.setDeptCodeAsync(this.props.deptCode);
    }

    private clearCheck(users?: UserDataBase[])
    {
        this.setState({users: users == undefined ? this.state.users : users, check: new Map<string, UserDataBase>()});
        //if(this.checkAll.current) this.checkAll.current.checked = false;
    }

    // 체크박스 단일 선택
    private handleSingleCheck(checked: boolean, LoginID: string)
    {
        if(this.state.users != null)
        {
            const filter = this.state.users.filter((user) => user.LoginID == LoginID);
            // 단일 선택 시 체크된 아이템을 배열에 추가
            if (checked)
            {
                filter.map((user) => this.state.check.set(user.LoginID, user));
                this.setState({...this.state, check: this.state.check});
            }
            // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
            else
            {
                filter.map((user) => this.state.check.delete(user.LoginID));
                this.setState({...this.state, check: this.state.check});
            }
        }
    }

    // 체크박스 전체 선택
    private handleAllCheck ()
    {
        if (this.checkAll.current?.checked)
        {
            if(this.state.users != null)
            {
                const map = new Map<string, UserDataBase>();
                this.state.users.map((user) => map.set(user.LoginID, user));
                this.setState({...this.state, check: map});
            }
        }
        else this.clearCheck();
    }

    public Save(): UserDataBase[]
    {
        const arr: UserDataBase[] = [];
        this.state.check.forEach((value: UserDataBase) => arr.push(value));
        this.clearCheck();
        return arr;
    }

    /**
     * 부서코드로 목록 불러오기
     * @param deptCode
     * @param isEmployee
     */
    public async setDeptCodeAsync(deptCode: string, isEmployee?: boolean)
    {
        const users = await DepartmentData.GetDepartmentUsers(deptCode, isEmployee);
        this.clearCheck(users ?? []);
    }
    /**
     * 검색으로 목록 불러오기
     * @param search 검색할 사용자명
     */
    async setSearchAsync(search?: UserSearchData)
    {
        const users = await UserData.GetUsersBaseAsync(search);
        this.clearCheck(users ?? []);
    }

    private readonly getText = (user: UserDataBase) => `${user.DeptName === null ? "[(없음)]" : `[${user.DeptName}]`} ${user.Name} ${user.PositionName1} (${user.PositionName1})`;
    private onClick(user: UserDataBase)
    {
        if(this.props.showCheck)
        {
            const check = this.state.check.has(user.LoginID);
            this.handleSingleCheck(!check, user.LoginID);
        }
        else
        {
            //this.clearCheck();
            //this.handleSingleCheck(true, user.LoginID);
            if(this.props.onSingleSelect != null) this.props.onSingleSelect(user);
        }
    }
    render() {
        return (
            <div className="row">
                <div className="col">
                    <div className="table_card">
                        <table className="common_table table table-vcenter card-table" style={{borderTop: "2px solid #333"}}>
                            <thead>
                            <tr>
                                {this.props.showCheck ?
                                <th className="w-1">
                                    <input ref={this.checkAll} type="checkbox"
                                           className="form-check-input m-0 align-middle" aria-label="전체선택"
                                           onChange={this.handleAllCheck}
                                           checked={this.state.check.size == this.state.users?.length && this.state.users.length > 0}/>
                                </th> : ""}
                                <th style={{ fontSize: '15px', textAlign: "center" }}>사원 선택</th>
                            </tr>
                            </thead>
                        </table>
                        <div className="table_wrap table-responsive">
                            <table className="common_table table table-vcenter card-table">
                                <tbody>
                                {this.state.users?.map((user: UserDataBase, i: number) => {
                                    return (
                                        <tr key={randomKey(i)}>
                                            {this.props.showCheck ?
                                            <td className="w-1">
                                                <input type="checkbox" className="form-check-input m-0 align-middle"
                                                       aria-label="사용자 선택"
                                                       onChange={(e) => this.handleSingleCheck(e.target.checked, user.LoginID)}
                                                       checked={this.state.check.has(user.LoginID)}/>
                                            </td> : ""}
                                            <td onClick={() => this.onClick(user)} style={{cursor: "pointer"}}>{this.getText(user)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}