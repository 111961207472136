import React, {FormEvent, useState} from 'react';
import axios, {formToJSON} from "axios";
import {URL_API_LOGIN_PW, URL_FRONT_LOGOUT} from "Define";
import {APIData} from "API";
import {useNavigate} from "react-router-dom";

type PasswordPageProps = {
    isSimplePW?: boolean
}

const PasswordPage = (props: PasswordPageProps) =>
{
    const [message, setMessage] = useState<string | null>(null);
    const Navigate = useNavigate();

    const onSubmit = async (e: FormEvent<HTMLFormElement>) =>
    {
        e.preventDefault();
        const form: any = formToJSON(e.currentTarget);
        if(form.ChangePW != form.ChangePWReinput)
        {
            alert("비밀번호와 비밀번호 확인이 일치하지 않습니다");
            return;
        }

        try
        {
            await axios.post(`${URL_API_LOGIN_PW}?IsSimplePW=${props.isSimplePW ?? false}`, form, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            setMessage(null);
            alert("성공적으로 비밀번호를 변경하였습니다");
        }
        catch (ex: any)
        {
            //비밀번호 오류횟수 초과로인한 로그아웃
            if(ex.response.status == 401) Navigate(URL_FRONT_LOGOUT);

            const error = APIData.fromData(ex.response.data)
            if(error != null)
            {
                if((error.code == "LOGIN_EMPTY_PW" || error.code == "LOGIN_WRONG_PW") && !props.isSimplePW)
                    setMessage(error.message);
                else alert(error.message);
            }
        }
    }

    return (
        <div className="col-12 col-md-9 d-flex flex-column">
            <div className="card-body" style={{minHeight: "800px"}}>
                <h2 className="mb-4">{props.isSimplePW ? "간편" : "계정"} 비밀번호 변경</h2>
                <form className="card" style={{maxWidth: "25rem", margin: "40px auto"}} onSubmit={onSubmit}>
                    <div className="card-body">
                        <h4 className="mb-4 text-center">{props.isSimplePW ? "간편" : "계정"} 비밀번호 변경</h4>
                        <p className="text-secondary mb-4">현재 비밀번호와 변경할 {props.isSimplePW ? "간편" : "계정"} 비밀번호를 입력하세요.</p>
                        <div className="mb-3">
                            <label className="form-label">현재 비밀번호</label>
                            <input type="password" name="LoginPW" className={`form-control ${message == null ? "" : "is-invalid"}`}/>
                            {message == null ? "": <div className="invalid-feedback">{message}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">변경할 비밀번호</label>
                            <input type="password" name="ChangePW" className="form-control" placeholder={ props.isSimplePW ? "간편 비밀번호 입력" : "숫자/영문자/특수문자 포함하여 10자리 이상"}/>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">변경할 비밀번호 재입력</label>
                            <input type="password" name="ChangePWReinput" className="form-control" placeholder={props.isSimplePW ? "간편 비밀번호 다시 입력" : "비밀번호 다시 입력"}/>
                        </div>
                        <div className="form-footer">
                            <input type="submit" className="btn btn-primary w-100" value="변경"/>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    )
}
export default PasswordPage;