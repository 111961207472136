import {Expose} from "class-transformer";
import {convertBoolean, isEmptyString, PathMakerMulti} from "@hskernel/hs-utils";
import * as Define from "Define";
import {SendValueAsync} from "API";
import axios from "axios";
import {URL_API_USER} from "Define";
import {parseBoolean} from "@hskernel/hs-utils";
import {SelectOptionData} from "common/component/SelectOption";
import {SearchControlData, SearchOptionEvent, SearchOptionProps, SearchOptionValue} from "common/control/SearchControl";
import UserSettingsData from "./UserSettingsData";

export type UserSearchData = {
    keyword?: string | null,
    keywordKind?: string | null,
    isEmployee?: boolean | null,
}

export function fromSearch(keyword: string | null | undefined, optionEvents: SearchOptionEvent[]): UserSearchData
{
    return {
        keyword: keyword,
        keywordKind: optionEvents[0]?.value,
        isEmployee: isEmptyString(optionEvents[1]?.value) ? null : convertBoolean(optionEvents[1]!.value),
    }
}
export const fromSearchData = (data: SearchControlData) => fromSearch(data.keyword, data.options);

export const UserSearchKinds: SearchOptionProps[] = [
    {
        autoSearch: false,
        options: [new SelectOptionData("이름", "NAME")]
    },
    {
        autoSearch: true,
        options: [
            new SelectOptionData("전체", ""),
            new SelectOptionData("재직", "true"),
            new SelectOptionData("퇴사", "false"),
        ]
    }
];

export default class UserDataBase
{
    @Expose() public LoginID: string;
    @Expose() public Name: string | null;
    @Expose() public NameKorean: string | null;
    @Expose() public DeptCode: string | null;
    @Expose() public DeptName: string | null;
    @Expose() public PositionCode1: string | null;
    @Expose() public PositionName1: string | null;
    @Expose() public PositionCode2: string | null;
    @Expose() public PositionName2: string | null;

    constructor()
    {
        //this.getPosition = this.getPosition.bind(this);
        //this.getProfileImageURL = this.getProfileImageURL.bind(this);
    }

    public Apply(user: UserDataBase)
    {
        this.LoginID = user.LoginID;
        this.Name = user.Name;
        this.NameKorean = user.NameKorean;
        this.DeptCode = user.DeptCode;
        this.DeptName = user.DeptName;
        this.PositionCode1 = user.PositionCode1;
        this.PositionName1 = user.PositionName1;
        this.PositionCode2 = user.PositionCode2;
        this.PositionName2 = user.PositionName2;
        return this;
    }

    //Thumb: ThumbSize = ThumbSize.Medium
    public static getImageURL(LoginID: string) { return PathMakerMulti(Define.URL_API_USER, LoginID, "/image"); }
    public static getImageThumbURL(LoginID: string) { return PathMakerMulti(Define.URL_API_USER, LoginID, "/image/thumb"); }

    static Parse(data: any): UserDataBase | null
    {
        return new UserDataBase().Apply(data);
    }

    public toString() { return this.ToString(true); }
    public ToString(showPosition?: boolean)
    {
        let position = "";
        if(showPosition ?? true)
        {
            position = ` ${UserDataBase.getPosition(this)}`;
            //if(isEmptyString(this.PositionName1)) position = `${this.PositionName2}` ?? "";
            //else if(isEmptyString(this.PositionName2)) position = `${this.PositionName1}` ?? "";
        }
        return `${this.Name}${position}`
    }

    public static toSearchData(keyword: string | null | undefined, optionValues: SearchOptionValue[] | undefined): UserSearchData
    {
        let keywordKind = UserSearchKinds[0].options[0].Value;
        //기본은 재직
        let isEmployee = UserSearchKinds[1].options[1].Value;
        if (optionValues != null && optionValues.length > 0)
        {
            if (optionValues[0] != null) keywordKind = optionValues[0];
            if (optionValues[1] != null) isEmployee = optionValues[1];
        }
        return {keyword: keyword, keywordKind: keywordKind, isEmployee: isEmptyString(isEmployee) ? null : parseBoolean(isEmployee)};
    }

    /**
     * 직급을 가져옵니다
     */
    public static getPosition(user: UserDataBase): string
    {
        if(isEmptyString(user.PositionName1)) return user.PositionName2 || "";
        else if(isEmptyString(user.PositionName2)) return user.PositionName1 || "";
        else return user.PositionName1 == user.PositionName2 ? (user.PositionName1 || "") : `${user.PositionName1} (${user.PositionName2})`;
    }

    /**
     * 해당 게정 삭제 (관리자 전용)
     * @param {string} loginID
     * @constructor
     */
    public static async DeleteUserAsync(loginID: string)
    {
        return await SendValueAsync(axios.delete(`${URL_API_USER}/${loginID}`), false);
    }
}
