import {IFileViewerProps} from "./IFileViewer";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";

import React, {useCallback, useRef} from "react";

const ImageViewer = (props: IFileViewerProps) =>
{
    const parentStyle: React.CSSProperties = {
        width: "100%",
        maxHeight: "fit-content",
    }
    const childStyle: React.CSSProperties = {
        display: "block",
        maxWidth: "100%",
        maxHeight: "700px",
    }

    type onUpdateType = {
        x: number,
        y: number,
        scale: number
    }
    const imgRef = useRef<HTMLImageElement | null>(null);
    const onUpdate = useCallback(({ x, y, scale }: onUpdateType) =>
    {
        const { current: img } = imgRef;
        if (img)
        {
            const value = make3dTransformValue({ x, y, scale });
            img.style.setProperty("transform", value);
        }
    }, []);

    return (
        <div style={parentStyle}>
            <QuickPinchZoom onUpdate={onUpdate}>
                <img ref={imgRef} src={props.fileURL} alt={props.fileName} style={childStyle}/>
            </QuickPinchZoom>
        </div>
    )
}

export default ImageViewer;