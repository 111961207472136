import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import ModuleMenuGroup, {ModuleMenuGroupForm} from "../../data/API/Module/Menu/ModuleMenuGroup";
import InputRadioYN from "../../common/input/InputRadioYN";
import {isEmptyString, serializeForm} from "@hskernel/hs-utils";

type UserAccessDialogProps = {
    isOpen : boolean,
    data: ModuleMenuGroup | undefined,
    onClose: (refresh: boolean) => void
}

const ModuleMenuGroupDialog = ({data, isOpen, onClose}:UserAccessDialogProps) => {

    const [busy, setBusy] = useState(false);
    const modalStyle = {
        content: {
            width: "300px",
            height:"fit-content",
            margin: "60px auto",
            background: "var(--tblr-bg-surface)",
            inset:0,
            padding:0
        },
        overlay: {
            background: "rgba(0,0,0,0.5)",
            zIndex: "9",
        }
    }

    useEffect(() => {

    }, []);

    async function save(formEvent: React.FormEvent<HTMLFormElement>)
    {
        formEvent.preventDefault();

        const data = serializeForm(new FormData(formEvent.currentTarget)) as ModuleMenuGroupForm;
        if (isEmptyString(data.Name)) { alert('이름은 필수 입니다'); return; }
        if (isEmptyString(data.GroupID)) { alert('그룹 ID는 필수 입니다'); return; }

        setBusy(true);
        try
        {
            await ModuleMenuGroup.saveAsync(data);
            onClose(true);
        }
        finally { setBusy(false); }
    }

    return(
        <Modal ariaHideApp={false} isOpen={isOpen} style={modalStyle}>
            <form className="card" onSubmit={save}>
                <div className="card-header">
                    설정
                </div>
                <div className="card-body">
                    <div className="mb-3">
                        <label className="form-label">권한 ID</label>
                        <input type="text" className="form-control" name="GroupID" placeholder="권한 ID" defaultValue={data == undefined ? '' : data.GroupID}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">권한 이름</label>
                        <input type="text" className="form-control" name="Name" placeholder="권한 이름" defaultValue={data == undefined ? '' : data.Name}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">설명</label>
                        <input type="text" className="form-control" name="Description" placeholder="권한 이름" defaultValue={data == undefined ? '' : data.Description}/>
                    </div>
                    <div className="mb-3">
                        <div className="form-label">기본으로 설정</div>
                        <span className="form-check-description">기본으로 설정할 시, 기본으로 설정한 다른 항목의 설정이 해제됩니다.</span>
                        <InputRadioYN name="IsDefault" value={data == undefined ? false : data.IsDefault}/>
                    </div>
                </div>
                <div className="card-footer text-end">
                    <button type="button" disabled={busy} className="btn ms-auto" onClick={() => onClose(false)}>취소</button>&nbsp;&nbsp;
                    <button type="submit" disabled={busy} className="btn btn-primary ms-auto">저장</button>
                </div>
            </form>

        </Modal>
    )
}

export default ModuleMenuGroupDialog;
