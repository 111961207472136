import styles from "./HandoverManagePage.module.css";
import TableListControl, {GetItemStyle, TableItemHeader} from "common/control/TableListControl";
import UserDataBase from "data/API/User/UserDataBase";
import Modal from "react-modal";
import React, {Component, createRef, useState} from "react";
import UserSelectModal from "common/control/selector/UserSelectModal";
import AuthData from "Auth";

const modalStyle = {
    content: {
        width: "1000px",
        margin: "0 auto",
        height:"770px",
        background: "var(--tblr-bg-surface)"
    },
    overlay: {
        background: "rgba(0,0,0,0.5)",
        zIndex: "9"
    }
}
const TableColumn: TableItemHeader<UserDataBase>[] = [
    {name: "사원명", key: "NameKorean", clickable: false, styleItem: GetItemStyle(30, false)},
    {name: "부서명", key: "DeptName", clickable: false, styleItem: GetItemStyle(30, false)},
    {name: "직급", key: "PositionName1", clickable: false, styleItem: GetItemStyle(20, false)},
    {name: "직책", key: "PositionName2", clickable: false, styleItem: GetItemStyle(20, false)},
]

type HandoverManageDialogProps = {
    onSave: () => void,
    auth: AuthData,
}

type HandoverManageDialogState = {
    isOpen: boolean,
    AcquirersCheck: Map<string, any>,
    Acquirers: UserDataBase[],
    DocumentClass?: string,
}

export default class HandoverManageDialog extends Component<HandoverManageDialogProps, HandoverManageDialogState>
{
    constructor(props: HandoverManageDialogProps) {
        super(props);
        this.state = {
            isOpen: false,
            AcquirersCheck: new Map<string, any>(),
            Acquirers: this.testData(), //[]
        }

        this.open = this.open.bind(this);
        this.save = this.save.bind(this);
        this.close = this.close.bind(this);
    }

    private tableAcq = createRef<TableListControl<UserDataBase>>();

    componentDidMount() {
        if(this.tableAcq.current != null) this.tableAcq.current.refresh();
    }

    public testData()
    {
        const user = new UserDataBase();
        user.NameKorean = "테스트";
        user.DeptName  = "부서";
        user.PositionName1 = "직급";
        return [user, user, user, user, user, user, user, user, user, user, user, user, user, user];
    }

    public open(){ this.setState({isOpen: true}) }

    private clear()
    {
        this.setState({isOpen: false, Acquirers: [], AcquirersCheck: new Map<string, UserDataBase>(), DocumentClass: undefined});
    }
    private save()
    {
        this.clear();
        this.props.onSave();
    }
    private close()
    {
        if(confirm("정말 취소하시겠습니까?")) this.clear();
    }

    private add(users: UserDataBase[])
    {
        const AcquirersCheck = this.state.AcquirersCheck;
        const Acquirers = this.state.Acquirers;
        users.forEach(user =>
        {
            if(!AcquirersCheck.has(user.LoginID))
            {
                AcquirersCheck.set(user.LoginID, null);
                Acquirers.push(user);
            }
        })
        this.setState({...this.state, Acquirers: Acquirers, AcquirersCheck: AcquirersCheck});
    }

    render() {
        return (
            <Modal isOpen={this.state.isOpen} style={modalStyle}>
                <UserSelectModal title="인수자 선택" auth={this.props.auth} onSave={(users) => this.add(users)}/>
                <div className="card">
                    <div className="card-header">
                        <p className="col">인수인계 등록</p>
                        <div className="col-auto">
                            <button type="button" className="btn btn-primary" onClick={this.save}>저장
                            </button>
                            &nbsp;&nbsp;
                            <button type="button" className="btn" onClick={this.close}>취소</button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-7">
                                {/*<div className="card">
                                    <div className="card-header">인계자</div>
                                    <div className="card-body">
                                        <div className={styles.docu_list_table}>
                                        </div>
                                    </div>
                                </div>
                                <br/>*/}
                                <div className="card">
                                    <div className="card-header">인수자</div>
                                    <div className="card-body">
                                        <div className={styles.docu_list_table}>
                                            <TableListControl<UserDataBase> ref={this.tableAcq} headerTable={TableColumn} autoRefresh={true} checkable={false} onRefresh={() => {return {data: this.state.Acquirers}}} hidePage={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="card">
                                    <div className="card-header">인수인계문서</div>
                                    <div className="card-body">
                                        <div className={styles.docu_list_table}>
                                            <table className={"common_table " + styles.handover_table_document}
                                                   style={{
                                                       borderBottom: "1px solid #dcdcdf",
                                                       borderRight: "1px solid #dbdbdf"
                                                   }}>
                                                <thead>
                                                <tr>
                                                    <th>문서분류</th>
                                                    <th>양식명</th>
                                                </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className={styles.handover_table_wrap} style={{height:"440px"}}>
                                            <table className={"common_table " + styles.handover_table} style={{
                                                borderBottom: "1px solid #dcdcdf",
                                                borderRight: "1px solid #dbdbdf"
                                            }}>
                                                <tbody>
                                                <tr>
                                                    <td>업무협조전</td>
                                                    <td>문서양식 이름</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}