import TreeViewControl from "../common/control/TreeViewControl";
import React, {useEffect, useRef, useState} from "react";
import SearchControl, {
    SearchOptionValue,
    getSearchOptionValueFromEvent, SearchOptionProps, makeSearchOptionParamKey, getDefaultSearch, SearchControlClass
} from "../common/control/SearchControl";
import {
    SEARCH_KIND_DOC,
    SEARCH_KIND_TITLE,
    SEARCH_KIND_WRITER
} from "../modules/approval/data/ApprovalDocumentListData";
import {dateFormat} from "@hskernel/hs-utils";
import {useNavigate} from "react-router-dom";
import SelectOption, {SelectOptionData, SelectOptionValue} from "../common/component/SelectOption";

const SearchTest = () =>
{
    const name = "SearchTest";
    const searchOptions: SearchOptionProps[] = [
        {
            autoSearch: false,
            name: "kind",
            options: [
                new SelectOptionData("제목", SEARCH_KIND_TITLE),
                new SelectOptionData("기안자", SEARCH_KIND_WRITER),
                new SelectOptionData("문서번호", SEARCH_KIND_DOC),
            ]
        },
        {
            autoSearch: false,
            name: "kindOption1",
            options: [
                new SelectOptionData("A", 'AAA'),
                new SelectOptionData("B", 'BBB'),
                new SelectOptionData("C", 'CCC'),
            ]
        },
        {
            autoSearch: true,
            name: "kindOption2",
            options: [
                new SelectOptionData("D", 'DDD'),
                new SelectOptionData("E", 'EEE'),
                new SelectOptionData("F", 'FFF'),
            ]
        },
    ]
    const searchOptionDefaults = [
        searchOptions[0].options[1].Value,
        searchOptions[1].options[0].Value,
        searchOptions[2].options[0].Value,
    ]

    const getDefaultSearchData = (options: SearchOptionProps[], optionValues: SelectOptionValue[], ignoreURL?: boolean) => getDefaultSearch(name, null, options, optionValues, ignoreURL);

    const defaultSearch = getDefaultSearchData(searchOptions, searchOptionDefaults, false);
    const searchControl = useRef<SearchControlClass | null>(null);
    const [keyword, setKeyword] = useState<string | undefined>(defaultSearch.keyword);

    const [options, setOptions] = useState<SearchOptionProps[]>(searchOptions);
    const [optionDefault, setOptionDefault] = useState<SelectOptionValue[]>(searchOptionDefaults);

    const [optionValue, setOptionValues] = useState<SearchOptionValue[]>(getSearchOptionValueFromEvent(defaultSearch.options));
    const navigate = useNavigate();


    async function forceSetOptions(options?: SearchOptionProps[] | null, defaultOptionValues?: SearchOptionValue[] | null)
    {
        if(searchControl.current != null)
        {
            await searchControl.current.setOptionsAsync(options, defaultOptionValues);
            const state = await searchControl.current.clearDataAsync();
            const optionValues = getSearchOptionValueFromEvent(state.options);
            setOptions(options ?? []);
            setOptionDefault(optionValues);
            setOptionValues(optionValues);
            setKeyword(state.keyword);
        }
    }

    useEffect(() => {
        console.log(optionValue)
    }, []);

    return (
            <>
                <SearchControlClass ref={searchControl} name={name} Navigate={navigate} defaultKeyword={keyword} defaultOptions={options} defaultOptionValue={searchOptionDefaults} onSearch={(keyword, optionEvents) =>
                {
                    console.log(`SEARCH: ${keyword}`);
                    setKeyword(keyword);
                    //setKindAddEvents(data.options);
                    setOptionValues(getSearchOptionValueFromEvent(optionEvents));
                }}/>
                <p>Keyword: {keyword}</p>
                <p>keywordKind: {optionValue[0]}</p>
                <p>AddParam: {JSON.stringify(optionValue)}</p>

                <button onClick={() => {
                    const data = getDefaultSearchData(options, optionDefault, true);
                    const optionValues = getSearchOptionValueFromEvent(data.options);
                    setKeyword(data.keyword);
                    setOptionValues(optionValues);
                    searchControl.current?.setDataAsync(data.keyword ?? null, optionValues).then();
                }}>Clear</button>

                <button onClick={() =>
                {
                    const op = [...searchOptions];
                    op.splice(0, 1);

                    const opv = [...searchOptionDefaults];
                    opv.splice(0, 1);

                    forceSetOptions(op, opv).then();
                }}>검색 변경1</button>

                <button onClick={() =>
                {
                    const op = [...searchOptions];
                    op[0].options.splice(1, 1);

                    //setOptions(op);
                    forceSetOptions(op).then();
                }}>검색 변경2</button>
            </>
        )
}

export default SearchTest;