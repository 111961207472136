import React, {useState} from "react";
import axios from "axios";
import {URL_API} from "../Define";

const SSOTokenTest = () =>
{
    const [key, setKey] = useState('UdzgFRthUh3ve3k31anCjRXjEydaLpsABoc9a07FZVIYXg6CuM');
    const [id, setID] = useState('black7eagle5');
    const [token, setToken] = useState('');

    function onClick()
    {
        axios.post(`${URL_API}/auth/sso/${id}`, null, {headers: {'X-API-Token': key}})
            .then(response => setToken(response.data))
            .catch(e => setToken(e.toString()))
    }

    return (
        <div>
            <input type='text' style={{width: '300px'}} value={key} placeholder='API 키' onChange={e => setKey(e.target.value)}/>
            <br/>
            <input type='text' style={{width: '100px'}} value={id} placeholder='사용자 ID' onChange={e => setID(e.target.value)}/>
            <button onClick={onClick}>전송</button>
            <br/>
            {token == null ? "" :
                <div>
                    <button onClick={() => navigator.clipboard.writeText(token)}>복사</button>
                    {token}
                </div>}
        </div>
    )
}
export default SSOTokenTest;