import {Expose} from "class-transformer";
import axios from "axios";
import {SendValueAsyncType} from "API";
import {GetAPIURL} from "../Define";
import {isEmptyString} from "@hskernel/hs-utils";
import BoardComment from "./BoardComment";

export default class BoardCommentForm
{
    @Expose() public Contents: string;

    public async WriteAsync(BoardID: string, BoardKind: string, CommentID?: string)
    {
        const url = GetAPIURL(BoardKind, BoardID) + "/comment";
        const send = axios({
            method: "POST",
            url: isEmptyString(CommentID) ? url : `${url}/${CommentID}`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: this,
            // 폼에서 [] 없애기
            formSerializer: { indexes: null },
        });

        return (await SendValueAsyncType<BoardComment>(send, true, BoardComment))!;
    }
}