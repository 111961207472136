import Modal from 'react-modal';
import {Component, createRef} from "react";
import React from 'react';
import UserDataBase from "data/API/User/UserDataBase";
import UserSelectControl, { UserSelectProps } from "./UserSelectControl";


export type UserSelectModalProps = UserSelectProps & {
    onSave: (users: UserDataBase[]) => void,
}

type UserSelectModalState = {
    isOpen: boolean
    users?: UserDataBase[]
}

/**
 *
 * @param setModalIsOpen
 * @param openProps
 * @param onSave
 * @param deptCode
 * @returns {JSX.Element}
 * @constructor
 */

export default class UserSelectModal extends Component<UserSelectModalProps, UserSelectModalState> {
    private select = createRef<UserSelectControl>();

    constructor(props: UserSelectModalProps)
    {
        super(props);

        this.openDialog = this.openDialog.bind(this);
        this.setOpenDialog = this.setOpenDialog.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.state = {
            isOpen: false,
            users: this.props.users
        };
    }


    //if(open != openProps.isOpen) setOpen(openProps.isOpen);

    public openDialog(users: UserDataBase[]) {
        this.setOpenDialog(true, users);
    }

    private setOpenDialog(open: boolean, users?: UserDataBase[]) {
        this.setState({isOpen: open, users: users ?? []});
    }

    private onSave() {
        this.setOpenDialog(false);
        const users = this.select.current == null ? [] : this.select.current.getUsers();
        this.props.onSave(users);
    }

    /**
     * 취소버튼 눌렀을 때
     * @private
     */
    private onCancel() {
        if (window.confirm("정말 취소하시겠습니까?")) {
            this.setOpenDialog(false);
        }
    }


    render() {

        const modalStyle = {
            content: {
                width:`${window.innerWidth <= 577? "calc(100% - 40px)":"calc(100% - 80px)"}`,
                margin:"0 auto",
                background: "var(--tblr-bg-surface)",
                padding:0,
                border:0,
                inset:`${window.innerWidth <= 577? "20px":"40px"}`,
                height:"fit-content"
            },
            overlay: {
                background: "rgba(0,0,0,0.5)",
                zIndex:"100"
            }
        }

        const title = this.props.title ?? "사용자 선택";
        return (
            <Modal isOpen={this.state.isOpen} style={modalStyle} ariaHideApp={false}>
                <div>
                    <div className="card">
                        <div className="card-header">
                            <p className="col">{title}</p>
                            <div className="col-auto" >
                                <button className="btn btn-primary" onClick={this.onSave}>저장</button>
                                &nbsp;
                                <button className="btn" onClick={this.onCancel}>취소</button>
                            </div>
                        </div>
                        <UserSelectControl ref={this.select} max={this.props.max} title={title} auth={this.props.auth} users={this.state.users}/>
                    </div>
                </div>
            </Modal>
        )
    }
}