import UserDataBase, {UserSearchData} from "./UserDataBase";
import axios from "axios";
import * as Define from "Define";
import {SendValueAsyncArrayType, SendValueAsyncType} from "API";
import {ClassConstructor, Expose, Type} from "class-transformer";
import UserDataSimple from "./UserDataSimple";
import UserCountData from "./UserCountData";
import UserDataEx from "./UserDataEx";

function getUserSearchParam(search?: UserSearchData)
{
    if(search == null) return null;

    const arr: string[] = [];
    if(search.keyword != null) arr.push(`Keyword=${search.keyword}`);
    if(search.keywordKind != null) arr.push(`Kind=${search.keywordKind}`);
    if(search.isEmployee != null) arr.push(`IsEmployee=${search.isEmployee}`);
    return arr.join('&');
}

type UserDataType = 'simple' | 'full' | 'fullex';

export default class UserData extends UserDataSimple
{
    @Expose() @Type(() => Date)
    public EnteringDate?: Date | null;
    @Expose() @Type(() => Date)
    public RetirementDate?: Date | null;
    @Expose() @Type(() => Date)
    public BirthDate?: Date | null;
    @Expose() public EmailPrivate?: string;
    @Expose() public EmpCode?: string | null;
    @Expose() public TelMobile?: string;

    public static async GetUserAsync(type: UserDataType, LoginID: string | null, password?: string, IsProfileImageThumb?: boolean): Promise<UserData | UserDataEx | UserDataSimple | null>
    {
        let endPoint = '';
        const params = [`Detail=${type}`]; //.'ProfileImage=true'
        if(LoginID == null) params.push(`Password=${password ?? ''}`);
        if(IsProfileImageThumb != null) params.push(`IsProfileImageThumb=${IsProfileImageThumb}`);
        else endPoint = `/info/${LoginID}`;
        return await SendValueAsyncType<UserData | null>(axios.get(Define.URL_API_USER + `${endPoint}?${params.join('&')}`), false, UserData);
    }

    public static async GetUsersCountAsync(search?: UserSearchData): Promise<UserCountData | null>
    {
        const searchParam = getUserSearchParam(search);
        return SendValueAsyncType<UserCountData>(axios.get(`${Define.URL_API_USERS}/count?${searchParam}`), true, UserCountData);
    }
    public static async GetUsersBaseAsync(search?: UserSearchData, offset?: number, count?: number, IsProfileImageThumb?: boolean): Promise<UserDataBase[] | null>{ return this._SearchUser(UserDataBase, search, offset, count, IsProfileImageThumb, true); }
    public static async GetUsersAsync(search?: UserSearchData, offset?: number, count?: number, IsProfileImageThumb?: boolean): Promise<UserDataSimple[] | null>{ return this._SearchUser(UserDataSimple, search, offset, count, IsProfileImageThumb, false); }
    protected static async _SearchUser<T>(type: ClassConstructor<T>, search?: UserSearchData, offset?: number, count?: number, IsProfileImageThumb?: boolean, base?: boolean): Promise<T[] | null>
    {
        const arr: string[] = [];
        const searchParam = getUserSearchParam(search);
        if(searchParam != null) arr.push(searchParam);
        if(IsProfileImageThumb != null) arr.push(`IsProfileImageThumb=${IsProfileImageThumb}`);
        offset = offset ?? 0;
        count = count ?? -1;
        arr.push(`Offset=${offset}`);
        arr.push(`Count=${count}`);
        arr.push(`Base=${base}`);
        return SendValueAsyncArrayType<T>(axios.get(`${Define.URL_API_USERS}?${arr.join('&')}`), false, type);
    }
}
