import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import ScheduleFormData from "../data/ScheduleFormData";
import styles from '../style/Calendar.module.css';
import {dateFormat, isEmptyString} from "@hskernel/hs-utils";
import {printError} from "Define";
import ScheduleData from "../data/ScheduleData";

type CalendarWriteProps = {
    onSave: (schedule: ScheduleData) => void
    onCancel: () => void,
    onBusy?: (isBusy: boolean) => void,
    start?: Date,
    end?: Date,
    data?: ScheduleData
}

const CalendarWrite = (props: CalendarWriteProps) => {
    const [isBusy, setBusy] = useState(false);

    useEffect(() => props.onBusy && props.onBusy(isBusy), [isBusy]);

    function Save(e: FormEvent<HTMLFormElement>)
    {
        e.preventDefault();

        const data = ScheduleFormData.FromForm(e.currentTarget);
        if(isEmptyString(data.Title)) alert("제목은 비어있을 수 없습니다");

        console.log(data)

        setBusy(true);
        data.SaveAsync()
            .then((schedule) => props.onSave(schedule))
            .catch((error: any) => printError(error))
            .finally(() => setBusy(false))
    }

    const formatStr = 'yyyy-mm-dd HH:mm';
    const defaultColor = "#e66465";

    function getStartDate()
    {
        if(props.data == null) return props.start ? dateFormat(props.start, formatStr) : undefined;
        else return dateFormat(props.data.StartDate, formatStr);
    }
    function getEndDate()
    {
        if(props.data == null) return props.end ? dateFormat(props.end, formatStr) : undefined;
        else return dateFormat(props.data.EndDate, formatStr);
    }

    return (
        <li className={styles.day_plan_li}>
            <form className={styles.day_plan_info} onSubmit={Save}>
                <div className={styles.color_pick_box}>
                    <input type="hidden" name="ID" value={props.data && props.data.ID}/>
                    <input type="color" name="Color" defaultValue={props.data == null || props.data.Color == null ? defaultColor : props.data.Color}/>
                </div>
                <input type="text" name="Title" placeholder="일정 제목" className={styles.dayplan_input} defaultValue={props.data && (props.data.Title ?? undefined)}/>
                <textarea name="Contents" placeholder="수정이나 추가 시 내용 입력 칸" defaultValue={props.data && (props.data.Contents ?? undefined)}/>
                <div className={styles.boundary}>
                    <p>공개 : </p>
                    <select name="ShareType" defaultValue={props.data == null ? "priv" : props.data.ShareType}>
                        <option value="priv">본인</option>
                        <option value="group">부서</option>
                        <option value="share">전체</option>
                    </select>
                </div>
                <div className={styles.location}>
                    <p>위치 : </p>
                    <input type="text" name="Location" defaultValue={props.data && (props.data.Location ?? undefined)} />
                </div>
                <div className={styles.date_pick}>
                    <div className={styles.date_pick_box}>
                        <p>시작 :</p>
                        <input type="datetime-local" name="StartDate" value={getStartDate()}/>
                    </div>
                    <div className={styles.date_pick_box}>
                        <p>종료 :</p>
                        <input type="datetime-local" name="EndDate" defaultValue={getEndDate()}/>
                    </div>
                </div>
                <div className={styles.day_plan_control}>
                    <input type="submit" style={{background: "#1b67bd"}} disabled={isBusy} value="저장"/>
                    <button type="button" style={{background: "rgb(61 64 71)"}} onClick={props.onCancel}>취소</button>
                </div>
            </form>
        </li>
    )

}
export default CalendarWrite;
