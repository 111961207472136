import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from 'react-router-dom';
import qs from 'qs';
import {PathMaker, randomKey} from "@hskernel/hs-utils";
import ApprovalDocumentTypeData from "../data/ApprovalDocumentTypeData";
import SearchControl from "common/control/SearchControl";
import {DOC_WINDOW_WIDTH, URL_APPROVAL_FORM, URL_APPROVAL_VIEW_FORMAT} from "../Define";
import {calcHeight} from "Define";
import {IconFileText} from "@tabler/icons-react";

type listType = {
    [Class: string]: ApprovalDocumentTypeData[]
}
/**
 * 문자열 키-값 으로 접근할 수 있는 형식
 */
type parentClassType = {
    [index: string]: string
}
const parentClass: parentClassType = {
    "10": "기안문서",
    "20": "보고문서",
    "30": "기타문서",
    "40": "근태신청서",
    "50": "지출결의서",
    "90": "출력양식",
}

function DocumentFormatListPage()
{
    const [list, setList] = useState<listType>({});
    const [Class, setClass] = useState<string | null>(null);

    const Navigate = useNavigate();
    const query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
    });

    const onSearch = (keyword: string | null) =>
    {
        keyword = keyword ?? "";

        ApprovalDocumentTypeData.GetDocumentTypeList(keyword).then(types =>
        {
            const list: listType = {};
            types?.map((type) => {
                if(list[type.Category] == undefined) list[type.Category] = [];
                list[type.Category].push(type);
            })
            setList(list);
            setClass(null);
        });
    };

    useEffect(() =>
    {
        onSearch(query.keyword ? query.keyword.toString() : null);
    }, [])

    const listElement = (doc: ApprovalDocumentTypeData, index: number) => (
        <div key={randomKey(index)} style={{padding: "4px"}}>
            <a className="detail_doc_list" onClick={() => window.open(PathMaker(doc.Readonly ? URL_APPROVAL_VIEW_FORMAT : URL_APPROVAL_FORM, doc.DocumentClass), '_blank', `width=${DOC_WINDOW_WIDTH},height=${calcHeight()}`)}>
                {doc.ClassName}
            </a>
        </div>
    );

    return (
        <div className="card-body">
            <div className="row">
                <div className="col-lg-4 col-sm-6">
                    <div className="input-icon">
                        <SearchControl onSearch={onSearch} Navigate={Navigate}/>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <button type="button" className="btn" style={{backgroundColor: "#29518b", color: "#fff"}} onClick={()=> onSearch(null)}>초기화</button>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-lg-4 col-sm-6">
                    <div className="card">
                        <div className="card-header card_header_bg">
                            전자결재양식
                        </div>
                        <div className="card-body">
                            {
                                Object.keys(parentClass).map((_Class, i) => {
                                    return (
                                        <div key={randomKey(i)} className="row" style={{paddingBottom: "6px"}}>
                                            <div className="list-group-item" style={_Class == Class ? { background: "#eee" } : {}}>
                                                <div className="row align-items-center" style={{padding: "3px"}}>
                                                    <div className="col-auto">
                                                        <a href="#"><IconFileText stroke={1}/></a>
                                                    </div>
                                                    <div className="col text-truncate">
                                                        <a onClick={() => setClass(_Class)} className="text-reset d-block">{parentClass[_Class]}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className="col-lg-8 col-sm-6">
                    <div className="card">
                        <div className="card-header card_header_bg">양식명</div>
                        <div className="card-body" style={{overflowY:"scroll",height:"600px"}}>
                            {
                                Class != null ?
                                    list[Class]?.map((doc, i) => listElement(doc, i)) :
                                    Object?.values(list).map((docs) => docs.map((doc, i) => listElement(doc, i)))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocumentFormatListPage;