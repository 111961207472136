import {getWVData, InitRNMessage, isWVMode, setWVData} from "./lib/RNMessage/RNMessageWV";
import {
    getCompanyID,
    getDeviceInfo,
    HEADER_COMPANY_ID, isAppMode, isDebugMode,
    sendRNData,
    STORAGE_KEY_LOGIN_APP_TOKEN, STORAGE_KEY_LOGIN_ID,
    toStringAny
} from "./Define";
import {LogDataLevel} from "./LogData";
import {InitEventHandler} from "./EventHandler";
import {InitMap} from "./Module";
import * as serviceWorkerRegistration from "./lib/serviceWorkerRegistration";
import axios from "axios";
import {deleteCookie, getCookie, isEmptyString, setCookie} from "@hskernel/hs-utils";
import AlertData from "./data/API/Alert/AlertData";
import {fireAlertClick, KEY_WV_ALERTS} from "Alert";
//import { preventDevTools } from "@types/hs-utils-dev";

export function InitAxiosHeader(login?: {Token: string, ID: string})
{
    //axios.defaults.baseURL = Define.URL_API;
    axios.defaults.withCredentials = true;

    let LoginToken = login == null ? localStorage[STORAGE_KEY_LOGIN_APP_TOKEN] : login.Token;
    //let LoginID = login == null ? localStorage[STORAGE_KEY_LOGIN_ID] : login.ID;
    //let CompanyID = localStorage[STORAGE_KEY_COMPANY_ID];
    if (LoginToken == null && isWVMode())
    {
        const Login = getWVData('Login');
        if (Login != null)
        {
            LoginToken = Login.Token;
        }
    }
    if(LoginToken != null) axios.defaults.headers.common["Authorization"] = `app ${LoginToken}`;//`app ${CompanyID}:${LoginID}:${LoginToken}`;

    const CompanyID = getCompanyID();
    if (CompanyID != null && !isEmptyString(CompanyID))
    {
        axios.defaults.headers.common[HEADER_COMPANY_ID] = CompanyID;
        deleteCookie(HEADER_COMPANY_ID);
        setCookie(HEADER_COMPANY_ID, CompanyID);
    }

    if (isDebugMode()) console.log(LoginToken);
}

/* Initializing function... */
export default function Init()
{
    console.log(`[Init] Device Info: ${JSON.stringify(getDeviceInfo())}`);

    //if (!isDebugMode()) preventDevTools();

    //Axios 헤더 초기화
    InitAxiosHeader();

    //강제 캐시 초기화
    InitCache();

    //ReactNative 초기화
    InitRNMessage();

    //모듈 맵 초기화
    InitMap();

    //이벤트 핸들러 초기화
    InitEventHandler();

    //웹뷰 모드일 때
    if (isWVMode())
    {
        //웹뷰 모드 시 알림 데이터가 존재하면 (알림 클릭하여 앱 살아남)
        const alerts: AlertData[] = getWVData(KEY_WV_ALERTS);
        if (alerts != null)
        {
            //window.alert(JSON.stringify(alerts));
            alerts.map(alert => fireAlertClick(alert));
        }
    }
    else if (isAppMode())
    {
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://cra.link/PWA
        serviceWorkerRegistration.register();
    }
}

/* 강제 캐시 초기화 */
function InitCache()
{
    if(window.caches != null)
    {
        window.caches.keys().then(function(names) {
            for (const name of names)
                window.caches.delete(name).then();
        });
    }
}

const _console = console;

/**
 *
 * @param {LogDataLevel} Level
 * @param {any} log
 * @constructor
 */
const ConsoleLog = function (Level: LogDataLevel, log: any)
{
    if(isWVMode())
    {
        /**
         *
         * @type {LogData}
         */
        const LogData = {level: Level, data: toStringAny(log)};
        sendRNData({type: 'LOG', data: LogData});
    }
    else _console.log(log);
}
/* eslint no-global-assign: off, */
// @ts-ignore
/*
console = {
    log: (log) => ConsoleLog('NONE', log),
    debug: (log) => ConsoleLog('DEBUG', log),
    info: (log) => ConsoleLog('INFO', log),
    warn: (log) => ConsoleLog('WARN', log),
    error: (log) => ConsoleLog('ERROR', log),
};
*/
