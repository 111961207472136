import React, {Component, createRef} from "react";
import AuthData from "Auth";
import UserDataBase from "data/API/User/UserDataBase";
import DepartmentPick from "common/control/DepartmentPickControl";
import UserListSelect from "./internal/UserListSelect";
import {ApprovalSelectorData} from "./internal/ISelector";
import UserListAddControl from "./internal/UserListAddControl";

export type UserSelectProps = {
    title: string,
    auth: AuthData,
    users?: UserDataBase[],
    max?: number,
}
export default class UserSelectControl extends Component<UserSelectProps>
{
    private userList = createRef<UserListAddControl>();
    private userAdd = createRef<UserListSelect>();
    constructor(props: UserSelectProps)
    {
        super(props);

        this.getUsers = this.getUsers.bind(this);
        this.addUsers = this.addUsers.bind(this);
    }

    public getUsers = () => this.userAdd.current == null ? [] : this.userAdd.current.getData().map((appruser) => appruser.user);
    public addUsers = (users?: UserDataBase[]) => { if(this.userAdd.current != null && users != null) this.userAdd.current.addData(users.map(user => new ApprovalSelectorData(user, "")));}

    componentDidMount()
    {
        this.addUsers(this.props.users);
    }

    render()
    {
        return (
            <div className="card-body" style={{overflowY:"scroll",height:"calc(100vh - 170px)"}}>
                <div className="row">
                    <div className="col-sm-12 col-lg-4 mb-3">
                        <div className="card">
                            <div className="card-header">
                                조직도
                            </div>
                            <div className="card-body">
                                <DepartmentPick deptCode={this.props.auth.user.DeptCode!} onSelect={(dept) => this.userList.current?.setDeptCodeAsync(dept.DepartmentCode) }/>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-lg-8">
                        <div className="card">
                            <div className="card-header">
                                {this.props.title}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <UserListAddControl ref={this.userList} onSelect={this.addUsers}/>
                                    <p style={{padding: "10px"}}></p>
                                    {<UserListSelect ref={this.userAdd} max={this.props.max} company={this.props.auth.company.Name} props={undefined}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

