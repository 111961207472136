import React from "react";
import {randomKey} from "utils/HSUtils";
import {ApprovalSelectorData, ISelector} from "common/control/selector/internal/ISelector";
import {GetApproverKindText} from "../../../data/ApprovalDocumentBase";
import ApprovalLineExtra from "../../../type/ApprovalLineExtra";

type ImplementTableProps = {
    showFinal?: boolean
}

export default class ImplementTable extends ISelector<ImplementTableProps, ApprovalLineExtra>
{
    private getCheckBox(data: ApprovalSelectorData<ApprovalLineExtra>)
    {
        if(data.extra == null) data.extra = { final: false };

        if(this.props.props.showFinal)
        {
            return data.constable ?
                <td></td>
                :
                <td className="input_head" style={{borderRight:"1px solid var(--tblr-border-color-translucent)"}}>
                    <input type="checkbox" className="form-check-input m-0 align-middle" defaultChecked={data.extra.final} onChange={(e) => data.extra.final = e.currentTarget.checked}/>
                </td>
        }
        return "";

    }

    render()
    {
        const offset = this.getOffset();
        return (
            <>
                <div className="col-6 col-sm-4 col-md-3 col-xl py-3">
                    <a className="btn btn-outline-mute w-100" onClick={this.removeHandle}>
                        삭제
                    </a>
                </div>
                <div className="table_m_over">
                    <table className="common_table implement_table"
                           style={{borderTop: "2px solid #333"}}>
                        <thead>
                        <tr>
                            <th className="input_head">
                                <input type="checkbox" className="form-check-input m-0 align-middle" aria-label="전채선택"
                                       onChange={(e) => this.handleAllCheck(e.target.checked)}
                                       checked={this.state.check.size + offset >= this.state.data.size && this.state.data.size > offset}/>
                            </th>
                            <th className="num_head">No</th>
                            <th className="type_head">결재종류</th>
                            <th className="company_head">회사</th>
                            <th className="name_head">이름</th>
                            {this.props.props.showFinal ? <th className="input_head">전결</th> : ""}
                        </tr>
                        </thead>
                    </table>
                    <div className="table-responsive implement_table_wrap">
                        <table className="common_table implement_table">
                            <tbody>
                            {this.getData().map((data, i) => {
                                return (
                                    <tr key={randomKey(i)}>
                                        <td className="input_head">
                                            {data.constable ? "" :
                                                <input type="checkbox" className="form-check-input m-0 align-middle"
                                                       aria-label="이 항목 선택"
                                                       onChange={(e) => this.handleSingleCheck(e.target.checked, data.user.LoginID)}
                                                       checked={this.state.check.has(data.user.LoginID)}/>
                                            }
                                        </td>
                                        <td className="num_head">{i + 1}</td>
                                        <td className="type_head">{GetApproverKindText(data.kind)}</td>
                                        <td className="company_head">{this.props.company}</td>
                                        <td className="name_head">{data.user.Name}</td>
                                        {this.getCheckBox(data)}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}