import React,{useState} from "react";
import {Link, Navigate, Outlet, Route, useLocation} from "react-router-dom";
import {backPreventURL, isDebugMode, PATH_FRONT_USER, URL_FRONT} from "Define";
import PasswordPage from "./PasswordPage";
import UserListPage from "./UserListPage";
import TopHeader from "component/TopHeader";
import {PathMaker} from "@hskernel/hs-utils";
import UserSettingsPage from "./UserSettingsPage";
import UserInfoPage from "./UserInfoPage";
import LoginHistoryPage from "./LoginHistoryPage";
import UserAlert from "./UserAlert";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import SideMenuMobile from "../../component/SideMenuMobile";
import 'css/style.css';
import DevicePage from "./DevicePage";
const URL_USER_ROOT = PathMaker(URL_FRONT, PATH_FRONT_USER);
const USER_PAGE_INFO = "info";
const USER_PAGE_PASSWORD = "password";
const USER_PAGE_PASSWORD_SIMPLE = "simple_pw";
const USER_PAGE_LIST = "list";
const USER_PAGE_SETTING = "setting";
const USER_PAGE_LOGIN_HISTORY = "history";
const USER_PAGE_DEVICE = "device";
const USER_PAGE_ALERT = "alert";

export const UserRouter = () => (
    <Route path={PATH_FRONT_USER} element={<UserMenuFrame/>}>
        {/*<Route path="form" element={<InformationPage/>}/>*/}
        <Route path={USER_PAGE_INFO} element={<UserInfoPage/>}/>
        <Route path={USER_PAGE_PASSWORD} element={<PasswordPage isSimplePW={false}/>}/>
        <Route path={USER_PAGE_PASSWORD_SIMPLE} element={<PasswordPage isSimplePW={true}/>}/>
        <Route path={USER_PAGE_SETTING} element={<UserSettingsPage/>}/>
        <Route path={USER_PAGE_LIST} element={<UserListPage/>}/>
        <Route path={USER_PAGE_DEVICE} element={<DevicePage/>}/>
        <Route path={USER_PAGE_LOGIN_HISTORY} element={<LoginHistoryPage/>}/>
        <Route path={USER_PAGE_ALERT} element={<UserAlert/>}/>
    </Route>
)

export const UserMenuFrame = () =>
{
    const getIsMobile = () => window.innerWidth <= 767;
    const [isMobile, setIsMobile] = useState(getIsMobile());
    const onWindowResize = useWindowResize();
    onWindowResize(() =>
    {
        setIsMobile(getIsMobile());
    })
    const location = useLocation();

    const getLinkClassName = (endpoint: string) => `list-group-item list-group-item-action d-flex align-items-center ${location.pathname === PathMaker(URL_USER_ROOT, endpoint) && "active"}`;
    return (location.pathname == URL_USER_ROOT ? <Navigate to={PathMaker(URL_USER_ROOT, USER_PAGE_INFO)}/> :
        <>
            <TopHeader backPreventURL={backPreventURL}/>
            <div className="page-body mt">
                <div className="container-xl">
                    <div className="card">
                        <div className="row g-0">
                            <div className="col-12 col-md-3 border-end">
                                <div className="card-body">
                                    <h4 className="subheader">내 정보관리</h4>
                                    <div className="list-group list-group-transparent">
                                        <Link to={PathMaker(URL_USER_ROOT, USER_PAGE_INFO)} className={getLinkClassName(USER_PAGE_INFO)}>마이페이지</Link>
                                        <Link to={PathMaker(URL_USER_ROOT, USER_PAGE_PASSWORD)} className={getLinkClassName(USER_PAGE_PASSWORD)}>계정 비밀번호 변경</Link>
                                        <Link to={PathMaker(URL_USER_ROOT, USER_PAGE_PASSWORD_SIMPLE)} className={getLinkClassName(USER_PAGE_PASSWORD_SIMPLE)}>간편 비밀번호 변경</Link>
                                        <Link to={PathMaker(URL_USER_ROOT, USER_PAGE_SETTING)} className={getLinkClassName(USER_PAGE_SETTING)}>사용자 설정</Link>
                                        <Link to={PathMaker(URL_USER_ROOT, USER_PAGE_LIST)} className={getLinkClassName(USER_PAGE_LIST)}>사원 목록</Link>
                                        <Link to={PathMaker(URL_USER_ROOT, USER_PAGE_DEVICE)} className={getLinkClassName(USER_PAGE_DEVICE)}>내 기기</Link>
                                        <Link to={PathMaker(URL_USER_ROOT, USER_PAGE_LOGIN_HISTORY)} className={getLinkClassName(USER_PAGE_LOGIN_HISTORY)}>로그인 기록</Link>
                                        <Link to={PathMaker(URL_USER_ROOT, USER_PAGE_ALERT)} className={getLinkClassName(USER_PAGE_ALERT)}>알림사항</Link>
                                    </div>
                                </div>
                            </div>
                            <Outlet/>
                        </div>
                    </div>
                    <br/>
                </div>
            </div>
        </>
    )
}

export default UserMenuFrame;
