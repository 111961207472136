import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import TableListControl, {GetItemStyle, TableItemType, TableListData} from "common/control/TableListControl";
import {LIST_COUNT} from "../../Define";
import ApprovalHandoverData from "../../data/ApprovalHandoverData";
import {
    SearchWithDateRangeControlClass,
    SearchWithDateRangeFullData
} from "common/control/SearchWithDateRangeControl";
import {SearchOptionProps} from "common/control/SearchControl";
import {SelectOptionData} from "common/component/SelectOption";
import {DEFAULT_DOCUMENT_SEARCH_PARAM, SearchDocumentParam} from "../../info/approval";

const HandoverDocumentPage = () =>
{
    const tableControl = useRef<TableListControl<ApprovalHandoverData>>(null);
    const searchControl = useRef<SearchWithDateRangeControlClass>(null);

    const navigate = useNavigate();

    const header = [
        {name: "인계자", key: "DocumentClassName", clickable: false, styleItem: GetItemStyle(10, false)},
        {name: "인수자", key: "DocumentID", clickable: true, styleItem: GetItemStyle(10, true)},
        {name: "인수인계문서", key: "Title", clickable: true, styleItem: GetItemStyle(60, true, true)},
        {name: "처리자", key: "Title", clickable: true, styleItem: GetItemStyle(10, true, true)},
        {name: "처리일", key: "Title", clickable: true, styleItem: GetItemStyle(10, true, true), type: TableItemType.Date},
    ];
    const searchKind: SearchOptionProps[] = [
        {
            autoSearch: false,
            options: [
                new SelectOptionData("인수인계문서", ""),
                new SelectOptionData("인계자", "")
            ]
        }
    ]

    const [search, setSearch] = useState<SearchDocumentParam>(DEFAULT_DOCUMENT_SEARCH_PARAM(searchKind));

    async function Search(search: SearchWithDateRangeFullData)
    {
        setSearch(SearchDocumentParam.fromSearchData(search));
        //검색시에는 테이블의 아이템, 페이지, 총 갯수 등이 초기화됨
        const total = await ApprovalHandoverData.GetCount(search);
        if (tableControl.current != null)
        {
            await tableControl.current.setTotalAsync(total ?? 0);
            await tableControl.current.refresh();
        }
    }

    const onCheck = (item: ApprovalHandoverData, checked: boolean, index: number) => {
        //체크시 여기 호출
        console.log(item);
        console.log(checked);
    }

    const onRefresh = async (offset: number, count: number, page: number, total: number): Promise<TableListData<ApprovalHandoverData>> =>
    {
        if (total > 0)
        {
            const list = await ApprovalHandoverData.GetList(search, (page - 1) * count, count)
            if (list != null) return {data: list};
        }

        return {data: []};
    }

    return (
        <>
            <SearchWithDateRangeControlClass ref={searchControl} dateRangeTitle={"처리일"} clearParam={TableListControl.URLParamKeys}
                                             defaultOptions={searchKind} defaultDateStart={search.start} defaultDateEnd={search.end}
                                             onChange={(search) => Search(search).then()}/>
            <TableListControl<ApprovalHandoverData> ref={tableControl} headerTable={header} autoRefresh={false} navigate={navigate} onRefresh={onRefresh} count={LIST_COUNT}
                                                    onItemClick={(value, header, data) =>
                                                        {
                                                            //
                                                        }}/>
        </>
    )
}

export default HandoverDocumentPage;
