// @ts-ignore
import CrossEditor, {NamoSEEvent, NamoSEParam} from 'react-crosseditor';
import {Component, createRef, EventHandler} from "react";
import IEditorProps from "./IEditorProps";
import React from 'react';
import {loadExternal, randomString} from '@hskernel/hs-utils';
import LoadingCircle from "../LoadingCircle";

type TextAreaNamoSEState = {
    isReady?: boolean,
}
export default class TextAreaNamoSE extends Component<IEditorProps<TextAreaNamoSE>, TextAreaNamoSEState> implements IEditor
{
    private Input = createRef<HTMLInputElement>();
    /**
     *
     * @type {NamoSE}
     */
    private editor: NamoSE;
    /**
     *
     * @type RefObject<HTMLDivElement>
     */
    private EditorDiv = createRef<HTMLDivElement>();
    private rootElement = createRef<HTMLDivElement>();

    /**
     *
     * @param {IEditorProps<TextAreaNamoSE>} props
     */
    constructor(props: IEditorProps<TextAreaNamoSE>) {
        super(props);

        this.state = {
        }

        this.onInitComplete = this.onInitComplete.bind(this);
        this.startLoop = this.startLoop.bind(this);
        this.stopLoop = this.stopLoop.bind(this);
    }

    onLoop?: NodeJS.Timeout;
    startLoop = () => this.onLoop = setInterval(() => this.save(), 500);
    stopLoop = () => clearInterval(this.onLoop);

    onInitComplete(editor: NamoSE)
    {
        this.setState({...this.state, isReady: false});
        editor.SetBodyValue(this.props.value ?? "");

        //editor.ShowToolbar(0, false)

        //this.startLoop();
    }

    componentWillUnmount() {
        this.stopLoop();
    }

    componentDidMount()
    {
        //나모 크로스에디터 4 메인 스크립트 (필수)
        if (this.rootElement.current != null) loadExternal(this.rootElement.current, ["/lib/crosseditor/js/namo_scripteditor.js"], () =>
        {
            if (typeof NamoSE !== 'undefined')
            {
                this.setState({...this.state, isReady: true});

                this.editor = new NamoSE(this.props.id);
                if (this.editor != null)
                {
                    this.editor.params = {
                        ParentEditor: this.EditorDiv.current ?? undefined,
                        MenuInEditor: true,
                        Width: "100%",
                        Chevron: false,
                        NewToolbar: true,
                        Menu: true,
                        QuickMenu: false,
                        ImageSrcBase64: true,
                        UserToolbar: true,
                        Placeholder: "여기에 내용을 입력하세요",
                        CreateToolbar:
                            "iconmenu|spacebar|fopen|print|topdf|pagebreak|spacebar|undo|redo|cut|copy|paste|pastetext|search|replace|selectall|spacebar|showruler|fixedwidth|fullscreen|tagcleaner|enter|"+
                            "word_style|spacebar|word_color|spacebar|word_script|spacebar|tabledraginsert|tableinsert|spacebar|image|flash|insertchart|hyperlink|remove_hyperlink|spacebar|specialchars|blockquote|blockquotetype|inserthorizontalrule|inserthorizontalruletype|enter|"+
                            "formatblock|fontname|fontsize|lineheight|spacebar|word_justify|spacebar|word_indentset|spacebar|word_listset",
                        DeleteCommand: [
                            "saveas", "layout", "autosave", "insertcode", "privacy", "validation", "translator", "dict",
                            "ce_imageeditor", "backgroundimage", "insertlayer", "layerattribute", "bookmark", "emoticon", "cancelattribute",
                            "txtmargin", "paragraphsetup", "templatelist", "insertdatetime", "insertdatetimelist", "responsive",
                        ],
                        Font: {"맑은 고딕":"맑은 고딕", "Dotum":"돋움", "Gulim":"굴림", "Batang":"바탕", "Gungsuh":"궁서",
                            "David":"David", "MS PGothic":"MS PGothic", "New MingLiu":"New MingLiu", "Simplified Arabic":"Simplified Arabic", "simsun":"simsun", "Arial":"Arial", "Courier New":"Courier New", "Tahoma":"Tahoma", "Times New Roman":"Times New Roman", "Verdana":"Verdana"},
                        DefaultFont: "맑은 고딕",
                        event: {
                            OnInitCompleted: (e) => this.onInitComplete(e.editorTarget),
                        }
                    }

                    this.editor.EditorStart();
                }
            }
        });
    }

    render()
    {
        let contents: JSX.Element | undefined = <h3 className="text-center">(편집기를 찾을 수 없습니다)</h3>;
        if(this.state.isReady === true) contents = <div style={{width:"100%",textAlign:"center",position:"absolute",top:"50px"}}><LoadingCircle size={"40px"} border={5} text={"편집기를 로딩중입니다..."}/></div>;
        else if(this.state.isReady === false) contents = undefined;

        return (
            <div ref={this.rootElement} style={{position:"relative"}}>
                <input ref={this.Input} type="hidden" name={this.props.name} value={this.props.value}/>
                <div ref={this.EditorDiv}>{contents}</div>
            </div>
        )
    }

    isReady = () => this.state.isReady;
    getContents() {
        if(this.editor == null) return undefined;
        return this.editor.GetBodyValue();
    }

    setContents(contents: string): void {
        if(this.editor != null) this.editor.SetBodyValue(contents);
    }

    save()
    {
        if(this.editor != null && this.Input.current != null)
        {
            this.Input.current.value = this.editor.GetBodyValue();
        }
    }
}