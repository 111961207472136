import {URL_API} from "Define";
import {SendValueAsync} from "API";
import axios from "axios";

export const ModuleID = "mailplug";
/**
 * 메일플러그
 */
export const EMAIL_MAILPLUG = "mailplug";

export const API_MAILPLUG = URL_API + "/mailplug";
export const API_MAILPLUG_CONFIG = API_MAILPLUG + "/config";
export const API_MAILPLUG_LOGIN = API_MAILPLUG + "/login";
export const API_MAILPLUG_MAIL_LIST_ALL = API_MAILPLUG + "/mail/list/All";


export type MailplugConfig = {
    Host: string,
    Domain: string,
}

export const GetConfigAsync = (): Promise<MailplugConfig | null> => SendValueAsync(axios.get(API_MAILPLUG_CONFIG), true);
export async function LoginAsync(Config: MailplugConfig, redirectURL?: string)
{
    const token = (await axios.post(API_MAILPLUG_LOGIN)).data;
    return `https://${Config.Host}/lw_api/token_sso/${token}?host_domain=${Config.Domain}&return_url=${redirectURL ?? ""}`;
}