import React, {useEffect} from "react";
import {LogoutAsync, STORAGE_KEY_FCM_TOKEN, unregisterFCMToken, URL_API_LOGOUT, URL_FRONT_LOGIN} from "Define";
import { useAuth } from "context/AuthContext";
import {useNavigate} from "react-router-dom";

const LogoutPage: React.FC = () =>
{
    const {setAuth} = useAuth();
    const Navigate = useNavigate();
    const logout = async () =>
    {
        await LogoutAsync(true);
        setAuth(null);
        Navigate(URL_FRONT_LOGIN, {replace: true});
    }
    useEffect(() =>
    {
        logout().then();
        //sleep(5000).then(() => logout())
    })

    return <div>로그아웃 중입니다.<br/>잠시만 기다려 주십시오...</div>
}

export default LogoutPage;
