import {Expose, Type} from "class-transformer";
import {API_SCHEDULE, API_SCHEDULES, ID_ALL, ScheduleShareType} from "../Define";
import {SendValueAsync, SendValueAsyncArrayType} from "API";
import axios from "axios";
import ScheduleFormData from "./ScheduleFormData";
import ScheduleDataBase from "./ScheduleDataBase";

const GetURL = (Type: ScheduleShareType | null, Year: string | number, Month: string | number, Day: string | number | null) => API_SCHEDULES.replace("{Type}", `${Type ?? ID_ALL}`).replace("{Year}", `${Year}`).replace("{Month}", `${Month}`).replace("{Day}", `${Day ?? "0"}`);

export default class ScheduleData extends ScheduleFormData
{
    @Expose() public LoginID: string;
    @Expose() public WriterName: string;
    @Expose() public WriterNameKor: string;
    @Expose() public WriterDeptCode: string;
    @Expose() public WriterDeptName: string;
    @Expose() public WriterPosition1Name: string;
    @Expose() public WriterPosition2Name: string;
    @Type(() => Date)
    @Expose() public WriteTime: Date = new Date();
    @Type(() => Date)
    @Expose() public ModifyTime: Date | null;

    /**
     *
     * @param {ScheduleShareType} Type
     * @param {string} Year
     * @param {string} Month
     * @param {string | null} Day
     * @param {boolean?} IsRange
     * @returns {Promise<ScheduleData[] | null>}
     * @constructor
     */
    public static async GetSchedulesAsync(Type: ScheduleShareType | null, Year: string | number, Month: string | number, Day: string | number | null, IsRange?: boolean)
    {
        const URL = GetURL(Type, Year, Month, Day);
        return SendValueAsyncArrayType<ScheduleData>(axios.get(`${URL}?Simple=false&IsRange=${IsRange ?? false}`), false, ScheduleData);
    }
    /**
     *
     * @param {ScheduleShareType} Type
     * @param {string} Year
     * @param {string} Month
     * @param {string | null} Day
     * @returns {Promise<ScheduleDataBase[] | null>}
     * @constructor
     */
    public static async GetSchedulesSimpleAsync(Type: ScheduleShareType | null, Year: string | number, Month: string | number, Day: string | number | null)
    {
        const URL = GetURL(Type, Year, Month, Day);
        return SendValueAsyncArrayType<ScheduleDataBase>(axios.get(`${URL}?Simple=true`), false, ScheduleDataBase);
    }

    public static async DeleteScheduleAsync(ID: string)
    {
        return await SendValueAsync(axios.delete(`${API_SCHEDULE}/${ID}`), false);
    }
}