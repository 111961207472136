import {Expose} from "class-transformer";

/**
 * 결재라인 공유범위 구분
 */
export type ApprovalLineShareType = "priv" | "dept" | "common";
export function GetApprovalLineShareText(share: ApprovalLineShareType)
{
    switch (share)
    {
        case "priv": return "개인";
        case "dept": return "부서";
        case "common": return "공용";
        default: return "(알 수 없음)";
    }
}

export default class ApprovalLineBase
{
    /**
     * 결재라인 명
     * @type {string}
     */
    @Expose() public LineName: string;
    /**
     * 공유범위 구분 (공통(common)/부서(dept)/개인(priv))
     * @type {ApprovalLineShareType}
     */
    @Expose() public ShareType: ApprovalLineShareType;
}