import React from "react";
import IFileViewer, {viewerFitWidthStyle} from "./IFileViewer";

/**
 * @deprecated 미완성
 */
export default class TextViewer extends IFileViewer
{
    render()
    {
        //let text = '';
        //if (this.props.fileURL.includes(';base64,')) text = Buffer.from(this.props.fileURL, 'base64');
        console.log(this.props.fileURL);
        return (
            <textarea style={viewerFitWidthStyle} value={"asdas"}/>
        )
    }
}