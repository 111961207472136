import { Expose } from "class-transformer";
import axios from "axios";
import {URL_API_COMPANY_POSITIONS} from "Define";
import {isEmptyString} from "@hskernel/hs-utils";
import {SendValueAsyncArrayType} from "API";

export default class PositionData
{
    @Expose() public GubunCode: string;
    @Expose() public PositionCode: string;
    @Expose() public PositionName: string;

    public static async getListAsync(gubunCode?: string)
    {
        let param = "";
        if(!isEmptyString(gubunCode)) param = `?GubunCode=${gubunCode}`;
        return await SendValueAsyncArrayType<PositionData>(axios.get(URL_API_COMPANY_POSITIONS + param), true, PositionData);
    }
}