//require('dotenv').config();
import {BrowserStorage} from "./lib/BrowserStorage";
import * as HSUtils from "./utils/HSUtils";
import {NavigateFunction} from "react-router-dom";
import {dateFormat, getParam, isEmptyString, PathMaker} from "@hskernel/hs-utils";
import _DefaultUserImage from "common/img/user.png";
import {APIData} from "API";
import {isAxiosError} from "axios";
import {DisconnectNotiFirebase, DisconnectNotiSignalRAsync} from "./App";
import axios from "axios";
import {RNMessageDataAPP} from "./lib/RNMessage/RNMessageAPP";
import {getWVData, isWVMode, RNDeviceInfoData} from "./lib/RNMessage/RNMessageWV";
import * as process from "process";
import PlatformWeb from 'platform';
import {ErrorPageParam} from "./page/error/Base";
import qs from "qs";

export const isDebugMode = () => process.env.NODE_ENV == "development";
export const isMockupMode = () => process.env.REACT_APP_MOCKUP_MODE == 'true';
export const toStringAny = (data: any) => (typeof data).toString() === 'object' ? JSON.stringify(data) : data;
export const backPreventURL = [
    '/',
    '/login'
]

//alert(JSON.stringify(window.ReactNativeWebView));
export const COMPANY = "이지정보";
export const PRODUCT = "이지";
export const TITLE = "그룹웨어";
export const TITLE_DEFAULT = `${PRODUCT} ${TITLE}`;
export const IMG_DEFAULT_LOGO = "/logo/easyinfo_144.png";

/**
 * 한 페이지에 보여줄 항목 갯수
 */
export const PAGE_COUNT = 10;
/**
 * 페이지가 보여질 갯수
 */
export const PAGE_MAX = 8;

/**
 * 관리자모드 헤더
 * @type {string}
 */
export const HEADER_ADMIN = "Hs-Mode-Admin";
export const HEADER_COMPANY_ID = "Hs-Company-ID";
export const HEADER_AUTH = 'Hs-Authorization';

export const STORAGE_KEY_LOGIN_APP_TOKEN = "LoginToken";
export const STORAGE_KEY_LOGIN_ID = "LoginID";
export const STORAGE_KEY_COMPANY_ID = "CompanyID";
export const STORAGE_KEY_FCM_TOKEN = "FCMToken";

//export const URL_MAIN = process.env.ENV === "development" ? "http://localhost:26840" : "https://dashboard.ihongju.com";
export const URL_MAIN = "";
export const URL_API_ROOT = "/api";
export const URL_API = URL_API_ROOT + "/v1";

export const URL_API_FILE_DOWNLOAD = URL_API + "/file/download";
export const URL_API_FILE_UPLOAD = URL_API + "/file/upload";

export const URL_API_LOGIN = URL_API + "/login";
export const URL_API_LOGIN_SSO = URL_API + "/login/sso";
export const URL_API_LOGOUT = URL_API + "/logout";
export const URL_API_LOGIN_HISTORY = URL_API_LOGIN + "/history";

export const URL_API_ALERT = URL_API + "/alert";
export const URL_API_ALERT_COUNT = URL_API_ALERT + "/count";
export const URL_API_ALERT_LIST = URL_API_ALERT + "/list";
export const URL_API_ALERT_VIEW = URL_API_ALERT + "/view";
export const URL_API_ALERT_DEVICE = URL_API_ALERT + "/device";
export const URL_API_ALERT_READ = URL_API_ALERT + "/read";
export const URL_API_ALERT_READ_ALL = URL_API_ALERT_READ + "/all";

export const URL_API_USER = URL_API + "/user";
export const URL_API_USERS = URL_API + "/users";
export const URL_API_USER_INFO = URL_API_USER + "/info";
export const URL_API_USER_SETTING = URL_API_USER + "/settings";

export const URL_API_LOGIN_PW = URL_API_LOGIN + "/password";

export const URL_API_COMPANY = URL_API + "/company";
export const URL_API_COMPANY_DEPT = URL_API_COMPANY + "/department";
export const URL_API_COMPANY_DEPTS = URL_API_COMPANY + "/departments";
export const URL_API_COMPANY_POSITIONS = URL_API_COMPANY + "/positions";
export const URL_API_COMPANY_INFO = URL_API_COMPANY + "/info";
export const URL_API_COMPANY_LOGO = URL_API_COMPANY + "/logo";
export const URL_API_COMPANY_FAVICON = URL_API_COMPANY + "/favicon";

export const URL_API_MODULE = URL_API + "/module";
export const URL_API_MODULE_MENU = URL_API_MODULE + "/menu";
export const URL_API_MODULE_MENU_GROUP = URL_API_MODULE + "/menu/group";

export const URL_API_DEVICE = URL_API + "/device";

export const URL_FRONT = "/";
//export const URL_FRONT_ADMIN = PathMaker(URL_FRONT, "admin");

export const PATH_FROM_MODULE = "modules";
export const URL_FRONT_MODULE = PathMaker(URL_FRONT, PATH_FROM_MODULE);//(admin?: boolean) => PathMaker(admin ? URL_FRONT_ADMIN : URL_FRONT, PATH_FROM_MODULE);

export const PATH_FRONT_MAIN = PathMaker(URL_FRONT, "");
export const URL_FRONT_MAIN = PathMaker(URL_FRONT, PATH_FRONT_MAIN);

export const PATH_PREVIEW_FILE = "preview/file";
export const URL_PREVIEW_FILE = PathMaker(URL_FRONT, PATH_PREVIEW_FILE);

export const URL_FRONT_LOGIN = PathMaker(URL_FRONT, "/login");
export const URL_FRONT_LOGOUT = PathMaker(URL_FRONT, "/logout");
export const URL_FRONT_ERROR = PathMaker(URL_FRONT, "/error");

export const PATH_FRONT_USER = "user";
export const URL_FRONT_USER = PathMaker(URL_FRONT, PATH_FRONT_USER);
export const URL_FRONT_USER_ALERT = PathMaker(URL_FRONT_USER, "/alert");

export const isShowMenu = () => getParam("menu") == "true";
export const isMobileUI = () => window.innerWidth <= 767;

export function getCompanyID(): string | undefined
{
    let CompanyID = getWVData('CompanyID');
    if (isEmptyString(CompanyID)) CompanyID = process.env.REACT_APP_COMPANY_ID;
    return CompanyID;
}


export type LoginMode = "Normal" | "APP" | "JWT";
export const getLoginMode = (): LoginMode => localStorage["LoginMode"] ?? "Normal";
export const setLoginMode = (Mode: LoginMode) => localStorage.setItem("LoginMode", Mode);
export const isAppMode = () => getLoginMode() == 'APP';

export function getDeviceInfo(): RNDeviceInfoData
{
    const filter = (text?: string) => isEmptyString(text) || text === 'unknown' ? undefined : text;

    // @ts-ignore
    if (isWVMode())
    {
        const device: RNDeviceInfoData = getWVData('DeviceInfo');
        if (isEmptyString(device.useragent)) device.useragent = filter(PlatformWeb.ua);
        return device;
    }
    else
    {
        const mode = isAppMode();
        return {
            id: undefined,
            platform: mode ? 'webapp' : 'web',
            os: 'web',
            base_os: filter(PlatformWeb.name),
            manufacturer: filter(PlatformWeb.manufacturer),
            name: filter(PlatformWeb.description),
            model: undefined,
            brand: undefined,
            system: filter(PlatformWeb.os?.family),
            version: filter(PlatformWeb.os?.version),
            useragent: filter(PlatformWeb.ua),
        };
    }
}

function getErrorPageURL(httpStatusCode: number)
{
    switch (httpStatusCode)
    {
        case 404: return URL_FRONT_ERROR + "/404";
        case 403: return URL_FRONT_ERROR + "/403";
        case 500: return URL_FRONT_ERROR + "/500";
        default: return URL_FRONT_ERROR;
    }
}

export const calcHeight = () => window.screen.height * 0.9;

export const printError = (error: any, message?: string) =>
{
    if (isEmptyString(message)) message = "";
    else message += "\n";

    console.error(error);
    if (isAxiosError(error))
    {
        const result = error.response == null ? null : APIData.fromData(error.response.data);
        if (result != null) alert(message + result.message);
        else alert(message + error.message);
    } else alert(message + error);
}

export function printAjaxFailMessage(jqXHR: XMLHttpRequest, exception_code: string)
{
    if (jqXHR.status === 0) return '네트워크에 연결되어있지 않습니다';
    else if (jqXHR.status === 404) return '페이지를 찾을 수 없습니다';
    else if (jqXHR.status === 500) return "서버 오류가 발생했습니다";
    else if (exception_code === 'parsererror') return "서버에서 잘못된 값을 반환했습니다";
    else if (exception_code === 'timeout') return "요청시간을 초과하였습니다";
    else if (exception_code === 'abort') return "서버에 요청 중 중단되었습니다";
    else return "알 수 없는 오류가 발생하였습니다. \n\n" + jqXHR.responseText;
}

export function GoErrorPage(httpStatusCode: number, message?: string | ErrorPageParam | null, Navigate?: NavigateFunction)
{
    let url = getErrorPageURL(httpStatusCode);

    if (Navigate)
    {
        let state: ErrorPageParam | undefined = undefined;
        if (message != null) state = typeof message == 'string' ? {message: message} : message;

        Navigate(url, {replace: true, state: state});
    }
    else
    {
        if (message != null)
        {
            if (typeof message == 'string') url += `?message=${encodeURIComponent(message)}`;
            else url += `?${qs.stringify(message, { skipNulls: true })}`;
        }
        window.location.replace(url);
    }
}

export function getAxiosAuthHeader()
{
    const auth_head = axios.defaults.headers.common["Authorization"];
    if (auth_head == undefined) return undefined;
    return isEmptyString(auth_head.toString()) ? null : auth_head.toString();
}

export function sendRNData(data: RNMessageDataAPP)
{
    // @ts-ignore
    if (isWVMode()) window.ReactNativeWebView.postMessage(JSON.stringify(data));
}

export function onBackClick(close = false)
{
    if (isWVMode()) sendRNData({type: "DO_BACK", data: close});
    else
    {
        if (close && window.opener != null)
        {
            window.close();
            return;
        }
        history.back();
    }
}

export async function registerFCMToken(token: string)
{
    //alert(`${URL_API_ALERT_DEVICE}/fcm/${encodeURIComponent(token)}`)
    try
    {
        await axios.post(`${URL_API_ALERT_DEVICE}/fcm/${token}`);
        localStorage[STORAGE_KEY_FCM_TOKEN] = token;
        console.info(`FCM 토큰 등록 성공!! (${token})`);
    }
    catch (ex)
    {
        console.error(`FCM 토큰 등록 실패!! ${toStringAny(ex)}`);
    }
}

export async function unregisterFCMToken(token?: string | null)
{
    const _token = token ?? localStorage[STORAGE_KEY_FCM_TOKEN];
    if (!isEmptyString(_token))
    {
        try
        {
            localStorage.removeItem(STORAGE_KEY_FCM_TOKEN);
            await axios.delete(`${URL_API_ALERT_DEVICE}/fcm/${encodeURIComponent(_token)}`);
        }
        catch (ex)
        {
            console.log("FCM 토큰 해제 실패!!");
            console.log(ex);
        }
    }
}

export async function LogoutAsync(disconnectNoti?: boolean)
{
    try { await axios.get(URL_API_LOGOUT); }
    catch (e)
    {
        console.log("Failed to logout");
        console.log(e);
    }

    try
    {
        if (isWVMode()) sendRNData({type: 'LOGOUT', data: null});

        localStorage.removeItem(STORAGE_KEY_LOGIN_APP_TOKEN);
        localStorage.removeItem(STORAGE_KEY_LOGIN_ID);
        localStorage.removeItem(STORAGE_KEY_COMPANY_ID);
    }
    catch (e)
    {
        console.log("Failed to remove LocalStorage");
        console.log(e);
    }

    if (disconnectNoti)
    {
        if (isWVMode()) await unregisterFCMToken();
        else
        {
            try { await DisconnectNotiSignalRAsync(); }
            catch (e)
            {
                console.log("Failed to disconnecting SignalR");
                console.log(e);
            }

            try { await DisconnectNotiFirebase(); }
            catch (e)
            {
                console.log("Failed to disconnecting Firebase");
                console.log(e);
            }
        }
    }
}

export type AxiosHeader = {
    [key: string]: string;
}

export const getURLParams = (): qs.ParsedQs => qs.parse(location.search, { ignoreQueryPrefix: true });
export const getDateString = (date: Date | undefined | null) => date == null ? "" : dateFormat(date, 'yyyy-mm-dd');

export const SEARCH_KIND_TITLE = "TITLE";
export const SEARCH_KIND_NAME = "NAME";

export function reactPrintDummy(arg: any)
{
    console.log(arg);
}

export function getAbsPath(URL: string): string
{
    //PathMaker
    /*
    URL = URL.startsWith('/', 0) ? `${window.location.pathname}#${URL}` : window.location.href + URL;

    console.log(process.env)
    console.log(window.location.pathname);
    console.log(window.location.href);
    console.log(URL);
    */
    //console.log(path.join(__dirname, URL));
    //URL = path.join(__dirname, URL);
    return URL;
}

export const DefaultUserImage = _DefaultUserImage;

export const POLICY_PW = "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$";


export const STORAGE_KEY_MEMBER = "STORAGE_KEY_MEMBER";

/*
const STORAGE_MEMBER = new BrowserStorage(STORAGE_KEY_MEMBER, new UserDataBaseMapper());
export function getStorageMember(): UserDataBase | null { return STORAGE_MEMBER.get(); }
export function setStorageMember(Member: UserDataBase | null): void { if (Member === null) STORAGE_MEMBER.clear(); else STORAGE_MEMBER.set(Member, false); }
*/

export function clearStorage()
{
    localStorage.clear();
    sessionStorage.clear();
}

export function alertError(prefix?: string | null, msg?: string | null)
{
    alert(prefix + "오류가 발생하였습니다" + (HSUtils.isEmptyString(msg) ? "" : "\n\n" + msg));
}

//export const getWebView = () => window.ReactNativeWebView.window

let preventEnable = false;

function preventListener(e: BeforeUnloadEvent)
{
    e.preventDefault();
    e.returnValue = '';
}

const preventGoBack = () =>
{
    history.go(1);
    console.log('prevent go back!');
};

export function setWindowClosePrevent(prevent: boolean)
{
    if (prevent)
    {
        if (!preventEnable)
        {
            window.addEventListener('beforeunload', preventListener);
            window.addEventListener('popstate', preventGoBack);
            preventEnable = true;
        }
    }
    else
    {
        window.removeEventListener('beforeunload', preventListener);
        window.removeEventListener('popstate', preventGoBack);
        preventEnable = false;
    }
}
