import {Component} from "react";
import UserDataBase from "data/API/User/UserDataBase";

export class ApprovalSelectorData<T>
{
    /**
     *
     * @param user
     * @param kind ApprovalStatus.STATUS_KIND_*
     * @param {boolean} constable 상수 여부 (건들 수 없음)
     * @param {boolean} extra 확장 데이터
     */
    constructor(user: UserDataBase, kind: string, constable?: boolean, extra?: T)
    {
        this.user = user;
        this.kind = kind;
        if(constable != null) this.constable = constable;
        if(extra != null) this.extra = extra;
    }
    public user: UserDataBase;
    public kind: string;
    public constable = false;
    public extra: T;
}

type ISelectorProps<T> = {
    /**
     * 한번에 추가할 수 있는 최대값
     */
    max?: number,
    company?: string,
    props: T,
}
type ISelectorState<S> = {
    data: Map<string, ApprovalSelectorData<S>>,
    check: Map<string, any>
}
export class ISelector<T, S> extends Component<ISelectorProps<T>, ISelectorState<S>>
{
    /*
    static getMap(datas?: ApprovalSelectorData<any>[])
    {
        const map = new Map<string, ApprovalSelectorData<any>>();
        if(datas != null) datas.map((data) => map.set(data.user.LoginID, data));
        return map;
    }
     */

    constructor(props: ISelectorProps<T>)
    {
        super(props);
        this.getData = this.getData.bind(this);
        this.addData = this.addData.bind(this);
        this.getOffset = this.getOffset.bind(this);
        this.handleSingleCheck = this.handleSingleCheck.bind(this);
        this.handleAllCheck = this.handleAllCheck.bind(this);
        this.removeHandle = this.removeHandle.bind(this);

        this.state = {
            data: new Map<string, ApprovalSelectorData<S>>(),
            check: new Map<string, UserDataBase>(),
        }
    }

    getCount(): number { return this.state.data.size; }
    getData(): ApprovalSelectorData<S>[] { return Array.from<ApprovalSelectorData<S>>(this.state.data.values()); }
    addData(datas: ApprovalSelectorData<S>[])
    {
        datas = datas.filter(data => !this.state.data.has(data.user.LoginID));
        let count = datas.length;
        if(this.props.max != null && datas.length + this.state.data.size > this.props.max)
        {
            if(this.state.data.size >= this.props.max) {alert(`최대 ${this.props.max}개까지 선택할 수 있습니다`); return;}
            else
            {
                count = Math.min(this.props.max - this.state.data.size, datas.length);
                alert(`${this.props.max}개가 넘어가므로 ${count} 개만 추가합니다`);
            }
        }

        for(let i = 0; i < count; i++)
            this.state.data.set(datas[i].user.LoginID, datas[i]);
        this.setState({...this.state, data: this.state.data});
    }

    clearData()
    {
        this.state.data.clear();
        this.state.check.clear();
        this.setState({data: this.state.data, check: this.state.check});
    }

    /*
    protected handleFinalCheck(checked: boolean, LoginID: string)
    {
        if(this.state.data != null)
        {
            const value = this.state.data.get(LoginID);
            if(value) value.final = checked;
            this.setState({...this.state, data: this.state.data});
        }
    }
    */

    getOffset()
    {
        let count = 0;
        this.state.data.forEach(data => {if(data.constable) count++});
        return count;
    }

    protected handleSingleCheck(checked: boolean, LoginID: string)
    {
        if(this.state.data != null)
        {
            // 단일 선택 시 체크된 아이템을 배열에 추가
            if (checked) this.state.check.set(LoginID, null);
            // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
            else this.state.check.delete(LoginID);

            this.setState({...this.state, check: this.state.check});
        }
    }

    // 체크박스 전체 선택
    protected handleAllCheck(checked: boolean)
    {
        const check = this.state.check;
        if (checked)
        {
            if(this.state.data != null)
                this.state.data.forEach((value) => { if(!value.constable) check.set(value.user.LoginID, null); });
        }
        else check.clear();

        this.setState({...this.state, check: check});
    }

    /**
     * 체크된 항목 삭제
     * @protected
     */
    protected removeHandle()
    {
        this.state.check.forEach((value, LoginID) =>  this.state.data.delete(LoginID));
        this.state.check.clear();
        this.setState({...this.state, data: this.state.data, check: this.state.check});
    }
}