import React, {useEffect, useState} from "react";
import parseHTML from "html-react-parser";
import FileAttachControl from "common/control/FileAttachControl";
import {useNavigate, useParams} from "react-router-dom";
import BoardData from "../data/BoardData";
import {getAxiosAuthHeader, GoErrorPage} from "Define";
import {BoardKindtoName, GetBoardURL, GetBoardWriteURL} from "../Define";
import {useAuth} from "context/AuthContext";
import ChatControl, {ChatData} from "common/control/ChatControl";
import BoardCommentForm from "../data/BoardCommentForm";
import BoardComment from "../data/BoardComment";


const BoardView = () =>
{
    const [busy, setBusy] = useState(false);
    const {BoardKind, BoardID} = useParams();
    const {auth} = useAuth();
    const [data, setData] = useState<BoardData | null>();
    const Navigate = useNavigate();

    useEffect(() =>
    {
        if(BoardKind == null || BoardID == null) GoErrorPage(404, null, Navigate);
        else
        {
            BoardData.GetViewAsync(BoardKind, BoardID)
                .then((data) => setData(data))
                .catch((error) =>
                {
                    alert(error.message);
                    Navigate(-1);
                })
        }
    }, [BoardKind, BoardID]);

    /**
     * 댓글 가져오기
     * @returns {Promise<ChatData[]>}
     */
    const getCommentDataAsync = async (): Promise<ChatData[]> =>
    {
        let comment: ChatData[] = [];
        if (BoardKind != null && BoardID != null && auth != null)
        {
            const list = await BoardComment.GetListAsync(BoardKind!, BoardID!);
            if(list != null)
                comment = list.map(data => data.toChatData(auth.user.LoginID))
        }

        return comment;
    }

    const writeCommentAsync = async (text: string, tag?: any) =>
    {
        const comment = new BoardCommentForm();
        comment.Contents = text;
        return (await comment.WriteAsync(BoardID!, BoardKind!)).toChatData(auth!.user.LoginID);
    }
    //const editCommentAsync = async (data: ChatData)=>{}

    /**
     * 목록으로
     */
    const onGotoList = () => Navigate(GetBoardURL(BoardKind!) + document.location.search);
    /**
     * 게시글 삭제
     */
    const onDelete = () =>
    {
        if(confirm("정말 게시글을 삭제하시겠습니까?"))
        {
            setBusy(true);
            BoardData.DeleteAsync(BoardKind!, BoardID!)
                .then(() => onGotoList())
                .finally(() => setBusy(false))
        }
    }
    const getModifyButton = () => auth!.user.LoginID != data!.LoginID ? "" :
        <>
            <input type="button" className="btn btn-danger" value="삭제" disabled={busy} onClick={onDelete}/>&nbsp;&nbsp;
            <input type="button" className="btn btn-teal" value="수정" disabled={busy} onClick={() => Navigate(GetBoardWriteURL(BoardKind!, BoardID!))}/>&nbsp;&nbsp;
        </>
    return (BoardKind == null || BoardID == null ? <h1>잘못된 접근입니다</h1> :
        <>
            <ChatControl title="댓글" showBadge={true} showInput={true} showProfile={true} showProfileMe={true} showBubbleCornMe={true} enterSend={true} inputPlaceholderText="댓글 입력" sendButtonText={"작성"} onSend={writeCommentAsync} onRefresh={getCommentDataAsync}/>
            <div className="card-body placeholder-glow">
                <div className="row">
                    <h2 className="col">{BoardKindtoName(BoardKind)} 게시글</h2>
                </div>
                <div className="row" style={{borderBottom:"1px solid var(--tblr-border-color)",borderTop:"1px solid var(--tblr-border-color)"}}>
                    <div className="col-lg-1 col-sm-12 pb-2 pt-2">
                        <b>작성자</b>
                    </div>
                    <div className="col-lg-11 col-sm-12 pb-2 pt-2">
                        {data == null ? <div className="placeholder col-9">..</div>:data.WriterName}
                    </div>
                </div>
                <div className="row" style={{borderBottom:"1px solid var(--tblr-border-color)"}}>
                    <div className="col-lg-1 col-sm-12 pb-2 pt-2">
                        <b>작성일</b>
                    </div>
                    <div className="col-lg-11 col-sm-12 pb-2 pt-2 ">
                        {data == null ? <div className="placeholder col-9">..</div> : data.WriteDate.format("yyyy-mm-dd TT hh:MM:ss")}
                    </div>
                </div>
                <div className="row" style={{borderBottom:"1px solid var(--tblr-border-color)"}}>
                    <div className="col-lg-1 col-sm-12 pb-2 pt-2">
                        <b>조회수</b>
                    </div>
                    <div className="col-lg-11 col-sm-12 pb-2 pt-2 ">
                        {data == null ? <div className="placeholder col-9">..</div> : data.Views}
                    </div>
                </div>
                <div className="row" style={{borderBottom:"1px solid var(--tblr-border-color)"}}>
                    <div className="col-lg-1 col-sm-12 pb-2 pt-2">
                        <b>제목</b>
                    </div>
                    <div className="col-lg-11 col-sm-12 pb-2 pt-2">
                        {data == null ? <div className="placeholder col-9">..</div> : data.Title}
                    </div>
                </div>
                <div className="row" style={{borderBottom:"1px solid var(--tblr-border-color)"}}>
                    <div className="col-lg-1 col-sm-12 pb-2 pt-2">
                        <b>내용</b>
                    </div>
                    <div className="col-lg-11 col-sm-12 pb-2 pt-2 " style={{minHeight:"400px"}}>
                        {data == null ? <div className="placeholder col-9">..</div> : parseHTML(data.Contents)}
                    </div>
                </div>
                <div className="mt-3 card">
                    <div className="card-body">
                        {data == null ? <div className="placeholder col-9">..</div> : <FileAttachControl attachs={data.Attach} authToken={getAxiosAuthHeader()}/>}
                    </div>
                </div>
            </div>
            <div className="card-footer text-end">
                {data == null ? <div className="placeholder col-9">..</div> : getModifyButton()}
                <input type="button" className="btn" value="목록으로" onClick={onGotoList}/>
            </div>
        </>
    )
}

export default BoardView;
