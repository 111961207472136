import "reflect-metadata";
import {Expose, Type} from "class-transformer";
import axios from "axios";
import * as Define from "../Define";
import {SendValueAsyncArrayType, SendValueAsyncType} from "API";

export default class ApprovalDocumentTypeData
{
     @Expose() public Category: string;
     @Expose() public DocumentClass: string;
     @Expose() public ClassName: string;
     @Expose() @Type(() => Date)
     public RegDate: Date;
    @Expose() public Readonly: boolean;
    @Expose() public UseERP: boolean;

    public static async GetDocumentTypeList(Keyword?: string|null): Promise<ApprovalDocumentTypeData[] | null>
    {
        return await SendValueAsyncArrayType<ApprovalDocumentTypeData>(axios.get<ApprovalDocumentTypeData[]>(Define.URL_API_APPROVAL_DOCU_TYPE + '?Keyword=' + Keyword), true, ApprovalDocumentTypeData);
    }
    public static async GetDocumentType(DocumentClass: string, process404: boolean): Promise<ApprovalDocumentTypeData | null>
    {
        return await SendValueAsyncType<ApprovalDocumentTypeData>(axios.get<ApprovalDocumentTypeData>(Define.URL_API_APPROVAL_DOCU_TYPE + "/" + DocumentClass), process404, ApprovalDocumentTypeData);
    }
}