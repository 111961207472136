import { isEmptyString } from "@hskernel/hs-utils";
import AttachData from "data/API/IO/AttachData";
import {GetAPIFileDownURL} from "../Define";
import {Expose} from "class-transformer";

export default class BoardFileAttach extends AttachData
{
    @Expose() public BoardKind: string;
    @Expose() public BoardID: string;

    public override getURL = () => isEmptyString(this.CDNURL) ? this.getURLWithParams(GetAPIFileDownURL(this.BoardKind, this.Key)): this.CDNURL;
}
