import {Route} from "react-router-dom";
import React from "react";
import {redirectModulePath} from "modules/ModuleInfo";

import {DEFAULT_MODULE_PATH} from "../info/approval";
import {
    PATH_APPROVAL,
    PATH_APPROVAL_FORM,
    PATH_APPROVAL_VIEW,
    PATH_APPROVAL_WRITE, PATH_APPROVAL_WRITE_FORMAT, PATH_APPROVAL_WRITE_TEMP,
    PATH_APPROVAL_LIST, PATH_APPROVAL_SETTING, LIST_KIND_TEMP, MENU_HANDOVER, MENU_HANDOVER_DOC

} from "../Define";
import DocumentFormatListPage from "../page/DocumentFormatListPage";
import DocumentListPage from "../page/document/DocumentListPage";
import DocumentViewPage from "../page/document/DocumentViewPage";
import DocumentWritePage from "../page/document/DocumentWritePage";
import LineSettingPage from "../page/setting/LineSettingPage";
import InsteadSettingPage from "../page/setting/InsteadSettingPage";
import HandoverManagePage from "../page/handover/HandoverManagePage";
import HandoverDocumentPage from "../page/handover/HandoverDocumentPage";
import DocumentViewFormPage from "../page/document/DocumentViewFormatPage";

//루트면 리다이렉트 되게 하기
const ApprovalRouter = (admin: boolean) =>
{
    return (
        <Route path={PATH_APPROVAL} element={redirectModulePath(PATH_APPROVAL, DEFAULT_MODULE_PATH(admin))}>
            <Route path={PATH_APPROVAL_VIEW}>
                <Route path=":documentID" element={<DocumentViewPage/>}/>
                <Route path="format/:Class" element={<DocumentViewFormPage/>}/>
            </Route>
            <Route path={PATH_APPROVAL_FORM + "/:ClassOrID"} element={<DocumentWritePage/>}/>
            <Route path={PATH_APPROVAL_LIST + "/:kind"} element={<DocumentListPage/>}/>
            <Route path={PATH_APPROVAL_WRITE}>
                <Route path={PATH_APPROVAL_WRITE_FORMAT} element={<DocumentFormatListPage/>}/>
                <Route path={PATH_APPROVAL_WRITE_TEMP} element={<DocumentListPage kind={LIST_KIND_TEMP}/>}/>
            </Route>
            <Route path={PATH_APPROVAL_SETTING}>
                <Route path="line" element={<LineSettingPage/>}/>
                <Route path="instead" element={<InsteadSettingPage/>}/>
            </Route>
            <Route path={MENU_HANDOVER}>
                <Route path="" element={<HandoverManagePage/>}/>
                <Route path={MENU_HANDOVER_DOC} element={<HandoverDocumentPage/>}/>
            </Route>
        </Route>
    )
}

export default ApprovalRouter;