import React, {FormEvent} from "react";
import {useAuth} from "context/AuthContext";
import {URL_FRONT_LOGOUT} from "Define";
import {APIData} from "API";
import UserSettingsData from "data/API/User/UserSettingsData";
import {useNavigate} from "react-router-dom";
import InputRadioYN from "common/input/InputRadioYN";

const UserSettingsPage = () =>
{
    const Navigate = useNavigate();
    const {auth, setSetting} = useAuth();

    const onSubmit = async (e: FormEvent<HTMLFormElement>) =>
    {
        e.preventDefault();
        const setting = UserSettingsData.fromForm(e.currentTarget)
        try
        {
            await setting.SaveAsync();
            if(auth != null)
            {
                auth.setting.SimplePWUse = setting.SimplePWUse;
                setSetting(auth.setting);
            }
            alert("저장 하였습니다");
        }
        catch (ex: any)
        {
            //비밀번호 오류횟수 초과로인한 로그아웃
            if(ex.response.status == 401) Navigate(URL_FRONT_LOGOUT);

            const error = APIData.fromData(ex.response.data)
            if(error != null) alert(error.message);
        }
    }

    return (
        <form className="col-12 col-md-9 d-flex flex-column" onSubmit={onSubmit}>
            <div className="card-body" style={{minHeight: "800px"}}>
                <h2 className="mb-4">사용자 설정</h2>
                <h3 className="card-title mt-4">설정 변경</h3>
                <div className="row g-3">
                    <div className="col-md">
                        <div className="form-label">결재 및 간편 비밀번호 사용</div>
                        {auth == null ? "..." : <InputRadioYN name="SimplePWUse" value={auth.setting.SimplePWUse}/>}
                    </div>
                    <div className="col-md">
                        <div className="form-label">ERP 사용 (예/아니오)</div>
                        <p className="text-muted">{auth == null ? "..." : (auth.setting.ERPUse ? "예" : "아니오")}</p>
                    </div>
                    <div className="col-md">
                        <div className="form-label">사용 정지 (예/아니오)</div>
                        <p className="text-muted">{auth == null ? "..." : (auth.setting.UsageStop ? "예" : "아니오")}</p>
                    </div>
                </div>
            </div>
            <div className="card-footer bg-transparent mt-auto">
                <div className="btn-list justify-content-end">
                    <input type="submit" className="btn btn-primary" value="저장"/>
                </div>
            </div>
        </form>
    )
}

export default UserSettingsPage;