import React, {Component} from 'react';
import {useEffect, useState} from "react";
import {URL_API_COMPANY_DEPTS} from "Define";
import {DepartmentData} from "data/API/DepartmentData";
import {SendValueAsync} from "API";
import {randomKey} from "utils/HSUtils";
import axios from "axios";
import styles from './index.module.css';

/*eslint @typescript-eslint/no-var-requires: "off"*/
import IconOpen from "./icon/folder-open.png";
import IconClose from "./icon/folder-close.png";
import IconDefault from "./icon/folder-default.png";

type DepartmentPickControlProps = {
    /**
     * 부서가 선택될 때 발생 하는 이벤트 입니다
     * @param dept
     */
    onSelect?: (dept: DepartmentData, isInit: boolean) => void,
    /**
     * 초기 부서 코드 입니다
     */
    deptCode?: string,
}

export type DepartmentEx = DepartmentData & {
    Sub: any
}

const DepartmentPickControl: React.FC<DepartmentPickControlProps> = ({onSelect, deptCode}) =>
{
    const [data, setData] = useState<any>(null);
    const [keys, setKeys] = useState<string[] | null>(null);

    useEffect(() => {
        SendValueAsync<any>(axios.get(URL_API_COMPANY_DEPTS), false).then(data =>
        {
            setData(data);
            if(onSelect != null)
            {
                if(deptCode == null)
                {
                    const keys = Object.keys(data);
                    onSelect(data[keys[0]], true);
                }
                else
                {
                    //현재 로그인한 사용자의 부서코드로 부서폴더 찾기
                    const find = findDept(data, deptCode);
                    if(find != null)
                    {
                        setKeys(find.key);
                        onSelect(find.dept, true);
                    }
                }
            }
        });
    }, []);

    return (
        data == null ? <></> :
        <div>
            {
                Object.keys(data).map((key, i) =>
                {
                    const subItem = data[key];
                    return (<SubDepartment key={randomKey(i)} rootKey={key} currentKey={key} findKeys={keys ?? []} onSelect={onSelect} item={subItem} depth={0} deptCode={deptCode}/>);
                })
            }
        </div>
    )
}
export default DepartmentPickControl;


type SubDepartmentProps = DepartmentPickControlProps & {
    parent?: SubDepartment, //부모
    item: DepartmentEx, //부서 아이템,
    depth: number, //깊이
    rootKey: string, //루트 키
    currentKey: string //현재 키
    findKeys: string[] //찾은 키값
}

type SubDepartmentState = {
    openFd: boolean;
}

class SubDepartment extends Component<SubDepartmentProps, SubDepartmentState>
{
    constructor(props: SubDepartmentProps) {
        super(props);
        this.selectFD = this.selectFD.bind(this);
        this.openFD = this.openFD.bind(this);

        this.state = {
            openFd: false,
        }
    }

    componentDidMount()
    {
        if(this.props.currentKey.length > this.props.depth)
        {
            /*
            const parentKey = this.props.item.ParentCode ?? this.props.rootKey;//this.props.findKey.joinEx(null, 0, this.props.depth + 1);
            const pattern = new RegExp(`${parentKey}.*`);
            if(pattern.test(this.props.item.DepartmentCode)) this.openFD();
             */

            if(this.props.findKeys[this.props.depth] == this.props.currentKey) this.openFD();
        }
    }
    //console.log(code);

    private selectFD() { if(this.props.onSelect != null) this.props.onSelect(this.props.item, false);}
    private openFD() { this.setState({openFd: !this.state.openFd});  }

    render()
    {
        let icon = IconDefault;
        if(Object.keys(this.props.item.Sub).length > 0)
            icon = this.state.openFd ? IconOpen : IconClose;

        return (
            <div>
                {this.props.item.Level > 0 && <span className={styles.folder_line} style={{width: 9}}></span>}
                <div style={{paddingBottom: "5px", display: "inline-block", cursor: "pointer"}}>
                    <img onClick={this.openFD} src={icon} style={{marginRight: "3px"}}/>
                    <span onClick={this.selectFD}>{this.props.item.Name}</span>
                </div>
                {this.state.openFd ? <div className={this.props.item.Level > 0 ? styles.folder_list + " " + styles.padding : styles.folder_list + " " + styles.level0}>
                    {Object.keys(this.props.item.Sub).map((key, i) =>
                        {
                            const child = this.props.item.Sub[key];
                            return (<SubDepartment key={randomKey(i)} rootKey={this.props.rootKey} currentKey={key} findKeys={this.props.findKeys} parent={this} depth={this.props.depth + 1} item={child} onSelect={this.props.onSelect} />)
                        }
                    )}
                </div> : ""}
            </div>
        );
    }
}


type findKeyResult = {
    key: string[],
    dept: DepartmentData
}
function findDept(deptData: any, deptCode: string): findKeyResult | null
{
    function _find(current: any, key: string[], parentKey: string): findKeyResult | null
    {
        if(current.DepartmentCode === deptCode) return {key: key, dept: current};

        const keys = Object.keys(current.Sub);
        for(let i = 0; i < keys.length; i++)
        {
            /*
            console.log(parentKey + keys[i])
            if(parentKey + keys[i] === deptCode)
            {
                console.log(key)
                console.log(current);
                return;
            }
            else */
            const dept = _find(current.Sub[keys[i]], key.concat(keys[i]), parentKey + keys[i]);
            if(dept != null) return dept;
        }
        return null;
    }

    if(deptCode == null)
    {
        const keys = Object.keys(deptData);
        return keys.length > 0 ? {key: [keys[0]], dept: deptData[keys[0]]} : null;
    }
    else
    {
        const rootKeys = Object.keys(deptData);
        for(let i = 0; i < rootKeys.length; i++)
        {
            const rootKey = rootKeys[i];
            const dept = _find(deptData[rootKey], [rootKey], rootKey);
            if(dept != null) return dept;
        }
    }

    return null;
}
