import React from "react";

type PageWaiterTablerProps = {
    message: string
}
const PageLoadingTabler = ({message}: PageWaiterTablerProps) =>
{
    return (
        <div>
            <div className="page page-center">
                <div className="container container-slim py-4">
                    <div className="text-center" style={{marginTop: "300px"}}>
                        <div className="text-muted mb-3" style={{fontSize:"16px"}}>{message == null ?? "준비중 입니다"}<br/>잠시만 기다려주세요.</div>
                        <div className="progress progress-sm">
                            <div className="progress-bar progress-bar-indeterminate"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageLoadingTabler;