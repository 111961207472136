import {Expose, Type} from "class-transformer";
import {IsArray} from "class-validator";
import UserDataLineBase from "./UserDataLineBase";
import UserDataBase from "data/API/User/UserDataBase";
import {SendValueAsync,  SendValueAsyncType} from "API";
import axios from "axios";
import {
    URL_API_APPROVAL_LINE_COUNT,
    URL_API_APPROVAL_LINE_DOCU,
} from "../Define";

export default class ApprovalLineDocuData
{
    /**
     * 결재/합의자 목록
     * @type {UserDataLineBase[]}
     */
    @Expose() @IsArray() @Type(() => UserDataLineBase)
    public Approval: UserDataLineBase[];
    /**
     * 시행자 목록
     * @type {UserDataBase[]}
     */
    @Expose() @IsArray() @Type(() => UserDataBase)
    public ExecUser: UserDataBase[];
    /**
     * 수신 및 참조자 목록 목록
     * @type {UserDataBase[]}
     */
    @Expose() @IsArray() @Type(() => UserDataBase)
    public RefUser: UserDataBase[];

    public static GetAsync(DocuClass: string)
    {
        return SendValueAsyncType<ApprovalLineDocuData>(axios.get(`${URL_API_APPROVAL_LINE_DOCU}/${DocuClass}`), false, ApprovalLineDocuData);
    }

    public static SetAsync()
    {
        return SendValueAsync<number>(axios.get(`${URL_API_APPROVAL_LINE_COUNT}?All=${false}`), false);
    }
}