import styles from './DocumentViewerControl.module.css';
import "../style/ApprovalDocument.css";
import React, {Component, createRef, CSSProperties, useRef} from "react";
import parseHTML from "html-react-parser";
import {dateFormat} from "utils/date.format";
import {randomKey} from "utils/HSUtils";
import DocumentHSAIDA from "../data/hsaida/DocumentHSAIDA";
import ApprovalLineControl from "./ApprovalLineControl";
import {APPROVER_KIND_AGREE, APPROVER_KIND_APPROVAL} from "../data/ApprovalStatus";
import {DocumentViewOpen} from "../page/document/DocumentViewPage";
import useWindowResize from "beautiful-react-hooks/useWindowResize";
import html2canvas from "html2canvas";
import {isEmptyString} from "@hskernel/hs-utils";
import {isMockupMode} from "Define";

type DocumentViewerProps = {
    doc: DocumentHSAIDA,
}

export default class DocumentViewerControl extends Component<DocumentViewerProps, DocumentViewerProps> {
    constructor(props: DocumentViewerProps) {
        super(props);

        this.state = props;

        this.setDocument = this.setDocument.bind(this);
    }

    private Div = createRef<HTMLDivElement>();

    public setDocument = (doc: DocumentHSAIDA) => this.setState({doc: doc});


    public async doCapture(): Promise<HTMLCanvasElement | null>
    {
        if(this.Div.current != null) return await html2canvas(this.Div.current);
        else return null;
    }

    render()
    {
        const doc = this.state.doc;
        return  (
            <div ref={this.Div} className={`${styles.viewer_wrap} viewer_wrap`}>
                <h2 className={styles.approval_doc_title}>{doc.DocumentClassName ?? ""}</h2>
                <table className={`${styles.approval_form} ${styles.form_view}`} style={{margin: "10px auto 0",borderBottom:"none"}}>
                    <thead>
                    <tr>
                        <td className={styles.form_head}>문 서 번 호</td>
                        <td>{isMockupMode() ? '테스트-2024-000001' : doc.DocumentID}</td>
                        <ApprovalLineControl {...ApprovalLineControl.FromApprovalStatus(doc.Approval, APPROVER_KIND_APPROVAL)} placeHolder=""/>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>작 성 일 시</td>
                        <td>{dateFormat(doc.WriteDate, "yyyy-mm-dd TT hh:MM")}</td>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>기 안 부 서</td>
                        <td>{doc.WriteDeptName}</td>
                        <ApprovalLineControl {...ApprovalLineControl.FromApprovalStatus(doc.Approval, APPROVER_KIND_AGREE)} placeHolder=""/>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>기&nbsp;&nbsp;안&nbsp;&nbsp;자</td>
                        <td>{doc.WriteNameKor}</td>
                    </tr>
                    {doc.DocumentClass === "1010" &&
                        <>
                            <tr>
                                <td className={styles.form_head}>협조의뢰부서</td>
                                <td colSpan={3}>{doc.CollaboDeptName1}</td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>협조요청부서</td>
                                <td colSpan={3}>{doc.CollaboDeptName2}</td>
                            </tr>
                        </>
                    }
                    <tr>
                        <td className={styles.form_head}>수신/참조</td>
                        <td colSpan={3}>
                            {isMockupMode() ? '홍길동 사원, 테스터 대리, 이아무개 전무' : (doc.IsRefUser ? doc.RefUser.map((data) => data.toString()).join(', ') : '')}
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>집&nbsp;행&nbsp;자</td>
                        <td colSpan={3}>
                            {isMockupMode() ? '박아무개 사원, 김아무개 부장' : (doc.IsExecUser ? doc.ExecUser.map((data) => data.toString()).join(', ') : '')}
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>집&nbsp;행&nbsp;일&nbsp;자</td>
                        <td colSpan={3} style={{height: "39px"}}>
                            {doc.ExecDate == null ? "" : dateFormat(doc.ExecDate, "yyyy-mm-dd TT hh:mm")}
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.form_head}>제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</td>
                        <td colSpan={3}>{doc.Title} </td>
                    </tr>
                    {doc.DocumentClass == "2010" || doc.DocumentClass == "2040" || doc.DocumentClass == "2050" &&
                        <>
                            <tr>
                                <td className={styles.form_head}>내용</td>
                                <td colSpan={3}></td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>사전보고 수령자</td>
                                <td colSpan={3}></td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>사전보고 유무</td>
                                <td colSpan={3}></td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>사전보고 시각</td>
                                <td colSpan={3}></td>
                            </tr>
                        </>
                    }

                    {doc.DocumentClass == "1040" &&
                        <>
                            <tr>
                                <td className={styles.form_head}>신청사유</td>
                                <td colSpan={3}></td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>사유발생일</td>
                                <td colSpan={3}>{`${doc.IssueStartDate} ~ ${doc.IssueEndDate}`}</td>
                            </tr>
                            <tr>
                                <td className={styles.form_head}>피신청인</td>
                                <td colSpan={3}>
                                    <div className="mb-3">
                                        <label className="form-label">주민등록번호</label>
                                        <div
                                            className="form-control">{doc.JuminNumber ? doc.JuminNumber : ""}</div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">성명</label>
                                        <div
                                            className="form-control">{doc.IssueName ? doc.IssueName : ""}</div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">신청인과의 관계</label>
                                        <div
                                            className="form-control">{doc.IssueRelation ? doc.IssueRelation : ""}</div>
                                    </div>
                                </td>
                            </tr>
                        </>
                    }
                    </thead>
                </table>
                <div className={`${styles.table_content} document_view_content`} style={{border:"2px solid var(--tblr-body-color)",borderTop:"none",margin:"0 auto",borderBottom:"none"}}>
                    <div className={styles.approval_textarea_view}>
                        {isEmptyString(doc.ERPText) ? "" : parseHTML(doc.ERPText)}
                        <br/>
                        {isEmptyString(doc.Text) ? "" : parseHTML(doc.Text)}
                    </div>
                </div>
                <table className={styles.table_footer} style={{borderLeft:"2px solid var(--tblr-body-color)", borderRight:"2px solid var(--tblr-body-color)",borderBottom:"2px solid var(--tblr-body-color)",  margin:"0 auto 10px"}}>
                    <tbody>
                    {/*<tr>
                        <td className={styles.form_head}>의&nbsp;&nbsp;견</td>
                        <td colSpan={3}>
                            <div style={{minHeight: "50px"}}>
                                {doc.Approval.map((approval, i) => approval.ApprovalComment == null ? "" :
                                    <div key={randomKey()}>{approval.toString()} : {approval.ApprovalComment}</div>)}
                            </div>
                        </td>
                    </tr>*/}
                    <tr className={styles.t_footer}>
                        <td className={styles.form_head}>참&nbsp;조&nbsp;문&nbsp;서</td>
                        <td colSpan={3}>
                            {
                                doc.DocumentRef.length == 0 ? "참조된 문서가 없습니다." :
                                    doc.DocumentRef.map((ref, i) =>
                                        <p className={styles.doc_ref_list} key={randomKey(i)}
                                           onClick={() => DocumentViewOpen(ref.DocumentID, doc.DocumentID)}>[{ref.DocumentID}] {ref.Title}</p>)
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>
                {/*<div className='card-footer'>
                            <div className="row ">
                                <div className="col-xl-4">
                                    <div className="mb-3">
                                        <label className="form-label">보안등급</label>
                                        <div className="row g-2">

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="mb-3">
                                        <label className="form-label">중요도</label>


                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="mb-3">
                                        <label className="form-label">보존기간</label>

                                    </div>
                                </div>
                            </div>
                        </div>*/}
            </div>
        )
    }
}

type ViewDocProps = DocumentViewerProps & {
    /**/
}
const ViewDoc = ({doc}: ViewDocProps) =>
{
    const onWindowResize = useWindowResize();
    const Div = useRef<HTMLDivElement | null>(null);

    return (
        <div ref={Div} className={styles.viewer_wrap}>
            <h2 className={styles.approval_doc_title}>{doc.DocumentClassName ?? ""}</h2>
            <table className={`${styles.approval_form} ${styles.form_view}`} style={{margin: "10px auto 0",borderBottom:"none"}}>
                <thead>
                <tr>
                    <td className={styles.form_head}>문 서 번 호</td>
                    <td>{doc.DocumentID}</td>
                    <ApprovalLineControl {...ApprovalLineControl.FromApprovalStatus(doc.Approval, APPROVER_KIND_APPROVAL)}
                                         placeHolder=""/>
                </tr>
                <tr>
                    <td className={styles.form_head}>작 성 일 시</td>
                    <td>{dateFormat(doc.WriteDate, "yyyy-mm-dd TT hh:mm:ss")}</td>
                </tr>
                <tr>
                    <td className={styles.form_head}>기 안 부 서</td>
                    <td>{doc.WriteDeptName}</td>
                    <ApprovalLineControl {...ApprovalLineControl.FromApprovalStatus(doc.Approval, APPROVER_KIND_AGREE)}
                                         placeHolder=""/>
                </tr>
                <tr>
                    <td className={styles.form_head}>기&nbsp;&nbsp;안&nbsp;&nbsp;자</td>
                    <td>{doc.WriteNameKor}</td>
                </tr>
                {doc.DocumentClass === "1010" &&
                    <>
                        <tr>
                            <td className={styles.form_head}>협조의뢰부서</td>
                            <td colSpan={3}>{doc.CollaboDeptName1}</td>
                        </tr>
                        <tr>
                            <td className={styles.form_head}>협조요청부서</td>
                            <td colSpan={3}>{doc.CollaboDeptName2}</td>
                        </tr>
                    </>
                }
                <tr>
                    <td className={styles.form_head}>수신/참조</td>
                    <td colSpan={3}>
                        {doc.IsRefUser ? doc.RefUser.map((data) => data.toString()).join(', ') : ''}
                    </td>
                </tr>
                <tr>
                    <td className={styles.form_head}>집&nbsp;행&nbsp;자</td>
                    <td colSpan={3}>
                        {doc.IsExecUser ? doc.ExecUser.map((data) => data.toString()).join(', ') : ''}
                    </td>
                </tr>
                <tr>
                    <td className={styles.form_head}>집&nbsp;행&nbsp;일&nbsp;자</td>
                    <td colSpan={3} style={{height: "39px"}}>
                        {doc.ExecDate == null ? "" : dateFormat(doc.ExecDate, "yyyy-mm-dd TT hh:MM")}
                    </td>
                </tr>
                <tr>
                    <td className={styles.form_head}>제&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;목</td>
                    <td colSpan={3}>{doc.Title} </td>
                </tr>
                {doc.DocumentClass == "2010" || doc.DocumentClass == "2040" || doc.DocumentClass == "2050" &&
                    <>
                        <tr>
                            <td className={styles.form_head}>내용</td>
                            <td colSpan={3}></td>
                        </tr>
                        <tr>
                            <td className={styles.form_head}>사전보고 수령자</td>
                            <td colSpan={3}></td>
                        </tr>
                        <tr>
                            <td className={styles.form_head}>사전보고 유무</td>
                            <td colSpan={3}></td>
                        </tr>
                        <tr>
                            <td className={styles.form_head}>사전보고 시각</td>
                            <td colSpan={3}></td>
                        </tr>
                    </>
                }


                {doc.DocumentClass == "1040" &&
                    <>
                        <tr>
                            <td className={styles.form_head}>신청사유</td>
                            <td colSpan={3}></td>
                        </tr>
                        <tr>
                            <td className={styles.form_head}>사유발생일</td>
                            <td colSpan={3}>{`${doc.IssueStartDate} ~ ${doc.IssueEndDate}`}</td>
                        </tr>
                        <tr>
                            <td className={styles.form_head}>피신청인</td>
                            <td colSpan={3}>
                                <div className="mb-3">
                                    <label className="form-label">주민등록번호</label>
                                    <div
                                        className="form-control">{doc.JuminNumber ? doc.JuminNumber : ""}</div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">성명</label>
                                    <div
                                        className="form-control">{doc.IssueName ? doc.IssueName : ""}</div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">신청인과의 관계</label>
                                    <div
                                        className="form-control">{doc.IssueRelation ? doc.IssueRelation : ""}</div>
                                </div>
                            </td>
                        </tr>
                    </>

                }
                </thead>
            </table>
            <div className={styles.table_content} style={{border:"2px solid var(--tblr-body-color)",borderTop:"none",margin:"0 auto",borderBottom:"none"}}>
                <div className={styles.approval_textarea_view}>
                    {isEmptyString(doc.ERPText) ? parseHTML(doc.ERPText) : ""}
                    {doc.Text ? parseHTML(doc.Text) : ""}
                </div>
            </div>
            <table className={styles.table_footer} style={{borderLeft:"2px solid var(--tblr-body-color)", borderRight:"2px solid var(--tblr-body-color)",borderBottom:"2px solid var(--tblr-body-color)",  margin:"0 auto 10px"}}>
                <tbody>
                {/*<tr>
                        <td className={styles.form_head}>의&nbsp;&nbsp;견</td>
                        <td colSpan={3}>
                            <div style={{minHeight: "50px"}}>
                                {doc.Approval.map((approval, i) => approval.ApprovalComment == null ? "" :
                                    <div key={randomKey()}>{approval.toString()} : {approval.ApprovalComment}</div>)}
                            </div>
                        </td>
                    </tr>*/}
                <tr className={styles.t_footer}>
                    <td className={styles.form_head}>참&nbsp;조&nbsp;문&nbsp;서</td>
                    <td colSpan={3}>
                        {
                            doc.DocumentRef.length == 0 ? "참조된 문서가 없습니다." :
                                doc.DocumentRef.map((ref, i) =>
                                    <p className={styles.doc_ref_list} key={randomKey(i)}
                                       onClick={() => DocumentViewOpen(ref.DocumentID, doc.DocumentID)}>[{ref.DocumentID}] {ref.Title}</p>)
                        }
                    </td>
                </tr>
                </tbody>
            </table>
            {/*<div className='card-footer'>
                            <div className="row ">
                                <div className="col-xl-4">
                                    <div className="mb-3">
                                        <label className="form-label">보안등급</label>
                                        <div className="row g-2">

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="mb-3">
                                        <label className="form-label">중요도</label>


                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="mb-3">
                                        <label className="form-label">보존기간</label>

                                    </div>
                                </div>
                            </div>
                        </div>*/}
        </div>
    )
}
