import {Expose, Type} from "class-transformer";
import {IsArray} from "class-validator";
import UserDataBase from "data/API/User/UserDataBase";
import ApprovalLineBase, {GetApprovalLineShareText} from "./ApprovalLineBase";
import {SendValueAsync, SendValueAsyncArrayType} from "API";
import {
    URL_API_APPROVAL_LINE_COUNT,
    URL_API_APPROVAL_LINE_DELETE,
    URL_API_APPROVAL_LINE_LIST
} from "../Define";
import axios from "axios";
import UserDataLineBase from "./UserDataLineBase";

export default class ApprovalLineData extends ApprovalLineBase
{
    /**
     * 해당 항목 작성자
     * @type {UserDataBase}
     */
    @Expose() @IsArray() @Type(() => UserDataLineBase)
    public Writer: UserDataBase;
    
    /**
     * 결재/합의자 목록
     * @type {UserDataLineBase[]}
     */
    @Expose() @IsArray() @Type(() => UserDataLineBase)
    public Approval: UserDataLineBase[];
    /**
     * 시행자 목록
     * @type {UserDataBase[]}
     */
    @Expose() @IsArray() @Type(() => UserDataBase)
    public ExecUser: UserDataBase[];
    /**
     * 수신 및 참조자 목록 목록
     * @type {UserDataBase[]}
     */
    @Expose() @IsArray() @Type(() => UserDataBase)
    public RefUser: UserDataBase[];

    public static GetApprovalLineListAsync(All: boolean, Offset?: number, Count?: number)
    {
        if(Offset == null) Offset = 0;
        if(Count == null) Count = -1;
        return SendValueAsyncArrayType<ApprovalLineData>(axios.get(`${URL_API_APPROVAL_LINE_LIST}?All=${All}&Offset=${Offset}&Count=${Count}`), false, ApprovalLineData);
    }

    public static GetApprovalLineCountAsync()
    {
        return SendValueAsync<number>(axios.get(`${URL_API_APPROVAL_LINE_COUNT}?All=${false}`), false);
    }

    public static GetApprovalLineDeleteAsync(LoginID: string, LineName: string)
    {
        return axios.delete(`${URL_API_APPROVAL_LINE_DELETE}/${LoginID}/${encodeURI(LineName)}`);
    }

    public toString = () => `[${GetApprovalLineShareText(this.ShareType)}] ${this.LineName}`;
}