import React, {Component, FormEvent, useEffect, useState} from "react";
import Modal from 'react-modal';
import UserDataSimple from "data/API/User/UserDataSimple";
import UserData from "data/API/User/UserData";
import {printError, URL_API_USER, URL_API_USER_INFO} from "Define";
import axios from "axios";
import InputRadioYN from "common/input/InputRadioYN";
import {isEmptyString, PathMaker, serializeForm} from "@hskernel/hs-utils";
import UserDataForm from "data/API/User/UserDataForm";
import DeptSelectInput from "../component/DeptSelectInput";
import ProfileImageInputControl from "../component/ProfileImageInputControl";
import PositionSelectInput from "../component/PositionSelectInput";
import PositionData from "../../data/API/PositionData";
import UserDataBase from "../../data/API/User/UserDataBase";
import MenuGroupInput from "../component/MenuGroupInput";
import UserDataEx from "../../data/API/User/UserDataEx";
import ModuleMenuGroup from "../../data/API/Module/Menu/ModuleMenuGroup";
import SimpleTextInput, {preventFormEnter} from "../../common/component/SimpleTextInput";

const modalStyle = {
    content: {
        maxWidth: "1000px",
        margin: "30px auto",
        background: "var(--tblr-bg-surface)"
    },
    overlay: {
        background: "rgba(0,0,0,0.5)",
        zIndex: "9"
    }
}

type UserInfoDialogProps = {
    onRefresh?: () => void
}

type UserInfoDialogState = {
    /**
     * null 이면 추가 아니면 수정
     */
    loginID?: string,
    isOpen: boolean,
}

export default class UserInfoDialog extends Component<UserInfoDialogProps, UserInfoDialogState>
{
    constructor(props: UserInfoDialogProps) {
        super(props);
        this.state = {
            isOpen: false,
        }

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onError = this.onError.bind(this);
    }

    public openDialog(user?: UserDataSimple)
    {
        this.setState({...this.state, loginID: user == null ? undefined : user.LoginID, isOpen: true});
    }
    private closeDialog(isCancel: boolean, isRefresh: boolean)
    {
        if(isCancel){ if(!confirm("정말 취소 하시겠습니까?")) return; }
        this.setState({...this.state, loginID: undefined, isOpen: false});
        if(this.props.onRefresh != null && isRefresh) this.props.onRefresh();
    }

    private onError(message: string)
    {
        alert(message);
        this.closeDialog(false, false);
    }

    render()
    {
        return (
            <Modal ariaHideApp={false} isOpen={this.state.isOpen} style={modalStyle}>
                <UserFormInner loginID={this.state.loginID} onError={this.onError} onComplete={() => this.closeDialog(false, true)} onCancel={() => this.closeDialog(true, false)}/>
            </Modal>
        )
    }
}


function Loading() { return <div className="placeholder">로딩중입니다...</div> }
type UserFormInnerProps = {
    loginID?: string,
    onCancel: () => void,
    onComplete: () => void,
    onError: (message: string) => void,
}
const UserFormInner = (props: UserFormInnerProps) =>
{
    const [isBusy, setBusy] = useState(false);
    const [imageBusy, setImageBusy] = useState(false);
    const [user, setUser] = useState<UserDataEx | null>(null);
    const [positions, setPositions] = useState<PositionData[]>([]);
    const [menuGroups, setMenuGroups] = useState<ModuleMenuGroup[]>([]);

    //useEffect(() => console.log(isBusy || imageBusy), [imageBusy]);

    useEffect(() =>
    {
        async function Init()
        {
            setBusy(true);

            const user = new UserDataEx();
            user.IsAdmin = false;
            user.IsEmployment = true;
            setUser(user)

            setPositions(await PositionData.getListAsync() ?? []);
            setMenuGroups(await ModuleMenuGroup.getListAsync() ?? []);
        }

        async function InitLoginID(LoginID: string)
        {
            const user = await UserData.GetUserAsync('fullex', LoginID);
            if(user == null) props.onError("사원 정보를 가져올 수 없습니다");
            else
            {
                setPositions(await PositionData.getListAsync() ?? []);
                setMenuGroups(await ModuleMenuGroup.getListAsync() ?? []);
                // @ts-ignore
                setUser(user);
            }
        }

        if(props.loginID == null)
        {

            Init().then().finally(() => setBusy(false));
        }
        else
        {

            InitLoginID(props.loginID).then()
                .catch(error =>
                {
                    printError(error);
                    props.onError(error.toString());
                    return;
                })
                .finally(() => setBusy(false));
        }

    }, [props.loginID])

    const onSubmit = async (e: FormEvent<HTMLFormElement>) =>
    {
        e.preventDefault();

        const form = UserDataForm.fromForm(e.currentTarget);
        //console.log(form)
        try
        {
            setBusy(true);

            const send = await axios({
                method: props.loginID == null ? "PUT" : "POST",
                url: props.loginID == null ? PathMaker(URL_API_USER, encodeURIComponent(form.LoginID)) : PathMaker(URL_API_USER_INFO, encodeURIComponent(props.loginID)),
                headers: { 'Content-Type': 'multipart/form-data' },
                data: form,
                // 폼에서 [] 없애기
                formSerializer: { indexes: null },
            });

            alert(`사원을 ${props.loginID == null ? '추가' : '수정'}하였습니다`);
            props.onComplete();
        }
        catch (ex: any) { printError(ex); }
        finally { setBusy(false); }
    }

    function onDelete()
    {
        if(props.loginID != null && confirm("정말 해당 사원을 삭제하시겠습니까?"))
        {
            setBusy(true);
            UserDataBase.DeleteUserAsync(props.loginID)
                .then(() => props.onComplete())
                .catch((ex: any) => { printError(ex); });
        }
    }
    function onInputID(e: HTMLInputElement)
    {
        e.value = e.value.replace(/[^A-Za-z0-9]/ig, '');
    }

    return (
        <form className="card placeholder-glow" onSubmit={onSubmit}>
            <h2 className="card-header">사원 {props.loginID == undefined ? "등록" : "수정"}</h2>
            <div className="card-body" style={{minHeight: "800px"}}>
                <h3 className="card-title">
                    <span>프로필 사진</span>
                    {/*<a className="btn" style={{width:"80px",float:"right"}}>사진 저장</a>*/}
                </h3>
                <ProfileImageInputControl loginID={props.loginID} name="ProfileImage" onBusy={(busy) => setImageBusy(busy)}/>
                <hr/>

                <h3 className="card-title mt-4">로그인 정보</h3>
                <div className="row g-3">
                    <div className="col-md">
                        <div className="form-label">아이디</div>
                        {user == null ? Loading() : <input type="text" className="form-control" name="LoginID" onInput={e => onInputID(e.currentTarget)} readOnly={!isEmptyString(props.loginID)} defaultValue={props.loginID}/>}
                    </div>
                    <div className="col-md">
                        <div className="form-label">사번코드</div>
                        {user == null ? Loading() : <input type="text" className="form-control" name="EmpCode" onInput={e => onInputID(e.currentTarget)} defaultValue={user.EmpCode ?? undefined}/>}
                    </div>
                    <div className="col-md">
                        {props.loginID == undefined ?
                            <>
                                <div className="form-label">비밀번호</div>
                                <input type="password" className="form-control" name="login_pw" />
                            </> : ""}
                    </div>
                    <div className="col-md"></div>
                </div>
                <hr/>

                <h3 className="card-title mt-4">개인 정보</h3>
                <div className="row g-3">
                    <div className="col-md">
                        <div className="form-label">이름</div>
                        {user == null ? Loading() : <SimpleTextInput className="form-control" name="Name" value={user.Name ?? undefined} />}
                    </div>
                    <div className="col-md">
                        <div className="form-label">이름(한글)</div>
                        {user == null ? Loading() : <SimpleTextInput className="form-control" name="NameKorean" value={user.NameKorean ?? undefined}/>}
                    </div>
                    <div className="col-md">
                        <div className="form-label">이름(영문)</div>
                        {user == null ? Loading() : <SimpleTextInput className="form-control" name="NameEnglish" value={user.NameEnglish ?? undefined}/>}
                    </div>
                </div>
                <div className="row g-3 mt-2">
                    <div className="col-md">
                        <div className="form-label">회사 이메일</div>
                        {user == null ? Loading() : <SimpleTextInput className="form-control" name="EmailCompany" value={user.EmailCompany ?? undefined}/>}
                    </div>
                    <div className="col-md">
                        <div className="form-label">개인 이메일</div>
                        {user == null ? Loading() : <SimpleTextInput className="form-control" name="EmailPrivate" value={user.EmailPrivate ?? undefined}/>}
                    </div>
                    <div className="col-md">
                    </div>
                </div>
                <div className="row g-3 mt-2">
                    <div className="col-md">
                        <div className="form-label">내선번호</div>
                        {user == null ? Loading() : <SimpleTextInput className="form-control" name="TelCompany" value={user.TelCompany ?? undefined}/>}
                    </div>
                    <div className="col-md">
                        <div className="form-label">내선 팩스번호</div>
                        {user == null ? Loading() : <SimpleTextInput className="form-control" name="TelFax" value={user.TelFax ?? undefined}/>}
                    </div>
                    <div className="col-md">
                        <div className="form-label">휴대폰 번호</div>
                        {user == null ? Loading() : <SimpleTextInput className="form-control" name="TelMobile" value={user.TelMobile ?? undefined}/>}
                    </div>
                </div>
                <div className="row g-3 mt-2">
                    <div className="col-md">
                        <div className="form-label">입사일</div>
                        {user == null ? Loading() : <input type="date" className="form-control" name="EnteringDate" defaultValue={user.EnteringDate == null ? '' : user.EnteringDate.format("yyyy-mm-dd")} onKeyDown={preventFormEnter}/>}
                    </div>
                    <div className="col-md">
                        <div className="form-label">퇴사일</div>
                        {user == null ? Loading() : <input type="date" className="form-control" name="RetirementDate" defaultValue={user.RetirementDate == null ? '' : user.RetirementDate.format("yyyy-mm-dd")} onKeyDown={preventFormEnter}/>}
                    </div>
                    <div className="col-md">
                        <div className="form-label">생년월일</div>
                        {user == null ? Loading() : <input type="date" className="form-control" name="BirthDate" defaultValue={user.BirthDate == null ? '' : user.BirthDate.format("yyyy-mm-dd")} onKeyDown={preventFormEnter}/>}
                    </div>
                </div>
                <hr/>

                <h3 className="card-title mt-4">업무 정보</h3>
                <div className="row">
                    <div className="col-md">
                        <div className="form-label">부서</div>
                        {user == null ? Loading() : <DeptSelectInput name="DeptCode" deptCode={user.DeptCode ?? undefined} deptName={user.DeptName ?? undefined}/>}
                    </div>
                    <div className="col-md">
                        <div className="form-label">직급</div>
                        {user == null ? Loading() : <PositionSelectInput name="PositionCode1" gubunCode='R' list={positions} positionCode={user.PositionCode1 ?? undefined} positionName={user.PositionName1 ?? undefined}/>}
                    </div>
                    <div className="col-md">
                        <div className="form-label">직책</div>
                        {user == null ? Loading() : <PositionSelectInput name="PositionCode2" gubunCode='P' list={positions} positionCode={user.PositionCode2 ?? undefined} positionName={user.PositionName2 ?? undefined} />}
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md">
                        <div className="form-label">담당업무</div>
                        {user == null ? Loading() : <SimpleTextInput className="form-control" name="ResponsWork" value={user.ResponsWork ?? undefined}/>}
                    </div>
                    <div className="col-md">
                        <div className="form-label">재직</div>
                        {user == null ? Loading() : <InputRadioYN name="IsEmployment" value={user.IsEmployment}/>}
                    </div>
                    <div className="col-md"></div>
                </div>
                <hr/>

                <h3 className="card-title mt-4">권한 및 메뉴</h3>
                <div className="row">
                    <div className="col-md">
                        <div className="form-label">메뉴 권한 그룹</div>
                        {user == null ? Loading() : <MenuGroupInput name="MenuGroupID" list={menuGroups} groupID={user.MenuGroupID} />}
                    </div>
                    <div className="col-md">
                        <div className="form-label">업무 관리자</div>
                        {user == null ? Loading() : <InputRadioYN name="IsAdmin" value={user.IsAdmin}/>}
                    </div>
                    <div className="col-md"></div>
                </div>
            </div>
            <div className="card-footer bg-transparent mt-auto">
                <div className="row">
                    {!isEmptyString(props.loginID) ?
                    <div className="col">
                        <button type="button" className="btn btn-danger" disabled={user == null || isBusy || imageBusy} onClick={onDelete}>삭제</button>
                    </div>
                    :"" }
                    <div className="col-auto">
                        <input type="submit" className="btn btn-primary" disabled={user == null || isBusy || imageBusy} value="저장"/>&nbsp;
                        <button type="button" className="btn" disabled={user == null || isBusy || imageBusy} onClick={() => props.onCancel()}>취소</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
