import React, {useEffect, useRef, useState} from "react";
import SearchControl, {
    getDefaultSearch, getSearchOptionEventFromValue,
    getSearchOptionValueFromEvent, SearchControlClass, SearchControlData, SearchOptionEvent,
    SearchOptionProps,
    SearchOptionValue
} from "common/control/SearchControl";
import {Link, useNavigate, useParams} from "react-router-dom";
import {PAGE_COUNT, PAGE_MAX, SEARCH_KIND_NAME, SEARCH_KIND_TITLE} from "Define";
import {GetBoardURL, MENU_BOARD_WRITE} from "../Define";
import TableListControl, {
    CardItemHeader,
    GetItemStyle,
    TableItemHeader,
    TableItemType,
    TableListData
} from "common/control/TableListControl";
import BoardDataSimple from "../data/BoardDataSimple";
import {IconEye, IconMessageDots, IconPaperclip} from "@tabler/icons-react";
import {SelectOptionData} from "common/component/SelectOption";

const BoardList = () =>
{
    const name = undefined;
    const searchKind: SearchOptionProps[] = [{
        autoSearch: false,
        name: 'keywordKind',
        options: [
            new SelectOptionData("제목", SEARCH_KIND_TITLE),
            new SelectOptionData("작성자", SEARCH_KIND_NAME),
        ]
    }]
    const searchKindValue: SearchOptionValue[] = [
        searchKind[0].options[0].Value
    ]

    const getDefaultSearchData = (ignoreURL = false) => getDefaultSearch(name, undefined, searchKind, searchKindValue, ignoreURL);

    const Navigate = useNavigate();
    const {BoardKind} = useParams();

    const tableControl = useRef<TableListControl<BoardDataSimple>>(null);
    const searchControl = useRef<SearchControlClass>(null);

    const [total, setTotal] = useState(0);
    const [boardKind, setBoardKind] = useState(BoardKind);

    const [search, setSearch] = useState<SearchControlData>(getDefaultSearchData());

    const header:  TableItemHeader<BoardDataSimple>[] = [
        {name: "NO", key: "ID", clickable: false, styleItem: GetItemStyle(7, true)},
        {
            name: "제목",
            key: "Title",
            clickable: true,
            styleItem: GetItemStyle(61, true, true),
            onRender: (title, header, data) => <>{title}&nbsp;{data.AttachCount > 0 ? <IconPaperclip stroke={1} width={16} height={16}/> : ""}&nbsp;{data.Comments > 0 ? <IconMessageDots stroke={1} width={16} height={16}/> : ""}</>
        },
        {name: "작성자", key: "WriterName", clickable: false, styleItem: GetItemStyle(13, false)},
        {name: "작성일", key: "WriteDate", clickable: false, styleItem: GetItemStyle(12, false), type: TableItemType.Date},
        {name: "조회수", key: "Views", clickable: false, styleItem: GetItemStyle(7, false)}
    ]

    const headerCard: CardItemHeader<BoardDataSimple>[] = [
        {...header[2], styleItem: {}, clickable: true},
        {...header[1], styleItem: {}},
        {...header[3], styleItem: {}, classNameItem: undefined, isRight: "title"},
        {...header[4], styleItem: {}, classNameItem: undefined, isRight: "normal", onRender: (value) => <span className={`badge bg-lime-lt`}><IconEye/> {value}</span>},
    ];

    /**
     * 검색을 초기화 합니다
     * @param BoardKind
     */
    async function clearSearch(BoardKind?: string)
    {
        if (BoardKind != undefined) setBoardKind(BoardKind);
        //else BoardKind = boardKind;

        const search = getDefaultSearchData(true);
        setSearch(search);

        if (searchControl.current != null) await searchControl.current.setSearchDataAsync(search);
    }

    /**
     * 검색 합니다
     * @param boardKind
     * @param search
     * @param processError
     */
    async function searchAsync(boardKind: string | undefined, search: SearchControlData, processError = true)
    {
        try
        {
            //if (boardKind == null) throw new Error('게시판 형식이 지정되지 않았습니다.');

            if (tableControl.current != null)
            {
                await tableControl.current.setTotalAsync(0);

                const total = boardKind == null ? 0 : await BoardDataSimple.GetCountAsync(boardKind, search) ?? 0;
                setTotal(total);

                await tableControl.current.setTotalAsync(total ?? 0);
                await tableControl.current.refresh();
            }
        }
        catch (error: any)
        {
            if (processError)
            {
                alert(error.message);
                Navigate(-1);
            }
            else throw error;
        }
    }

    async function onRefresh(offset: number, count: number, total: number, page: number): Promise<TableListData<BoardDataSimple>>
    {
        let list: BoardDataSimple[] = [];
        if(total > 0)
        {
            if(boardKind != null) list = await BoardDataSimple.GetListAsync(boardKind, search, offset, count) ?? [];
        }
        return {data: list};
    }

    //검색 데이터 변경시...
    useEffect(() => { searchAsync(boardKind, search).then(); }, [search]);
    //보드 종류 변경 시...
    useEffect(() => { if (boardKind != BoardKind) clearSearch(BoardKind).then(); }, [BoardKind, search]);
    //갯수 변경시
    useEffect(() => { if (tableControl.current != null) tableControl.current.setTotalAsync(total).then(); }, [total])

    return (boardKind == null ? <h1>잘못된 접근입니다</h1> :
        <>
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-8 col-md-8 row">
                        <div className="col">
                            <SearchControlClass
                                ref={searchControl} Navigate={Navigate} clearParam={TableListControl.URLParamKeys}
                                defaultKeyword={search.keyword} defaultOptions={searchKind} defaultOptionValue={searchKindValue} onSearch={(keyword, optionEvents) => setSearch({keyword: keyword, options: optionEvents})}/>

                        </div>
                        <div className="col-auto text-end">
                        <Link to={MENU_BOARD_WRITE} className="btn">글쓰기</Link>
                    </div>
                    </div>

                </div>
            </div>
            <div className="card-body">
                <div className="docu_list_table">
                    <p className="mb-1">전체 게시글 : {total} 건</p>
                    <TableListControl<BoardDataSimple>
                        ref={tableControl} headerTable={header} headerCard={headerCard} navigate={Navigate} autoRefresh={false} onRefresh={onRefresh} maxPage={PAGE_MAX} count={PAGE_COUNT} total={total}
                        onItemClick={(value, header, data) => Navigate(GetBoardURL(boardKind, data.ID, true))}/>
                </div>
            </div>
        </>
    )
}

export default BoardList;
