import React, {Component} from "react";
import {URL_FRONT_ERROR} from "Define";
import {Route} from "react-router-dom";
import ErrorPageBase, {ErrorPageParam} from "./Base";
import ErrorPage404 from "./404";
import ErrorPage403 from "./403";
import ErrorPage500 from "./500";
import ErrorPage502 from "./502";
import NoAdmin from "./NoAdmin";

export default class ErrorPage extends ErrorPageBase
{
    constructor(props?: ErrorPageParam)
    {
        super({
            errorCode: props?.errorCode,
            title: props?.title ?? '서버 오류가 발생 하였습니다',
            message: props?.message ?? '알 수 없는 오류가 발생 하였습니다',
            hideBack: props?.hideBack ?? true,
            hideHome: props?.hideHome,
        });
    }
}

export function ErrorRouter()
{
    return (
        <Route path={URL_FRONT_ERROR}>
            <Route path={""}  element={<ErrorPage/>}/>
            <Route path={"403"} element={<ErrorPage403/>}/>
            <Route path={"404"} element={<ErrorPage404/>}/>
            <Route path={"500"} element={<ErrorPage500/>}/>
            <Route path={"502"} element={<ErrorPage502/>}/>
            <Route path={"noadmin"} element={<NoAdmin/>}/>
        </Route>
    )
}