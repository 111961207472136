import {Expose, plainToInstance} from "class-transformer";
import axios, {formToJSON} from "axios";
import {URL_API_USER_SETTING} from "Define";

export default class UserSettingsData
{
    @Expose() public SimplePWUse: boolean;

    public SaveAsync()
    {
        return axios.post(URL_API_USER_SETTING, this, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    public static fromForm(form: HTMLFormElement)
    {
        const obj = formToJSON(form);
        return plainToInstance<UserSettingsData, any>(UserSettingsData, obj, {
            excludeExtraneousValues: true,
            exposeUnsetFields: false,
        });
    }
}