import {Expose, Type} from "class-transformer";
import React from "react";
import axios from "axios";
import BoardCommentForm from "./BoardCommentForm";
import UserDataBase from "data/API/User/UserDataBase";
import {SendValueAsync, SendValueAsyncArrayType} from "API";
import {GetAPIURL} from "../Define";
import {ChatData} from "common/control/ChatControl";
import Avatar from "react-avatar";

export default class BoardComment
{
    /**
     * 게시판 종류
     * @type {string}
     */
    @Expose() public BoardKind: string;
    /**
     * 게시글
     * @type {string}
     */
    @Expose() public BoardID: string;
    /**
     * 댓글 ID
     * @type {string}
     */
    @Expose() public ID: string;
    /**
     * 댓글 작성자
     * @type {string}
     */
    @Expose() @Type(() => UserDataBase)
    public Writer: UserDataBase;
    /**
     * 댓글 작성일
     * @type {Date}
     */
    @Expose() @Type(() => Date)
    public WriteTime: Date;
    /**
     * 댓글 수정일
     * @type {Date}
     */
    @Expose() @Type(() => Date)
    public ModifyTime: Date;
    /**
     * 댓글 내용
     * @type {string}
     */
    @Expose() public Contents: string;

    //TODO: 이미지 할당 / 꼭지는 map 으로 돌릴 때 async + 해쉬맵(중복제거) 쓰기
    public toChatData(LoginID: string): ChatData
    {
        const isMe = this.Writer.LoginID == LoginID;
        return {
            //image: <img src={this.Writer.getImageURL()} alt={this.Writer.Name ?? ""}/>,
            image: <Avatar name={this.Writer.Name ?? ""} size="32"/>,
            bubbleCorn: "Writer.LoginID",
            title: this.Writer.Name ?? "",
            text: this.Contents,
            time: this.WriteTime,
            me: isMe,

            editable: isMe,
            deletable: isMe,
            data: this
        }
    }


    public static async GetListAsync(BoardKind: string, BoardID: string, offset = 0, count = -1)
    {
        const param = [];
        param.push(`count=${count}`);
        param.push(`offset=${offset}`);
        return await SendValueAsyncArrayType<BoardComment>(axios.get(GetAPIURL(BoardKind, BoardID) + `/comment?${param.join('&')}`), true, BoardComment);
    }
    public static async GetCountAsync(BoardKind: string, BoardID: string)
    {
        return await SendValueAsync<number>(axios.get(GetAPIURL(BoardKind, BoardID) + '/comment/count'), true);
    }
}