import React, {CSSProperties, ReactElement, useEffect, useMemo, useRef, useState} from 'react';
import styles from './index.module.css';
import {IconMessageDots, IconMoodHappy, IconX} from "@tabler/icons-react";
import {getDataByKey, isEmptyString, randomKey} from "@hskernel/hs-utils";


type ChatControlProps = {
    title: string,
    /**
     *
     */
    inputPlaceholderText?: string,
    /**
     * 높이를 해당값으로 고정합니다 (기본값은 600px 입니다)
     */
    height?: string,
    /**
     *
     */
    showInput?: boolean,
    /**
     * 뱃지 표시 여부
     */
    showBadge?: boolean,
    /**
     * 프로필 이미지 표시 여부
     */
    showProfile?: boolean,
    /**
     * 내 프로필 이미지 표시 여부
     */
    showProfileMe?: boolean,
    /**
     * 내 꼭지 표시 여부
     */
    showBubbleCornMe?: boolean,
    /**
     * 엔터키로 전송 여부
     */
    enterSend?: boolean,
    /**
     *
     * @returns  {Promise<ChatData>}
     */
    onSend?: (text: string) => Promise<ChatData | null>,
    /**
     * 코멘트를 수정할 때 발생하는 이벤트 입니다 (성공적으로 지웠으면 true, 아니면 false 를 반환하십시오)
     * @param {ChatData} data
     * @returns {Promise<boolean>}
     */
    onEdit?: (data: ChatData) => Promise<boolean>,
    /**
     * 코멘트를 삭제할 때 발생하는 이벤트 입니다 (성공적으로 지웠으면 true, 아니면 false 를 반환하십시오)
     * @param {ChatData} data
     * @returns {Promise<boolean>}
     */
    onDelete?: (data: ChatData) => Promise<boolean>,
    /**
     * 시간을 년-월-일 로만 표시할지 여부입니다
     */
    showDateOnly?: false,
    /**
     * 초기 의견 데이터 입니다
     */
    data?: ChatData[],
    /**
     * 처음에 새로고침 여부
     * @returns {Promise<ChatData[]>}
     */
    onRefresh?: () => Promise<ChatData[]>,
    /**
     * 전송버튼 텍스트 입니다
     */
    sendButtonText?: string
}

type ChatBubbleProps = {
    /**
     *
     */
    text: string,
    /**
     *
     */
    title?: string,
    /**
     *
     */
    time?: Date,
    /**
     * 말풍선 꼭지 표시 여부 (string 이면 해당 값으로 data 객체를 가져와 전 값과 비교하여 결정합니, number 면 해당 객체가 Array 타입입니다)
     */
    bubbleCorn?: number | string
    /**
     * 이미지 표시
     */
    image?: ReactElement,
    /**
     * 추가적으로 붙일 태그입니다 (왼쪽에 붙습니다)
     */
    tag?: string,
    /**
     * 삭제 가능 여부입니다
     */
    deletable?: boolean,
    /**
     * 삭제 가능 여부입니다
     */
    editable?: boolean,
    /**
     * 사용자 추가 데이터 입니다
     */
    data?: any,
}
/**
 * 채팅 데이터 입니다
 */
export type ChatData = ChatBubbleProps & {
    /**
     * 내 대화 여부
     */
    me: boolean
}

function doFade(isShow: boolean, transform: number): CSSProperties
{
    return {
        transition:"0.5s",
        transform:`translateY(${transform}px)`,
        opacity: isShow ? 1 : 0,
        visibility: isShow ? "visible" : "hidden"
    }
}

const ChatControl = (props: ChatControlProps) =>
{
    const [chatData, setChatData] = useState<ChatData[] | null>(props.data == null ? null : props.data);
    const [show, setShow] = useState(false);
    const [sendWait, setSendWait] = useState(false);
    const [chatText, setChatText] = useState("");
    const divChat = useRef<HTMLDivElement>(null);


    const refresh = async () => setChatData(props.onRefresh == null ? [] : await props.onRefresh());
    useEffect(() => { refresh().then() }, []);
    //요소가 추가될때마다 밑으로 자동 스크롤
    useEffect(() =>
    {
        if(divChat.current != null)
        {
            divChat.current.scrollIntoView({ behavior: 'smooth' });
            divChat.current.scrollTop = divChat.current.scrollHeight;
        }
    }, [chatData]);

    function onSendClick()
    {
        if(props.onSend != null && !isEmptyString(chatText))
        {
            setSendWait(true);
            props.onSend(chatText).then(chat =>
            {
                if(chat != null)
                {
                    setChatText("");
                    if(chatData == null) setChatData([chat]);
                    else setChatData([...chatData, chat]);
                }
            }).finally(() => setSendWait(false));
        }
    }

    const getChat = (chatData: ChatData[] | null) =>
    {
        let beforeData: any = null;

        let position = 0;
        let isMe = false;
        return chatData == null ? "" : chatData.map((chat, i) =>
        {
            //서로 아니면 초기화
            if(isMe == chat.me) position++;
            else
            {
                isMe = chat.me;
                position = 0;
            }

            let bubbleCorn: boolean;
            if(chat.me) {bubbleCorn = !!props.showProfileMe; }
            else bubbleCorn = !!props.showProfile //props.showProfile ? true : false

            if(chat.bubbleCorn != null && !chat.me)
            {
                const value: any = typeof chat.bubbleCorn == "number" ?
                    chat.data[chat.bubbleCorn] :
                    (isEmptyString(chat.bubbleCorn) ? chat.data : getDataByKey(chat.data, chat.bubbleCorn));

                bubbleCorn = beforeData != value;
                if(bubbleCorn) beforeData = value;
            }
            //bubbleCorn && (props.showBubbleCornMe ?? false)
            return chat.me ?
                <ChatBubbleMe key={randomKey(i)} position={position} showBubbleCorn={bubbleCorn? true:(props.showBubbleCornMe ?? false)} showProfile={props.showProfileMe ?? false} showDateOnly={props.showDateOnly ?? false} {...chat}/> :
                <ChatBubble key={randomKey(i)} position={position} showBubbleCorn={bubbleCorn} showProfile={props.showProfile ?? false} showDateOnly={props.showDateOnly ?? false} {...chat}/>;
        });

        /*
        return chatData.map((data, i) => data.me ?
            <ChatBubbleMe key={randomKey(i)} showDateOnly={props.showDateOnly ?? false} {...data}/> :
            <ChatBubble key={randomKey(i)} showDateOnly={props.showDateOnly ?? false} {...data}/>)
         */
    }

    const onInput = (e: React.KeyboardEvent<HTMLTextAreaElement>) =>
    {
        if(props.enterSend)
        {
            // isComposing 이 true 이면 조합 중이므로 동작을 막는다.
            if (e.nativeEvent.isComposing) return;

            // [shift] + [Enter] 치면 걍 리턴
            if (e.key === 'Enter' && e.shiftKey) return;
            // [Enter] 치면 전송
            else if (e.key === 'Enter') onSendClick();
        }
    }

    //불필요한 렌더링 막기
    const charRender = useMemo(() => getChat(chatData), [chatData]);
    //console.log(chatData)
    return (
        <>
            <div className={styles.comment_button} onClick={() => setShow(!show)}>
                {chatData == null ? "" :
                    <>
                        {props.showBadge ? <p className={styles.comment_alarm}>{chatData.length}</p> : ""}
                        <IconMessageDots/>
                    </>
                }
            </div>
            <div className={styles.comment_wrap} style={doFade(show,  show ? 0 : 40)}>
                <div className={`card ${styles.card}`} style={{height: props.height ?? "600px"}}>
                    <div className="card-header">
                        <div className="col">
                            <b>{props.title ?? "채팅"}</b>
                            <b style={{float: "right", cursor: "pointer"}} onClick={() => setShow(false)}>
                                <IconX size={20} strokeWidth={1}/>
                            </b>
                        </div>
                    </div>
                    {chatData == null ? <div>기다려 주십시오...</div> :
                        <>
                            <div ref={divChat} className={`card-body ${styles.chat_wrap}`}>
                                {charRender}
                            </div>
                        {props.showInput ?
                            <div className={`card-body ${styles.input_area_comment}`}>
                                <div className="input-group mb-2">
                                    <textarea value={chatText} className="form-control" placeholder={props.inputPlaceholderText ?? ""} style={{resize: "none", height: "80px"}}
                                              onKeyDown={onInput}
                                              onChange={(e) => setChatText(e.currentTarget.value)}/>
                                </div>
                                <div>
                                    <div className="btn-group" role="group">
                                        <button type="button" className="btn btn-icon"><IconMoodHappy/></button>
                                    </div>
                                    <button type="button" className="btn btn-primary" disabled={isEmptyString(chatText) || sendWait} style={{float: "right"}} onClick={onSendClick}>
                                        {props.sendButtonText ?? "전송"}
                                    </button>
                                </div>
                            </div>
                            :""}
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default ChatControl

type ChatBubblePropsEx = ChatBubbleProps & {
    showDateOnly: boolean,
    showBubbleCorn: boolean,
    showProfile: boolean,
    position: number,
}
const getDateString = (props: ChatBubblePropsEx) =>
{
    if(props.time == null) return "";

    const type = typeof props.time;
    if(type == "string") return type;
    else if(props.time.constructor == Date)
    {
        return props.showDateOnly ?
            props.time.format("yyyy-mm-dd") :
            props.time.format("yyyy-mm-dd TT hh:MM");
    }
    return props.time.toString();
}

const getChatBubbleInner = (props: ChatBubblePropsEx, className: string) =>
{
    return (
        <div className={className}>
            {props.title == null ? "" : <div className={styles.comment_name}>{props.title}</div>}
            <p className={styles.comment_content}>{props.text}</p>
            <div className={styles.comment_info}>
                <p className={styles.comment_state}>{props.tag ?? ""}</p>
                <p className={styles.comment_date}>{getDateString(props)}</p>
            </div>
        </div>
    )
}
const ChatBubble = (props: ChatBubblePropsEx) =>
{
    return (props.showBubbleCorn ?
        <div className={props.showProfile? styles.comment_box_profile : styles.comment_box} style={{marginTop:"15px"}}>
            {!props.showProfile ? "" :
                <div className={styles.comment_profile}>
                    <div className={styles.comment_avatar}>
                        {props.image ?? ""}
                    </div>
                </div>

            }
            {getChatBubbleInner(props, `${styles.comment_content_wrap} ${styles.bubble_corn}`)}
        </div>
        :
            <div className={props.showProfile? styles.comment_box_profile : styles.comment_box}>{getChatBubbleInner(props, styles.comment_content_plus_wrap)}</div>
    )
}

const ChatBubbleMe = (props: ChatBubblePropsEx) =>
{
    return (
                props.position == 0 ?
                    (props.showBubbleCorn ?
                        <div className={styles.comment_box_self} style={{marginTop: "15px",transform:"translateX(10px)"}}>
                            {!props.showProfile ? "" :
                                <div className={styles.comment_profile}>
                                    <div className={styles.comment_avatar}>
                                        {props.image ?? ""}
                                    </div>
                                </div>
                            }
                            {getChatBubbleInner(props, `${styles.comment_content_wrap} ${styles.bubble_corn_me}`)}
                        </div>
                        :
                        <div
                            className={`${styles.comment_box_self} ${props.showProfile ? styles.profile_box_self : styles.none_profile_box_self}`}>
                            {getChatBubbleInner(props, `${styles.comment_content_wrap}`)}
                        </div>)
                    :
                    <div
                        className={`${styles.comment_box_self} ${props.showProfile ? styles.profile_box_self : styles.none_profile_box_self}`}>
                        {getChatBubbleInner(props, `${styles.comment_content_plus_wrap}`)}
                    </div>

    )
    /*return (
        props.showProfile && props.showBubbleCorn ?
            <div className={styles.comment_box_self} style={{marginTop:"15px"}}>
                <div className={styles.comment_profile}>
                    <div className={styles.comment_avatar}>
                        {props.image ?? ""}
                    </div>
                </div>
                {getChatBubbleInner(props, `${styles.comment_content_wrap}`)}
                <span className={styles.bubble_corn_me}></span>
            </div>
            :
            <div className={styles.comment_box_self}>
                {getChatBubbleInner(props, `${styles.comment_content_wrap}`)}
                {props.showBubbleCorn?<span className={styles.bubble_corn_me}></span>:""}
            </div>
    )*/

}