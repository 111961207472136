import Modal from 'react-modal';
import {Component, createRef} from "react";
import {ApprovalLineMultiData, ApprovalLineMultiSelect, ConvertApprovalLineData} from "./ApprovalLineMultiSelect";
import React from 'react';
import AuthData from "Auth";
import ApprovalLineDocuData from "../../data/ApprovalLineDocuData";


export type ApprovalModalProps = {
    /**
     *  '결재'/'합의'/'수신및참조'/'집행자'
     */
    initData?: ApprovalLineMultiData,
    onSave: (data: ApprovalLineMultiData) => void,
    auth: AuthData,
    showFinal: boolean,
    /**
     * 값이 할당되면 기본값을 불러옵니다 (initData 가 존재하면 불러오지 않습니다);
     */
    docuClass?: string
}

type ApprovalModalState = {
    isOpen: boolean,
    data?: ApprovalLineMultiData,
    busy: boolean,
    busyButton: boolean,
    docuClass?: string
}

/**
 *
 * @param setModalIsOpen
 * @param openProps
 * @param onSave
 * @param deptCode
 * @returns {JSX.Element}
 * @constructor
 */

export class ApprovalLineMultiSelectModal extends Component<ApprovalModalProps, ApprovalModalState> {
    private select = createRef<ApprovalLineMultiSelect>();

    constructor(props: ApprovalModalProps) {
        super(props);
        this.openDialog = this.openDialog.bind(this);
        this.setOpenDialog = this.setOpenDialog.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.state = {
            isOpen: false,
            data: this.props.initData,
            busy: false,
            busyButton: false,
        };
    }


    //if(open != openProps.isOpen) setOpen(openProps.isOpen);

    openDialog(data: ApprovalLineMultiData, docuClass: string, loadInitData?: boolean)
    {
        if(loadInitData)
        {
            this.setState({...this.state, busy: true, isOpen: true});
            ApprovalLineDocuData.GetAsync(docuClass)
                .then(data => this.setOpenDialog(true, docuClass, ConvertApprovalLineData(data ?? undefined)))
                .catch(e =>
                {
                    console.log(e);
                    this.setOpenDialog(true, docuClass, data);
                })

        }
        else this.setOpenDialog(true, docuClass, data);
    }

    private setOpenDialog(open: boolean, docuClass?: string, data?: ApprovalLineMultiData) {
        this.setState({busy: false, isOpen: open, data: data, docuClass: docuClass});
    }

    private onSave() {
        this.setOpenDialog(false);
        const data = this.select.current == null ? new ApprovalLineMultiData() : this.select.current.getData();
        this.props.onSave(data);
    }

    /**
     * 취소버튼 눌렀을 때
     * @private
     */
    private onCancel() {
        if (window.confirm("정말 취소하시겠습니까?")) {
            this.setOpenDialog(false);
        }
    }


    render() {
        const modalStyle = {
            content: {
                background: "transparent",
                width:`${window.innerWidth <= 577? "calc(100% - 40px)":"calc(100% - 80px)"}`,
                padding:0,
                border:0,
                inset:`${window.innerWidth <= 577? "20px":"40px"}`,
                height:"fit-content"
            },
            overlay: {
                background: "rgba(0,0,0,0.5)",
                zIndex:"2",
            }
        }
        return (
            <Modal isOpen={this.state.isOpen} style={modalStyle} ariaHideApp={false}>
                {this.state.busy ? <h1>기본 결재라인을 불러오는 중입니다...</h1> :
                <div>
                    <div className="card">
                        <div className="card-header">
                            <p className="col">결재라인지정</p>
                            <div className="col-auto">
                                <button className="btn btn-primary" disabled={this.state.busyButton} onClick={this.onSave}>저장</button>
                                &nbsp;
                                <button className="btn" disabled={this.state.busyButton} onClick={this.onCancel}>취소</button>
                            </div>
                        </div>
                        <ApprovalLineMultiSelect ref={this.select} auth={this.props.auth} initData={this.state.data} docuClass={this.state.docuClass} showFinal={this.props.showFinal}
                                                 onBusy={busy => this.setState({...this.state, busyButton: busy})}/>
                    </div>
                </div>}
            </Modal>
        )
    }
}

export default ApprovalLineMultiSelectModal;