import React, {useEffect, useRef, useState} from 'react';
import TableListControl, {
    CardItemHeader,
    GetItemStyle,
    TableItemHeader,
    TableItemHeaderBase
} from "common/control/TableListControl";
import LoginHistoryData from "data/API/LoginHistoryData";
import {dateFormat, isEmptyString} from "@hskernel/hs-utils";
import {PAGE_COUNT, PAGE_MAX, printError} from "Define";
import {useNavigate} from "react-router-dom";
import DeviceData from "../../data/API/Device/DeviceData";
import {getBadgeStyle} from "../../DefineStyle";
import DeviceViewDialog from "../../component/dialog/DeviceViewDialog";

const DevicePage = () =>
{
    const headerTable: TableItemHeader<DeviceData>[] = [
        {name: "종류", key: "Platform", clickable: true, styleItem: GetItemStyle(5, true), onRender: (value => DeviceData.PlatformString(value))},
        {name: "이름", key: "Name", clickable: true, styleItem: GetItemStyle(30, true), },
        {name: "모델", key: "Model", clickable: true, styleItem: GetItemStyle(20, true)},
        {name: "OS", key: "OS", clickable: true, styleItem: GetItemStyle(10, true), onRender: (value => DeviceData.GetOS(value))},
        {name: "브랜드", key: "Brand", clickable: true, styleItem: GetItemStyle(10, true), onRender: (value => DeviceData.GetBrandName(value))},
        {name: "접속 시간", key: "AccessTime", clickable: true, styleItem: GetItemStyle(15, true), onRender: (value => <span style={{whiteSpace: 'pre-line'}}>{dateFormat(value, "yyyy-mm-dd\nHH:MM:ss")}</span>)}
    ]
    const headerCard: CardItemHeader<DeviceData>[] = [
        {...headerTable[1], styleItem: {}, classNameItem: undefined},
        {...headerTable[2], styleItem: {}, classNameItem: undefined},
        {...headerTable[0], styleItem: {}, classNameItem: undefined, isRight: 'title', onRender: (value => getBadgeStyle(DeviceData.PlatformString(value), 'lime'))},
        {...headerTable[5], styleItem: {}, classNameItem: undefined, isRight: 'none', onRender: (value => dateFormat(value, "yyyy-mm-dd TT hh:MM:ss"))},
    ]

    const navigate = useNavigate();
    const table = useRef<TableListControl<DeviceData> | null>(null);
    const deviceViewer = useRef<DeviceViewDialog | null>(null);

    async function onRefresh(offset: number, count: number)
    {
        count = Math.max(count, PAGE_COUNT);
        return DeviceData.GetDeviceListAsync(offset, count);
    }

    async function Refresh()
    {
        try
        {
            if(table.current != null)
            {
                await table.current.setTotalAsync(await DeviceData.GetCountAsync() ?? 0);
                await table.current.refresh();
            }
        }
        catch (e) { printError(e); }
    }

    async function onDelete(device: DeviceData)
    {
        await DeviceData.DeleteAsync(device);
        Refresh().then();
        return true;
    }

    useEffect(() => { Refresh().then(); }, []);
    function onItemClick(value: any, header: TableItemHeaderBase<DeviceData>, item: DeviceData)
    {
        if(deviceViewer.current != null) deviceViewer.current.openDialog(item);
    }

    return (
        <>
            <DeviceViewDialog ref={deviceViewer} onDelete={onDelete}/>
            <div className="col-12 col-md-9 d-flex flex-column">
                <div className="card-body" style={{minHeight: "800px"}}>
                    <h2>내 기기</h2>
                    <TableListControl<DeviceData> ref={table} autoRefresh={false} headerTable={headerTable} headerCard={headerCard} navigate={navigate} maxPage={PAGE_MAX}
                                                  onItemClick={onItemClick}
                                                  onRefresh={async (offset, count) => {
                                                      return {data: await onRefresh(offset, count) ?? []}
                                                  }}/>
                </div>
            </div>
        </>
    )
}

export default DevicePage;
