import {isNullorWhiteSpace} from "@hskernel/hs-utils";
import {dateFormat} from "@hskernel/hs-utils";
import {SearchWithDateRangeFullData} from "common/control/SearchWithDateRangeControl";

export function getSearchAPIParams(data: SearchWithDateRangeFullData): string[]
{
    const params = [];
    if (!isNullorWhiteSpace(data.keyword)) params.push(`keyword=${data.keyword}`);
    if (data.options[0] != null) params.push(`keywordKind=${data.options[0].value}`);
    if (data.start != null) params.push(`datestart=${dateFormat(data.start.addDays(-1), 'yyyy-mm-dd')}`);
    if (data.end != null) params.push(`dateend=${dateFormat(data.end.addDays(1), 'yyyy-mm-dd')}`);
    for (let i = 1; i < data.options.length; i++)
    {
        const e = data.options[i];
        if (e != null && e.name != null) params.push(`${e.name}=${e.value}`);
    }
    return params;
}