import {Route} from "react-router-dom";
import React from "react";
import {DEFAULT_SETTINGS_PATH, SettingsModuleID} from "./info";
import {redirectModulePath} from "../modules/ModuleInfo";
import AdminUserListPage from "./page/user/AdminUserListPage";
import ModuleMenuPage from "./page/module/menu/ModuleMenuPage";

const SettingsRouter = () =>
    <Route path={SettingsModuleID} element={redirectModulePath(SettingsModuleID, DEFAULT_SETTINGS_PATH)}>
        <Route path={"user"}>
            <Route path={"list"} element={<AdminUserListPage/>}/>
        </Route>
        <Route path={"module"}>
            <Route path={"menu"} element={<ModuleMenuPage/>}/>
        </Route>
    </Route>

export default SettingsRouter;
