import {Expose, Type} from "class-transformer";
import {
    APPROVER_KIND_AGREE,
    APPROVER_KIND_APPROVAL,
    APPROVER_KIND_EXEC,
    APPROVER_KIND_REF,
    APPROVER_KIND_SHARE
} from "./ApprovalStatus";
import UserDataBase from "data/API/User/UserDataBase";
import ApprovalDocumentERPBase from "./ApprovalDocumentERPBase";

/**
 * 임시보관(저장) 상태
 */
export const STATUS_SAVE = "0";
/**
 * 상신 상태
 */
export const STATUS_REPORT = "1";
/**
 * 결재진행중 상태
 */
export const STATUS_PROGRESS = "2";
/**
 * 결재보류중 상태
 */
export const STATUS_HOLD = "3";
/**
 * 결재반려 상태
 */
export const STATUS_REJECT = "4";
/**
 * 전결 상태
 */
export const STATUS_FINAL = "5";
/**
 * 종결 상태
 */
export const STATUS_COMPLETE = "6";

export const APPROVER_KIND_APPROVAL_TEXT = "결재자";
export const KIND_APPROVAL_DESC = "결재";
export const APPROVER_KIND_AGREE_TEXT = "합의자";
export const KIND_AGREE_DESC = "합의";
export const APPROVER_KIND_EXEC_TEXT = "집행자";
export const KIND_EXEC_DESC = "집행";
export const APPROVER_KIND_REF_TEXT = "수신및참조자";
export const KIND_REF_DESC = "수신및참조";
export const APPROVER_KIND_SHARE_TEXT = "수신및참조자";
export const KIND_SHARE_DESC = "수신및참조";

export const GetApproverKindText = (kind: string) =>
{
    switch (kind)
    {
        case APPROVER_KIND_APPROVAL: return APPROVER_KIND_APPROVAL_TEXT;
        case APPROVER_KIND_AGREE: return APPROVER_KIND_AGREE_TEXT;
        case APPROVER_KIND_EXEC: return APPROVER_KIND_EXEC_TEXT;
        case APPROVER_KIND_REF: return APPROVER_KIND_REF_TEXT;
        case APPROVER_KIND_SHARE: return APPROVER_KIND_REF_TEXT;
        default: return "";
    }
}

export default class ApprovalDocumentBase extends ApprovalDocumentERPBase
{
    @Expose() public DocumentClass: string;
    @Expose() public DocumentClassName: string;
     /**
      * 문서상태 [0:저장 → 1:상신 → 2:결재 진행중 → 3:결재 보류중 → 4: 결재 반려 → 5:종결]
      */
    @Expose() public DocumentStatus: string;
    @Expose() public DocumentComment: string;
    @Expose() @Type(() => Date)
    public WriteDate: Date;
    @Expose() public WriteDateChanged: boolean;
    @Expose() @Type(() => Date)
    public ReportDate?: Date | null;
    @Expose() public WriteDeptName: string | null;
    @Expose() public WriteDeptCode: string | null;
    @Expose() public EmployeeCode: string;
    @Expose() public WriteName?: string | null;
    @Expose() public WriteNameKor?: string | null;
    @Expose() public WritePositionName1?: string | null;
    @Expose() public WritePositionName2?: string | null;
    @Expose() @Type(() => Date)
    public ExecDate?: Date | null;
    @Expose() public IsRefUser: boolean;
    @Expose() public IsExecUser: boolean;
    @Expose() public IsAttachFile: boolean;
    @Expose() public ApprovalNo: number;
    @Expose() public AgreementNo: number;
    @Expose() public AcquirerLoginID: string;
    @Expose() @Type(() => UserDataBase)
    public Acquirer: UserDataBase;

    public getWriterUser()
    {
        const user = new UserDataBase();
        user.LoginID = this.LoginID ?? '';
        user.Name = this.WriteName ?? null;
        user.NameKorean = this.WriteNameKor ?? null;
        user.DeptCode = this.WriteDeptCode;
        user.DeptName = this.WriteDeptName;
        user.PositionName1 = this.WritePositionName1 ?? null;
        user.PositionName2 = this.WritePositionName2 ?? null;
        return user;
    }
}

export function GetDocumentStatusText(DocumentStatus: string)
{
    switch (DocumentStatus)
    {
        case STATUS_SAVE: return "임시저장";
        case STATUS_REPORT: return "상신";
        case STATUS_PROGRESS: return "진행중";
        case STATUS_HOLD: return "보류";
        case STATUS_REJECT: return "반려";
        case STATUS_FINAL: return "전결";
        case STATUS_COMPLETE: return "종결";
        default: return `알 수 없음 (${DocumentStatus})`;
    }
}

export function GetDocumentStatusColor(DocumentStatus: string)
{
    switch (DocumentStatus)
    {
        case STATUS_SAVE: return "dark";
        case STATUS_REPORT: return "orange";
        case STATUS_PROGRESS: return "red";
        case STATUS_HOLD: return "yellow";
        case STATUS_REJECT: return "muted";
        case STATUS_FINAL:
        case STATUS_COMPLETE: return "lime";
        default: return "muted";
    }
}
