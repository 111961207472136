import ModuleInfo, {AdminMenuKind} from "../ModuleInfo";
import Icon from "./resource/mail-icon.png";
import {ModuleID} from "./Define";
import React from "react";
import { IconMail } from "@tabler/icons-react";

const Info: ModuleInfo =
    {
        id: ModuleID,
        name: "E-Mail",
        title: "이메일",
        //icon: Icon,
        onRenderIcon: (darkMode, isSmall) => <IconMail/>,
        defaultPath: (admin: boolean) => ModuleID,
        admin: AdminMenuKind.UserOnly,
        category: [
            {
                id: 'mail',
                title: "메일",
                path: '',
                open: true,
                menu: [
                    {
                        id: 'open',
                        title: "메일 열기 (새 창)",
                        path: '',
                        popup: false,
                    }
                ]
            },
        ],
    }

    export default Info;