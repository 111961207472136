import {Route} from "react-router-dom";
import MailPlugMailRedirect from "./page/MailPlugMailRedirect";
import React from "react";
import {ModuleID} from "./Define";

const MailplugRouter = () => {
    return (
        <Route path={ModuleID} element={<MailPlugMailRedirect/>}/>
    )
}

export default MailplugRouter;
