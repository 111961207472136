import React from "react";
import PositionData from "data/API/PositionData";
import {randomKey} from "@hskernel/hs-utils";

type PositionSelectInputProps = {
    list: PositionData[],
    gubunCode: string
    name?: string,
    positionCode?: string,
    positionName?: string,
}
const PositionSelectInput = (props: PositionSelectInputProps) =>
{
    function getList()
    {
        const list = props.list.length > 0 ?
            props.list.filter(pos => pos.GubunCode == props.gubunCode).map((pos, i) => <option value={pos.PositionCode} selected={pos.PositionCode == props.positionCode} key={randomKey(i)}>{pos.PositionName}</option>) :
            [props.positionCode ? <option key={randomKey()} selected={true} value={props.positionCode}>{props.positionName}</option> : '']

        return [<option key={randomKey()} value=''>(없음)</option>, ...list]
    }
    return (
        <>
            <div className="input-group mb-2">
                <select className="form-select" name={props.name}>
                    {getList()}
                </select>
                {/*<input type="hidden" name={props.name} value={position == null ? undefined : position.positionCode}/>*/}
                {/*<input type="text" className="form-control" readOnly={true} placeholder={props.gubunCode ? '직급(직급코드)' : '직책(직책코드)'} value={position == null ? undefined : position.positionName}/>*/}
                {/*<button type="button" className="btn">변경</button>*/}
            </div>
        </>
    )
}

export default PositionSelectInput;