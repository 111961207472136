import {SendValueAsync, SendValueAsyncArrayType} from "API";
import axios from "axios";
import {Expose, Type} from "class-transformer";
import {API_MAILPLUG_MAIL_LIST_ALL} from "../Define";

export default class MailplugMailList
{
    @Expose() public num: number;
    //@Type(() => Date)
    @Expose() public mail_date: number;
    @Expose() public mail_from: string;
    @Expose() public mail_read: string;
    @Expose() public subject: string;
    @Expose() public return_url: string;

    public getDate(): Date
    {
        const value = this.mail_date.toString();
        const year = parseInt(value.substring(0, 4));
        const month = parseInt(value.substring(4, 6));
        const day = parseInt(value.substring(6, 8));
        const hour = parseInt(value.substring(8, 10));
        const minute = parseInt(value.substring(10, 12));
        const second = parseInt(value.substring(12, 14));

        return new Date(year, month - 1, day, hour, minute, second);
    }
}

export async function GetMailListAsync(count?: number, offset?: number): Promise<MailplugMailList[] | null>
{
    return await SendValueAsyncArrayType<MailplugMailList>(axios.get(`${API_MAILPLUG_MAIL_LIST_ALL}?count=${count ?? 5}&offset=${offset ?? 0}`), true, MailplugMailList);
}