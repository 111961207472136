import React, {useEffect, useState} from "react";

type SimpleFormInputProps = {
    className?: string,
    name?: string,
    value?: string,
    disabled?: boolean,
    readOnly?: boolean,
    placeholder?: string,
    isMultiLine?: boolean,
    onEnter?: (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => boolean | undefined,
    preventOnEnter?: boolean,
}

const SimpleTextInput = (props: SimpleFormInputProps) =>
{
    const [value, setValue] = useState(props.value);
    useEffect(() => setValue(props.value), [props.value]);

    function onChange(event: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>)
    {
        setValue(event.currentTarget.value);
    }

    function onKeyDown(event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>)
    {
        if (isEnterClick(event))
        {
            if (props.preventOnEnter ?? true) event.preventDefault();

            let ReturnValue = undefined;
            if (props.onEnter != undefined) ReturnValue = props.onEnter(event);
            return ReturnValue ?? false;
        }
    }

    return props.isMultiLine ?
        <textarea className={props.className} name={props.name} placeholder={props.placeholder} disabled={props.disabled} readOnly={props.readOnly} value={value} onKeyDown={onKeyDown} onChange={onChange}/> :
        <input type="text" className={props.className} name={props.name} placeholder={props.placeholder} disabled={props.disabled} readOnly={props.readOnly} value={value} onKeyDown={onKeyDown} onChange={onChange}/>;
}

export default SimpleTextInput;

export function isEnterClick(event: React.KeyboardEvent)
{
    const key = event.key || event.keyCode;
        // @ts-ignore
    return (key === 'Enter' && !event.shiftKey) || (key === 13 && key !== 16);
}

export function preventFormEnter(event: React.KeyboardEvent)
{
    if (isEnterClick(event))
    {
        event.preventDefault();
        return false;
    }
}
