import React, {useEffect, useRef, useState} from "react";
import {IconX} from "@tabler/icons-react";
import PageControl, {calcCount, calcOffset} from "common/control/PageControl";
import {PAGE_COUNT, PAGE_MAX, printError} from "Define";
import AlertData from "data/API/Alert/AlertData";
import {dateFormat, randomKey} from "@hskernel/hs-utils";
import AlertViewDialog from "../../component/AlertViewDialog";

const UserAlert = () =>
{
    const [isRead,setIsRead] = useState(undefined);
    const [data,setData] = useState<AlertData[]>([]);
    const [total,setTotal] = useState<number>(0);
    const [currentPage,setCurrentPage] = useState<number>(0);
    const [busy, setBusy] = useState(false);
    const viewDialog = useRef<AlertViewDialog | null>(null);

    async function pageChange(page: number) {
        try {
            const offset = calcOffset(total, page, PAGE_MAX);
            setCurrentPage(offset);
            Refresh().then();
            return data.length > 0 ?? false;
        }
        catch(e){ return false; }

    }

    async function Refresh()
    {
        setBusy(true);
        try
        {
            setTotal(await AlertData.GetCountAsync(isRead) ?? 0);
            setData(await AlertData.GetListAsync(isRead,currentPage,PAGE_MAX) ?? []);
        }
        catch (e) { printError(e); }
        finally { setBusy(false); }
    }

    useEffect(() => { Refresh().then(); }, []);


    async function onRead(alert: AlertData)
    {
        if(viewDialog.current != null)
        {
            await viewDialog.current?.setAlertIDAsync(alert.ID);
            await Refresh();
        }
    }
    async function onDelete(alert: AlertData)
    {
        await AlertData.DeleteAsync([alert.ID]);
        await Refresh();
    }
    function onDeleteAll()
    {
        if(confirm("정말 전체 알림을 삭제 하시겠습니까?"))
            AlertData.DeleteAllAsync().then();
    }

    return (
        <>
            <AlertViewDialog ref={viewDialog}/>
            {data ?
                <div className="col-12 col-md-9 d-flex flex-column">
                    <div className="card-body" style={{minHeight: "800px"}}>
                        <h2 className="mb-4">알림 사항</h2>
                        <div className="card">
                            <div className="list-group list-group-flush list-group-hoverable">
                                <div className="list-group-item text-end">
                                    <button className="btn btn-ghost-secondary" style={{cursor: "pointer"}} disabled={busy} onClick={onDeleteAll}>알림 전체 삭제</button>
                                </div>
                                {busy ?
                                    [...Array(3)].map((dummy, i) => <AlertItem key={randomKey(i)} data={null}/>)
                                    :
                                    data.map((data, i) => <AlertItem key={randomKey(i)} data={data} onRead={onRead} onDelete={onDelete}/>)
                                }
                            </div>
                        </div>
                        <PageControl total={total} onChange={async (page) => await pageChange(currentPage)}/>
                    </div>
                </div>
                :
                <div className="text-center">알림 사항이 없습니다.</div>}
        </>
    )
}

export default UserAlert;


type AlertItemProps = {
    data: AlertData | null,
    onRead?: (alert: AlertData) => Promise<void>,
    onDelete?: (alert: AlertData) => Promise<void>
}
const AlertItem = ({data, ...props}: AlertItemProps) => {
    const [busy, setBusy] = useState(false);

    function process(func?: (alert: AlertData) => Promise<void>)
    {
        if(data != null && func != null)
        {
            setBusy(true);
            func(data).then().finally(() => setBusy(false));
        }
    }

    return (
        <div className="list-group-item">
            <div className="row align-items-center placeholder-glow">
                {data == null ?
                    <>
                        <div className="col-auto">
                            <span className="badge bg-secondary"/>
                        </div>
                        <div className="col">
                            <a className="text-reset d-block placeholder">
                                .......................
                            </a>
                            <div className="d-block mt-1">
                                <p className="placeholder">수신 시간 : -</p><br/>
                                <p className="placeholder mt-1">읽은 시간 : -</p>
                            </div>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-ghost-secondary">
                                <IconX size={"20px"}/>
                            </button>
                        </div>
                    </>
                    :
                    <>
                        <div className="col-auto">
                            <span className={data.IsRead ? "badge bg-lime" : "badge bg-red"}/>
                        </div>
                        <div className="col text-truncate">
                            <a className="text-reset d-block" style={{cursor: "pointer"}}
                               onClick={() => process(props.onRead)}>
                                <b>[{data.Category}]</b> - {data.Message}
                            </a>
                            <div className="d-block text-secondary text-truncate mt-n1">
                                수신 시간 : {dateFormat(data.CreateTime, "yyyy-mm-dd TT hh:MM:ss")}<br/>
                                읽은 시간 : {data.ReadTime != null ? dateFormat(data.ReadTime, "yyyy-mm-dd TT hh:MM:ss") : '-'}
                            </div>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-ghost-secondary" disabled={busy} onClick={() => process(props.onDelete)}>
                                <IconX size={"20px"}/>
                            </button>
                        </div>
                    </>
                }
            </div>
        </div>)
}
