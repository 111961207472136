import {ModuleMenuSubData} from "./ModuleMenuData";
import {Expose} from "class-transformer";
import {SendValueAsyncType} from "API";
import axios from "axios";
import {URL_API_MODULE_MENU} from "Define";

export default class ModuleMenuProperty
{
    /**
     * 일반 모드 메뉴 데이터
     */
    @Expose()
    public Normal: ModuleMenuSubData;

    /**
     * 관리자 모드일 때 데이터
     */
    @Expose()
    public Admin: ModuleMenuSubData;

    public GetMenuData = (isAdmin = false) => isAdmin ? this.Admin : this.Normal;

    public static async GetMenuAsync()
    {
        return await SendValueAsyncType<ModuleMenuProperty>(axios.get(URL_API_MODULE_MENU), false, ModuleMenuProperty);
    }

    public static GetDefault()
    {
        return new ModuleMenuProperty();
    }
}