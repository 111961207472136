import {Route} from "react-router-dom";
import React from "react";
import {redirectModulePath} from "modules/ModuleInfo";
import {ID, MENU_BOARD_WRITE, TEMP_COMPONENT} from "../Define";
import BoardList from "../page/BoardList";
import {DEFAULT_MODULE_PATH} from "../info";
import BoardWrite from "../page/BoardWrite";
import BoardView from "../page/BoardView";
//import TempComponent from "../page/TempComponent";

const BoardRouter = () =>
{
    return (
        <Route path={ID} element={redirectModulePath(ID, DEFAULT_MODULE_PATH)}>
            {/*<Route path={TEMP_COMPONENT} element={<TempComponent/>} />*/}
            <Route path={":BoardKind"}>
                <Route path={""} element={<BoardList/>} />
                <Route path={":BoardID"} element={<BoardView/>}/>
                <Route path={MENU_BOARD_WRITE} element={<BoardWrite/>}>
                    <Route path={""} element={<BoardWrite/>} />
                    <Route path={":BoardID"} element={<BoardWrite/>} />
                </Route>
            </Route>
        </Route>
    )
}

export default BoardRouter;