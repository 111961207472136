import React from "react";
import {randomKey} from "@hskernel/hs-utils";
import ModuleMenuGroup from "../../data/API/Module/Menu/ModuleMenuGroup";

type PositionSelectInputProps = {
    list: ModuleMenuGroup[],
    name: string,
    groupID: string,
}
const PositionSelectInput = (props: PositionSelectInputProps) =>
{
    return (
        <>
            <div className="input-group mb-2">
                <select className="form-select" name={props.name}>
                    {props.list.map((data, i) =>
                        <option value={data.GroupID} selected={data.GroupID == props.groupID} title={data.Description} key={randomKey(i)}>{data.Name}</option>)}
                </select>
            </div>
        </>
    )
}

export default PositionSelectInput;
