import React, {useState, useEffect, FormEvent, useMemo,} from "react";
import axios, {AxiosError} from "axios";
import {
    COMPANY, getCompanyID, getDeviceInfo, getLoginMode, HEADER_COMPANY_ID,
    IMG_DEFAULT_LOGO, isMockupMode, LoginMode, PRODUCT,
    sendRNData, setLoginMode, STORAGE_KEY_COMPANY_ID,
    STORAGE_KEY_LOGIN_ID, TITLE_DEFAULT,
    URL_API_COMPANY_LOGO,
    URL_API_LOGIN,
    URL_FRONT_MAIN
} from "Define";
import {getParam, isEmptyString} from "@hskernel/hs-utils";
import {ProcessResponseError} from "API";
import {useNavigate} from "react-router-dom";
import CompanyData from "data/API/CompanyData";
import {STORAGE_KEY_LOGIN_APP_TOKEN} from "Define";
import styled from "styled-components";
import {isWVMode} from "lib/RNMessage/RNMessageWV";
import RNDataLogin from "lib/RNMessage/Data/RNDataLogin";
import LoadingCircle from "../../common/control/LoadingCircle";
import DeviceData from "../../data/API/Device/DeviceData";
import {InitAxiosHeader} from "../../Init";
import * as Define from "../../Define";

const NAME_COMPANY_ID = HEADER_COMPANY_ID;
const NAME_LOGIN_ID = "LoginID";
const NAME_LOGIN_PW = "LoginPW";

const MobileLoginAreaUI = styled.div`
  @media screen and (min-width: 577px) {
    display:none
  }
`

function LoginPage()
{
    const Navigate = useNavigate();
    const [mode, setMode] = useState<LoginMode>(isWVMode() ? 'APP' : getLoginMode());
    const [company, setCompany] = useState<CompanyData | null>(null);
    const [loading, setLoading] = useState(true);
    const [busy, setBusy] = useState(false);
    const [companyName, setCompanyName] = useState(COMPANY);
    const CompanyID = getCompanyID();
    //const auth = useAuth();
    /**
     *
     * @param {SubmitEvent} e
     * @returns {Promise<boolean>}
     */
    const onLogin = async (e: FormEvent<HTMLFormElement>) =>
    {
        e.preventDefault();
        setBusy(true);

        try
        {
            const form = new FormData(e.currentTarget);
            const LoginID = `${form.get(NAME_LOGIN_ID)}`;
            const CompanyID = `${form.get(NAME_COMPANY_ID)}`;

            const response = await axios.post(`${URL_API_LOGIN}?LoginMode=${mode}`, form, {headers: {HEADER_COMPANY_ID: CompanyID}});

            setLoginMode(mode);
            if (isWVMode() || mode == "APP")
            {
                const login_token = response.data;

                localStorage.setItem(STORAGE_KEY_LOGIN_APP_TOKEN, login_token);
                localStorage.setItem(STORAGE_KEY_LOGIN_ID, LoginID);

                // @ts-ignore
                InitAxiosHeader();

                if (isWVMode())
                {
                    const LoginData: RNDataLogin = {
                        LoginID: LoginID,
                        LoginToken: login_token,
                    };
                    sendRNData({type: 'LOGIN', data: LoginData});
                }

                //window.alert(JSON.stringify(await axios.get(Define.URL_API_USER, {validateStatus: () => true})));

                const DeviceInfo = DeviceData.ConvertRNDeviceInfo(getDeviceInfo());
                console.info(`DEVICE INFO: ${JSON.stringify(DeviceInfo)}`);
                await DeviceData.AddDeviceAsync(DeviceInfo);
            }


            const callback = getParam("callback");
            const url = isEmptyString(callback) ? URL_FRONT_MAIN : callback!;
            if (url.indexOf('://') >= 0) location.href = url;
            else
            {
                Navigate(url, {replace: true});
                location.reload();
            }
        }
        catch (ex)
        {
            if(ex instanceof AxiosError)
            {
                let message = "";
                if(ex.response != null)
                {
                    const result = ex.response.data;

                    if(result != null && result.message != null) message = result.message;
                    else
                    {
                        const status = ex.response.status;
                        if(status === 403) message = "아이디 혹은 비밀번호를 확인하세요";
                        else if(status === 401) message = "계정 로그인(접근)이 거부되었습니다";
                        else if(status === 400) message = "아이디 또는 비밀번호가 비어있습니다";
                        else message = ProcessResponseError(ex, false, false).message;
                    }
                }

                alert("로그인 실패!!" + (isEmptyString(message) ? "" : `\n\n${message}`));
            }
        }

        setBusy(false);

        return false;
    }

    useEffect(() =>
    {
        setLoading(true);
        setBusy(true);
        CompanyData.GetCompany()
            .then(c => setCompany(c))
            .finally(() =>
            {
                setLoading(false);
                setBusy(false);
            })
        //아이디가 저장되있으면 setSave(true) 하고 저장된 아이디 가져오기
    }, []);
    useEffect(() =>
    {
        if(company != null) setCompanyName(company.Name);
    }, [company])

    return (
        <div className="page page-center">
            <div className="container container-tight py-4">
                <div className="text-center mb-4">
                    <a className="navbar-brand navbar-brand-autodark" style={{width: '144px', height: '144px'}}>
                        {loading ?
                            <LoadingCircle size="64px"/>:
                            <img src={company == null || isMockupMode() ? IMG_DEFAULT_LOGO : `${URL_API_COMPANY_LOGO}?Size=144`} alt={company == null ? "회사 로고" : `${company.Name} 로고`}/>}
                    </a>
                </div>
                <div className="card card-md">
                    <div className="card-body">
                        <h2 className="h2 text-center mb-4">{`${isMockupMode() ? PRODUCT : companyName} 그룹웨어 로그인`}</h2>
                        <form method="post" noValidate={true} onSubmit={onLogin}>
                            <div className="mb-3" style={{display: (company != null || loading || CompanyID != null) && !isMockupMode() ? 'none' : 'block'}}>
                                <label className="form-label">회사 ID</label>
                                <input type="text" name={NAME_COMPANY_ID} defaultValue={CompanyID ?? undefined} className="form-control" placeholder="회사 아이디 입력" autoComplete="off" autoCapitalize="off"/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">사용자 아이디</label>
                                <input type="text" name={NAME_LOGIN_ID} className="form-control" placeholder="아이디 입력"
                                       autoComplete="off" autoCapitalize="off"/>
                            </div>
                            <div className="mb-2">
                                <label className="form-label">비밀번호</label>
                                <div className="input-group input-group-flat">
                                    <input type="password" name={NAME_LOGIN_PW} className="form-control"
                                           placeholder="비밀번호 입력" autoComplete="off"/>
                                </div>
                            </div>
                            {isWVMode() ? '' :
                                <MobileLoginAreaUI>
                                    <div className="mb-2">
                                        <label className="form-check">
                                            <input type="checkbox" name="AppMode" className="form-check-input"
                                                   checked={mode == "APP"}
                                                   onChange={e => setMode(e.target.checked ? "APP" : "Normal")}/>
                                            <span className="form-check-label">앱 모드 로그인</span>
                                        </label>
                                    </div>
                                </MobileLoginAreaUI>
                            }

                            <div className="form-footer">
                                <button type="submit" disabled={busy} className="btn btn-primary w-100">로그인</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
