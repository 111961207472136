import ErrorPageBase from "./Base";

export default class NoAdminPage extends ErrorPageBase
{
    constructor()
    {
        super({
            errorCode: '401',
            title: '해당 관리자만 접근 가능합니다',
        });
    }
}