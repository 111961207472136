//by HSKernel (v2.1)
import {Component, createRef, CSSProperties} from "react";
import React from "react";
import {paddingNumber, randomKey, dateFormat} from "@hskernel/hs-utils";
import parseHTML from 'html-react-parser';
import styles from './index.module.css';
import Modal from "react-modal";

const _leapYear = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const _notLeapYear = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export class CalendarProps {
    /**
     * @type {string | undefined}
     */
    id;
    /**
     * @type {CSSProperties | undefined}
     */
    style;
    /**
     * @type {boolean | undefined} 음력 표시
     */
    showLunar;
    /**
     * @type {boolean | undefined} 손 없는날 표시
     */
    showNohands;
    /**
     * @type {boolean | undefined} 기념일 표시
     */
    showMemorialDay;
    /**
     * @type {Date | undefined} 날짜 지정
     */
    date;
    /**
     * @type {(date: Date, sender: CalendarDay) => void | undefined}
     */
    onClick;
    /**
     * @type {(year: number, month: number, sender: Calendar) => void}
     */
    onMonthChange;
}

class CalendarState {
    /**
     * @type {boolean}
     */
    showLunar;
    /**
     * @type {boolean} 손 없는날 표시
     */
    showNohands;
    /**
     * @type {boolean}
     */
    showMemorialDay;
    /**
     * @type {Date} 현재 날짜
     */
    date;
    /**
     * @type {CalendarDayProps[][]}
     */
    days;
    /**
     * @type {Date}
     */
    firstDate;
    /**
     * @type {number} 현재달의 달력 시작 위치 입니다
     */
    dateOffset;
    /**
     * @type {number} 현재달의 최대 일 수 입니다
     */
    dateMax;
    /**
     * 날짜 컨트롤 활성화 여부
     */
    controlEnable = true;
}

export class Calendar extends Component
{
    /**
     * @private
     * @type {string}
     */
    id = "";
    /**
     * @private
     * @type {Date}
     */
    date = new Date();
    /**
     * @private
     * @type {CSSProperties}
     */
    style = {height: "100%"};

    /**
     *
     * @type {React.LegacyRef<CalendarDay>[]}
     */
    days = new Array(42);

    /**
     *
     * @param {CalendarProps} props
     */
    constructor(props)
    {
        //if(props.style == null) props.style = {width: "100%", height: "100%"};
        super(props);

        /**
         *
         * @type CalendarState
         */
        this.state = {
            showLunar: props.showLunar ?? false,
            showNohands: props.showNohands ?? false,
            showMemorialDay: props.showMemorialDay ?? true,
            date: props.date ?? new Date(),
            days: [][7], //6x7
            onClick : props.onClick,
            firstDate: new Date(this.date.getFullYear(), this.date.getMonth(), 1),
            firstDateOffset: -1,
            controlEnable: true,
        }

        this.id = props.id ?? `hs-calendar-${randomKey()}`;

        this.setDate = this.setDate.bind(this);
        this.getCurrentDate = this.getCurrentDate.bind(this);
        this.getCurrentOffset = this.getCurrentOffset.bind(this);
        this.getCurrentMaxDay = this.getCurrentMaxDay.bind(this);
        this.getCurrentFirstDate = this.getCurrentFirstDate.bind(this);
        this.getShowLunar = this.getShowLunar.bind(this);
        this.getShowMemorialDay = this.getShowMemorialDay.bind(this);
        this.getShowNohands = this.getShowNohands.bind(this);
        this.setShowLunar = this.setShowLunar.bind(this);
        this.setShowNohands = this.setShowNohands.bind(this);
        this.setShowMemorialDay = this.setShowMemorialDay.bind(this);

        this.setControlEnable = this.setControlEnable.bind(this);

        this._addMonth = this._addMonth.bind(this);

        this.clearHTMLAll = this.clearHTMLAll.bind(this);
        this.setHTMLRaw = this.setHTMLRaw.bind(this);

        for(let i = 0; i < this.days.length; i++)
            this.days[i] = createRef();
    }

    /**
     * 컴포넌트가 붙을 때
     * @private
     */
    componentDidMount()
    {
        this.setDate(this.date);
    }

    /**
     * 현재 표시되는 날짜를 가져옵니다
     * @returns {Date}
     */
    getCurrentDate = () => this.state.date;
    /**
     * 현재 달의 시작 위치를 가져옵니다
     * @returns {number}
     */
    getCurrentOffset = () => this.state.dateOffset;
    /**
     * 현재 달의 끝 날짜를 가져옵니다
     * @returns {number|number|*}
     */
    getCurrentMaxDay = () => this.state.dateMax;
    /**
     * 현재 달의 시작 날짜 입니다
     * @returns {Date}
     */
    getCurrentFirstDate = () => this.state.firstDate;

    /**
     *
     * @returns {boolean}
     */
    getShowLunar = () => this.state.showLunar;
    /**
     *
     * @returns {boolean}
     */
    getShowMemorialDay = () => this.state.showMemorialDay;
    /**
     *
     * @returns {boolean}
     */
    getShowNohands = () => this.state.showNohands;
    /**
     *
     * @param {boolean} showLunar 달력에 음력날짜를 표시할지 여부입니다
     * @returns {void}
     */
    setShowLunar = (showLunar) => this.setState({...this.state, showLunar: showLunar});
    /**
     *
     * @param {boolean} showNohands 달력에 손없는 날을 표시할지 여부입니다
     * @returns {void}
     */
    setShowNohands = (showNohands) => this.setState({...this.state, showNohands: showNohands});
    /**
     *
     * @param {boolean} showMemorialDay 달력에 기념일을 표시할지 결정합니다
     * @returns {void}
     */
    setShowMemorialDay = (showMemorialDay) => this.setState({...this.state, showMemorialDay: showMemorialDay});

    /**
     *
     * @param {boolean} enable
     * @returns {void}
     */
    setControlEnable = (enable) => this.setState({...this.state, controlEnable: enable});

    /**
     *
     * @param {Date} date
     * @param {Map<string, JSX.Element | string | null>?} htmls
     */
    setDate(date, htmls)
    {
        this.date = date;

        const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const yearDays = firstDate.isLeafYear() ? _leapYear : _notLeapYear;
        const dateMax = yearDays[firstDate.getMonth()];

        /**
         *
         * @type {CalendarDayProps[][]}
         */
        const days = [];
        let dateOffset = -1;
        // 윤년 체크하기

        let day = 1;

        for (let i = 0; i < 6; i++)
        {
            /**
             *
             * @type {CalendarDayProps[]}
             */
            const day_temp = [];
            for (let j = 0; j < 7; j++)
            {
                let deactivate = false;
                let displayDate = date;
                if(i === 0 && j < firstDate.getDay())
                {
                    deactivate = true;
                    displayDate = firstDate.addDays(j - firstDate.getDay());
                }
                else
                {
                    //다음 달
                    if (day > dateMax) deactivate = true;
                    else if(dateOffset < 0) dateOffset = j;

                    displayDate = firstDate.addDays(day - 1);
                    day++;
                }

                let memorial = null;
                let lunar = null; try { lunar = LunarDate.CalculateLunar(displayDate.getFullYear(), displayDate.getMonth() + 1, displayDate.getDate()); } catch{ console.log("음력 데이터 존재하지 않음!!") }
                for(let z = 0; z < _memorialDays.length; z++)
                {
                    let check = false;
                    const md = _memorialDays[z];
                    //양력일 때
                    if(md.isSolarLunar()) check = md.getMonth() === displayDate.getMonth() + 1 && md.getDay() === displayDate.getDate();
                    //음력일 때
                    else if(lunar != null)
                    {
                        let day = md.getDay();
                        //음력 12월 총 일 수 계산
                        if(md.isCalculateDay()) day = md.calculateDay(date.getFullYear());
                        check = md.getMonth() === lunar.getMonth() && day === lunar.getDay();
                    }

                    if(check) memorial = md;
                }

                let html = null;
                if(htmls != null && htmls.size > 0) html = htmls.get(dateFormat(displayDate, "yyyy-mm-dd"));
                day_temp.push({
                    ...this.state,
                    parent_id: this.id,
                    deactivate: deactivate,
                    date: displayDate,
                    lunar: lunar,
                    memorial: memorial,
                    html: html
                });
            }
            days.push([...day_temp]);
        }

        this.setState({
            ...this.state,
            days: days,
            firstDate: firstDate,
            dateOffset: dateOffset,
            dateMax: dateMax,
        });
    }

    clearHTMLAll()
    {
        this.setDate(this.date);
    }

    /**
     *
     * @param {number} offset
     * @param {JSX.Element | string | null} html
     */
    setHTMLRaw(offset, html)
    {
        const days = this.state.days;
        console.log(this.state.days);
        /*
        if(this.days[offset].current != null)
        {
            this.days[offset].current.setHTML(html);
        }

         */
        //this.setState({...this.state, days: days});
    }

    /**
     * 헤더에 이전/다음/오늘 버튼 클릭 할때
     * @param {number} count
     */
    _addMonth(count){
        let addYear = this.date.getFullYear();
        let month = this.date.getMonth();
        if(month === 12 && count > 0) {
            addYear = addYear + 1;
            month = 0;
        }
        else if(month === 1 && count < 0) {
            addYear = addYear - 1;
            month = 13;
        }

        this.setDate(new Date(addYear, month + count, this.date.getDate()));

        if(this.props.onMonthChange != null) this.props.onMonthChange(addYear, month + count + 1, this);
    }

    render()
    {
        const {date, firstDate} = this.state;
        const modalStyle = {
            content: {
                width:"600px",
                margin:"0 auto",
                background: "var(--tblr-bg-surface)",
                height:"500px",
                top:"50%",
                transform:"translateY(-50%)",
                padding:"0"
            },
            overlay: {
                background: "rgba(0,0,0,0.5)",
                zIndex:"13"
            }
        }

        return (
            <>
                <Modal isOpen={false} style={modalStyle}>
                    <div className="card">
                        <div className="card-header">일정 등록 (2023년 9월 7일)</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="mb-3">
                                    <label className="form-label">제목</label>
                                    <input type="text" className="form-control" name="example-text-input" placeholder="(제목은 달력에 표시됩니다)"/>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">내용</label>
                                    <textarea className="form-control" style={{height:"186px"}} placeholder="내용을 입력하시오"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="button" className="btn btn-primary">등록</button>&nbsp;&nbsp;
                            <button type="button" className="btn">취소</button>
                        </div>
                    </div>
                </Modal>
                <div className={styles.calendar_root} style={this.props.style ?? this.style}>
                <div className={styles.calendar_header}>
                    <div className={styles.date_setting_popup} style={{display:"none"}}>
                        <div className="card">
                            <div className="card-body">
                                <table className={styles.date_setting_table}>
                                    <tr>
                                        <td>1</td>
                                        <td>2</td>
                                        <td>3</td>
                                        <td>4</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>6</td>
                                        <td>7</td>
                                        <td>8</td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>10</td>
                                        <td>11</td>
                                        <td>12</td>
                                    </tr>
                                </table>
                            </div>

                            <div className="card-footer">
                                <p className="col-12 text-center"><button className="btn">연도 지정</button></p>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <table className={styles.date_setting_table}>
                                    <tr>
                                        <td>2018</td>
                                        <td>2019</td>
                                        <td>2020</td>
                                        <td>2021</td>
                                    </tr>
                                    <tr>
                                        <td>2022</td>
                                        <td>2023</td>
                                        <td>2024</td>
                                        <td>2025</td>
                                    </tr>
                                    <tr>
                                        <td>2026</td>
                                        <td>2027</td>
                                        <td>2028</td>
                                        <td>2029</td>
                                    </tr>
                                </table>
                            </div>

                            <div className="card-footer">
                                <p className="col-12 text-center"><button className="btn">달(월) 지정</button></p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.calendar_header_now}></div>
                    <div className={styles.calendar_button_nav}>
                        <button className={styles.calendar_button_prev} disabled={this.controlEnable} onClick={() => this._addMonth(-1)}>이전</button>
                        <button className={styles.calendar_button_today} disabled={this.controlEnable} onClick={() => this.setDate(new Date())}>
                            {`${this.date.getFullYear()}년 ${this.date.getMonth() + 1}월`}
                        </button>
                        <button className={styles.calendar_button_next} disabled={this.controlEnable} onClick={() => this._addMonth(1)}>다음</button>
                    </div>
                </div>
                <div className={styles.calendar_table}>
                    <div className={styles.calendar_table_header}>
                        <div className={styles.day + " " + styles.sun}>일</div>
                        <div className={styles.day}>월</div>
                        <div className={styles.day}>화</div>
                        <div className={styles.day}>수</div>
                        <div className={styles.day}>목</div>
                        <div className={styles.day}>금</div>
                        <div className={styles.day + " " + styles.sat}>토</div>
                    </div>
                    <div className={styles.dates}>
                        {this.state.days?.map((days, i) => {
                            return (
                                <div key={randomKey(i)} id={`weekly_${100 + i}`} className={styles.weekly}>
                                    {days.map(props => <CalendarDay ref={this.days[i]} key={randomKey(i)} {...props}/>)}
                                </div>)
                        })}
                    </div>
                </div>
            </div>
            </>
        );
    }
}
export default Calendar;

class CalendarDayState {
    /**
     * @type {boolean}
     */
    showLunar;
    /**
     * @type {boolean}
     */
    showNohands;
    /**
     * @type {boolean}
     */
    showMemorialDay;
    /**
     * @type {JSX.Element | string | null | undefined}
     */
    html;
}
export class CalendarDayProps extends CalendarDayState {
    /**
     * {Date}
     */
    date;
    /**
     * 클릭시 발생하는 이벤트
     * @type {(date: Date, sender: CalendarDay) => void}
     */
    onClick;
    /**
     * @type {string}
     */
    parent_id;
    /**
     * 날짜 비활성화 여부
     * @type {boolean}
     */
    deactivate;
    /**
     * 움력일
     * @type {LunarDate | null}
     */
    lunar;
    /**
     * 기념일
     * @type {MemorialDay | null}
     */
    memorial;
}

export class CalendarDay extends Component
{
    /**
     * @private
     * @type {Data}
     */
    date = new Date();

    /**
     *
     * @param {CalendarDayProps} props
     */
    constructor(props)
    {
        super(props);
        this.getDate = this.getDate.bind(this);
        this.getHTML = this.getHTML.bind(this);

        this.setShowLunar = this.setShowLunar.bind(this);
        this.setShowNohands = this.setShowNohands.bind(this);
        this.setShowMemorialDay = this.setShowMemorialDay.bind(this);
        this.setHTML = this.setHTML.bind(this);

        this.GenerateDayElementID = this.GenerateDayElementID.bind(this);
        this.onClick = this.onClick.bind(this);
        this.getDateString = this.getDateString.bind(this);

        this.date = props.date;
        /**
         *
         * @type {CalendarDayState}
         */
        this.state = {...props};

    }

    /**
     *
     * @returns Date {Date}
     */
    getDate() { return this.date; }

    /**
     *
     * @returns {JSX.Element | string | null}
     */
    getHTML()
    {
        if(typeof this.state.html == "string") return parseHTML(this.state.html);
        else if(this.state.html != null) return this.state.html;
        else return "";
    }

    /**
     *
     * @returns {boolean}
     */
    getEnabled = () => !this.props.deactivate;

    /**
     *
     * @param {boolean} showLunar
     */
    setShowLunar(showLunar){ this.setState({...this.state, showLunar: showLunar}); }

    /**
     *
     * @param {boolean} showNohands
     */
    setShowNohands(showNohands){ this.setState({...this.state, showNohands: showNohands}); }

    /**
     *
     * @param {boolean} showMemorialDay
     */
    setShowMemorialDay(showMemorialDay){ this.setState({...this.state, showMemorialDay: showMemorialDay}); }

    /**
     *
     * @param {JSX.Element | string} html
     */
    setHTML(html){ this.setState({...this.state, html: html}); }

    /**
     * [Private]
     * @returns {string}
     * @constructor
     */
    GenerateDayElementID()
    {
        const date = this.props.date;
        return this.props.parent_id + "_" + String.prototype.format("cal_day_{0}_{1}", date.format("yyyy_MM"), paddingNumber(date.getDate(), 2));
    }

    /**
     * @private
     */
    onClick() {
        if(this.props.onClick != null) this.props.onClick(this.getDate(), this);
    }

    /**
     * @private
     * @returns {string}
     */
    getDateString = () => String.prototype.format("{0}-{1}-{2}",
        paddingNumber(this.date.getFullYear(), 4),
        paddingNumber(this.date.getMonth() + 1, 2),
        paddingNumber(this.date.getDay(), 2));

    /**
     * @private
     * @returns {JSX.Element}
     */
    render()
    {
        const {date, lunar, memorial, deactivate} = this.props;
        const { showLunar, showNohands, showMemorialDay } = this.state;

        let colorClass = null;
        let memorial_str = null;

        if(memorial != null)
        {
            if(memorial.isHoliday()) colorClass = styles.sun;
            if(memorial.getName() != null && showMemorialDay)
            {
                const holiday = memorial.isHoliday() ? "_holiday" : "";
                memorial_str = <div className={"cal_memorial" + holiday}>{memorial.getName()}</div>;
            }
            //TODO: 나중에 대체공휴일 계산하기
        }

        if(colorClass == null)
        {
            if(date.getDay() === 6) colorClass = styles.sat;
            else if(date.getDay() === 0) colorClass = styles.sun;
        }

        return (
            <div
                id={this.GenerateDayElementID()}
                className={"cal_day" + ( deactivate ? " " + styles.deactivate : "")}
                onClick={this.onClick}
                data-date={this.getDateString()}>
                    <p className={colorClass ?? undefined}>
                        {date.getDate()}
                        {lunar != null && showLunar ? <span>({lunar.isLeapMonth() ? "윤 " : ""}{lunar.getMonth()}.{lunar.getDay()})</span> : ""}
                    </p>
                    {memorial_str ?? ""}
                    {lunar != null && showNohands ? <div className={styles.cal_nohands}>손없는 날</div> : ""}
                    <div id={`${this.GenerateDayElementID()}_contents`} className={styles.daily_plan}>
                        {this.getHTML()}
                        {/*<p>일정 제목입니다. 일정 제목입니다. 일정 제목입니다. </p>
                        <p>일정 제목입니다. 일정 제목입니다. 일정 제목입니다. </p>*/}
                    </div>
            </div>
        )
    }
}

/**
 * 음력 데이터 (평달 - 작은달 :1,  큰달:2 )
 * (윤달이 있는 달 - 평달이 작고 윤달도 작으면 :3 , 평달이 작고 윤달이 크면 : 4)
 * (윤달이 있는 달 - 평달이 크고 윤달이 작으면 :5,  평달과 윤달이 모두 크면 : 6)
 * @type {number[][]}
 * @private
 */
const _lunarMonthTable = [
    [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 5, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],   /* 1901 */
    [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 3, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1],
    [2, 2, 1, 2, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 4, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
    [1, 5, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 5, 1, 2, 2, 1, 2, 2],   /* 1911 */
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
    [2, 2, 1, 2, 5, 1, 2, 1, 2, 1, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 3, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 5, 2, 2, 1, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],   /* 1921 */
    [2, 1, 2, 2, 3, 2, 1, 1, 2, 1, 2, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2],
    [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1],
    [2, 1, 2, 5, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 5, 1, 2, 1, 1, 2, 2, 1, 2, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],
    [1, 2, 2, 1, 1, 5, 1, 2, 1, 2, 2, 1],
    [2, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1],   /* 1931 */
    [2, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 2, 2, 1, 6, 1, 2, 1, 2, 1, 1, 2],
    [1, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 4, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
    [2, 2, 1, 1, 2, 1, 4, 1, 2, 2, 1, 2],
    [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 1, 2, 2, 4, 1, 1, 2, 1, 2, 1],   /* 1941 */
    [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
    [1, 1, 2, 4, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
    [2, 5, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 3, 2, 1, 2, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],   /* 1951 */
    [1, 2, 1, 2, 4, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 1, 2, 2, 1, 2, 2, 1, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
    [2, 1, 4, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 5, 2, 1, 2, 2],
    [1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],
    [2, 1, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1],
    [2, 1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],   /* 1961 */
    [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 2, 3, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2],
    [1, 2, 5, 2, 1, 1, 2, 1, 1, 2, 2, 1],
    [2, 2, 1, 2, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 1, 5, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
    [1, 2, 1, 1, 5, 2, 1, 2, 2, 2, 1, 2],   /* 1971 */
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2, 1],
    [2, 2, 1, 5, 1, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 1, 5, 2, 1, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 1],
    [2, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 6, 1, 2, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2],   /* 1981 */
    [2, 1, 2, 3, 2, 1, 1, 2, 2, 1, 2, 2],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
    [2, 1, 2, 2, 1, 1, 2, 1, 1, 5, 2, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1, 1],
    [2, 1, 2, 2, 1, 5, 2, 2, 1, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2],
    [1, 2, 1, 1, 5, 1, 2, 1, 2, 2, 2, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2],   /* 1991 */
    [1, 2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2],
    [1, 2, 5, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 2, 1, 5, 2, 1, 1, 2],
    [1, 2, 1, 2, 2, 1, 2, 1, 2, 2, 1, 2],
    [1, 1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 1, 2, 3, 2, 2, 1, 2, 2, 2, 1],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1],
    [2, 2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1],
    [2, 2, 2, 3, 2, 1, 1, 2, 1, 2, 1, 2],   /* 2001 */
    [2, 2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2],
    [1, 5, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 2, 1, 2, 2, 1, 1],
    [2, 1, 2, 1, 2, 1, 5, 2, 2, 1, 2, 2],
    [1, 1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2],
    [2, 1, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2],
    [2, 2, 1, 1, 5, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 1, 2, 2, 1, 2, 1, 1, 2, 1, 2, 1],   /* 2011 */
    [2, 1, 6, 2, 1, 2, 1, 1, 2, 1, 2, 1],
    [2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2],
    [1, 2, 1, 2, 1, 2, 1, 2, 5, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 2, 2, 1, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2],
    [2, 1, 1, 2, 3, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2],
    [2, 1, 2, 1, 2, 1, 1, 2, 1, 2, 1, 2],
    [2, 1, 2, 5, 2, 1, 1, 2, 1, 2, 1, 2],
    [1, 2, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1],   /* 2021 */
    [2, 1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2],
    [1, 5, 2, 1, 2, 1, 2, 2, 1, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1],
    [2, 1, 2, 1, 1, 5, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2],
    [1, 2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 2],
    [1, 2, 2, 1, 5, 1, 2, 1, 1, 2, 2, 1],
    [2, 2, 1, 2, 2, 1, 1, 2, 1, 1, 2, 2],
    [1, 2, 1, 2, 2, 1, 2, 1, 2, 1, 2, 1],
    [2, 1, 5, 2, 1, 2, 2, 1, 2, 1, 2, 1],   /* 2031 */
    [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 5, 2],
    [1, 2, 1, 1, 2, 1, 2, 1, 2, 2, 2, 1],
    [2, 1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 4, 1, 1, 2, 2, 1, 2],
    [2, 2, 1, 2, 1, 1, 2, 1, 1, 2, 1, 2],
    [2, 2, 1, 2, 1, 2, 1, 2, 1, 1, 2, 1],
    [2, 2, 1, 2, 5, 2, 1, 2, 1, 2, 1, 1],
    [2, 1, 2, 2, 1, 2, 2, 1, 2, 1, 2, 1],
    [2, 1, 1, 2, 1, 2, 2, 1, 2, 2, 1, 2],   /* 2041 */
    [1, 5, 1, 2, 1, 2, 1, 2, 2, 2, 1, 2],
    [1, 2, 1, 1, 2, 1, 1, 2, 2, 1, 2, 2]];

class LunarDate
{
    /**
     * @private
     * @type number
     */
    Year;
    /**
     * @private
     * @type number
     */
    Month;
    /**
     * @private
     * @type number
     */
    Day;
    /**
     * @private
     * @type number
     */
    LeapMonth;

    /**
     *
     * @param {number} Year
     * @param {number} Month
     * @param {number} Day
     * @param {number} LeapMonth
     */
    constructor(Year, Month, Day, LeapMonth) {
        this.Year = Year;
        this.Month = Month;
        this.Day = Day;
        this.LeapMonth = LeapMonth;
    }

    /**
     * @returns {number} 년
     */
    getYear(){ return this.Year; }
    /**
     * @returns {number} 월
     */
    getMonth(){ return this.Month; }
    /**
     * @returns {number} 일
     */
    getDay(){ return this.Day; }
    /**
     * @returns {number} 0: 평달, 1: 윤달
     */
    getLeapMonth(){ return this.LeapMonth; }

    /**
     *
     * @returns {boolean} 윤달 여부
     */
    isLeapMonth() { return this.getLeapMonth() === 1; }

    /**
     * 양력으로 계산합니다
     */
    toSolarDate()
    {
        const solar = LunarDate._CalculateLunarSolar(this.Year, this.Month, this.Day, false, this.LeapMonth);
        return new Date(solar.getYear(), solar.getMonth() - 1, solar.getDay());
    }

    /**
     *
     * @param {Date} date
     * @constructor
     * @return {LunarDate}
     */
    static CalculateLunarDate(date){ return LunarDate.CalculateLunar(date.getFullYear(), date.getMonth() + 1, date.getDate()); }
    /**
     *
     * @param {number} Year
     * @param {number} Month
     * @param {number} Day
     * @constructor
     * @return {LunarDate}
     * @throws
     */
    static CalculateLunar(Year, Month, Day){ return LunarDate._CalculateLunarSolar(Year, Month, Day, true, -1); }

    /**
     * 양력 <-> 음력 변환 함수 입니다 (계산은 1900년부터 2040까지만 지원합니다')
     * @private
     * @param {number} year
     * @param {number} month
     * @param {number} day
     * @param {boolean} isSolLun true: 양력->음력, false 음력->양력
     * @param {number} leapmonth 1: 윤달, 0: 평달 (type = 2 일때만 유효)
     * @returns {LunarDate}
     * @throws 날짜가 범위를 넘어갔을 때 예외가 발생합니다
     */
    static _CalculateLunarSolar(year, month, day, isSolLun, leapmonth)
    {
        if ( !year || year == 0 ||
            !month || month == 0 ||
            !day || day == 0 )
        {
            throw '날짜를 정확히 입력해주세요';
        }

        let solYear, solMonth, solDay;
        let lunYear, lunMonth, lunDay;
        let lunLeapMonth, lunMonthDay;
        let i, lunIndex;

        const solMonthDay = [31, 0, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        /* range check */
        if (year < 1900 || year > 2040)
        {
            throw '음/양력 계산은 1900년부터 2040년까지만 지원합니다';
        }

        /* 속도 개선을 위해 기준 일자를 여러개로 한다 */
        if (year >= 2000)
        {
            /* 기준일자 양력 2000년 1월 1일 (음력 1999년 11월 25일) */
            solYear = 2000;
            solMonth = 1;
            solDay = 1;
            lunYear = 1999;
            lunMonth = 11;
            lunDay = 25;
            lunLeapMonth = 0;

            solMonthDay[1] = 29;    /* 2000 년 2월 28일 */
            lunMonthDay = 30;   /* 1999년 11월 */
        }
        else if (year >= 1970)
        {
            /* 기준일자 양력 1970년 1월 1일 (음력 1969년 11월 24일) */
            solYear = 1970;
            solMonth = 1;
            solDay = 1;
            lunYear = 1969;
            lunMonth = 11;
            lunDay = 24;
            lunLeapMonth = 0;

            solMonthDay[1] = 28;    /* 1970 년 2월 28일 */
            lunMonthDay = 30;   /* 1969년 11월 */
        }
        else if (year >= 1940)
        {
            /* 기준일자 양력 1940년 1월 1일 (음력 1939년 11월 22일) */
            solYear = 1940;
            solMonth = 1;
            solDay = 1;
            lunYear = 1939;
            lunMonth = 11;
            lunDay = 22;
            lunLeapMonth = 1;

            solMonthDay[1] = 29;    /* 1940 년 2월 28일 */
            lunMonthDay = 29;   /* 1939년 11월 */
        }
        else
        {
            /* 기준일자 양력 1900년 1월 1일 (음력 1899년 12월 1일) */
            solYear = 1900;
            solMonth = 1;
            solDay = 1;
            lunYear = 1899;
            lunMonth = 12;
            lunDay = 1;
            lunLeapMonth = 0;

            solMonthDay[1] = 28;    /* 1900 년 2월 28일 */
            lunMonthDay = 30;   /* 1899년 12월 */
        }

        lunIndex = lunYear - 1899;

        /* eslint no-constant-condition: "off" */
        while (true)
        {
            if (isSolLun &&
                year == solYear &&
                month == solMonth &&
                day == solDay)
            {
                return new LunarDate(lunYear, lunMonth, lunDay, lunLeapMonth);
            }
            else if (!isSolLun &&
                year == lunYear &&
                month == lunMonth &&
                day == lunDay &&
                leapmonth == lunLeapMonth)
            {
                return new LunarDate(solYear, solMonth, solDay, -1);
            }

            /* add a day of solar calendar */
            if (solMonth == 12 && solDay == 31)
            {
                solYear++;
                solMonth = 1;
                solDay = 1;

                /* set monthDay of Feb */
                if (solYear % 400 == 0)
                    solMonthDay[1] = 29;
                else if (solYear % 100 == 0)
                    solMonthDay[1] = 28;
                else if (solYear % 4 == 0)
                    solMonthDay[1] = 29;
                else
                    solMonthDay[1] = 28;

            }
            else if (solMonthDay[solMonth - 1] == solDay)
            {
                solMonth++;
                solDay = 1;
            }
            else
                solDay++;

            /* add a day of lunar calendar */
            if (lunMonth == 12 &&
                ((_lunarMonthTable[lunIndex][lunMonth - 1] == 1 && lunDay == 29) ||
                    (_lunarMonthTable[lunIndex][lunMonth - 1] == 2 && lunDay == 30)))
            {
                lunYear++;
                lunMonth = 1;
                lunDay = 1;

                if (lunYear > 2043) {
                    throw "음력->양력 변환은 2043 년 까지만 제공됩니다";
                }

                lunIndex = lunYear - 1899;

                if (_lunarMonthTable[lunIndex][lunMonth - 1] == 1)
                    lunMonthDay = 29;
                else if (_lunarMonthTable[lunIndex][lunMonth - 1] == 2)
                    lunMonthDay = 30;
            }
            else if (lunDay == lunMonthDay)
            {
                if (_lunarMonthTable[lunIndex][lunMonth - 1] >= 3
                    && lunLeapMonth == 0)
                {
                    lunDay = 1;
                    lunLeapMonth = 1;
                }
                else
                {
                    lunMonth++;
                    lunDay = 1;
                    lunLeapMonth = 0;
                }

                if (_lunarMonthTable[lunIndex][lunMonth - 1] == 1)
                    lunMonthDay = 29;
                else if (_lunarMonthTable[lunIndex][lunMonth - 1] == 2)
                    lunMonthDay = 30;
                else if (_lunarMonthTable[lunIndex][lunMonth - 1] == 3)
                    lunMonthDay = 29;
                else if (_lunarMonthTable[lunIndex][lunMonth - 1] == 4 &&
                    lunLeapMonth == 0)
                    lunMonthDay = 29;
                else if (_lunarMonthTable[lunIndex][lunMonth - 1] == 4 &&
                    lunLeapMonth == 1)
                    lunMonthDay = 30;
                else if (_lunarMonthTable[lunIndex][lunMonth - 1] == 5 &&
                    lunLeapMonth == 0)
                    lunMonthDay = 30;
                else if (_lunarMonthTable[lunIndex][lunMonth - 1] == 5 &&
                    lunLeapMonth == 1)
                    lunMonthDay = 29;
                else if (_lunarMonthTable[lunIndex][lunMonth - 1] == 6)
                    lunMonthDay = 30;
            }
            else
                lunDay++;
        }
    }
}
//https://webprogrammer.tistory.com/?page=248
//https://kin.naver.com/qna/detail.nhn?d1id=1&dirId=1040202&docId=69098309&qb=amF2YXNjcmlwdCDslpHroKUg7J2M66Cl&enc=utf8&section=kin&rank=1&search_sort=0&spq=0


export class MemorialDay
{
    /**
     * @type {string | null}
     * @private
     */
    _name;
    /**
     * @type {number}
     * @private
     */
    _month
    /**
     * @type {number}
     * @private
     */
    _day;
    /**
     * @type {boolean}
     * @private
     */
    _isSolarLunar;
    /**
     * @type {boolean}
     * @private
     */
    _isHoliday;
    /**
     * @type {boolean}
     * @private
     */
    _isCalculateDay;
    /**
     *
     * @param {string|null} name
     * @param {number} month
     * @param {number} day
     * @param {boolean} isSolarLunar true: 양력, false: 음력
     * @param {boolean} isHoliday true: 빨간날, false: 안빨간날
     * @param {boolean} isCalculateDay true: day 가 상대값이 됩니다.
     */
    constructor(name, month, day, isSolarLunar, isHoliday, isCalculateDay) {
        this._name = name;
        this._month = month;
        this._day = day;
        this._isSolarLunar = isSolarLunar;
        this._isHoliday = isHoliday;
        this._isCalculateDay = isCalculateDay;
    }

    getName() { return this._name; }
    getMonth() { return this._month; }
    getDay() { return this._day; }
    isSolarLunar() { return this._isSolarLunar; }
    isHoliday() { return this._isHoliday; }
    isCalculateDay() { return this._isCalculateDay; }

    calculateDay(Year)
    {
        /* set the day before 설날 */
        if (_lunarMonthTable[Year - 1 - 1899][this._month -1] === 1) return 29;
        else if (_lunarMonthTable[Year - 1 - 1899][this._month - 1] === 2) return 30;
        else return 0;
    }
}

const _memorialDays = [
    new MemorialDay("신정", 1, 1, true, true, false),
    new MemorialDay(null, 12, 0, false, true, true),    /* 실시간으로 정해짐 */
    new MemorialDay("설날", 1, 1, false, true, false),
    new MemorialDay(null, 1, 2, false, true, false),
    new MemorialDay("삼일절", 3, 1, true, true, false),
    new MemorialDay("석가탄신일", 4, 8, false, true, false),
    new MemorialDay("어린이날", 5, 5, true, true, false),
    new MemorialDay("현충일", 6, 6, true, true, false),
    new MemorialDay("광복절", 8, 15, true, true, false),
    new MemorialDay(null, 8, 14, false, true, false),
    new MemorialDay("추석", 8, 15, false, true, false),
    new MemorialDay(null, 8, 16, false, true, false),
    new MemorialDay("개천절", 10, 3, true, true, false),
    new MemorialDay("한글날", 10, 9, true, true, false),
    new MemorialDay("성탄절", 12, 25, true, true, false),

    new MemorialDay("정월대보름", 1, 15, false, false, false),
    new MemorialDay("단오", 5, 5, false, false, false),
    new MemorialDay("국군의날", 10, 1, true, false, false),
    new MemorialDay("6.25전쟁일", 6, 25, true, false, false),
    new MemorialDay("삼짇날", 3, 3, false, false, false),
    new MemorialDay("물의날", 3, 22, true, false, false),
    new MemorialDay("만우절", 4, 1, true, false, false),
    new MemorialDay("식목일", 4, 5, true, false, false),
    new MemorialDay("장애인의날", 4, 20, true, false, false),
    new MemorialDay("과학의날", 4, 21, true, false, false),
    new MemorialDay("충무공탄신일", 4, 28, true, false, false),
    new MemorialDay("근로자의날·법의날", 5, 1, true, false, false),
    new MemorialDay("어버이날", 5, 8, true, false, false),
    new MemorialDay("스승의날", 5, 15, true, false, false),
    new MemorialDay("발명의날", 5, 19, true, false, false),
    new MemorialDay("바다의날", 5, 31, true, false, false),
    new MemorialDay("환경의날", 6, 5, true, false, false),
    new MemorialDay("유두", 6, 15, false, false, false),
    new MemorialDay("칠월칠석", 7, 7, false, false, false),
    new MemorialDay("제헌절", 7, 17, true, false, false),
    new MemorialDay("중양절", 9, 9, false, false, false),
    new MemorialDay("철도의날", 9, 18, true, false, false),
    new MemorialDay("소방의날", 11, 9, true, false, false)];

//레거시 커스텀 이벤트 만드는 법: https://dirask.com/posts/JavaScript-custom-event-listener-class-pYqaJp