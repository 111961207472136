import React, {useEffect, useState, useRef } from "react";
import {Outlet, Route} from "react-router-dom";
import {backPreventURL, PATH_FROM_MODULE, URL_FRONT_MODULE} from "./Define";
import ModuleInfo, {ModuleMenu, ModuleMenuCategory} from "modules/ModuleInfo";
import ApprovalInfo from "modules/approval/info/approval";
import ApprovalRouter from "./modules/approval/router/approval";
import CalendarInfo from "./modules/calendar/info";
import CalendarRouter from "./modules/calendar/router";
import EmailInfo from "./modules/email/info";
import EmailRouter from "./modules/email/router";
import MailplugInfo from "./modules/mailplug/info";
import MailplugRouter from "./modules/mailplug/router";
import BoardInfo from "./modules/board/info";
import BoardRouter from "./modules/board/router";
import AccountInfo from "./modules/approval/info/account";
import AccountRouter from "./modules/approval/router/account";
//import CertificateInfo from "./modules/certificate/info";
//import CertificateRouter from "./modules/certificate/router";
import TopHeader from "./component/TopHeader";
import SideMenu from "./component/SideMenu";
import {PathMaker, PathMakerMulti} from "utils/HSUtils";
import {useMenuUI} from "./context/MenuUIContext";
import {isEmptyString, randomKey} from "@hskernel/hs-utils";
import SettingsInfo, { SettingsModuleID } from "settings/info";
import SettingsRouter from "./settings/route";
import SideMenuMobile from "./component/SideMenuMobile";
import SideMenuShortcut from "./component/SideMenuShortcut";
import {useAuth} from "./context/AuthContext";
import TestRouter from "./test/router";

/**
 * 여기에 모듈정보를 등록합니다
 */
export const Info: ModuleInfo[] = [
    ApprovalInfo,
    CalendarInfo,
    //EmailInfo,
    MailplugInfo,
    BoardInfo,
    //CertificateInfo,
    AccountInfo,
    SettingsInfo,
]

/**
 * 여기에 모듈라우터를 등록합니다
 */
export const ModuleRouter = (location: Location) => {
    //console.log(location)
    return (
        <Route path={PATH_FROM_MODULE} element={RouteModule(location)}>
            {ApprovalRouter(false)}
            {CalendarRouter()}
            {/*{EmailRouter()}*/}
            {MailplugRouter()}
            {BoardRouter()}
            {/*CertificateRouter()*/}
            {AccountRouter()}
            {SettingsRouter()}
        </Route>
    )
}

/**
 * 여기에 시스템 라우터 등록
 * @param {Location} location
 * @constructor
 */
export const SystemRouter = (location: Location) => [

]

class ModuleMapItem
{
    public Title: string[];
    public Module: ModuleInfo;
    public Category?: ModuleMenuCategory;
    public Menu?: ModuleMenu;

    public getPopup = () => this.Menu != null ? this.Menu.popup : false;

    constructor(Title: string[], Module: ModuleInfo, Category?: ModuleMenuCategory, Menu?: ModuleMenu)
    {
        this.Title = Title;
        this.Category = Category;
        this.Menu = Menu;
        this.Module = Module;
    }
}

type RouteModuleState = {
    title: string,
    hideFrame: boolean,
    current?: ModuleMapItem,
}
export function getRouteModuleState(location: string): RouteModuleState
{
    let map = ModuleMap.get(location);
    while(map == null && !isEmptyString(location))
    {
        const index = location.lastIndexOf('/');
        if(index > -1)
        {
            location = location.remove(index);
            map = ModuleMap.get(location);
        }
        else break;
    }
    let title = "홈";
    let hideFrame = false;
    if(map)
    {
        if(map.getPopup()) hideFrame = true;
        title = `${title} > ${map.Title.join(" > ")}`;
    }
    //else hideFrame = true;

    return { title: title, hideFrame: hideFrame, current: map };
}
/**
 * 모듈 UI
 * @constructor
 */
export const RouteModule = (location: Location) =>
{
    const [loc, setLoc] = useState<RouteModuleState>(getRouteModuleState(location.pathname));

    //console.log(loc);
    useEffect(() => setLoc(getRouteModuleState(location.pathname)), [location]);

    //Context 적용을 위해 한번 컴포넌트로 감쌈!!
    return loc.hideFrame ? <Outlet/> : <UIFrame title={loc.title} item={loc.current}/>
}

type UIFrameProps = {
    title: string,
    item?: ModuleMapItem
}


const UIFrame = ({title, item}: UIFrameProps) =>
{
    const {menuUI} = useMenuUI();
    const [menuIsClick, setMenuIsClick] = useState(false);


    return menuUI.hideAll ? <Outlet/> : (
        <div className="layout-fluid container_frame">
            {menuUI.hideTop ? "" : <TopHeader backPreventURL={backPreventURL} onIsClick={setMenuIsClick} isClick={menuIsClick}/>}
            <div className="contents_page">
                {menuUI.hideShortcut || item == null ? "" : <SideMenuShortcut menu={item.Menu} module={item.Module}/>}
                {menuUI.hideSide ? "" : <><SideMenu/><SideMenuMobile isClick={menuIsClick} onIsClick={setMenuIsClick}/></>}
                <div className="page_body">
                    <div className="card common_margin">
                        {menuUI.hideLocate ? "" :
                            <div className="card-header">
                                <p className="small">{title}</p>
                            </div>
                        }
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ModuleMap = new Map<string, ModuleMapItem>();

function getCategory(info: ModuleInfo, root: string)
{
    const path = PathMakerMulti('/', root, info.id);
    return info.category.map((category) =>
    {
        const path_category = PathMaker(path, category.path ?? "");
        ModuleMap.set(path_category, new ModuleMapItem([info.title, category.title], info, category, undefined));

        category.menu?.map((menu, i) => AddMenu(path_category, info, menu, [info.title, category.title], i));
    })
}
function AddMenu(path_parent: string, module: ModuleInfo, menu: ModuleMenu, title_parent: any[], i: number)
{
    if(menu.id == null) menu.id = randomKey(i);
    const path_menu = PathMaker(path_parent, menu.path ?? "");
    ModuleMap.set(path_menu, new ModuleMapItem([...title_parent, menu.title], module, undefined, menu));
    //재귀함수로 메뉴 추가
    if(menu.menu != null) menu.menu.map((subMenu, i) => AddMenu(path_menu, module, subMenu, [...title_parent, menu.title], i));
}

/**
 * 라우터 맵 초기화 (맨 처음 호출요망!!)
 */
export function InitMap()
{
    getCategory(SettingsInfo, SettingsModuleID);
    Info.map((info) => getCategory(info, URL_FRONT_MODULE));
}

const infoMap = new Map<string, ModuleInfo>();
Info.map((module) => infoMap.set(module.id, module));
/**
 * 모듈을 가져 옵니다
 * @param {string} module 모듈 ID 입니다
 * @returns {ModuleInfo | undefined} 해당 모듈이 존재하지 않을 때 undefined 를 반환 합니다
 */
export const getModule = (module: string): ModuleInfo | undefined => infoMap.has(module) ? infoMap.get(module) : undefined;

/**
 * 모듈로 데이터를 전송합니다
 * @param {string} module 모듈 ID 입니다
 * @param {string} data 데이터 입니다
 * @returns {boolean} 데이터 처리 결과값 입니다
 */
export function sendModuleData(module: string, data: string): boolean
{
    const Module = getModule(module);
    if (Module != null && Module.onDataReceive != null) return Module.onDataReceive(data);
    return false;
}
