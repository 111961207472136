import axios from "axios";
import {URL_API_COMPANY_INFO} from "Define";
import {Expose} from "class-transformer";

export default class CompanyData
{
     @Expose() public Name: string;
     @Expose() public NameEnglish: string;

    //TODO: 예외처리 필요!!
    static async GetCompany(): Promise<CompanyData>
    {
        const send = await axios.get<CompanyData>(URL_API_COMPANY_INFO);
        return send.data;
    }
}